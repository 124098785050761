import { Button, Stack, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { StaffService } from "../../../services/StaffService";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import Paper from "@mui/material/Paper";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { LibraryService } from "../../../services/libraryService";
import { C } from "../../util/C";
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import { autoTable } from "jspdf-autotable";
import { AddStandarsService } from "../../../services/addStandardsService";
import { useNavigate } from "react-router-dom";

export default function Books() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  React.useEffect(() => {
    onBookLoad();
  }, []);
  const [columns, setColumns] = React.useState([
    {
      field: "no",
      headerName: "NO",
      // renderHeader: () => <strong style={{ fontSize: 18 }}>{"NO "}</strong>,
      flex: 0.5,
      editable: false,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      editable: false,
      sortable: false,
      field: "bookNumber",
      headerName: "BOOK NUMBER",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"BOOK NUMBER"}</strong>
      ),
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      editable: false,
      sortable: false,
      field: "bookTitle",
      headerName: "BOOK TITLE",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"BOOK TITLE "}</strong>
      ),
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "type",
      headerName: "TYPE",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"TYPE "}</strong>,
      flex: 1,
      editable: false,
      sortable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      editable: false,
      sortable: false,
      field: "authorApplication",
      headerName: "AUTHOR/PUBLICATION",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"AUTHOR/PUBLICATION "}</strong>
      ),
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      editable: false,
      sortable: false,
      field: "condition",
      headerName: "CONDITION ",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"CONDITION "}</strong>
      ),
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      editable: false,
      sortable: false,
      field: "borrowedBy",
      headerName: "BORROWED BY",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"BORROWED BY"}</strong>
      ),
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "date",
      headerName: "DATE",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"DATE"}</strong>,
      flex: 1,
      editable: false,
      sortable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
  ]);
  const [rows, setRows] = React.useState([]);
  const [book, setBook] = React.useState([]);
  const [bookCount, SetBookCount] = React.useState([]);
  const[loading, setLoading] = React.useState(false);

  const onBookLoad = async () => {
    setLoading(true);
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await LibraryService.borrowedBooklist(data.yearName);
      console.log(response.data.data);
      let book_array = [];
      SetBookCount(response.data.data.bookCounts);
      response.data.data.bookBorroweds.forEach(function (rec, i) {
        console.log(rec);
        book_array.push({
          id: i + 1,
          no: i + 1,
          bookNumber: rec.bookNumber,
          bookTitle: rec.bookTitle,
          type: rec.type,
          authorApplication: rec.authorPublication,
          condition: rec.condition,
          date: rec.date,
          borrowedBy: rec.ssid + "-" + rec.studentName,
        });
      });
      setRows(book_array);
      console.log(rows);
      setLoading(false);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };

  // for calculating total nuber of books
  var no = rows.length;
  // console.log(no);

  const [Standardname, setStandardName] = React.useState();

  let navigate = useNavigate();
  const onLaunchPad = () => {
    console.log("launchpad");
    navigate("/launchpad", { replace: true });
  };
  const academicYear = JSON.parse(localStorage.getItem("academicYear"))
  const schoolName = JSON.parse(localStorage.getItem("schoolDetails"))
  
  const generatePDF = () => {
    const doc = new jsPDF("landscape");
    const sch = schoolName.schoolNameOnDocs.toUpperCase();
    const acad = academicYear.yearName;
    const img = schoolName.schoolLogoUrl;
    doc.setFont("helvetica", "bold");

    var fontSize = 18;
    var text = `${sch}`;
    var pageWidth = doc.internal.pageSize.getWidth();
    var textWidth =
      (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
    var centerX = (pageWidth - textWidth) / 2;
    doc.text(text, centerX, 15);

    doc.addImage(img ,"JPEG", 15,5,27,27);
    doc.setFontSize(10);
    doc.text(` ${acad}`, 250, 15);
    doc.setFontSize(15);
    doc.text("LIBRARY BOOKS BORROWED", 105, 35);

    const tableData = rows.map((row) => [
      row.id,
      row.bookNumber,
      row.bookTitle,
      row.type,
      row.authorApplication,
      row.condition,
      row.date,
      row.borrowedBy,
    ]);

    doc.autoTable({
      startY: 40,
      tableLineColor: [0,0,0],
      head: [
        [
          "NO",
          "BOOK NUMBER",
          "BOOK TITLE",
          "TYPE",
          "AUTHOR/PUBLICATION",
          "CONDITION",
          "DATE",
          "BORROWED BY",
        ],
      ],
      body: tableData,
      tableLineWidth: 0.45,
      theme: "grid",
      headStyles: {
        valign: "middle",
        backgroundColor: "lightgray",
        halign: "center",
       
      },
      bodyStyles: {
        valign: "middle",
        halign: "center",
        lineColor: [0,0,0],
      },
    });
    doc.save("Library Books Borrow List - "+acad+".pdf" )
  }

  const fileType = "xlsx";
  const exportToCSV = () => {
    const visible = XLSX.utils.json_to_sheet(rows);
    const wb = { Sheets: { product: visible }, SheetNames: ["product"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    filesaver.saveAs(data, "Library Book Borrow List" + ".xlsx");
  };

  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="library" /> */}
      {loading ? (
         <div style={{padding:"180px 0px", display:"flex", justifyContent:"center",}}>
         <div className = "spinner-container">
         <div className = "loading-spinner">
         </div>
       </div>
       </div>
      ) : (
<Box>
      <Box sx={{ flexGrow: 1, ml: 5, mt: 2 }}>
        <Grid container>
          <Grid item xs={12}>
            {/* <Item > */}
            <Grid container spacing={0}>
              {bookCount.map((element) => (
                <Grid item xs={1.3} sx={{ cursor: "pointer", mt: 2 }}>
                  <Button
                    variant="contained"
                    id={element.standared}
                    name={element.standared}
                    sx={{
                      fontWeight: "fontWeightBold",
                      backgroundColor: "#aaecac",
                      width: "150px",
                      border: "2px solid black",
                      color: "black",
                      "&:hover": {
                        backgroundColor: "#035e06",
                        color: "white",
                        fontWeight: "bold",
                      },
                    }}
                  >
                    {element.standared}
                  </Button>
                  <Item
                    sx={{
                      fontWeight: "fontWeightBold",
                      border: "2px solid black",
                      borderTop: "none",
                      borderRadius: "1px",
                      width: "130px",
                      color: "black",
                    }}
                  >
                    {element.count}
                  </Item>
                </Grid>
              ))}
              <Grid item xs={2} border="2px solid black" sx={{ mt: 2 }}>
                {/* <Item
                    sx={{
                      fontWeight: "fontWeightBold",
                      // border: "2px solid black",
                      borderRadius: "1px",
                      color: "black",
                      backgroundColor: "lightblue",
                      width: "92%",
                    }}
                  > */}
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{
                    color: "black",
                    backgroundColor: "skyblue",
                    textAlign: "center",
                  }}
                >
                  ALL STD
                </Typography>
                {/* </Item> */}
                <Item
                  sx={{
                    fontWeight: "fontWeightBold",
                    borderRadius: "1px",
                    color: "black",
                    // backgroundColor: "#49F477",
                    width: "92%",
                    // fontSize: 20,
                  }}
                >
                  {no}
                </Item>
              </Grid>
            </Grid>
            {/* </Item> */}
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            mt: 2,
            fontWeight: "bold",
            backgroundColor: "lightblue",
          }}
        >
          LIBRARY BOOKS BORROWED
        </Typography>
      </Box>
      <Box
        sx={{
          height: 450,
          mt: 2,
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "#c0c0c0",
            //  color:'white',
            fontWeight: "bold",
            fontSize: "15px",
            border: "1px solid white",
          },
        }}
      >
        <DataGrid
          showColumnRightBorder={true}
          showCellRightBorder={true}
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          disableColumnMenu
        />
      </Box>

   <Box sx={{display:"flex", justifyContent:"space-evenly",mt:2}}>
        <Button
          variant="contained"
          color="error"
          sx={{
            color: "white",
            borderRadius: "30px",
            fontSize: 15,
            width: "140px",
            fontWeight: "bold",
          }}
          onClick={generatePDF}
        >
          PDF
        </Button>
        <Button
          variant="contained"
          color="success"
          sx={{
            color: "white",
            borderRadius: "30px",
            fontSize: 15,
            width: "140px",
            fontWeight: "bold",
          }}
          onClick={exportToCSV}
        >
          EXCEL
        </Button>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#ca424f",
            borderRadius: "30px",
            fontSize: 15,
            width: "140px",
            fontWeight: "bold",
            "&:hover": { backgroundColor: "#ca424f" },
          }}
          onClick={onLaunchPad}
        >
          EXIT
        </Button>
        </Box>
     
      </Box>
      )}
    </React.Fragment>
  );
}
