import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  Alert,
  Autocomplete,
  FormControl,
  InputLabel,
  List,
  ListItem,
  Modal,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { C } from "../../util/C";
import { AddStandarsService } from "../../../services/addStandardsService";
import { StandardDivision } from "../../../services/StandardDIvision";
import { StudentService } from "../../../services/studentService";
import { LibraryService } from "../../../services/libraryService";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";

const StandardItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid",
}));

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));

const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color: "white",
  },
}));
const ItemD = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#03dff9",
  "&:hover": {
    background: "#038da6",
    color: "white",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid red",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};

export default function BasicGrid() {
  const [bookStatus, setBookStatus] = React.useState("");
  const [student, setStudent] = React.useState("");
  const [value, setValue] = React.useState(new Date());
  const [open, setOpen] = React.useState(false);
  const [handleOpen, setHandleOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [Standard, setStandard] = React.useState([]);
  React.useEffect(() => {
    onstandardsLoad();
    loadBook();
  }, []);

  const onstandardsLoad = async () => {
    setLoading(true);
    let data = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      setStandard(response.data.data);
      setLoading(false);
    } catch (error) {
      C.c(error);
    }
  };

  const [libraryBook, SetLibraryBooks] = React.useState([]);

  const loadBook = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await LibraryService.unBorrowedBooklist(data.yearID);
      SetLibraryBooks(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const [Standardname, setStandardName] = React.useState();
  const [StandardID, setStandardID] = React.useState();

  const [DivisionName, setDivisionName] = React.useState();
  const [Division, setDivision] = React.useState([]);
  async function onStandardSelect(e, index) {
    e.preventDefault();
    setStandardName(e.target.textContent);
    setStandardID(e.target.id);
    C.c(e.target.textContent);
    let highlightedStandardList = [...Standard];
    highlightedStandardList = highlightedStandardList.map(
      ({ isSelected, ...highlightedStandardList }) => highlightedStandardList
    );
    setStandard(highlightedStandardList);
    highlightedStandardList[index]["isSelected"] = highlightedStandardList[
      index
    ]["isSelected"]
      ? false
      : true;
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await StandardDivision.StandardDivisionWiseStandard(
        e.currentTarget.id,
        data.yearName,
        data.yearID
      );
      C.c(response.data.data);
      setDivision(response.data.data);
      setBooks([]);
    } catch (error) {
      C.c(error.message);
    }
  }

  const [studentList, setstudentList] = React.useState([]);

  async function onDivisionSelect(e, index) {
    C.c(e.target.id);
    C.c(StandardID);
    setDivisionName(e.target.id);
    let highlightDivision = [...Division];
    highlightDivision = highlightDivision.map(
      ({ isSelected, ...highlightDivision }) => highlightDivision
    );
    setDivision(highlightDivision);
    highlightDivision[index]["isSelected"] = highlightDivision[index][
      "isSelected"
    ]
      ? false
      : true;

    let data = JSON.parse(localStorage.getItem("academicYear"));

    let students = [];
    try {
      let response = await StudentService.approveddivisionstudentListAll(
        data.yearName,
        e.target.id,
        StandardID
      );
      setstudentList(response.data.data.sort((a,b)=> a.studentName.localeCompare(b.studentName)));
    } catch (error) {
      C.c(error.message);
    }
  }

  const onsStandardClose = (e, v) => {
    // debugger;
    C.c(v);
  };

  const onStudentSelect = (e) => {
    setStudent(e.target.value);
    console.log(e.target.value);
  };

  const [selectedBook, setSelectedBook] = React.useState();

  const onSelectBook = (e, v) => {
    console.log(v);
    const found = allBooks.find((element) => element.bookNumber === v.no);

    console.log(found);
    setSelectedBook(found);
  };
  const onBookStatus = (event) => {
    setBookStatus(event.target.value);
    if (event.target.value === "return_book") {
      console.log("return_book");
      borrowedBooklists();
    }
    if (event.target.value === "borrow_book") {
      console.log("borrow_book");
      unBorrowedBooklists();
    }
  };

  const [allBooks, setAllBooks] = React.useState("");
  const [books, setBooks] = React.useState("");

  const unBorrowedBooklists = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let ub = [];
    try {
      let response = await LibraryService.unBorrowedBooklist(data.yearID);
      setAllBooks(response.data.data);
      response.data.data.forEach((element) => {
        if (element.status == "false" || element.status == null)
          ub.push({
            label: element.bookTitle + " --- " + element.bookNumber,
            // name: element.bookTitile,
            no: element.bookNumber,
          });
      });
      setBooks(ub);
      console.log(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const borrowedBooklists = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    console.log(student);
    let ub = [];
    try {
      let response = await LibraryService.GetStudentBooks(
        student,
        data.yearName
      );
      setAllBooks(response.data.data.libraryBooksBorroweds);
      response.data.data.libraryBooksBorroweds.forEach((element) => {
        console.log(element);
        if (element.borrowedstatus == "true") {
          ub.push({
            //  name: element.bookTitile,
            label: element.bookTitle + " -- " + element.bookNumber,
            no: element.bookNumber,
          });
        }
      });
      setBooks(ub);
      console.log(ub);
      // console.log(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onSave = async (e) => {
    console.log("library");
    let data = JSON.parse(localStorage.getItem("academicYear"));

    let studentFound = studentList.find((rec) => rec.studentUSN === student);
    console.log(studentFound);

    if (bookStatus == "return_book") {
      let borrowObj = {
        libraryBookBorrowedID: selectedBook.libraryBookBorrowedID,
        bookNumber: selectedBook.bookNumber,
        condition: selectedBook.bookCondition,
        bookTitle: selectedBook.bookTitle,
        borrowedon: selectedBook.borrowedon,
        returnedOn: value.toDateString(),
        borrowedstatus: "false",
        condition: selectedBook.bookCondition,
        division: DivisionName,
        ssid: studentFound.studentUSN,
        standard: Standardname,
        studentName: studentFound.studentName,
        yearID: data.yearID,
        yearName: data.yearName,
      };

      let BookFound = libraryBook.find(
        (rec) => rec.bookNumber === selectedBook.bookNumber
      );
      // console.log(BookFound);

      let bookObj = {
        libraryID: BookFound.libraryID,
        bookNumber: BookFound.bookNumber,
        bookTitle: BookFound.bookTitle,
        bookType: BookFound.bookType,
        bookCondition: BookFound.bookCondition,
        bookAuthorPublication: BookFound.bookAuthorPublication,
        bookImageCoverPath: BookFound.bookImageCoverPath,
        status: "false",
      };
      // console.log(bookObj);
      try {
        let response = await LibraryService.createLibraryBook(bookObj);
        console.log(response.data.data);
      } catch (error) {
        console.log(error);
      }
      // console.log(borrowObj);
      try {
        let response = await LibraryService.createBookBorrow(borrowObj);
        console.log(response);
        setHandleOpen(true);
        setTimeout(() => {
          setHandleOpen(false);
        }, 2000);
      } catch (error) {
        console.log(error);
      }
    } else if (bookStatus == "borrow_book") {
      let borrowObj = {
        bookNumber: selectedBook.bookNumber,
        bookTitle: selectedBook.bookTitle,
        borrowedon: value.toDateString(),
        borrowedstatus: "true",
        condition: selectedBook.bookCondition,
        division: DivisionName,
        ssid: studentFound.studentUSN,
        standard: Standardname,
        studentName: studentFound.studentName,
        title: selectedBook.bookTitle,
        yearID: data.yearID,
        yearName: data.yearName,
      };
      // console.log(selectedBook);
      let bookObj = {
        libraryID: selectedBook.libraryID,
        bookNumber: selectedBook.bookNumber,
        bookTitle: selectedBook.bookTitle,
        bookType: selectedBook.bookType,
        bookCondition: selectedBook.bookCondition,
        bookAuthorPublication: selectedBook.bookAuthorPublication,
        bookImageCoverPath: selectedBook.bookImageCoverPath,
        status: "true",
      };
      try {
        let response = await LibraryService.createLibraryBook(bookObj);
        console.log(response.data.data);
      } catch (error) {
        console.log(error);
      }
      // console.log(borrowObj);
      try {
        let response = await LibraryService.createBookBorrow(borrowObj);
        // alert("Sucessfully Saved");
        console.log(response);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 2000);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Something Went wrong, please try again later !");
    }
    setStudent("");
    setBookStatus("");
    setBooks([]);
  };

  let navigate = useNavigate();
  const onLaunchPad = () => {
    console.log("launchpad");
    navigate("/launchpad", { replace: true });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* <LaunchpadTopBar id="library" /> */}
      {loading ? (
        <div
          style={{
            padding: "180px 0px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        </div>
      ) : (
        <Box>
          <Box sx={{ flexGrow: 1, mt: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <Item>
                  <Grid container spacing={1} sx={{ minHeight: 40 }}>
                    {Standard.map((element, index) => (
                      <Grid item xs={2} sx={{ cursor: "pointer" }}>
                        <ItemS
                          id={element.standardID}
                          name={element.standardName}
                          onClick={(e) => {
                            onStandardSelect(e, index);
                          }}
                          className={element.isSelected ? "Selected" : ""}
                          sx={{ fontWeight: "bold" }}
                        >
                          {element.standardName}
                        </ItemS>
                      </Grid>
                    ))}
                  </Grid>
                </Item>
              </Grid>
              <Grid item xs={4}>
                <Item>
                  <Grid container spacing={0} sx={{ height: 40 }}>
                    {Division.map((element, index) => (
                      <Grid item xs={2} sx={{ cursor: "pointer" }}>
                        <ItemD
                          sx={{
                            fontWeight: "bold",
                          }}
                          onClick={(e) => {
                            onDivisionSelect(e, index);
                          }}
                          className={element.isSelected ? "selected" : ""}
                          id={element.divisionName}
                        >
                          {" "}
                          {element.divisionName}
                        </ItemD>
                      </Grid>
                    ))}
                  </Grid>
                </Item>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={2}>
            {/* <Grid item display={"inline-flex"} xs={12}>
          <Box m={2}>
            <Autocomplete
              disablePortal
              fullWidth
              id="standard"
              options={Standard}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="standard" />
              )}
              onClose={onsStandardClose}
            />
          </Box>
          <Box m={2}>
            <Autocomplete
              fullWidth
              disablePortal
              id="division"
              options={top100Films}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="division" />
              )}
            />
          </Box>
          <Box m={2}>
            <Autocomplete
              fullWidth
              disablePortal
              id="student"
              options={top100Films}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="student" />
              )}
            />
          </Box>
        </Grid> */}

            <Grid item xs={10}>
              <Box m={2}>
                <FormControl
                  disabled={studentList.length > 0 ? false : true}
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-label">
                    Student Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={student}
                    label="Student Name"
                    onChange={onStudentSelect}
                  >
                    {studentList.map((rec) => (
                      <MenuItem value={rec.studentUSN}>
                        {rec.studentName.toUpperCase()}-
                        {rec.studentUSN.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box m={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Book Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={bookStatus}
                    label="Book Status"
                    onChange={onBookStatus}
                  >
                    <MenuItem value="return_book">Return Book</MenuItem>
                    <MenuItem value="borrow_book">Borrow Book</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box m={2}>
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                readOnly
                label="Basic example"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider> */}
              </Box>
            </Grid>

            <Grid item xs={10}>
              <Box m={2}>
                <Autocomplete
                  disabled={books.length > 0 ? false : true}
                  fullWidth
                  disablePortal
                  id="combo-box-demo"
                  options={books}
                  onChange={onSelectBook}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Book" />
                  )}
                />
                {/* <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Book</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={book}
                label="Select Book"
                onChange={onSelectBook}
              >
                {unBorrowBook.map((rec) => (
                  <MenuItem value={rec.bookNumber}>{rec.bookTitle}</MenuItem>
                ))}
              </Select>
            </FormControl> */}
              </Box>
            </Grid>

            <Modal
              open={open}
              // onClose={() => setSucess(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div sx={style}>
                <SucessModal
                  text="Book issued to Student Successfully"
                  name="Book issued to Student Successfully"
                />
              </div>
            </Modal>

            <Modal
              open={handleOpen}
              // onClose={() => setSucess(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div sx={style}>
                <SucessModal
                  text="Book Returned to Library Successfully"
                  name="Book Returned to Library Successfully"
                />
              </div>
            </Modal>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <Button
              sx={{
                width: "120px",
                borderRadius: "30px",
                fontSize: 15,
              }}
              onClick={onSave}
              variant="contained"
            >
              Save
            </Button>

            <Button
              onClick={onLaunchPad}
              variant="contained"
              sx={{
                backgroundColor: "#ca424f",
                fontSize: 15,
                width: "120px",
                borderRadius: "30px",
                "&:hover": { backgroundColor: "#ca424f" },
              }}
            >
              exit
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
