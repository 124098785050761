import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TopBar from '../../MainTopbar/Appbar';
import Fees from './Fees';
import Collection from './FeesCollection';
import Edit from './EditStudentsFeesTag';
import Edit1 from './EditStudentsFeesTag1';
import Reports from './FeesReport';
import { useNavigate } from 'react-router-dom';

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ height:"100%", width:"100%" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs() {

React.useEffect(()=>{
  window.history.pushState(null, null, window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, null, window.location.href);
    };
},[])

  const [value, setValue] = React.useState(0);
//   const [highlight, setHighlight] = React.useState(false);

  const Click = (newValue) => {
    setValue(newValue);
    // setHighlight(newValue);
    console.log(value);
    // console.log(highlight);
  };

  return (
    <div>
      <TopBar/>
         <Box sx={{border:"2px solid black",
         background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
        }}>
            <Grid
          container
          spacing={1}
          py={0.5}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="warning"
              size="small"
              sx={{ fontWeight: "bold" }}
              onClick={()=> Click(0)}
            >
              fees
            </Button>
          </Grid>

          <Grid item xs={1.5}>
            <Button
              size="small"
              sx={{
                color: "black",
                fontWeight: "bold",
                backgroundColor: value == 1 ? 'black' : '' ,
                color: value == 1 ? 'white' : 'black' ,
                "&:hover": { backgroundColor: "black", color: "white" },
              }}
              // onClick={()=> Click(1)}
            >
              Fees collection
            </Button>
          </Grid>

          <Grid item xs={2.5}>
            <Button
              size="small"
              sx={{
                color: "black",
                fontWeight: "bold",
                backgroundColor: value == 2 ? 'black' : '' ,
                color: value == 2 ? 'white' : 'black' ,
                "&:hover": { backgroundColor: "black", color: "white" },
              }}
              onClick={()=> Click(2)}
            >
              Edit students fees tag(in group)
            </Button>
          </Grid>

          <Grid item xs={2.5}>
            <Button
              fullWidth
              size="small"
              sx={{
                color: "black",
                fontWeight: "bold",
                backgroundColor: value == 3 ? 'black' : '' ,
                color: value == 3 ? 'white' : 'black' ,
                "&:hover": { backgroundColor: "black", color: "white" },
              }}
              onClick={()=> Click(3)}
            >
              Edit individual student's fees tag
            </Button>
          </Grid>

          <Grid item xs={2}>
            <Button
              fullWidth
              size="small"
              sx={{
                color: "black",
                fontWeight: "bold",
                backgroundColor: value == 4 ? 'black' : '' ,
                color: value == 4 ? 'white' : 'black' ,
                "&:hover": { backgroundColor: "black", color: "white" },
              }}
              onClick={()=> Click(4)}
            >
              Fee reports
            </Button>
          </Grid>
        </Grid>
</Box>

      <CustomTabPanel value={value} index={0}>
      <Fees/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
      <Collection/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
    <Edit/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
    <Edit1/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={4}>
    <Reports/>
      </CustomTabPanel>
     
      </div>
  );
}
