import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Button,
  Fade,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Topbar from "../../MainTopbar/Topbar";
import { AddStandarsService } from "../../../services/addStandardsService";
import { FeeStructure } from "../../../services/FeeStructure";
import CancelIcon from "@mui/icons-material/Cancel";
import Backdrop from "@mui/material/Backdrop";
import { useNavigate } from "react-router-dom";
import { BuildSchoolControll } from "../../../services/buildSchoolControllService";
import SucessModal from "../Dashboard/popup/sucessPopUp";
import AlertModal from "../Dashboard/popup/alertPopUp";
import DeleteModal from "../Dashboard/popup/deletePopUp";
import { SixKOutlined } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
// import CancelIcon from "@mui/icons-material/Cancel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  height: 370,
  textAlign: "center",
  border: "2px solid",
  overflowY: "scroll",
  background: "transparent",
}));

export default function BasicGrid() {
  let navigate = useNavigate();

  // * =======================window Pop=====================================
  // *delete
  const [openDelete, setDelete] = React.useState(false);
  const handleDeleteOpen = () => setDelete(true);
  const handleDeleteClose = () => setDelete(false);

  // *alert
  const [openAlert, setAlert] = React.useState(false);
  const [alertVal, setAlertVal] = React.useState("");
  const handleAlertOpen = () => setAlert(true);
  const handleAlertClose = () => setAlert(false);

  // *sucess
  const [openSucess, setSucess] = React.useState(false);
  const [sucessVal, setSucessVal] = React.useState("");
  const handleSucessOpen = () => setSucess(true);
  const handleSucessClose = () => setSucess(false);

  // * =======================window Popup end=====================================
  const [status, setStatus] = React.useState();

  const onLoadBuildSchool = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolControll.buildSchoolStatusListAll(
        data.yearName
      );
      // console.log(response.data.data[0]);
      setStatus(response.data.data[4].buildSchoolStatus);
      setFiveId(response.data.data[4].buildSchoolID);
      setSixId(response.data.data[5].buildSchoolID);
    } catch (error) {
      console.error(error);
    }
  };

  const [columns, setColumns] = React.useState([
    // { field: "id", headerName: "ID", width: 90, hidden: true },
    {
      field: "feestitleName",
      headerName: "Fees Name",
      flex: 1,
      // editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "amount",
      headerName: "Annual Amount",
      flex: 1,
      editable: true,
      type: "number",
      // alignIte: "center",
      valueGetter: (r) => {
        // console.log(r.value);
        if (r.value < 0) {
          return;
        } else {
          return r.value;
        }
      },
      headerAlign: "center",
      align: "center",
    },
  ]);
  const [rows, setRows] = React.useState([
    // { id: 1, feestitleName: "Snow", amount: "Jon" },
  ]);

  React.useEffect(() => {
    onStandardLoad();
    onLoadBuildSchool();
    onFeeReviewLoad();
  }, []);

  const [standards, setStandards] = React.useState([]);
  const onStandardLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    // console.log("hello standard");

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      //console.log(response.data.data);

      setStandards(response.data.data);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };
  const [fee, setFee] = React.useState([]);

  const onFeeReviewLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await FeeStructure.feesStructureListAll(data.yearName);
      console.log(response.data.data);

      setFee(response.data.data);
    } catch (error) {
      console.log(error);
      alert("Failure");
    }
  };

  const [selectedStandard, setSelectedStandard] = React.useState("");
  const [selectedStandardID, setSelectedStandardID] = React.useState("");
  const [tutionFee, settutionFee] = React.useState(0);
  const [totalFeesAmount, setTotalFeesAmount] = React.useState(0);

  // const [standardListAll, updateStandard] = React.useState([]);
  const [standardID, updateStandardID] = React.useState("");

  async function onStandardSelect(e, index) {
    setSelectedStandard(e.currentTarget.name);
    setSelectedStandardID(e.currentTarget.id);

    updateStandardID(e.target.value);
    let highlightedStandardList = [...standards];
    highlightedStandardList = highlightedStandardList.map(
      ({ isSelected, ...highlightedStandardList }) => highlightedStandardList
    );
    setStandards(highlightedStandardList);
    // console.log(e.currentTarget.value, e.currentTarget.name)
    // onselectedSubID(e.currentTarget.value);
    // onselectedSub(e.currentTarget.name);
    highlightedStandardList[index]["isSelected"] = highlightedStandardList[
      index
    ]["isSelected"]
      ? false
      : true;

    setStandards(highlightedStandardList);
    let feeData = [],
      feesArray = [];
    let yearData = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await FeeStructure.feeStructureListAll(
        e.target.id,
        yearData.yearName
      );

      feesArray = response.data.data;

      const indexTA = feesArray.findIndex(
        (prop) => prop.feestitleName === "Total Fees Amount"
      );
      const foundTotalAmount = feesArray.splice(indexTA, 1);
      setTotalFeesAmount(foundTotalAmount[0].amount);

      const indexTF = feesArray.findIndex(
        (prop) => prop.feestitleName === "Tution Fee"
      );
      const foundTutionFee = feesArray.splice(indexTF, 1);
      settutionFee(foundTutionFee[0].amount);

      console.log(foundTotalAmount);
      console.log(foundTutionFee);
      console.log(feesArray);

      feesArray.forEach((rec) => {
        feeData.push({
          id: rec.feesStructureID,
          feestitleName: rec.feestitleName,
          amount: rec.amount,
        });
      });
      setRows(feeData);
    } catch (error) {
      console.log(error);
    }
    setMonthly(0);
  }

  const [Monthly, setMonthly] = React.useState(0);
  const onMonthlyFee = (e) => {
    console.log(e.currentTarget.value);
    setMonthly(e.currentTarget.value);
    settutionFee(e.currentTarget.value * 12);
    doCalu(e.currentTarget.value * 12);
  };

  const feeEnter = async (rec, e) => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    // console.log(rec);
    // console.log(e);

    let recFound = rows.find((row) => row.id === rec.id);

    recFound.amount = parseInt(rec.value);
    setTimeout(() => {
      doCalu();
    }, 1000);
  };

  const doCalu = (tutionVal) => {
    let total_value = 0;
    console.log(tutionVal);
    if (tutionVal < 0) {
      return;
    } else {
      rows.forEach((row) => {
        if (row.amount < 0) {
          return;
        } else {
          total_value = total_value + row.amount;
        }
      });
      if (tutionVal) {
        setTotalFeesAmount(total_value + tutionVal);
      } else {
        setTotalFeesAmount(total_value + tutionFee);
      }
    }
  };

  const onSave = async (e) => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    let feeArray = [];

    let feeObj;

    rows.forEach((row) => {
      feeObj = {
        standardID: selectedStandardID,
        standardName: selectedStandard,
        feestitleName: row.feestitleName,
        amount: row.amount,
        yearID: data.yearID,
        yearName: data.yearName,
      };
      feeArray.push(feeObj);
    });

    var tutionObj = {
      standardID: selectedStandardID,
      standardName: selectedStandard,
      feestitleName: "Tution Fee",
      amount: tutionFee,
      yearID: data.yearID,
      yearName: data.yearName,
    };
    feeArray.push(tutionObj);

    var totalAmountObj = {
      standardID: selectedStandardID,
      standardName: selectedStandard,
      feestitleName: "Total Fees Amount",
      amount: totalFeesAmount,
      yearID: data.yearID,
      yearName: data.yearName,
    };
    feeArray.push(totalAmountObj);

    console.log(feeArray);

    if (feeArray) {
      try {
        let response = await FeeStructure.feesStructureCreate(feeArray);
        console.log(response.data.data);
        setSucessVal(`Fee Saved Successfully`);
        handleSucessOpen();
        setTimeout(() => {
          handleSucessClose();
          onFeeReviewLoad();
        }, 2000);
      } catch (error) {
        console.log(error.message);
      }
    } else {
      console.log("Fees error");
    }
  };
  const onPreviousPage = () => {
    navigate("/feesStructure", { replace: true });
  };

  const [open, setOpen] = React.useState(false);
  // const onFreeze = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onFeeFreeze = () => {
    setOpen(true);
  };

  const [five, setFive] = React.useState();
  const [fiveId, setFiveId] = React.useState();
  const [six, setSix] = React.useState();
  const [sixId, setSixId] = React.useState();

  const onFeezeReview = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    console.log("onFeezeReview");
    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: fiveId,
        buildSchoolName: "feestructure",
        buildSchoolStatus: "true",
        buildSchoolStep: "five",
        buildSchoolYear: data.yearName,
        yearName: data.yearName,
      });
    } catch (error) {
      alert("Error creating buildSchool " + error);
    }
    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: sixId,
        buildSchoolName: "exam",
        buildSchoolStatus: "false",
        buildSchoolStep: "six",
        buildSchoolYear: data.yearName,
        yearName: data.yearName,
      });
    } catch (error) {
      alert("Error creating buildSchool " + error);
    }
    navigate("/feesReview", { replace: true });
  };

  const onNextPage = () => {
    navigate("/feesReview", { replace: true });
  };

  const onlyCharaters = (e, callback) => {
    const value = e.target.value ? e.target.value.replace(/[^0-9]+/gi, "") : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  return (
    <div>
      <Topbar
        text={
          "Step 5 CREATING FEES STRUCTURE 1. Select Standard from left 2. Click the Fees Head from Right and fill the Amount "
        }
      ></Topbar>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Item>
              {/* <Typography variant="subtitle2">SELECT STANDARD</Typography> */}
              {/* <Stack direction="column" spacing={0}> */}
              {standards.map((element, index) => (
                <Button
                  size="small"
                  id={element.standardID}
                  // value={element.standardID}
                  name={element.standardName}
                  sx={{
                    position: "relative",
                    border: "3px solid #aaecac",
                   width:"100%",
                    // minWidth: "100%",
                    height: 35,
                    color: "Black",
                    fontWeight: "bold",
                    mt:0.8,
                    // marginBottom: 0.2,
                    background: "#aaecac",
                    "&:hover": {
                      background: "#035e06",
                      color: "white",
                    },
                  }}
                  onClick={(event) => {
                    onStandardSelect(event, index);
                  }}
                  className={element.isSelected ? "standardSelected" : ""}
                >
                  {element.standardName}
                </Button>
              ))}
              {/* </Stack> */}
            </Item>
          </Grid>

          {/* <Grid item xs={5}>
            <Box m={0} sx={{ height: 260 ,}}>
              <Typography textAlign="center" variant="subtitle2">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#aaecac",
                    color: "black",
                    height: "30px",
                    fontWeight: "bold",
                    "&:hover": { background: "#035e06", color: "white" },
                  }}
                >
                  {selectedStandard ? selectedStandard : " "}
                </Button>
              </Typography>
              <h2>Enter the Monthly Amount for Tution Fee :</h2>
              <br />
              <TextField
                sx={{
                  // minWidth: "285px",
                  minWidth: "315px",
                }}
                id="month"
                label="Monthly Tution Fee x 12 Month"
                variant="outlined"
                onChange={onMonthlyFee}
                value={Monthly}
                size="small"
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 99,
                    inputMode: "numeric",
                    // maxLength: 2,
                  },
                }}
                onInput={(e) => onlyCharaters(e)}
              />
              <TextField
                sx={{
                  minWidth: "315px",
                }}
                id="totalFee"
                label="Annual Tution Fee"
                variant="outlined"
                value={tutionFee}
                size="small"
                readonly="true"
              />
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={30}
                rowsPerPageOptions={[30]}
                onCellEditCommit={feeEnter}
                hideFooter={true}
                // isRowEditable={(rec) => rec.row.feestitleName != "totalAmt"}
              />

              <h2>Total Fees Amount : {totalFeesAmount}</h2>
            </Box>
          </Grid> */}

<Grid item xs={5}>
            <Box m={0} sx={{ height: 260 ,}}>
              <Typography textAlign="center" variant="subtitle2">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#aaecac",
                    color: "black",
                    height: "30px",
                    fontWeight: "bold",
                    "&:hover": { background: "#035e06", color: "white" },
                  }}
                >
                  {selectedStandard ? selectedStandard : " "}
                </Button>
              </Typography>
              <h2>Enter the Monthly Amount for Tution Fee :</h2>
              <br />
              <Box sx={{display:"flex"}}>
              <TextField
                sx={{
                  width:"100%"
                  // minWidth: "285px",
                  // minWidth: "315px",
                }}
                id="month"
                label="Monthly Tution Fee x 12 Month"
                variant="outlined"
                onChange={onMonthlyFee}
                value={Monthly}
                size="small"
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 99,
                    inputMode: "numeric",
                    // maxLength: 2,
                  },
                }}
                onInput={(e) => onlyCharaters(e)}
              />
              <TextField
                sx={{
                  width:"100%"
                  // minWidth: "315px",
                }}
                id="totalFee"
                label="Annual Tution Fee"
                variant="outlined"
                value={tutionFee}
                size="small"
                readonly="true"
              />
</Box>

              <Box sx={{width:"100%",height:"100%"}}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={30}
                rowsPerPageOptions={[30]}
                onCellEditCommit={feeEnter}
                hideFooter={true}
                // isRowEditable={(rec) => rec.row.feestitleName != "totalAmt"}
              />
              </Box>

              <h2>Total Fees Amount : {totalFeesAmount}</h2>
            </Box>
          </Grid>


          <Grid item xs={5}>
            <Item>
              <Typography variant="subtitle2">SELECT STANDARD</Typography>
              <Grid container spacing={1}>
                {/* <Stack direction="column" spacing={0}> */}
                {fee.map((element, index) => (
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        border: "3px solid black",
                        color: "Blue",
                        fontWeight: "bold",
                      }}
                    >
                      <Button
                        size="small"
                        id={element.standardID}
                        // value={element.standardID}
                        name={element.standardName}
                        sx={{
                          position: "relative",
                          // border: "3px solid blue",
                          // border: "3px solid blue",
                          minWidth: "100%",
                          // height: 30,
                          color: "Blue",
                          fontWeight: "bold",
                          // marginBottom: 0.2,
                        }}
                      >
                        {element.standardName}
                      </Button>
                      {element.amount}
                    </Box>
                  </Grid>
                ))}
              </Grid>
              {/* </Stack> */}
            </Item>
          </Grid>
        </Grid>
      </Box>
      {/* <Box sx={{ flexGrow: 1 }}> */}
      
        {/* <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
              sx={{ m: 2 }}
            >
              <Button
                variant="contained"
                sx={{
                  // backgroundColor: "yellow",
                  // "&:hover": {
                  //   backgroundColor: "yellow",
                  // },
                  // border: "3px solid orange",
                  // color: "black",
                  // borderRadius: "30px",
                  borderRadius: "25px",
                  py: 1,
                  mx: 1,
                  width: 300,
                }}
                onClick={onPreviousPage}
              >
                Previous Page
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{
                  
                  borderRadius: "25px",
                  py: 1,
                  mx: 1,
                  width: 300,
                }}
                onClick={onSave}
                disabled={
                  status == "true" || selectedStandard.length === 0
                    ? true
                    : false
                }
              >
                Save Fee
              </Button>
              <Typography
                sx={{
                  backgroundColor: "lightyellow",
                  color: "red",
                  p: 1,
                  border: "2px solid black",
                  borderRadius: "5px",
                }}
              >
                PLEASE ENTER INFORMATION CAREFULLY THIS INFORMATION CAN NOT BE
                EDITED ONCE THE FEES RECEIPTS ARE GENERATED.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  //   backgroundColor: "yellow",
                  //   "&:hover": {
                  //     backgroundColor: "yellow",
                  //   },
                  //   border: "3px solid orange",
                  //   color: "black",
                  //   borderRadius: "30px",
                  borderRadius: "25px",
                  py: 1,
                  mx: 1,
                  width: 300,
                }}
                onClick={onFeeFreeze}
                disabled={
                  status == "true" || selectedStandard.length === 0
                    ? true
                    : false
                }
              >
                Review and Freeze
              </Button>
              <Button
                variant="contained"
                sx={{
                  // backgroundColor: "yellow",
                  // "&:hover": {
                  //   backgroundColor: "yellow",
                  // },
                  // border: "3px solid orange",
                  // color: "black",
                  // borderRadius: "30px",
                  borderRadius: "25px",
                  py: 1,
                  mx: 1,
                  width: 200,
                }}
                onClick={onNextPage}
                disabled={status == "true" ? false : true}
              >
                Next Page
              </Button>
            </Stack>
          </Grid>
        </Grid> */}

{/* <Grid container spacing = {1} sx={{mt:3}}> */}
  {/* <Grid item xs={2}> */}
  <Box sx={{display:"flex",mt:2}}>
  <Button
                variant="contained"
                sx={{
                  // backgroundColor: "yellow",
                  // "&:hover": {
                  //   backgroundColor: "yellow",
                  // },
                  // border: "3px solid orange",
                  // color: "black",
                  // borderRadius: "30px",
                  borderRadius: "15px",
                  // py: 1,
                  mx: 1,
                  width: 180,
                }}
                onClick={onPreviousPage}
              >
                Previous Page
              </Button>
  {/* </Grid> */}

{/* <Grid item xs={2} sx={{}}> */}

<Button
                variant="contained"
                color="success"
                sx={{
                  
                  borderRadius: "15px",
                  py: 1,
                  mx: 1,
                  width: 180,
                }}
                onClick={onSave}
                disabled={
                  status == "true" || selectedStandard.length === 0
                    ? true
                    : false
                }
              >
                Save Fee
              </Button>
{/* </Grid> */}

{/* <Grid item xs={4}> */}
<Typography
             variant="body2"
             sx={{
                  backgroundColor: "lightyellow",
                  color: "red",
                  p: 1,
                  border: "2px solid black",
                  borderRadius: "5px",
                  
                }}
              >
                PLEASE ENTER INFORMATION CAREFULLY THIS INFORMATION CAN NOT BE
                EDITED ONCE THE FEES RECEIPTS ARE GENERATED.
              </Typography>
{/* </Grid> */}

{/* <Grid item xs={2}> */}
<Button
                variant="contained"
                color="primary"
                sx={{
                  //   backgroundColor: "yellow",
                  //   "&:hover": {
                  //     backgroundColor: "yellow",
                  //   },
                  //   border: "3px solid orange",
                  //   color: "black",
                  //   borderRadius: "30px",
                  borderRadius: "15px",
                  // py: 1,
                  mx: 1,
                  width: 180,
                }}
                onClick={onFeeFreeze}
                disabled={
                  status == "true" || selectedStandard.length === 0
                    ? true
                    : false
                }
              >
                Review and Freeze
              </Button>
{/* </Grid> */}

{/* <Grid item xs={2}> */}
<Button
                variant="contained"
                sx={{
                  // backgroundColor: "yellow",
                  // "&:hover": {
                  //   backgroundColor: "yellow",
                  // },
                  // border: "3px solid orange",
                  // color: "black",
                  // borderRadius: "30px",
                  borderRadius: "15px",
                  // py: 1,
                  mx: 1,
                  width: 180,
                }}
                onClick={onNextPage}
                disabled={status == "true" ? false : true}
              >
                Next Page
              </Button>
              </Box>
{/* </Grid> */}

{/* </Grid> */}

      {/* </Box> */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="body1"
              component="h2"
            >
              You have clicked on
            </Typography>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              FREEZ INFORMATION
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              We request you to kindly review the information once again and
              then freeze the data, as once its FREEZE can’t be changed. Thank
              you
            </Typography>
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
              <Button
                variant="contained"
                size="small"
                color="success"
                onClick={onFeezeReview}
              >
                FREEZE
              </Button>
              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={handleClose}
              >
                Review
              </Button>
            </Stack>
          </Box>
        </Fade>
      </Modal>
      <Modal
        open={openSucess}
        onClose={handleSucessClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <SucessModal text={sucessVal} />
        </div>
      </Modal>
      {/* <Box
        m={1}
        display="flex"
        fontWeight="800"
        flexDirection="column"
        alignItems="center"
      >
        <h1>SELECT THE STANDARD</h1>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {standards.map((element, index) => (
            <Grid item xs={2}>
              <Button
                fullWidth
                variant="outlined"
                id={element.standardID}
                name={element.standardName}
                onClick={(event) => {
                  onStandardSelect(event, index);
                }}
                className={element.isSelected ? "standardSelected" : ""}
              >
                {element.standardName}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box> */}
    </div>
  );
}
