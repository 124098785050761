import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Button,
  Fade,
  IconButton,
  Modal,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import Topbar from "../../MainTopbar/Topbar";
import FreezeModal from "../Dashboard/freezeModel";
import { DesignationService } from "../../../services/DesignationService";
// import IconButton from "@mui/material/IconButton";
import DeleteModal from "../Dashboard/popup/deletePopUp";
import AlertModal from "../Dashboard/popup/alertPopUp";
import SucessModal from "../Dashboard/popup/sucessPopUp";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import { BuildSchoolControll } from "../../../services/buildSchoolControllService";

// * "======================Style=============================="

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "2px solid skyblue",
  display: "flex",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};

// * "======================Style=============================="

export default function Designation() {
  // * "======================onload============================"
  React.useEffect(() => {
    onDesignationload();
    onLoadBuildSchool();
  }, []);
  // * "======================onload============================"

  // * =======================window Pop=====================================
  // *delete
  const [openDelete, setDelete] = React.useState(false);
  const [delVal, setDelVal] = React.useState("");
  const handleDeleteOpen = () => setDelete(true);
  const handleDeleteClose = () => setDelete(false);

  // *alert
  const [openAlert, setAlert] = React.useState(false);
  const [alertVal, setAlertVal] = React.useState("");
  const handleAlertOpen = () => setAlert(true);
  const handleAlertClose = () => setAlert(false);

  // *sucess
  const [openSucess, setSucess] = React.useState(false);
  const [sucessVal, setSucessVal] = React.useState("");
  const handleSucessOpen = () => setSucess(true);
  const handleSucessClose = () => setSucess(false);

  // * =======================window Popup end=====================================

  const onchangedesignation = async (e) => {
    console.log(e.target.value);
    // localStorage.setItem("designation", e.target.value);
  };

  const [designationList, updateBooks] = React.useState([]);
  const textInput = React.useRef("");
  const onlyCharaters = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^a-zA-Z ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const onDesignationload = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await DesignationService.designationListAll(data.yearName);
      //console.log(response.data.data);

      updateBooks(response.data.data);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };

  // const [designationText, updateText] = React.useState();

  const onAddDesignation = async (event) => {
    // console.log(event);

    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log(data.get("designation"));

    let designation = data.get("designation");

    if (designation.trim().length === 0) {
      console.log(data.get("designation"));
      setAlertVal("Please enter a valid designation");
      handleAlertOpen();
      // alert("Please enter a valid designation");
    } else {
      const found = designationList.find(
        (element) =>
          element.designation == data.get("designation").toUpperCase()
      );

      if (found) {
        setAlertVal("Designation is already entered");
        handleAlertOpen();
      } else {
        let yeardata = JSON.parse(localStorage.getItem("academicYear"));

        try {
          let response = await DesignationService.createDesignation({
            designation: data.get("designation").toUpperCase(),
            yearID: yeardata.yearID,
            yearName: yeardata.yearName,
          });

          let nObj = {
            designation: response.data.data.designation,
            designationID: response.data.data.designationID,
          };

          updateBooks([...designationList, nObj]);

          if (response.data.success) {
            setSucessVal(
              `${data.get("designation").toUpperCase()} Added Successfully`
            );
            handleSucessOpen();
            console.log("success");
            console.log("Designation Added Successfully");
          } else {
            alert(response.data.message);
          }
        } catch (error) {
          console.log(error);
          setAlertVal("Failure");
          handleAlertOpen();
          // alert("Failure");
        }
      }
    }

    textInput.current.value = "";
    setTimeout(() => {
      handleAlertClose();
      handleSucessClose();
    }, 2000);
  };

  const onDesigantionSelect = async (e) => {
    console.log(e);
    console.log(e.currentTarget.accessKey);
    try {
      let response = await DesignationService.deleteDesignation(
        e.currentTarget.accessKey
      );

      updateBooks(
        designationList.filter(
          (item) => item.designationID !== response.data.data.designationID
        )
      );
      setDelVal("Deleted");
      handleDeleteOpen();
    } catch (error) {
      console.log("error");
      setAlertVal("Failure");
      handleAlertOpen();
      // alert("Failure");
    }
    setTimeout(() => {
      handleDeleteClose();
    }, 2000);
  };
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const nextPage = (e) => {
    // navigate("/dashborad", { replace: true });
    setOpen(true);
  };

  const onPause = () => {
    setAlertVal("Designation Module will be paused");
    handleAlertOpen();
    // alert("Designation Module will be paused");
    navigate("/dashborad", { replace: true });
  };

  const handleClose = () => setOpen(false);

  const [status, setStatus] = React.useState();

  const onLoadBuildSchool = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolControll.buildSchoolStatusListAll(
        data.yearName
      );
      // console.log(response.data.data[0]);
      setStatus(response.data.data[2].buildSchoolStatus);
      setThreeId(response.data.data[2].buildSchoolID);
      setFourId(response.data.data[3].buildSchoolID);
    } catch (error) {
      console.error(error);
    }
  };

  const [three, setThree] = React.useState();
  const [threeId, setThreeId] = React.useState();
  const [four, setFour] = React.useState();
  const [fourId, setFourId] = React.useState();

  const onBuildSchool = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    console.log("onBuildSchool");
    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: threeId,
        buildSchoolName: "designation",
        buildSchoolStatus: "true",
        buildSchoolStep: "three",
        buildSchoolYear: data.yearName,
        yearName: data.yearName,
      });
    } catch (error) {
      setAlertVal("Error creating buildSchool " + error);
      handleAlertOpen();
      // alert("Error creating buildSchool " + error);
    }
    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: fourId,
        buildSchoolName: "timetable",
        buildSchoolStatus: "false",
        buildSchoolStep: "four",
        buildSchoolYear: data.yearName,
        yearName: data.yearName,
      });
    } catch (error) {
      setAlertVal("Error creating buildSchool " + error);
      handleAlertOpen();
      // alert("Error creating buildSchool " + error);
    }
    navigate("/dashborad", { replace: true });
  };

  return (
    <div>
      <Topbar
        text={
          "Step3 of Building School, Please add Designation for assigning rights roles for operating this management software"
        }
      />
      <Box
        variant="div"
        sx={{
          flexGrow: 1,
          border: "2px solid skyblue",
          borderRadius: 5,
          height: 310,
          overflow: "auto",
          p: 2,
          m: 1,
        }}
      >
        <Grid container spacing={2}>
          {designationList.map((element) => (
            <Grid key={element.designationID} item xs={2.2}>
              <Item component="form" sx={{ background: "rgb(90, 207, 233)" }}>
                <Grid container spacing={0} sx={{maxWidth:"100%",}} >
                  <Grid item xs={10}>
                    <Typography sx={{ fontWeight: "bold",overflowWrap:"break-word" }}>
                      {element.designation}
                    </Typography>
                  </Grid>
                  <IconButton
                    aria-label="delete"
                    accessKey={element.designationID}
                    onClick={onDesigantionSelect}
                    size="small"
                    color="error"
                    disabled={
                      element.designation == "ADMINISTRATOR" ||
                      element.designation == "PRINCIPAL" ||
                      element.designation == "TEACHER" ||
                      element.designation == "PARENTS" ||
                      // element.designation == "VICE-PRINCIPAL" ||
                      element.designation == "CLERK" ||
                      status == "true"
                        ? true
                        : false
                    }
                    // sx={{ marginLeft: 5 }}
                  >
                    {/* <DeleteIcon fontSize="inherit"></DeleteIcon> */}
                    <CancelIcon fontSize="inherit" />
                    {/* <FontAwesomeIcon
                    fontSize="inherit"
                    icon="fa-solid fa-circle-xmark"
                  ></FontAwesomeIcon> */}
                  </IconButton>
                </Grid>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          height: 100,
          p: 2,
          m: 1,
        }}
      >
        <Box component="form" onSubmit={onAddDesignation}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              {/* <Item> */}
              {/* <Button variant="outlined">Outlined</Button> */}
              <TextField
                // color="primary"
                // color="#2e7eff"
                // id="outlined-basic"
                label="Enter Designation"
                variant="outlined"
                size="small"
                name="designation"
                inputRef={textInput}
                onInput={(e) => onlyCharaters(e)}
                inputProps={{ style: { textTransform: "uppercase" } }}
                onChange={onchangedesignation}
              />

              {/* <Typography>Administrator</Typography> */}
              {/* </Item> */}
            </Grid>
            <Grid item xs={2}>
              {/* <Item> */}
              <Button
                type="submit"
                // onClick={onAddDesignation}
                variant="contained"
                color="success"
                sx={{
                  borderRadius: "25px",
                }}
                disabled={status == "true" ? true : false}
              >
                Add Designation
              </Button>
              {/* <Typography>Administrator</Typography> */}
              {/* </Item> */}
            </Grid>
            <Grid item xs={1}></Grid>
            {/* <Grid item xs={3}>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "25px",
                }}
                disabled={status == "true" ? true : false}
                onClick={nextPage}
              >
                Review and Freeze
              </Button>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                // onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <Box sx={style}>
                    <Typography
                      id="transition-modal-title"
                      variant="body1"
                      component="h2"
                    >
                      You have clicked on
                    </Typography>
                    <Typography
                      id="transition-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      FREEZ INFORMATION
                    </Typography>
                    <Typography
                      id="transition-modal-description"
                      sx={{ mt: 2 }}
                    >
                      We request you to kindly review the information once again
                      and then freeze the data, as once its FREEZE can’t be
                      changed. Thank you
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                      <Button
                        variant="contained"
                        size="small"
                        color="success"
                        onClick={onBuildSchool}
                      >
                        FREEZE
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => setOpen(false)}
                      >
                        Review
                      </Button>
                    </Stack>
                  </Box>
                </Fade>
              </Modal>
            </Grid> */}
            <Grid
              item
              xs={7}
              sx={{ border: "2px solid red", borderRadius: "15px" }}
            >
              <Typography>
                More designation can also be added later as required but can’t
                be added to class timetable
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            // mt: 2,
            px: 2,
          }}
        >
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {/* <Grid
              item
              xs={8}
              sx={{ border: "2px solid red", borderRadius: "15px" }}
            >
              <Typography>
                More designation can also be added later as required but can’t
                be added to class time table
              </Typography>
            </Grid> */}

            <Grid item xs={3}>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "25px",
                }}
                disabled={status == "true" ? true : false}
                onClick={nextPage}
              >
                Review and Freeze
              </Button>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                // onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <Box sx={style}>
                    <Typography
                      id="transition-modal-title"
                      variant="body1"
                      component="h2"
                    >
                      You have clicked on
                    </Typography>
                    <Typography
                      id="transition-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      FREEZ INFORMATION
                    </Typography>
                    <Typography
                      id="transition-modal-description"
                      sx={{ mt: 2 }}
                    >
                      We request you to kindly review the information once again
                      and then freeze the data, as once its FREEZE can’t be
                      changed. Thank you
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                      <Button
                        variant="contained"
                        size="small"
                        color="success"
                        onClick={onBuildSchool}
                      >
                        FREEZE
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => setOpen(false)}
                      >
                        Review
                      </Button>
                    </Stack>
                  </Box>
                </Fade>
              </Modal>
            </Grid>

            <Grid item xs={4}>
              <Button
                variant="contained"
                onClick={onPause}
                color="warning"
                sx={{
                  borderRadius: "25px",
                  px: 12,
                  // margin: "12px",
                }}
                disabled={status == "true" ? true : false}
              >
                Pause and Continue Setup Later
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Modal
        open={openSucess}
        onClose={handleSucessClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <SucessModal text={sucessVal} />
        </div>
      </Modal>
      <Modal
        open={openAlert}
        onClose={handleAlertClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <AlertModal text={alertVal} />
        </div>
      </Modal>
      <Modal
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <DeleteModal text={delVal} />
        </div>
      </Modal>
    </div>
  );
}
