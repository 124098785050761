import React, { useState } from "react";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import {
  Avatar,
  Button,
  Paper,
  Typography,
  Box,
  Grid,
  TextField,
  Stack,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import styled from "@emotion/styled";
import { StudentInfoService } from "../../../services/StudentInfo/StudentList";
import { StudentService } from "../../../services/studentService";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TopBar from "../../MainTopbar/Appbar";
import StudentAttendance from "./studentAttendence";
import StudentFees from "./studentFeeRecipt";
import StudentExam from "./studentExam";
import StudentLibrary from "./studentLibrary";
import Supplies from "./studentSupplies";
import Document from "./studentDocument";
import Remarks from "./studentRemarks";
import GovtInfo from "./studentGovtInfo";
import Uploads from "./studentUpload";
import { C } from "../../util/C";
import { ImageConverter } from "../../../programs/imgaeToBase";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: "center",
  // fontWeight:"bold"
  // color: theme.palette.text.secondary
}));

const FormItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  height: 400,
  // height: 80
  // color: theme.palette.text.secondary,
}));

const style = {
  position: "absolute",
  top: "80%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 150,
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  // overflow: "scroll",
};

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ height: "100%", width: "100%" }}>{children}</Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function StudentDocument() {
  const [approveStatus, setApproveStatus] = useState("");
  const [divisionID, setDivisionID] = useState("");
  const [divisionName, setDivisionName] = useState("");
  const [fatherAadharCardData, setFatherAadharCardData] = useState("");
  const [fatherAadharUrl, setFatherAadharUrl] = useState("");
  const [fatherBloodGroup, setFatherBloodGroup] = useState("");
  const [fatherDLData, setFatherDLData] = useState("");
  const [fatherDLUrl, setFatherDLUrl] = useState("");
  const [fatherDOB, setFatherDOB] = useState("");
  const [fatherEmailID, setFatherEmailID] = useState("");
  const [fatherMobNum, setFatherMobNum] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [fatherPanCardData, setFatherPanCardData] = useState("");
  const [fatherPanCardUrl, setFatherPanCardUrl] = useState("");
  const [fatherPhotoUrl, setFatherPhotoUrl] = useState("");
  const [fatherProfession, setFatherProfession] = useState("");
  const [fatherQualification, setFatherQualification] = useState("");
  const [fatherVehicleNum, setFatherVehicleNum] = useState("");
  const [guardianName, setGuardianName] = useState("");
  const [motherAadharCardData, setMotherAadharCardData] = useState("");
  const [motherAadharUrl, setMotherAadharUrl] = useState("");
  const [motherBloodGroup, setMotherBloodGroup] = useState("");
  const [motherDLData, setMotherDLData] = useState("");
  const [motherDLUrl, setMotherDLUrl] = useState("");
  const [motherDOB, setMotherDOB] = useState("");
  const [motherEmailID, setMotherEmailID] = useState("");
  const [motherMobNum, setMotherMobNum] = useState("");
  const [motherName, setMotherName] = useState("");
  const [motherPanCardData, setMotherPanCardData] = useState("");
  const [motherPanCardUrl, setMotherPanCardUrl] = useState("");
  const [motherPhotoUrl, setMotherPhotoUrl] = useState("");
  const [motherProfession, setMotherProfession] = useState("");
  const [motherQualification, setMotherQualification] = useState("");
  const [motherVehicleNum, setMotherVehicleNum] = useState("");
  const [rtestudent, setrtestudent] = useState("");
  const [sibling, setsibling] = useState("");
  const [standardID, setstandardID] = useState("");
  const [standardName, setstandardName] = useState("");
  const [studentAadhar, setstudentAadhar] = useState("");
  const [studentAddress, setstudentAddress] = useState("");
  const [studentAge, setstudentAge] = useState("");
  const [studentBloodGroup, setstudentBloodGroup] = useState("");
  const [studentDOB, setstudentDOB] = useState("");
  const [studentFirstLanguage, setstudentFirstLanguage] = useState("");
  const [studentGender, setstudentGender] = useState("");
  const [studentID, setstudentID] = useState("");
  const [studentMedicalAttentionDetail, setstudentMedicalAttentionDetail] =
    useState("");
  const [studentName, setstudentName] = useState("");
  const [studentPreviousSchool, setstudentPreviousSchool] = useState("");
  const [studentProfileUrl, setstudentProfileUrl] = useState("");
  const [studentReligion, setstudentReligion] = useState("");
  const [studentType, setstudentType] = useState("");
  const [studentUSN, setstudentUSN] = useState("");

  const [openLoad, setOpenLoad] = React.useState(false);
  const [edit, setEdit] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, null, window.location.href);
    };
    studentDetailsLoad();
    // setOpenLoad(true);
    // setTimeout(() => {
    // }, 2000);
  }, []);

  const [value, setValue] = React.useState(1);

  const Click = (newValue) => {
    setValue(newValue);
    console.log(value);
  };

  let navigate = useNavigate();
  const onSelect = () => {
    navigate("/studentSearch", { replace: true });
  };

  const onLaunchPad = () => {
    navigate("/launchpad", { replace: true });
  };

  const studentDetailsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let studentUSN = localStorage.getItem("studentUSN");
    try {
      let response = await StudentInfoService.studentInfoShowStudentDetails(
        studentUSN,
        data.yearName
      );
      console.log(response.data.data);
      const fatherDOBString = response.data.data.fatherDOB;
      const motherDOBString = response.data.data.motherDOB;
      const studentDOBString = response.data.data.studentDOB;

      console.log(response.data.data.studentDOB);
      console.log(fatherDOBString);
      console.log(motherDOBString);
      console.log(studentDOBString);

      if (fatherDOBString) {
        const [day, month, year] = fatherDOBString.split("/");
        const fbdate = new Date(year, month - 1, day);
        setFatherDOB(fbdate);
        console.log(fbdate);
      }

      if (motherDOBString) {
        const [day, month, year] = motherDOBString.split("/");
        const mdate = new Date(year, month - 1, day);
        setMotherDOB(mdate);
        console.log(mdate);
      }

      if (studentDOBString) {
        const [day, month, year] = studentDOBString.split("/");
        const sdate = new Date(year, month - 1, day);
        setstudentDOB(sdate);
        console.log(sdate);
      }

      setApproveStatus(response.data.data.approveStatus);
      setDivisionID(response.data.data.divisionID);
      setDivisionName(response.data.data.divisionName);
      setFatherAadharCardData(response.data.data.fatherAadharCardData);
      setFatherAadharUrl(response.data.data.fatherAadharUrl);
      setFatherBloodGroup(response.data.data.fatherBloodGroup);
      setFatherDLData(response.data.data.fatherDLData);
      setFatherDLUrl(response.data.data.fatherDLUrl);
      // setFatherDOB(response.data.data.);
      setFatherEmailID(response.data.data.fatherEmailID);
      setFatherMobNum(response.data.data.fatherMobNum);
      setFatherName(response.data.data.fatherName);
      setFatherPanCardData(response.data.data.fatherPanCardData);
      setFatherPanCardUrl(response.data.data.fatherPanCardUrl);
      setFatherPhotoUrl(response.data.data.fatherPhotoUrl);
      setFatherProfession(response.data.data.fatherProfession);
      setFatherQualification(response.data.data.fatherQualification);
      setFatherVehicleNum(response.data.data.fatherVehicleNum);
      setGuardianName(response.data.data.guardianName);
      setMotherAadharCardData(response.data.data.motherAadharCardData);
      setMotherAadharUrl(response.data.data.motherAadharUrl);
      setMotherBloodGroup(response.data.data.motherBloodGroup);
      setMotherDLData(response.data.data.motherDLData);
      setMotherDLUrl(response.data.data.motherDLUrl);
      // setMotherDOB(response.data.data.motherDOB);
      setMotherEmailID(response.data.data.motherEmailID);
      setMotherMobNum(response.data.data.motherMobNum);
      setMotherName(response.data.data.motherName);
      setMotherPanCardData(response.data.data.motherPanCardData);
      setMotherPanCardUrl(response.data.data.motherPanCardUrl);
      setMotherPhotoUrl(response.data.data.motherPhotoUrl);
      setMotherProfession(response.data.data.motherProfession);
      setMotherQualification(response.data.data.motherQualification);
      setMotherVehicleNum(response.data.data.motherVehicleNum);
      setrtestudent(response.data.data.rtestudent);
      setsibling(response.data.data.sibling);
      setstandardID(response.data.data.standardID);
      setstandardName(response.data.data.standardName);
      setstudentAadhar(response.data.data.studentAadhar);
      setstudentAddress(response.data.data.studentAddress);
      setstudentAge(response.data.data.studentAge);
      setstudentBloodGroup(response.data.data.studentBloodGroup);
      // setstudentDOB(response.data.data.);
      setstudentFirstLanguage(response.data.data.studentFirstLanguage);
      setstudentGender(response.data.data.studentGender);
      setstudentID(response.data.data.studentID);
      setstudentMedicalAttentionDetail(
        response.data.data.studentMedicalAttentionDetail
      );
      setstudentName(response.data.data.studentName);
      setstudentPreviousSchool(response.data.data.studentPreviousSchool);
      setstudentProfileUrl(response.data.data.studentProfileUrl);
      setstudentReligion(response.data.data.studentReligion);
      setstudentType(response.data.data.studentType);
      setstudentUSN(response.data.data.studentUSN);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileUpload = async (e, id) => {
    const file = e.target.files[0];
    const base64 = await ImageConverter.convertToBase64(file);
    // console.log(id);
    if (id === "studentProfileUrl") {
      setstudentProfileUrl(base64);
    }
    if (id === "motherAadharUrl") {
      setMotherAadharUrl(base64);
    }
    if (id === "motherPanCardUrl") {
      setMotherPanCardUrl(base64);
    }
    if (id === "motherDLUrl") {
      setMotherDLUrl(base64);
    }
    if (id === "fatherDLUrl") {
      setFatherDLUrl(base64);
    }
    if (id === "fatherPanCardUrl") {
      setFatherPanCardUrl(base64);
    }
    if (id === "fatherAadharUrl") {
      setFatherAadharUrl(base64);
    }
    if (id === "motherPhotoUrl") {
      setMotherPhotoUrl(base64);
    }
    if (id === "fatherPhotoUrl") {
      setFatherPhotoUrl(base64);
    }
    // console.log(base64);
  };

  const ageCalu = (dob) => {
    const birthDate = new Date(dob);
    const currentDate = new Date();

    if (birthDate > currentDate) {
      setstudentAge("Date of birth is in the future");
      return;
    }

    let yearsDiff = currentDate.getFullYear() - birthDate.getFullYear();
    let monthsDiff = currentDate.getMonth() - birthDate.getMonth();

    if (monthsDiff < 0) {
      yearsDiff--;
      monthsDiff = 12 + monthsDiff;
    }

    if (yearsDiff === 0 && monthsDiff === 0) {
      setstudentAge("Born today");
    } else if (yearsDiff === 0) {
      setstudentAge(`${monthsDiff} m${monthsDiff > 1 ? "s" : ""}`);
    } else if (monthsDiff === 0) {
      setstudentAge(`${yearsDiff} y${yearsDiff > 1 ? "s" : ""}`);
    } else {
      setstudentAge(
        `${yearsDiff} y${yearsDiff > 1 ? "" : ""} ${monthsDiff} m${
          monthsDiff > 1 ? "" : ""
        }`
      );
    }
  };

  const updatestudentInfor = async (e) => {
    e.preventDefault();
    setOpenLoad(true);

    let yearData = JSON.parse(localStorage.getItem("academicYear"));

    setTimeout(async () => {
      let obj = {
        approveStatus: approveStatus,
        divisionID: divisionID,
        divisionName: divisionName,
        fatherAadharCardData: fatherAadharCardData,
        fatherAadharUrl: fatherAadharUrl,
        fatherBloodGroup: fatherBloodGroup,
        fatherDLData: fatherDLData,
        fatherDLUrl: fatherDLUrl,
        fatherDOB: fatherDOB ? fatherDOB.toLocaleDateString("en-GB") : null,
        fatherEmailID: fatherEmailID,
        fatherMobNum: fatherMobNum,
        fatherName: fatherName,
        fatherPanCardData: fatherPanCardData,
        fatherPanCardUrl: fatherPanCardUrl,
        fatherPhotoUrl: fatherPhotoUrl,
        fatherProfession: fatherProfession,
        fatherQualification: fatherQualification,
        fatherVehicleNum: fatherVehicleNum,
        guardianName: guardianName,
        motherAadharCardData: motherAadharCardData,
        motherAadharUrl: motherAadharUrl,
        motherBloodGroup: motherBloodGroup,
        motherDLData: motherDLData,
        motherDLUrl: motherDLUrl,
        motherDOB: motherDOB ? motherDOB.toLocaleDateString("en-GB") : null,
        motherEmailID: motherEmailID,
        motherMobNum: motherMobNum,
        motherName: motherName,
        motherPanCardData: motherPanCardData,
        motherPanCardUrl: motherPanCardUrl,
        motherPhotoUrl: motherPhotoUrl,
        motherProfession: motherProfession,
        motherQualification: motherQualification,
        motherVehicleNum: motherVehicleNum,
        rtestudent: rtestudent,
        sibling: sibling,
        standardID: standardID,
        standardName: standardName,
        studentAadhar: studentAadhar,
        studentAddress: studentAddress,
        studentAge: studentAge,
        studentBloodGroup: studentBloodGroup,
        studentDOB: studentDOB ? studentDOB.toLocaleDateString("en-GB") : null,
        studentFirstLanguage: studentFirstLanguage,
        studentGender: studentGender,
        studentID: studentID,
        studentMedicalAttentionDetail: studentMedicalAttentionDetail,
        studentName: studentName,
        studentPreviousSchool: studentPreviousSchool,
        studentProfileUrl: studentProfileUrl,
        studentReligion: studentReligion,
        studentType: "string",
        studentUSN: studentUSN,
        yearID: yearData.yearID,
        yearName: yearData.yearName,
      };
      C.c(obj);
      try {
        let response = await StudentService.addStudent(obj);
        C.c(response.data.data);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      } catch (error) {
        C.c(error.message);
      }
      setOpenLoad(false);
      studentDetailsLoad();
    }, 1000);
    setEdit(true);
  };

  const onlyCharaters = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^zA-Z ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const onlyCharatersBg = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^A-Z+-]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const phoneNumber = (e, callback) => {
    const value = e.target.value ? e.target.value.replace(/[^0-9]+/gi, "") : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const onlyCharatersQual = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^zA-Z0-9. ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const onlyCharatersVn = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^zA-Z0-9- ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  return (
    <React.Fragment>
      <TopBar />

      <Box
        sx={{
          border: "2px solid black",
          background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
        }}
      >
        <Grid container spacing={2} py={0.5}>
          <Grid item xs={1}></Grid>
          <Grid item xs={1.5}>
            <Button
              variant="contained"
              color="warning"
              size="small"
              // onClick={() => Click(0)}
              sx={{ fontWeight: "bold" }}
              onClick={onSelect}
            >
              STUDENT’S INFO
            </Button>
          </Grid>
          {/* <Grid item xs={1}>
            <Button fullWidth size="small" onClick={onSelectStudent}>
              SELECT STUDENT
            </Button>
          </Grid> */}
          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(1)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 1 ? "black" : "",
                color: value == 1 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              INFORMATION
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              onClick={() => Click(2)}
              size="small"
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 2 ? "black" : "",
                color: value == 2 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              ATTENDANCE
            </Button>
          </Grid>
          <Grid item xs={0.5}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(3)}
              // onClick={(e) => {
              //   alert("Coming Soon !");
              // }}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 3 ? "black" : "",
                color: value == 3 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              FEES
            </Button>
          </Grid>
          <Grid item xs={0.5}>
            <Button
              fullWidth
              size="small"
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 4 ? "black" : "",
                color: value == 4 ? "white" : "black",
                fontWeight: "bold",
              }}
              // onClick={(e) => {
              //   alert("Coming Soon !");
              // }}
              onClick={() => Click(4)}
            >
              EXAMS
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(5)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 5 ? "black" : "",
                color: value == 5 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              LIBRARY
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(6)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 6 ? "black" : "",
                color: value == 6 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              SUPPLIES
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(7)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 7 ? "black" : "",
                color: value == 7 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              DOCUMENTS
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(8)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 8 ? "black" : "",
                color: value == 8 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              REM N RECOG
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(9)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 9 ? "black" : "",
                color: value == 9 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              GOVT INFO
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(10)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 10 ? "black" : "",
                color: value == 10 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              UPLOADS
            </Button>
          </Grid>
        </Grid>
      </Box>

      <CustomTabPanel value={value} index={1}>
        <Box
          sx={{
            height: 300,
          }}
        >
          <Box
            sx={{ width: "93.5%", p: "0 32px", boxSizing: "border-box", mt: 2 }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 520,
                fontSize: "18px",
                backgroundColor: "lightgray",
                textAlign: "center",
              }}
            >
              STUDENT NAME
            </Typography>

            <TextField
              id="studentName"
              fullWidth
              InputProps={{
                readOnly: edit,
                inputProps: {
                  style: {
                    height:"13px",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "20px",
                    textTransform: "uppercase",
                    verticalAlign: "middle",
                  },
                },
              }}
              value={studentName}
              variant="filled"
              size="small"
              name="studentName"
              onChange={(e) => {
                onlyCharaters(e);
                setstudentName(e.target.value.toUpperCase());
              }}
            />
          </Box>
          <Box
            sx={{
              width: "80%",
              marginLeft: "2%",
              my: "1%",
            }}
          >
            <Stack direction="row">
              <Stack direction="column" sx={{ marginX: 0.5 }}>
                <Item sx={{ width: "100px" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "18px",
                      backgroundColor: "lightgray",
                    }}
                  >
                    USN
                  </Typography>
                  <TextField
                    fullWidth
                    InputProps={{
                      readOnly: edit,
                      inputProps: {
                        style: {
                          textAlign: "center",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          // fontSize: "40px",
                        },
                      },
                    }}
                    value={studentUSN}
                    inputProps={{
                      style: {
                        textTransform: "uppercase",
                      },
                    }}
                    variant="outlined"
                    size="small"
                    name="studentUSN"
                  />
                </Item>
              </Stack>
              <Stack direction="column" sx={{ marginX: 0.5 }}>
                <Item sx={{ width: "200px" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "18px",
                      backgroundColor: "lightgray",
                    }}
                  >
                    STANDARD | DIVISION
                  </Typography>
                  <TextField
                    fullWidth
                    InputProps={{
                      readOnly: edit,
                      inputProps: {
                        style: {
                          textAlign: "center",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        },
                      },
                    }}
                    value={standardName + " | " + divisionID}
                    inputProps={{
                      style: {
                        textTransform: "uppercase",
                      },
                    }}
                    variant="outlined"
                    size="small"
                    name="standardDivisions"
                  />
                </Item>
              </Stack>
              <Stack direction="column" sx={{ marginX: 0.5 }}>
                <Item sx={{ width: "170px" }}>
                  <Typography
                    variant="h6"
                    readOnly
                    sx={{
                      fontSize: "18px",
                      backgroundColor: "lightgray",
                    }}
                  >
                    CLASS TR
                  </Typography>
                  <TextField
                    fullWidth
                    InputProps={{
                      readOnly: edit,
                      inputProps: {
                        style: {
                          textAlign: "center",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        },
                      },
                    }}
                    value={"--"}
                    // value={classteacher}
                    inputProps={{
                      style: {
                        textTransform: "uppercase",
                      },
                    }}
                    variant="outlined"
                    size="small"
                    name="classteacher"
                  />
                </Item>
              </Stack>
              <Stack direction="column" sx={{ marginX: 1 }}>
                <Item sx={{ width: "300px" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "18px",
                      backgroundColor: "lightgray",
                    }}
                  >
                    MEDICAL ATTENTION
                  </Typography>
                  <TextField
                    fullWidth
                    InputProps={{
                      readOnly: edit,
                      inputProps: {
                        style: {
                          textAlign: "center",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        },
                      },
                    }}
                    value={studentMedicalAttentionDetail}
                    inputProps={{
                      style: {
                        textTransform: "uppercase",
                      },
                    }}
                    variant="outlined"
                    size="small"
                    name="studentMedical"
                    onChange={(e) => {
                      onlyCharaters(e);
                      setstudentMedicalAttentionDetail(
                        e.target.value.toUpperCase()
                      );
                    }}
                  />
                </Item>
              </Stack>
              <Stack direction="column" sx={{ marginX: 1 }}>
                <Item sx={{ width: "150px" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "18px",
                      backgroundColor: "lightgray",
                    }}
                  >
                    BLOOD GROUP
                  </Typography>
                  <TextField
                    fullWidth
                    InputProps={{
                      readOnly: edit,
                      inputProps: {
                        style: {
                          textAlign: "center",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        },
                      },
                    }}
                    value={studentBloodGroup}
                    inputProps={{
                      style: {
                        textTransform: "uppercase",
                      },
                    }}
                    variant="outlined"
                    size="small"
                    name="studentBloodGroup"
                    onChange={(e) => {
                      onlyCharatersBg(e);
                      setstudentBloodGroup(e.target.value.toUpperCase());
                    }}
                  />
                </Item>
              </Stack>
              <Stack direction="column" sx={{ marginX: 1 }}>
                <Item sx={{ width: "180px" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      // fontWeight: "bold",
                      fontSize: "18px",
                      backgroundColor: "lightgray",
                    }}
                  >
                    DATE OF BIRTH
                  </Typography>
                  <LocalizationProvider
                    size="small"
                    required
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      format="dd/MM/yyyy"
                      size="small"
                      value={studentDOB}
                      disabled={edit}
                      onChange={(newDate) => {
                        setstudentDOB(newDate);
                        ageCalu(newDate);
                      }}
                      sx={{ backgroundColor: "#eeeeee" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          size="small"
                          name="studentDOB"
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {/* <TextField
                  fullWidth
                  InputProps={{
                    inputProps: {
                      style: {
                        textAlign: "center",
                        fontWeight: "bold",
                      },
                    },
                  }}
                  value={studentInfo ? studentInfo.studentDOB : "--"}
                  inputProps={{
                    style: {
                      textTransform: "uppercase",
                    },
                  }}
                  variant="outlined"
                  size="small"
                  name="studentName"
                  onInput={(e) => onlyCharaters(e)}
                /> */}
                </Item>
              </Stack>
              <Stack direction="column" sx={{ marginX: 1 }}>
                <Item sx={{ width: "180px" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "18px",
                      backgroundColor: "lightgray",
                    }}
                  >
                    AGE
                  </Typography>
                  <TextField
                    fullWidth
                    InputProps={{
                      readOnly: edit,
                      inputProps: {
                        style: {
                          textAlign: "center",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        },
                      },
                    }}
                    value={studentAge}
                    inputProps={{
                      style: {
                        textTransform: "uppercase",
                      },
                    }}
                    variant="outlined"
                    size="small"
                    name="studentAge"
                  />
                </Item>
              </Stack>
            </Stack>
          </Box>

          <Box sx={{ flexGrow: 1 }} ml="1%">
            <Grid container>
              <Grid container xs={10} width="80%">
                <Grid item xs={2} border="2px solid black">
                  <Avatar
                    alt="A"
                    src={fatherPhotoUrl}
                    sx={{
                      width: 200,
                      height: 195,
                      // mx: 3,

                      border: "3px solid skyblue",
                      borderRadius: "5%",
                    }}
                    variant="square"
                  />
                  <input
                    style={{ display: "none" }}
                    id="fatherPhotoUrl"
                    type="file"
                    accept="image/*"
                    name="fatherPhotoUrl"
                    onChange={(e) => {
                      handleFileUpload(e, e.target.id);
                    }}
                  />
                  {/* ----------------------------------STAFF IMAGE--------------------------- */}
                  <label htmlFor="fatherPhotoUrl">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      size="small"
                      disabled={edit}
                      sx={{
                        marginY: 2,
                        fontWeight: 700,
                        ml: "29%",
                      }}
                    >
                      UPLOAD
                    </Button>
                  </label>
                  {/* <Avatar
                  alt="Remy Sharp"
                  src={studentInfo.StudentFprofile}
                  //   id={element.staffID}
                  variant="square"
                  sx={{
                    width: 150,
                    height: 150,
                    mx: 2,
                    mt: 2,
                    mb: 2,
                    border: "5px solid skyblue",
                    borderRadius: "5%",
                  }}
                /> */}
                </Grid>

                <Grid item xs={4} border="2px solid black" borderLeft="none">
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"stretch"}
                  >
                    <Box textAlign={"center"}>
                      <TextField
                        fullWidth
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            style: {
                              textAlign: "center",
                              fontWeight: "bold",
                              textTransform: "uppercase",
                              backgroundColor: "#c6d9f1",
                            },
                          },
                        }}
                        value={fatherName}
                        inputProps={{
                          style: {
                            textTransform: "uppercase",
                          },
                        }}
                        variant="outlined"
                        label="Father Name"
                        size="small"
                        name="fatherName"
                        onChange={(e) => {
                          onlyCharaters(e);
                          setFatherName(e.target.value.toUpperCase());
                        }}
                      />
                      {/* <Typography
                      variant="h6"
                      padding={"5px 0 0 10px"}
                      gutterBottom
                      sx={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        backgroundColor: "#c6d9f1",
                      }}
                    >
                      {studentInfo.fatherName
                        ? studentInfo.fatherName.toUpperCase()
                        : "Father's Name"}
                    </Typography> */}
                    </Box>

                    <Box marginY={0.8} textAlign={"center"} display={"flex"}>
                      <TextField
                        fullWidth
                        label="Qualification"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            style: {
                              textAlign: "center",
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            },
                          },
                        }}
                        value={fatherQualification}
                        inputProps={{
                          style: {
                            textTransform: "uppercase",
                          },
                        }}
                        variant="outlined"
                        size="small"
                        name="fatherQualification"
                        onChange={(e) => {
                          onlyCharatersQual(e);
                          setFatherQualification(e.target.value.toUpperCase());
                        }}
                      />
                      {/* <Typography
                      variant="body2"
                      sx={{
                        fontSize: "16px",
                        width: "50%",
                        fontWeight: "bold",
                      }}
                    >
                      Qualification :{" "}
                    </Typography>
                    <Typography
                      // variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "16px",
                        textTransform: "uppercase",
                        // width:"50%",
                      }}
                    >
                      {studentInfo.fatherQualification
                        ? studentInfo.fatherQualification
                        : "--"}
                    </Typography> */}
                    </Box>
                    <Box marginY={0.8} textAlign={"center"} display={"flex"}>
                      <TextField
                        fullWidth
                        label="Profession"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            style: {
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            },
                          },
                        }}
                        value={fatherProfession}
                        // inputProps={{
                        //   style: {
                        //     textTransform: "uppercase",
                        //   },
                        // }}
                        variant="outlined"
                        size="small"
                        name="fatherProfession"
                        onChange={(e) => {
                          onlyCharatersQual(e);
                          setFatherProfession(e.target.value.toUpperCase());
                        }}
                      />
                      {/* <Typography
                      variant="body2"
                      sx={{
                        fontSize: "16px",
                        width: "50%",
                        fontWeight: "bold",
                      }}
                    >
                      Profession :{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        // backgroundColor: "lightgray",
                      }}
                    >
                      {studentInfo.fatherProfession
                        ? studentInfo.fatherProfession.toUpperCase()
                        : "--"}
                    </Typography> */}
                    </Box>
                    <Box marginY={0.8} textAlign={"center"} display={"flex"}>
                      <TextField
                        fullWidth
                        label=" Contact Number"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            maxLength: 10,
                            style: {
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            },
                          },
                        }}
                        value={fatherMobNum}
                        // inputProps={{
                        //   style: {
                        //     textTransform: "uppercase",
                        //   },
                        // }}
                        variant="outlined"
                        size="small"
                        name="fatherMobNum"
                        onChange={(e) => {
                          phoneNumber(e);
                          setFatherMobNum(e.target.value);
                        }}
                      />
                      {/* <Typography
                      variant="body2"
                      sx={{
                        fontSize: "16px",
                        width: "50%",
                        fontWeight: "bold",
                      }}
                    >
                      Contact No. :{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        // backgroundColor: "lightgray",
                      }}
                    >
                      {studentInfo.fatherMobNum
                        ? studentInfo.fatherMobNum
                        : "--"}
                    </Typography> */}
                    </Box>
                    <Box marginY={0.8} textAlign={"center"} display={"flex"}>
                      <TextField
                        fullWidth
                        label="Email"
                        type="email"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            style: {
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            },
                          },
                        }}
                        value={fatherEmailID}
                        inputProps={{
                          style: {
                            textTransform: "uppercase",
                          },
                        }}
                        variant="outlined"
                        size="small"
                        name="fatherEmailID"
                        onChange={(e) => {
                          setFatherEmailID(e.target.value.toUpperCase());
                        }}
                      />
                      {/* <TextField
                      fullWidth
                      label="E-mail"
                      InputProps={{
                        inputProps: {
                          style: {
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        },
                      }}
                      value={
                        studentInfo.fatherEmailID
                          ? studentInfo.fatherEmailID.toUpperCase()
                          : "--"
                      }
                      inputProps={{
                        style: {
                          textTransform: "uppercase",
                        },
                      }}
                      variant="outlined"
                      size="small"
                      name="fatherName"
                    /> */}
                      {/* <Typography
                      variant="body2"
                      sx={{
                        fontSize: "16px",
                        width: "50%",
                        fontWeight: "bold",
                      }}
                    >
                      E-mail :{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        // backgroundColor: "lightgray",
                      }}
                    >
                      {studentInfo.fatherEmailID
                        ? studentInfo.fatherEmailID.toUpperCase()
                        : "--"}
                    </Typography> */}
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={4} border="2px solid black" borderLeft="none">
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"stretch"}
                  >
                    <Box textAlign={"center"}>
                      <TextField
                        fullWidth
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            style: {
                              textAlign: "center",
                              fontWeight: "bold",
                              textTransform: "uppercase",
                              backgroundColor: "#f1ccad",
                            },
                          },
                        }}
                        value={motherName}
                        inputProps={{
                          style: {
                            textTransform: "uppercase",
                          },
                        }}
                        variant="outlined"
                        label="Mother Name"
                        size="small"
                        name="motherName"
                        onChange={(e) => {
                          onlyCharaters(e);
                          setMotherName(e.target.value.toUpperCase());
                        }}
                      />
                      {/* <Typography
                      variant="h6"
                      padding={"5px 0 0 10px"}
                      gutterBottom
                      sx={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        backgroundColor: "#f1ccad",
                      }}
                    >
                      {studentInfo.motherName
                        ? studentInfo.motherName.toUpperCase()
                        : "--"}
                    </Typography> */}
                    </Box>

                    <Box marginY={0.8} textAlign={"center"} display={"flex"}>
                      <TextField
                        fullWidth
                        label="Qualification"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            style: {
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            },
                          },
                        }}
                        value={motherQualification}
                        inputProps={{
                          style: {
                            textTransform: "uppercase",
                          },
                        }}
                        variant="outlined"
                        size="small"
                        name="motherQualification"
                        onChange={(e) => {
                          onlyCharatersQual(e);
                          setMotherQualification(e.target.value.toUpperCase());
                        }}
                      />
                      {/* <Typography
                      variant="body2"
                      sx={{
                        fontSize: "16px",
                        width: "50%",
                        fontWeight: "bold",
                      }}
                    >
                      Qualification :{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        // backgroundColor: "lightgray",
                      }}
                    >
                      {studentInfo.motherQualification
                        ? studentInfo.motherQualification.toUpperCase()
                        : "--"}
                    </Typography> */}
                    </Box>
                    <Box marginY={0.8} textAlign={"center"} display={"flex"}>
                      <TextField
                        fullWidth
                        label="Profession"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            style: {
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            },
                          },
                        }}
                        value={motherProfession}
                        inputProps={{
                          style: {
                            textTransform: "uppercase",
                          },
                        }}
                        variant="outlined"
                        size="small"
                        name="motherProfession"
                        onChange={(e) => {
                          onlyCharatersQual(e);
                          setMotherProfession(e.target.value.toUpperCase());
                        }}
                      />
                      {/* <Typography
                      variant="body2"
                      sx={{
                        fontSize: "16px",
                        width: "50%",
                        fontWeight: "bold",
                      }}
                    >
                      Profession :{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        // backgroundColor: "lightgray",
                      }}
                    >
                      {studentInfo.motherProfession
                        ? studentInfo.motherProfession.toUpperCase()
                        : "--"}
                    </Typography> */}
                    </Box>
                    <Box marginY={0.8} textAlign={"center"} display={"flex"}>
                      <TextField
                        fullWidth
                        label=" Contact Number"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            maxLength: 10,
                            style: {
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            },
                          },
                        }}
                        value={motherMobNum}
                        // inputProps={{
                        //   style: {
                        //     textTransform: "uppercase",
                        //   },
                        // }}
                        variant="outlined"
                        size="small"
                        name="motherMobNum"
                        onChange={(e) => {
                          phoneNumber(e);
                          setMotherMobNum(e.target.value);
                        }}
                      />
                      {/* <Typography
                      variant="body2"
                      sx={{
                        fontSize: "16px",
                        width: "50%",
                        fontWeight: "bold",
                      }}
                    >
                      Contact No. :{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        // backgroundColor: "lightgray",
                      }}
                    >
                      {studentInfo.motherMobNum
                        ? studentInfo.motherMobNum
                        : "--"}
                    </Typography> */}
                    </Box>
                    <Box marginY={0.8} textAlign={"center"} display={"flex"}>
                      <TextField
                        fullWidth
                        label="Email"
                        type="email"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            style: {
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            },
                          },
                        }}
                        value={motherEmailID}
                        inputProps={{
                          style: {
                            textTransform: "uppercase",
                          },
                        }}
                        variant="outlined"
                        size="small"
                        name="motherEmailID"
                        onChange={(e) => {
                          setMotherEmailID(e.target.value.toUpperCase());
                        }}
                      />
                      {/* <Typography
                      variant="body2"
                      sx={{
                        fontSize: "16px",
                        width: "50%",
                        fontWeight: "bold",
                      }}
                    >
                      E-mail :{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        // backgroundColor: "lightgray",
                      }}
                    >
                      {studentInfo.motherEmailID
                        ? studentInfo.motherEmailID.toUpperCase()
                        : "--"}
                    </Typography> */}
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={2} border="2px solid black" borderLeft="none ">
                  <Avatar
                    alt="A"
                    src={motherPhotoUrl}
                    sx={{
                      width: 205,
                      height: 195,
                      // mx: 3.5,
                      // mt: 1,
                      border: "2px solid skyblue",
                      borderRadius: "5%",
                    }}
                    variant="square"
                  />
                  <input
                    style={{ display: "none" }}
                    id="motherPhotoUrl"
                    type="file"
                    accept="image/*"
                    name="motherPhotoUrl"
                    onChange={(e) => {
                      handleFileUpload(e, e.target.id);
                    }}
                  />
                  {/* ----------------------------------STAFF IMAGE--------------------------- */}
                  <label htmlFor="motherPhotoUrl">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      disabled={edit}
                      sx={{
                        marginY: 2,
                        fontWeight: 700,
                        ml: "27%",
                      }}
                    >
                      UPLOAD
                    </Button>
                  </label>
                  {/* <Avatar
                  alt="Remy Sharp"
                  src={studentInfo.StudentMprofile}
                  //   id={element.staffID}
                  variant="square"
                  sx={{
                    width: 150,
                    height: 150,
                    mx: 2,
                    mt: 2,
                    mb: 2,
                    border: "5px solid skyblue",
                    borderRadius: "5%",
                  }}
                /> */}
                </Grid>

                <Grid container width="100%">
                  <Grid item xs={2} border="2px solid black" borderTop="none">
                    <Box marginY={0.5} textAlign={"center"} display={"flex"}>
                      <LocalizationProvider
                        size="small"
                        required
                        dateAdapter={AdapterDateFns}
                      >
                        <DatePicker
                          inputFormat="dd/MM/yyyy"
                          format="dd/MM/yyyy"
                          size="small"
                          label="Date of Birth"
                          value={fatherDOB}
                          onChange={(newDate) => {
                            setFatherDOB(newDate);
                          }}
                          disabled={edit}
                          sx={{ backgroundColor: "#eeeeee", mt: 1 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              size="small"
                              name="fatherDOB"
                              inputProps={{ style: { height: "30px" } }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      {/* <TextField
                      fullWidth
                      label="Father's DOB"
                      InputProps={{
                        inputProps: {
                          style: {
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        },
                      }}
                      value={fatherDOB}
                      inputProps={{
                        style: {
                          textTransform: "uppercase",
                        },
                      }}
                      variant="outlined"
                      size="small"
                      name="fatherName"
                    /> */}
                      {/* <Typography
                      variant="body2"
                      sx={{ fontSize: "16px", fontWeight: "bold" }}
                    >
                      Father's DOB :{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        // backgroundColor: "lightgray",
                      }}
                    >
                      {studentInfo.fatherDOB ? studentInfo.fatherDOB : "--"}
                    </Typography> */}
                    </Box>
                    {/* <Item>{studentInfo.fatherDOB}</Item> */}
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    borderBottom="2px solid black"
                    borderRight="2px solid black"
                  >
                    <Box marginY={0.5} textAlign={"center"} display={"flex"}>
                      <TextField
                        fullWidth
                        label="Blood Group"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            style: {
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                              height: 40,
                            },
                          },
                        }}
                        value={fatherBloodGroup}
                        // inputProps={{
                        //   style: {
                        //     textTransform: "uppercase",
                        //     height:"50px"
                        //   },
                        // }}
                        variant="outlined"
                        sx={{ mt: 1 }}
                        size="small"
                        name="fatherBloodgroup"
                        onChange={(e) => {
                          onlyCharatersBg(e);
                          setFatherBloodGroup(e.target.value.toUpperCase());
                        }}
                      />
                      {/* <Typography
                      variant="body2"
                      sx={{ fontSize: "16px", fontWeight: "bold" }}
                    >
                      Blood Group :{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        // backgroundColor: "lightgray",
                      }}
                    >
                      {studentInfo.fatherBloodGroup
                        ? studentInfo.fatherBloodGroup.toUpperCase()
                        : "--"}
                    </Typography> */}
                    </Box>
                    {/* <Item>fatherBloodGroup :</Item>
                  <Item>{studentInfo.fatherBloodGroup}</Item> */}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    borderBottom="2px solid black"
                    borderRight="2px solid black"
                  >
                    <Box marginY={0.5} textAlign={"center"} display={"flex"}>
                      <TextField
                        fullWidth
                        label="Vehicle Number"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            style: {
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                              height: 40,
                            },
                          },
                        }}
                        value={fatherVehicleNum}
                        inputProps={{
                          style: {
                            textTransform: "uppercase",
                          },
                        }}
                        variant="outlined"
                        sx={{ mt: 1 }}
                        size="small"
                        name="fatherVehicleName"
                        onChange={(e) => {
                          onlyCharatersVn(e);
                          setFatherVehicleNum(e.target.value.toUpperCase());
                        }}
                      />
                      {/* <Typography
                      variant="body2"
                      sx={{
                        fontSize: "16px",
                        width: "50%",
                        fontWeight: "bold",
                      }}
                    >
                      Vehicle Number :{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        // backgroundColor: "lightgray",
                      }}
                    >
                      {studentInfo.fatherVehicleNum
                        ? studentInfo.fatherVehicleNum.toUpperCase()
                        : "--"}
                    </Typography> */}
                    </Box>
                    {/* <Item>fatherVehicleNum :</Item>
                  <Item>{studentInfo.fatherVehicleNum}</Item> */}
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    borderBottom="2px solid black"
                    borderRight="2px solid black"
                  >
                    <Box marginY={0.5} textAlign={"center"} display={"flex"}>
                      <TextField
                        fullWidth
                        label="Vehicle Number"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            style: {
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                              height: 40,
                            },
                          },
                        }}
                        value={motherVehicleNum}
                        inputProps={{
                          style: {
                            textTransform: "uppercase",
                          },
                        }}
                        variant="outlined"
                        sx={{ mt: 1 }}
                        size="small"
                        name="motherVehicleNum"
                        onChange={(e) => {
                          onlyCharatersVn(e);
                          setMotherVehicleNum(e.target.value.toUpperCase());
                        }}
                      />
                      {/* <Typography
                      variant="body2"
                      sx={{
                        fontSize: "16px",
                        width: "50%",
                        fontWeight: "bold",
                      }}
                    >
                      Vehicle Number :{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        // backgroundColor: "lightgray",
                      }}
                    >
                      {studentInfo.motherVehicleNum
                        ? studentInfo.motherVehicleNum
                        : "--"}
                    </Typography> */}
                    </Box>
                    {/* <Item>motherVehicleNum :</Item>
                  <Item>{studentInfo.motherVehicleNum}</Item> */}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    borderBottom="2px solid black"
                    borderRight="2px solid black"
                  >
                    <Box marginY={0.5} textAlign={"center"} display={"flex"}>
                      <TextField
                        fullWidth
                        label="Blood Group"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            style: {
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                              height: 40,
                            },
                          },
                        }}
                        value={motherBloodGroup}
                        inputProps={{
                          style: {
                            textTransform: "uppercase",
                          },
                        }}
                        variant="outlined"
                        sx={{ mt: 1 }}
                        size="small"
                        name="motherBloodGroup"
                        onChange={(e) => {
                          onlyCharatersBg(e);
                          setMotherBloodGroup(e.target.value.toUpperCase());
                        }}
                      />
                      {/* <Typography
                      variant="body2"
                      sx={{ fontSize: "16px", fontWeight: "bold" }}
                    >
                      Blood Group :{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        // backgroundColor: "lightgray",
                      }}
                    >
                      {studentInfo.motherBloodGroup
                        ? studentInfo.motherBloodGroup.toUpperCase()
                        : "--"}
                    </Typography> */}
                    </Box>
                    {/* <Item>motherBloodGroup :</Item>
                  <Item>{studentInfo.motherBloodGroup}</Item> */}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    border="2px solid black"
                    borderTop="none"
                    borderLeft="none"
                  >
                    <Box marginY={0.5} textAlign={"center"} display={"flex"}>
                      <LocalizationProvider
                        size="small"
                        required
                        dateAdapter={AdapterDateFns}
                      >
                        <DatePicker
                          inputFormat="dd/MM/yyyy"
                          format="dd/MM/yyyy"
                          size="small"
                          label="Date of Birth"
                          value={motherDOB}
                          onChange={(newDate) => {
                            setMotherDOB(newDate);
                          }}
                          disabled={edit}
                          sx={{ backgroundColor: "#eeeeee", mt: 1 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              size="small"
                              name="motherDOB"
                            />
                          )}
                        />
                      </LocalizationProvider>
                      {/* <TextField
                      fullWidth
                      label="Email"
                      InputProps={{
                        inputProps: {
                          style: {
                            textAlign: "center",
                            fontWeight: "bold",
                          },
                        },
                      }}
                      value={motherEmailID.toUpperCase()}
                      inputProps={{
                        style: {
                          textTransform: "uppercase",
                        },
                      }}
                      variant="outlined"
                      size="small"
                      name="motherEmailID"
                      onChange={(e) => {
                        setMotherEmailID(e.target.value);
                      }}
                    /> */}
                      {/* <Typography
                      variant="body2"
                      sx={{ fontSize: "16px", fontWeight: "bold" }}
                    >
                      Mother's DOB :{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        // backgroundColor: "lightgray",
                      }}
                    >
                      {studentInfo.motherDOB ? studentInfo.motherDOB : "--"}
                    </Typography> */}
                    </Box>
                    {/* <Item>motherDOB :</Item>
                  <Item>{studentInfo.motherDOB}</Item> */}
                  </Grid>
                </Grid>

                <Grid container width="100%">
                  <Grid item xs={2} border="2px solid black" borderTop="none">
                    <Avatar
                      alt="A"
                      src={fatherAadharUrl}
                      sx={{
                        width: 150,
                        height: 120,
                        mx: 4,
                        mt: 2,
                        border: "5px solid skyblue",
                        borderRadius: "5%",
                      }}
                      variant="square"
                    />
                    <input
                      style={{ display: "none" }}
                      id="fatherAadharUrl"
                      type="file"
                      accept="image/*"
                      name="fatherAadharUrl"
                      onChange={(e) => {
                        handleFileUpload(e, e.target.id);
                      }}
                    />
                    {/* ----------------------------------STAFF IMAGE--------------------------- */}
                    <label htmlFor="fatherAadharUrl">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        disabled={edit}
                        sx={{
                          marginY: 2,
                          fontWeight: 700,
                          ml: "25%",
                        }}
                      >
                        UPLOAD
                      </Button>
                    </label>
                    {/* <Avatar
                    //   alt="Remy Sharp"
                    src={studentInfo.StudentFAprofile}
                    //   id={element.staffID}
                    variant="square"
                    sx={{
                      width: 130,
                      height: 100,
                      mx: 4,
                      mt: 2,
                      mb: 2,
                      border: "5px solid skyblue",
                      borderRadius: "5%",
                    }}
                  /> */}
                    <Box marginY={0.5} textAlign={"center"}>
                      <TextField
                        fullWidth
                        label="Aadhar Number"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            maxLength: 12,
                            style: {
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            },
                          },
                        }}
                        value={fatherAadharCardData}
                        inputProps={{
                          style: {
                            textTransform: "uppercase",
                          },
                        }}
                        variant="outlined"
                        size="small"
                        name="motherAadharCardData"
                        onChange={(e) => {
                          phoneNumber(e);
                          setFatherAadharCardData(e.target.value);
                        }}
                      />
                      {/* <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      <b> AADHAR No. : </b>{" "}
                      {studentInfo.fatherAadharCardData
                        ? studentInfo.fatherAadharCardData
                        : "--"}
                    </Typography> */}
                    </Box>
                  </Grid>
                  <Grid item xs={2} borderBottom="2px solid black">
                    <Avatar
                      alt="A"
                      src={fatherPanCardUrl}
                      sx={{
                        width: 150,
                        height: 120,
                        mx: 4,
                        mt: 2,
                        border: "5px solid skyblue",
                        borderRadius: "5%",
                      }}
                      variant="square"
                    />
                    <input
                      style={{ display: "none" }}
                      id="fatherPanCardUrl"
                      type="file"
                      accept="image/*"
                      name="fatherPanCardUrl"
                      onChange={(e) => {
                        handleFileUpload(e, e.target.id);
                      }}
                    />
                    {/* ----------------------------------STAFF IMAGE--------------------------- */}
                    <label htmlFor="fatherPanCardUrl">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        disabled={edit}
                        sx={{
                          marginY: 2,
                          fontWeight: 700,
                          ml: "25%",
                        }}
                      >
                        UPLOAD
                      </Button>
                    </label>
                    {/* <Avatar
                    src={studentInfo.StudentFPprofile}
                    //   id={element.staffID}
                    variant="square"
                    sx={{
                      width: 130,
                      height: 100,
                      mx: 4,
                      mt: 2,
                      mb: 2,
                      border: "5px solid skyblue",
                      borderRadius: "5%",
                    }}
                  /> */}
                    <Box marginY={0.5} textAlign={"center"}>
                      <TextField
                        fullWidth
                        label="Pan Card"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            maxLength: 10,
                            style: {
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            },
                          },
                        }}
                        value={fatherPanCardData}
                        // inputProps={{
                        //   style: {
                        //     textTransform: "uppercase",
                        //   },
                        // }}
                        variant="outlined"
                        size="small"
                        name="fatherPanCard"
                        onChange={(e) => {
                          onlyCharatersQual(e);
                          setFatherPanCardData(e.target.value.toUpperCase());
                        }}
                      />
                      {/* <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      <b> PAN NO :</b>{" "}
                      {studentInfo.fatherPanCardData
                        ? studentInfo.fatherPanCardData.toUpperCase()
                        : "--"}
                    </Typography> */}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    border="2px solid black"
                    borderTop="none"
                    borderRight="none"
                  >
                    <Avatar
                      alt="A"
                      src={fatherDLUrl}
                      sx={{
                        width: 150,
                        height: 120,
                        mx: 4,
                        mt: 2,
                        border: "5px solid skyblue",
                        borderRadius: "5%",
                      }}
                      variant="square"
                    />
                    <input
                      style={{ display: "none" }}
                      id="fatherDLUrl"
                      type="file"
                      accept="image/*"
                      name="fatherDLUrl"
                      onChange={(e) => {
                        handleFileUpload(e, e.target.id);
                      }}
                    />
                    {/* ----------------------------------STAFF IMAGE--------------------------- */}
                    <label htmlFor="fatherDLUrl">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        disabled={edit}
                        sx={{
                          marginY: 2,
                          fontWeight: 700,
                          ml: "25%",
                        }}
                      >
                        UPLOAD
                      </Button>
                    </label>
                    {/* <Avatar
                    src={studentInfo.StudentFDlprofile}
                    //   id={element.staffID}
                    variant="square"
                    sx={{
                      width: 130,
                      height: 100,
                      mx: 4,
                      mt: 2,
                      mb: 2,
                      border: "5px solid skyblue",
                      borderRadius: "5%",
                    }}
                  /> */}
                    <Box marginY={0.5} textAlign={"center"}>
                      <TextField
                        fullWidth
                        label="Driving License"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            style: {
                              maxLength: 16,
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            },
                          },
                        }}
                        value={fatherDLData}
                        // inputProps={{
                        //   style: {
                        //     textTransform: "uppercase",
                        //   },
                        // }}
                        variant="outlined"
                        size="small"
                        name="fatherDLdata"
                        onChange={(e) => {
                          onlyCharatersVn(e);
                          setFatherDLData(e.target.value.toUpperCase());
                        }}
                      />
                      {/* <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      <b>DRIVING LICENSE :</b>{" "}
                      {studentInfo.fatherDLData
                        ? studentInfo.fatherDLData.toUpperCase()
                        : "--"}
                    </Typography> */}
                    </Box>
                    {/* <Item>fatherDLData :</Item> */}
                    {/* <Item>{studentInfo.fatherDLData}</Item> */}
                  </Grid>
                  <Grid item xs={2} border="2px solid black" borderTop="none">
                    <Avatar
                      alt="A"
                      src={motherDLUrl}
                      sx={{
                        width: 150,
                        height: 120,
                        mx: 4,
                        mt: 2,
                        border: "5px solid skyblue",
                        borderRadius: "5%",
                      }}
                      variant="square"
                    />
                    <input
                      style={{ display: "none" }}
                      id="motherDLUrl"
                      type="file"
                      accept="image/*"
                      name="motherDLUrl"
                      onChange={(e) => {
                        handleFileUpload(e, e.target.id);
                      }}
                    />
                    {/* ----------------------------------STAFF IMAGE--------------------------- */}
                    <label htmlFor="motherDLUrl">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        disabled={edit}
                        sx={{
                          marginY: 2,
                          fontWeight: 700,
                          ml: "25%",
                        }}
                      >
                        UPLOAD
                      </Button>
                    </label>
                    {/* <Avatar
                    src={studentInfo.StudentMDlprofile}
                    //   id={element.staffID}
                    variant="square"
                    sx={{
                      width: 130,
                      height: 100,
                      mx: 4,
                      mt: 2,
                      mb: 2,
                      border: "5px solid skyblue",
                      borderRadius: "5%",
                    }}
                  /> */}
                    <Box marginY={0.5} textAlign={"center"}>
                      <TextField
                        fullWidth
                        label="Driving License"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            maxLength: 16,
                            style: {
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            },
                          },
                        }}
                        value={motherDLData}
                        inputProps={{
                          style: {
                            textTransform: "uppercase",
                          },
                        }}
                        variant="outlined"
                        size="small"
                        name="motherPanCardData"
                        onChange={(e) => {
                          onlyCharatersVn(e);
                          setMotherDLData(e.target.value.toUpperCase());
                        }}
                      />
                      {/* <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        textTransform: "uppercase",
                      }}
                    >
                      <b> PAN NO :</b>{" "}
                      {studentInfo.motherPanCardData
                        ? studentInfo.motherPanCardData.toUpperCase()
                        : "--"}
                    </Typography> */}
                    </Box>
                  </Grid>
                  <Grid item xs={2} borderBottom="2px solid black">
                    <Avatar
                      alt="A"
                      src={motherPanCardUrl}
                      sx={{
                        width: 150,
                        height: 120,
                        mx: 4,
                        mt: 2,
                        border: "5px solid skyblue",
                        borderRadius: "5%",
                      }}
                      variant="square"
                    />
                    <input
                      style={{ display: "none" }}
                      id="motherPanCardUrl"
                      type="file"
                      accept="image/*"
                      name="motherPanCardUrl"
                      onChange={(e) => {
                        handleFileUpload(e, e.target.id);
                      }}
                    />
                    {/* ----------------------------------STAFF IMAGE--------------------------- */}
                    <label htmlFor="motherPanCardUrl">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        disabled={edit}
                        sx={{
                          marginY: 2,
                          fontWeight: 700,
                          ml: "25%",
                        }}
                      >
                        UPLOAD
                      </Button>
                    </label>
                    {/* <Avatar
                    src={studentInfo.StudentMPprofile}
                    //   id={element.staffID}
                    variant="square"
                    sx={{
                      width: 130,
                      height: 100,
                      mx: 4,
                      mt: 2,
                      mb: 2,
                      border: "5px solid skyblue",
                      borderRadius: "5%",
                    }}
                  /> */}
                    <Box marginY={0.5} textAlign={"center"}>
                      <TextField
                        fullWidth
                        label="Pan Card"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            maxLength: 10,
                            style: {
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            },
                          },
                        }}
                        value={motherPanCardData}
                        inputProps={{
                          style: {
                            textTransform: "uppercase",
                          },
                        }}
                        variant="outlined"
                        size="small"
                        name="motherDLData"
                        onChange={(e) => {
                          onlyCharatersQual(e);
                          setMotherPanCardData(e.target.value.toUpperCase());
                        }}
                      />
                      {/* <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        textTransform: "uppercase",
                      }}
                    >
                      <b>DRIVING LICENSE :</b>{" "}
                      {studentInfo.motherDLData
                        ? studentInfo.motherDLData.toUpperCase()
                        : "--"}
                    </Typography> */}
                    </Box>
                  </Grid>
                  <Grid item xs={2} border="2px solid black" borderTop="none">
                    <Avatar
                      alt="A"
                      src={motherAadharUrl}
                      sx={{
                        width: 150,
                        height: 120,
                        mx: 4,
                        mt: 2,
                        border: "5px solid skyblue",
                        borderRadius: "5%",
                      }}
                      variant="square"
                    />
                    <input
                      style={{ display: "none" }}
                      id="motherAadharUrl"
                      type="file"
                      accept="image/*"
                      name="motherAadharUrl"
                      onChange={(e) => {
                        handleFileUpload(e, e.target.id);
                      }}
                    />
                    {/* ----------------------------------STAFF IMAGE--------------------------- */}
                    <label htmlFor="motherAadharUrl">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        disabled={edit}
                        sx={{
                          marginY: 2,
                          fontWeight: 700,
                          ml: "25%",
                        }}
                      >
                        UPLOAD
                      </Button>
                    </label>
                    {/* <Avatar
                    src={studentInfo.StudentMAprofile}
                    //   id={element.staffID}
                    variant="square"
                    sx={{
                      width: 130,
                      height: 100,
                      mx: 4,
                      mt: 2,
                      mb: 2,
                      border: "5px solid skyblue",
                      borderRadius: "5%",
                    }}
                  /> */}
                    <Box marginY={0.5} textAlign={"center"}>
                      <TextField
                        fullWidth
                        label="Aadhar Number"
                        InputProps={{
                          readOnly: edit,
                          inputProps: {
                            maxLength: 12,
                            style: {
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            },
                          },
                        }}
                        value={motherAadharCardData}
                        inputProps={{
                          style: {
                            textTransform: "uppercase",
                          },
                        }}
                        variant="outlined"
                        size="small"
                        name="motherAadharCardData"
                        onChange={(e) => {
                          phoneNumber(e);
                          setMotherAadharCardData(e.target.value);
                        }}
                      />
                      {/* <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      <b>AADHAR CARD :</b>{" "}
                      {studentInfo.motherAadharCardData
                        ? studentInfo.motherAadharCardData
                        : "--"}
                    </Typography> */}
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    border="2px solid black"
                    borderTop="none"
                    display="flex"
                    justifyContent="flex-start"
                  >
                    {/* <Box
                    margin={0.5}
                    textAlign={"center"}
                    display={"flex"}
                    justifyContent={"center"}
                  > */}
                    <TextField
                      fullWidth
                      label="Address"
                      InputProps={{
                        readOnly: edit,
                        inputProps: {
                          style: {
                            textAlign: "center",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                          },
                        },
                      }}
                      value={studentAddress}
                      // inputProps={{
                      //   style: {
                      //     textTransform: "uppercase",
                      //   },
                      // }}
                      sx={{ mt: 1.2 }}
                      variant="outlined"
                      size="small"
                      name="studentAddress"
                      onChange={(e) => {
                        // onlyCharaters(e);
                        setstudentAddress(e.target.value.toUpperCase());
                      }}
                    />
                    {/* <Typography variant="body2" fontWeight="bold">
                      ADDRESS :{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        textTransform: "uppercase",
                      }}
                    >
                      {studentInfo.studentAddress
                        ? studentInfo.studentAddress
                        : "--"}
                    </Typography> */}
                    {/* </Box> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container xs={2} sx={{ height: 600 }}>
                <Box display={"flex"} flexDirection={"column"}>
                  <Avatar
                    alt="A"
                    src={studentProfileUrl}
                    sx={{
                      width: 150,
                      height: 180,
                      mx: 4,
                      // mt: 2,
                      border: "2px solid skyblue",
                      borderRadius: "5%",
                    }}
                    variant="square"
                  />
                  <input
                    style={{ display: "none" }}
                    id="studentProfileUrl"
                    type="file"
                    accept="image/*"
                    name="studentProfileUrl"
                    onChange={(e) => {
                      handleFileUpload(e, e.target.id);
                    }}
                  />
                  {/* ----------------------------------STAFF IMAGE--------------------------- */}
                  <label htmlFor="studentProfileUrl">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      disabled={edit}
                      sx={{
                        marginY: 2,
                        fontWeight: 700,
                        ml: "25%",
                      }}
                    >
                      UPLOAD
                    </Button>
                  </label>{" "}
                  {/* <Avatar
                  alt="Remy Sharp"
                  src={studentInfo.Studentprofile}
                  //   id={element.staffID}
                  variant="square"
                  sx={{
                    width: 150,
                    height: 120,
                    mx: 4,
                    mt: 2,
                    border: "5px solid skyblue",
                    borderRadius: "5%",
                  }}
                /> */}
                  <Box margin={0.5} textAlign={"center"} display={"flex"}>
                    <TextField
                      fullWidth
                      label="RELIGION"
                      InputProps={{
                        readOnly: edit,
                        inputProps: {
                          style: {
                            textAlign: "center",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                          },
                        },
                      }}
                      value={studentReligion}
                      inputProps={{
                        style: {
                          textTransform: "uppercase",
                        },
                      }}
                      variant="outlined"
                      size="small"
                      name="studentReligion"
                      onChange={(e) => {
                        onlyCharaters(e);
                        setstudentReligion(e.target.value.toUpperCase());
                      }}
                    />
                    {/* <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    <b>RELIGION : </b>{" "}
                    {studentInfo.studentReligion
                      ? studentInfo.studentReligion.toUpperCase()
                      : "--"}
                  </Typography> */}
                  </Box>
                  <Box margin={0.5} textAlign={"center"}>
                    <TextField
                      fullWidth
                      label="First Language"
                      InputProps={{
                        readOnly: edit,
                        inputProps: {
                          style: {
                            textAlign: "center",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          },
                        },
                      }}
                      value={studentFirstLanguage}
                      inputProps={{
                        style: {
                          textTransform: "uppercase",
                        },
                      }}
                      variant="outlined"
                      size="small"
                      name="studentFirstLanguage"
                      onChange={(e) => {
                        onlyCharaters(e);
                        setstudentFirstLanguage(e.target.value.toUpperCase());
                      }}
                    />
                    {/* <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    <b>FIRST LANGUAGE :</b>{" "}
                    {studentInfo.studentFirstLanguage
                      ? studentInfo.studentFirstLanguage.toUpperCase()
                      : "--"}
                  </Typography> */}
                  </Box>
                  <Box margin={0.5} textAlign={"center"}>
                    <TextField
                      fullWidth
                      label="Sibling"
                      InputProps={{
                        readOnly: edit,
                        inputProps: {
                          style: {
                            textAlign: "center",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                          },
                        },
                      }}
                      value={sibling}
                      inputProps={{
                        style: {
                          textTransform: "uppercase",
                        },
                      }}
                      variant="outlined"
                      size="small"
                      name="sibling"
                      onChange={(e) => {
                        onlyCharaters(e);
                        setsibling(e.target.value.toUpperCase());
                      }}
                    />
                    {/* <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    <b>SIBLING : </b>{" "}
                    {studentInfo.sibling
                      ? studentInfo.sibling.toUpperCase()
                      : "--"}
                  </Typography> */}
                  </Box>
                  <Box margin={0.5} textAlign={"center"}>
                    <TextField
                      fullWidth
                      label="Sibling Standard"
                      InputProps={{
                        readOnly: edit,
                        inputProps: {
                          style: {
                            textAlign: "center",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                          },
                        },
                      }}
                      value={"--"}
                      inputProps={{
                        style: {
                          textTransform: "uppercase",
                        },
                      }}
                      variant="outlined"
                      size="small"
                      name="siblingStandard"
                    />
                    {/* <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    <b>SIBLING STD : </b>{" "}
                    {studentInfo.sibling ? studentInfo.siblingStandard : "--"}
                  </Typography> */}
                  </Box>
                  <Box margin={0.5} textAlign={"center"}>
                    <TextField
                      fullWidth
                      label="Guardian Name"
                      InputProps={{
                        readOnly: edit,
                        inputProps: {
                          style: {
                            textAlign: "center",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                          },
                        },
                      }}
                      value={guardianName}
                      inputProps={{
                        style: {
                          textTransform: "uppercase",
                        },
                      }}
                      variant="outlined"
                      size="small"
                      name="guardianName"
                      onChange={(e) => {
                        onlyCharaters(e);
                        setGuardianName(e.target.value.toUpperCase());
                      }}
                    />
                    {/* <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    <b>GUARDIAN : </b>{" "}
                    {studentInfo.guardianName
                      ? studentInfo.guardianName.toUpperCase()
                      : "--"}
                  </Typography> */}
                  </Box>
                  <Box margin={0.5} textAlign={"center"}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={{ color: "black" }}>RTE</InputLabel>
                      <Select
                        labelId="RtaType"
                        id="RteType"
                        value={rtestudent}
                        label="RTA"
                        variant="outlined"
                        name="rtestudent"
                        disabled={edit}
                        InputProps={{
                          inputProps: {
                            style: {
                              textAlign: "center",
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            },
                          },
                        }}
                        onChange={(e) => {
                          setrtestudent(e.target.value);
                        }}
                      >
                        <MenuItem value="True">RTE STUDENT</MenuItem>
                        <MenuItem value="NON-RTA">NON-RTE STUDENT</MenuItem>
                      </Select>
                    </FormControl>
                    {/* <TextField
                    fullWidth
                    label="RTA"
                    InputProps={{
                      inputProps: {
                        style: {
                          textAlign: "center",
                          fontWeight: "bold",
                        },
                      },
                    }}
                    value={rtestudent}
                    inputProps={{
                      style: {
                        textTransform: "uppercase",
                      },
                    }}
                    variant="outlined"
                    size="small"
                    name="rtestudent"
                    onChange={(e) => {
                      setrtestudent(e.target.value);
                    }}
                  /> */}
                    {/* <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    <b>RTA STATUS : </b>{" "}
                    {studentInfo.rtestudent == true ? "RTA" : "NON-RTA"}
                  </Typography> */}
                  </Box>
                  <Box margin={0.5} textAlign={"center"}>
                    <TextField
                      fullWidth
                      sx={{ mt: 2 }}
                      // label="Student Status"
                      InputProps={{
                        readOnly: edit,
                        inputProps: {
                          style: {
                            textAlign: "center",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            color: "#fff",
                            backgroundColor: `${
                              approveStatus ? "#00ff00" : "#ff0000"
                            } `,
                          },
                        },
                      }}
                      value={approveStatus ? "Approved" : "Not Approved"}
                      inputProps={{
                        style: {
                          textTransform: "uppercase",
                        },
                      }}
                      variant="outlined"
                      size="small"
                      name="approveStatus"
                      // onChange={(e) => {
                      //   setApproveStatus(e.target.value);
                      // }}
                    />
                    <Button
                      fullWidth
                      disabled={edit}
                      sx={{
                        mt: 2,
                        color: "#fff",
                        backgroundColor: `${
                          !approveStatus || !edit ? "#56ff56" : "#ff2e2e"
                        } `,
                        "&:hover": {
                          background: `${
                            !approveStatus ? "#00ff00" : "#ff0000"
                          } `,
                          color: "white",
                        },
                      }}
                      // onClick={() => {
                      //   setApproveStatus(!approveStatus);
                      // }}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      {!approveStatus ? "Approve" : "Unapprove"}
                    </Button>
                    {/* <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    <b>STATUS : </b>{" "}
                    {studentInfo.approveStatus == true ? "ACTIVE" : "--"}
                  </Typography> */}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* <Box
          sx={{
            display: "flex",
            mt: "10",
            flexDirection: "column",
            alignItems: "center",
            "& > *": {
              mt: 7,
            },
          }}
        ></Box> */}
          {/* <Grid conatainer>
          <Grid xs={8} sx={{ ml: 8 }}>
            <Box display={"flex"} m={1} justifyContent={"center"}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: "10px",
                  ml: 1,
                  // width: "12%",
                }}
                // disabled = {true}
                // disabled ={StudentListAll === [] ? false : true}
                onClick={() => {
                  setEdit(!edit);
                }}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                // size="large"
                color="success"
                sx={{
                  borderRadius: "10px",
                  ml: 1,
                  // width: "12%",
                }}
                // disabled = {true}
                // disabled ={StudentListAll === [] ? false : true}
                onClick={updatestudentInfor}
              >
                Update
              </Button>
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: "10px",
                  ml: 1,
                  // width: "12%",
                }}
                // disabled = {true}
                // disabled ={StudentListAll === [] ? false : true}
                onClick={updatestudentInfor}
              >
                {" "}
                EXIT
              </Button>
            </Box>
          </Grid>
        </Grid> */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              mt: 2,
              paddingBottom: 1,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                borderRadius: "30px",
                ml: 1,
                width: "140px",
              }}
              // disabled = {true}
              // disabled ={StudentListAll === [] ? false : true}
              onClick={() => {
                setEdit(!edit);
              }}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              disabled={edit}
              color="success"
              sx={{
                borderRadius: "30px",
                ml: 1,
                width: "140px",
              }}
              // disabled = {true}
              // disabled ={StudentListAll === [] ? false : true}
              onClick={updatestudentInfor}
            >
              Update
            </Button>
            <Button
              variant="contained"
              onClick={onLaunchPad}
              sx={{
                backgroundColor: "#ca424f",
                fontSize: 15,
                fontWeight: "bold",
                borderRadius: "30px",
                width: "140px",
                ml: 5,
                "&:hover": { backgroundColor: "#ca424f" },
              }}
            >
              exit
            </Button>
          </Box>
        </Box>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={openLoad}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography variant="h6" textAlign="center">
              <b>DO YOU WANT TO UNAPPROVE THIS STUDENT? </b>
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "space-evenly", mt: 3 }}
            >
              <Button
                color="success"
                size="small"
                variant="contained"
                // onClick={() => {
                //   setOpen(false);
                // }}
                onClick={() => {
                  setApproveStatus(!approveStatus);

                  setTimeout(() => {
                    setOpen(false);
                  }, 500);
                }}
              >
                YES
              </Button>
              <Button
                color="error"
                size="small"
                variant="contained"
                onClick={() => {
                  setOpen(false);
                }}
              >
                NO
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={success}
          // onClose={() => setSucess(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div sx={style}>
            <SucessModal
              text="Student Info Updated Successfully"
              name="Student Info Updated Successfully"
            />
          </div>
        </Modal>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <StudentAttendance />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        <StudentFees />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={4}>
        <StudentExam />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={5}>
        <StudentLibrary />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={6}>
        <Supplies />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={7}>
        <Document />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={8}>
        <Remarks />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={9}>
        <GovtInfo />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={10}>
        <Uploads />
      </CustomTabPanel>
    </React.Fragment>
  );
}
