import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import Topbar from "../../MainTopbar/Topbar";
import { useNavigate } from "react-router-dom";
import { FeeStructure } from "../../../services/FeeStructure";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  background: "transparent",

  ...theme.typography.body2,
  padding: theme.spacing(4),
  textAlign: "center",
  border: "2px solid blue",
  borderRadius: 10,
  color: theme.palette.text.secondary,
}));

export default function BasicGrid() {
  let navigate = useNavigate();

  const onBuildSchool = () => {
    navigate("/dashborad", { replace: true });
  };

  React.useEffect(() => {
    onFeeReviewLoad();
  }, []);

  const [fee, setFee] = React.useState([]);

  const onFeeReviewLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await FeeStructure.feesStructureListAll(data.yearName);
      console.log(response.data.data);

      setFee(response.data.data);
    } catch (error) {
      console.log(error);
      alert("Failure");
    }
  };
  return (
    <div>
      <Topbar text={"Step 5 FEES STRUCTURE SUMMARY"}></Topbar>
      <Box sx={{ flexGrow: 1, minHeight: 520 }}>
        <Grid container spacing={4}>
          {fee.map((element) => (
            <Grid item xs={2.4}>
              <Item>
                <Typography variant="subtitle1" fontWeight="bold" sx={{color:"black"}}>
                  {element.standardName}
                </Typography>
                <Typography variant="subtitle2" fontWeight="bold" sx={{color:"black"}}>Rs. {element.amount}</Typography>
              </Item>
            </Grid>
          ))}
        </Grid>
        <Button
          variant="contained"
          sx={{
            // backgroundColor: "yellow",
            // "&:hover": {
            //   backgroundColor: "yellow",
            // },
            // border: "3px solid orange",
            // color: "black",
            borderRadius: "25px",
            // borderRadius: "30px",
            m: 2,
          }}
          onClick={onBuildSchool}
        >
          PROCEED TO NEXT STEP
        </Button>
      </Box>
    </div>
  );
}
