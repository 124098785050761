import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class ExamMarksService {
  static service = "marksheet/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static createMarks(contact) {
    var dataURL = this.serverURL +"create";

    return axios.post(dataURL,contact);
  }
  //143.110.188.253:1919/marksheet/getbyclassdivision?divisionID=A&standardID=7cbc2749-3b51-406a-8f90-c3175b09a270&standardTestID=a36eac02-69c5-4772-aa09-6adec59d4291x&subjectID=f77b23d6-cd39-43a7-8b64-49f58aa35aeb&yearName=48c22c02-e9e1-41dc-a9a4-d020ebcdaecc
  static marksList(division, standardID, testID, subjectID, yearName) {
    var dataURL =
      this.serverURL +
      "getbyclassdivision?divisionID=" +
      division +
      "&standardID=" +
      standardID +
      "&standardTestID=" +
      testID +
      "&subjectID=" +
      subjectID +
      "&yearName=" +
      yearName;
    // var dataURL = this.serverURL + "getbyclassdivision";
    return axios.get(dataURL);
  }
}
