import { styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import * as React from "react";
import { AddStandarsService } from "../../../services/addStandardsService";
import { StandardDivision } from "../../../services/StandardDIvision";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { C } from "../../util/C";
import Img from "../../launchPad/student.jpg";
import { StudentService } from "../../../services/studentService";
// ----------------Student Lists Import---------------------------
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { UploadFileService } from "../../../services/FileUploadService";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "100%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1350,
  height: 565,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};
const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color: "white",
  },
}));
const ItemD = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#03dff9",
  "&:hover": {
    background: "#038da6",
    color: "white",
  },
}));

const Item1 = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
  border: "1px solid black",
}));

const Item = styled(Paper)(({ theme }) => ({
  // padding: theme.spacing(1),
  // background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
  textAlign: "center",
  // border: "1px solid black",
}));

export default function IdCards() {
  const [Standard, setStandard] = React.useState([]);
  React.useEffect(() => {
    onstandardsLoad();
  }, []);

  const onstandardsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      C.c(response.data.data);
      setStandard(response.data.data);
    } catch (error) {
      C.c(error);
    }
  };
  const [standardName, setstandardName] = React.useState();
  const [StandardID, setStandardID] = React.useState();
  const [DivisionName, setDivisionName] = React.useState();
  const [Division, setDivision] = React.useState([]);
  const [StudentListAll, SetStudentListAll] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [academicYear, setacademicYear] = React.useState(
    JSON.parse(localStorage.getItem("academicYear"))
  );
  const [schoolName, setschoolName] = React.useState(
    JSON.parse(localStorage.getItem("schoolDetails"))
  );

  async function onStandardSelect(e, index) {
    e.preventDefault();
    setstandardName(e.target.innerText);
    setStandardID(e.target.id);
    C.c(e.currentTarget.innerText);

    let highlightStandardList = [...Standard];
    highlightStandardList = highlightStandardList.map(
      ({ isSelected, ...highlightStandardList }) => highlightStandardList
    );
    setStandard(highlightStandardList);
    highlightStandardList[index]["isSelected"] = highlightStandardList[index][
      "isSelected"
    ]
      ? false
      : true;

    let data = JSON.parse(localStorage.getItem("academicYear"));
    //   const schoolData = JSON.parse(localStorage.getItem("schoolDetails"));
    //   const academicData = JSON.parse(localStorage.getItem("academicYear"));
    //   setschoolName(
    //    schoolData ? (
    //      <b>{schoolData.schoolNameOnDocs}</b>
    //    ) : (
    //      "NEW INTERNATIONAL SCHOOL"
    //    )
    //  );
    //  setacademicYear(academicData.yearName);
    //  console.log(academicData);
    try {
      let response = await StandardDivision.StandardDivisionWiseStandard(
        e.currentTarget.id,
        data.yearName,
        data.yearID
      );
      C.c(response.data.data);
      setDivision(response.data.data);
    } catch (error) {
      C.c(error.message);
    }
  }
  async function onSelectDivision(e, index) {
    console.log(e.currentTarget.id);
    setDivisionName(e.currentTarget.id);

    let highlightDivision = [...Division];
    highlightDivision = highlightDivision.map(
      ({ isSelected, ...highlightDivision }) => highlightDivision
    );
    setDivision(highlightDivision);
    highlightDivision[index]["isSelected"] = highlightDivision[index][
      "isSelected"
    ]
      ? false
      : true;

    let data = JSON.parse(localStorage.getItem("academicYear"));
    let student = [];
    try {
      let response = await StudentService.approveddivisionstudentListAll(
        data.yearName,
        e.currentTarget.id,
        StandardID
      );
      C.c(response.data.data);

      response.data.data.forEach(async (rec) => {
        try {
          let responseFile = await UploadFileService.downloadFile(
            rec.studentProfileUrl
          );
          console.log(responseFile.request.responseURL);
          rec.imgURL = responseFile.request.responseURL;
        } catch (error) {
          console.log(error.message);
        }
      });
      SetStudentListAll(response.data.data.sort((a,b)=> a.studentName.localeCompare(b.studentName)));
    } catch (error) {
      C.c(error.message);
    }
  }

  let navigate = useNavigate();
  const onLaunchPad = () => {
    navigate("/launchpad", { replace: true });
  };
  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="reports" /> */}

      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            backgroundColor: "lightblue",
            my: 2,
            fontWeight: "bold",
          }}
        >
          SELECT STANDARD, DIVISION TO DISPLAY ID CARDS
        </Typography>
        {/* <Box sx={{ flexGrow: 1 }}> */}
        <Grid container spacing={1}>
          <Grid item1 xs={8}>
            <Item1>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Standard.map((element, index) => (
                  <Grid item xs={2} sx={{ cursor: "pointer" }}>
                    <ItemS
                      id={element.standardID}
                      name={element.standardName}
                      onClick={(e) => {
                        onStandardSelect(e, index);
                      }}
                      className={element.isSelected ? "Selected" : ""}
                      sx={{ fontWeight: "bold" }}
                    >
                      {element.standardName}
                    </ItemS>
                  </Grid>
                ))}
              </Grid>
            </Item1>
          </Grid>
          <Grid item xs={4}>
            <Item1>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Division.map((element, index) => (
                  <Grid item xs={2} sx={{ cursor: "pointer" }}>
                    <ItemD
                      sx={{
                        fontWeight: "bold",
                      }}
                      onClick={(e) => {
                        onSelectDivision(e, index);
                      }}
                      className={element.isSelected ? "selected" : ""}
                      id={element.divisionName}
                    >
                      {" "}
                      {element.divisionName}
                    </ItemD>
                  </Grid>
                ))}
              </Grid>
            </Item1>
          </Grid>
        </Grid>
        {/* </Box> */}
        <Grid container spacing={2} sx={{padding:"0 0 15px 0"}}>
          <Grid xs={4}>
            <Grid
              sx={{
                width: "70%",
                marginTop: "8%",
                marginLeft: "5%",
                height: "55%",
              }}
            >
              <Typography
                sx={{
                  border: "2px solid black",
                  width: "540px",
                }}
                variant="body1"
                fontWeight="fontWeightBold"
                color="primary"
                backgroundColor="lightgray"
                textAlign="center"
              >
                STUDENTS LIST
              </Typography>
              <Item
                sx={{
                  border: "1px solid black",
                  width: "540px",
                  textAlign: "center",
                  overflowY: "scroll",
                  height: 320,
                }}
              >
                {/* <Stack sx={{border: '1px solid black'}}> */}
                <List
                  dense
                  sx={{
                    width: 500,
                    bgcolor: "background.paper",
                    textAlign: "center",
                  }}
                >
                  {StudentListAll.map((value) => {
                    //  const labelId = `checkbox-list-secondary-label-${value}`;
                    return (
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar
                            alt={`Avatar n°${value + 1}`}
                            //  src={Img}
                          />
                        </ListItemAvatar>
                        {/* <ListItemText id={labelId} primary={`Student Details ${value + 1}`} /> */}
                        <ListItemText id={value.divisionName}>
                          <b>Student Name :</b>{" "}
                          {value.studentName
                            ? value.studentName
                            : "invalid name"}{" "}
                          || <b>Division :</b> {value.divisionName}
                        </ListItemText>
                      </ListItemButton>
                    );
                  })}
                </List>

                {/* </Stack>   */}
              </Item>
            </Grid>
          </Grid>
          <Grid item xs={8} >
            <Box sx={{ width: "70%", marginTop: "3%",ml:"10%"}}>
              <Grid container rowSpacing={1} mt="20">
                <Grid
                  item
                  xs={6}
                  sx={{ border: "1px solid black", padding: "5px" }}
                >
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={{ textAlign: "center", fontWeight: "bold" }}
                  >
                    <b>{schoolName.schoolNameOnDocs.toUpperCase()}</b>
                  </Typography>
                  <Avatar
                    alt="Remy Sharp"
                    variant="square"
                    // src={Img}
                    sx={{
                      width: 150,
                      height: 110,
                      ml: 9,
                      my: 1,
                       float:'center',
                      border: "5px solid skyblue",
                      borderRadius: "5%",
                    }}
                  />
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={{ textAlign: "center" }}
                  >
                    STUDENT'S NAME
                  </Typography>
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={{ textAlign: "center" }}
                  >
                    Blood Group
                  </Typography>
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={{ textAlign: "center" }}
                  >
                    STUDENT'S USN
                  </Typography>
                  {/* <Typography variant='body1' color='secondary' sx={{ textAlign: 'center' }}>FATHER NAME

                  </Typography>
                  <Typography variant='body1' color='secondary' sx={{ textAlign: 'center' }}>MOTHER NAME

                  </Typography> */}
                  <Typography
                    variant="body1"
                    color="secondary"
                    sx={{ textAlign: "center" }}
                  >
                    Contact Numbers:
                    <br />
                  </Typography>
                  <Typography
                    variant="body1"
                    color="secondary"
                    sx={{ textAlign: "center" }}
                  >
                    {standardName} - {DivisionName}
                    <br />
                  </Typography>
                  <Typography
                    variant="body1"
                    color="secondary"
                    sx={{ textAlign: "center" }}
                  >
                    <br />
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ border: "1px solid black", borderLeft: "none" }}
                >
                  {/* <Item>2</Item> */}
                  <Typography
                    variant="h6"
                    color="primary"
                    textAlign="center"
                    sx={{ fontWeight: "bold" }}
                  >
                    <b>{schoolName.schoolNameOnDocs.toUpperCase()}</b>
                  </Typography>
                  <Typography textAlign="center">
                    {schoolName.schoolAddress.toUpperCase()}
                  </Typography>
                  <Typography textAlign="center">
                    PHONE NO. : {schoolName.schoolOfficialMobileOne}
                  </Typography>
                  <Typography variant="body2" textAlign="center" color="blue" sx={{fontSize:14.9}}>
                    EMAIL: {schoolName.schoolOfficialEmailID.toUpperCase()}
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    sx={{ marginTop: "4%" }}
                  >
                    <b>FATHER'S NAME</b>
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    <b>MOTHER'S NAME:</b>
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    <b>PHONE:</b>
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    <b>ADDRESS:</b>
                    {/* <Typography>Sudent's Address</Typography> */}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          </Grid>
          {/* <Grid container spacing={2}> */}
            {/* <Grid item xs={12} sx={{ marginTop: "30px", ml: "40%" }}> */}
              {/* <Box sx={{ "& button": { m: 1 } }}> */}
              <Box sx={{display:"flex", justifyContent:"space-evenly"}}>
              <Button
                variant="contained"
                size="large"
                color="success"
                sx={{
                  borderRadius: "30px",
                  fontSize: 15,
                  fontWeight: "bold",
                  
                }}
                onClick={handleOpen}
              >
                Generate ID CardS
              </Button>

              <Button
            variant="contained"
            size="large"
            onClick={onLaunchPad}
            sx={{
              backgroundColor: "#ca424f",
              fontSize: 15,
              fontWeight: "bold",
              borderRadius: "30px",
              width: "120px",
              "&:hover": { backgroundColor: "#ca424f" },
              mt: 1,
            }}
          >
            exit
          </Button>
              </Box>
              <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                <Box sx={{position: 'sticky', top: -33, zIndex: 1000,width:"100%"}}>

<Box sx={{float:"right",width:"100%",backgroundColor:"lightblue"}}> 
<Button variant="contained" color="error" sx={{float:"right"}} 
onClick={()=>{
setOpen(false);
// setIfo({});
}}
>
close</Button>
<Typography variant="h5" fontWeight="bold" textAlign={"center"}sx={{ml:8}} >
  GENERATED ID CARDS
</Typography>            
  
</Box>
</Box>
                  {/* <Typography variant="h4" textAlign="center">
                    <b>GENERATED ID CARDS</b>
                  </Typography> */}
                  {StudentListAll.map((rec) => (
                    <Grid container rowSpacing={1} sx={{ mt: 6, display:"flex", justifyContent:"center" }}>
                      <Grid
                        item
                        xs={3}
                        sx={{ border: "1px solid black", padding: "1px" }}
                      >
                        <Typography
                          variant="h6"
                          color="primary"
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          <b>{schoolName.schoolNameOnDocs.toUpperCase()}</b>
                        </Typography>
                        <Avatar
                          alt="Remy Sharp"
                          variant="square"
                          src={rec.studentProfileUrl}
                          sx={{
                            width: 150,
                            height: 185,
                            mx: 11,
                            // my: 1,
                            //  float:'center',
                            border: "5px solid skyblue",
                            borderRadius: "5%",
                          }}
                        />
                        <Typography
                          variant="h6"
                          color="primary"
                          sx={{ textAlign: "center" }}
                        >
                       NAME :  {rec.studentName ? rec.studentName : ""}
                        </Typography>

                        <Typography
                          variant="h6"
                          color="primary"
                          sx={{ textAlign: "center" }}
                        >
                       USN : {rec.studentUSN ? rec.studentUSN : ""}
                        </Typography>

                        <Typography
                          variant="h6"
                          color="primary"
                          sx={{ textAlign: "center" }}
                        >
                       BLOOD GROUP  : {rec.studentBloodGroup ? rec.studentBloodGroup : ""}
                        </Typography>

                        <Typography
                          variant="h6"
                          color="primary"
                          sx={{ textAlign: "center" }}
                        >
                      GENDER : {rec.studentGender ? rec.studentGender : " "}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="secondary"
                          sx={{ textAlign: "center" }}
                        >
                          CONTACT NO. : {rec.fatherMobNum}
                          <br />
                          {standardName} - {DivisionName}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          fontWeight="bold"
                          textAlign="center"
                          sx={{ flexGrow: 1 }}
                        >
                          ACADEMIC YEAR :{" "}
                          {academicYear.yearName ? academicYear.yearName : ""}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{ border: "1px solid black", borderLeft: "none" }}
                      >
                        {/* <Item>2</Item> */}
                        <Typography textAlign="center" sx={{textTransform:"uppercase"}}>
                          <b>School address :</b>
                          {schoolName.schoolAddress}
                        </Typography>
                        <Typography textAlign="center" sx={{textTransform:"uppercase"}}>
                          <b>Phone no. :</b>
                          {schoolName.schoolOfficialMobileOne} /{" "}
                          {schoolName.schoolOfficialMobileTwo}
                        </Typography>
                        <Typography
                          variant="body1"
                          textAlign="center"
                          color="blue"
                          sx={{textTransform:"uppercase"}}
                        >
                          <b>Email:</b> {schoolName.schoolOfficialEmailID}
                        </Typography>
                        {/* <Typography variant='body1' textAlign="center" sx={{ marginTop: '4%' }}>Students detail</Typography>
                    <Typography variant='body1' textAlign="center">Name: Samir Shah</Typography>
                    <Typography variant='body1' textAlign="center">USID: SS032</Typography>
                    <Typography variant='body1' textAlign="center">Blood Group: O+</Typography>
                    <Typography variant='body1' textAlign="center">Std I – Div – A</Typography> */}
                        {/* <Typography variant='body1' textAlign="center">Academic year : 2021-2022</Typography> */}
                        <Typography
                          variant="body1"
                          textAlign="center"
                          sx={{ marginTop: "4%",textTransform:"uppercase" }}
                        >
                          <b>Father's name:</b> {rec.fatherName ? rec.fatherName : " "}
                        </Typography>
                        <Typography variant="body1" textAlign="center" sx={{textTransform:"uppercase"}}>
                          <b>Mother's name:</b> {rec.motherName ? rec.motherName : " "}
                        </Typography>
                        <Typography variant="body1" textAlign="center" sx={{textTransform:"uppercase"}}>
                          <b>Phone no.:</b>
                          {rec.fatherMobNum
                            ? rec.fatherMobNum
                            : "No number"} /{" "}
                          {rec.motherMobNum ? rec.motherMobNum : "No number"}
                        </Typography>
                        <Typography variant="body1" textAlign="center" sx={{textTransform:"uppercase"}}>
                          <b>Address:</b>
                          <Typography>
                            {rec.studentAddress
                              ? rec.studentAddress
                              : "No Address"}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                  {/* <Button
                    variant="contained"
                    size="large"
                    color="warning"
                    sx={{
                      marginLeft: "28%",
                      borderRadius: "30px",
                      width: "20%",
                      marginTop: "5%",
                    }}
                  >
                    PDF
                  </Button> */}
                  {/* <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    sx={{
                      marginLeft: "2%",
                      borderRadius: "30px",
                      width: "20%",
                      marginTop: "5%",
                    }}
                  >
                    Print
                  </Button> */}
                </Box>
              </Modal>
            {/* </Grid> */}
          {/* </Grid> */}
        
      </Box>
    </React.Fragment>
  );
}
