import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, TextField, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { StudentInfoService } from "../../../services/StudentInfo/StudentList";
import { useNavigate } from "react-router-dom";
import { FeeCollectionStructure } from "../../../services/Feescollectionservice";
import { UploadFileService } from "../../../services/FileUploadService";

const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  //   ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  // color: theme.palette.text.secondary,
}));

export default function BasicGrid() {
  const navigate = useNavigate();

  const gotoStudentInfo = async (e) => {
    // console.log();
    localStorage.setItem(
      "studentUSN",
      e.currentTarget.parentNode.parentNode.getAttribute("data-id")
    );
    navigate("/EditStudentsFeesTag2", { replace: true });
  };
  React.useEffect(() => {
    onStudentsListLoad();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "No.",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"No."}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "name",
      headerName: "Name",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"Name"}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"Gender"}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "usid",
      headerName: "USID",
      // type: 'number',
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"USID"}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "std",
      headerName: "STD/DIV",
      // description: 'This column has a value getter and is not sortable.',
      // sortable: false,
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"STD/DIV"}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "fathername",
      headerName: "Fathers Name",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"Fathers Name"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },

    {
      field: "totalFees",
      headerName: "TotalFees",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"TotalFees"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "paid",
      headerName: "PAID",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"PAID"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "concession",
      headerName: "Concession",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"Concession"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"Balance"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    // {
    //   field: "info",
    //   headerName: "Show Info",
    //   flex: 1,
    //   headerAlign: "center",
    //   renderHeader: () => (
    //     <strong style={{ fontSize: 15 }}>{"Show Info"} </strong>
    //   ),
    //   headerClassName: "super-app-theme--header",
    //   renderCell: () => {
    //     return (
    //       <Button
    //         variant="contained"
    //         color="primary"
    //         // sx={{ height: 27, my: "5px", ml: "6px" }}
    //         onClick={gotoStudentInfo}
    //         // id={}
    //       >
    //         Show Info
    //       </Button>
    //     );
    //   },
    // },
  ];
  const columnstd = [
    {
      field: "id",
      headerName: "No.",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"No."}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"Name"}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"Gender"}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "usid",
      headerName: "USID",
      // type: 'number',
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"USID"}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "std",
      headerName: "STD/DIV",
      // description: 'This column has a value getter and is not sortable.',
      // sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"STD/DIV"}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "fathername",
      headerName: "Fathers Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"Fathers Name"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },

    {
      field: "totalFees",
      headerName: "TotalFees",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"TotalFees"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "paid",
      headerName: "PAID",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"PAID"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "concession",
      headerName: "Concession",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"Concession"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"Balance"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    // {
    //   field: "info",
    //   headerName: "Show Info",
    //   flex: 1,
    //   headerAlign: "center",
    //   renderHeader: () => (
    //     <strong style={{ fontSize: 15 }}>{"Show Info"} </strong>
    //   ),
    //   headerClassName: "super-app-theme--header",
    //   renderCell: () => {
    //     return (
    //       <Button
    //         variant="contained"
    //         color="primary"
    //         // sx={{ height: 27, my: "5px", ml: "6px" }}
    //         onClick={gotoStudentInfo}
    //         // id={}
    //       >
    //         Show Info
    //       </Button>
    //     );
    //   },
    // },
  ];
  const columnaf = [
    {
      field: "id",
      headerName: "No.",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"No."}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "feesTitle",
      headerName: "Fees Title",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"Fees Title"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },

    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"Amount"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "recipt_no",
      headerName: "Recipe No",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"Recipe No"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "paid",
      headerName: "Paid",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"Paid"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"Balance"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "concession",
      headerName: "Concession",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"Concession"}</strong>
      ),
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "remake",
      headerName: "remake",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"remake"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
  ];
  const columnf = [
    {
      field: "id",
      headerName: "No.",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"No."}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "feesTitle",
      headerName: "Fees Title",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"Fees Title"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },

    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"Amount"}</strong>,
      headerClassName: "super-app-theme--header",
    },
  ];

  const [studentsList, setStudentList] = React.useState([]);
  const [filterStudentsList, setFilterStudentList] = React.useState([]);

  const onStudentsListLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    try {
      // alert(data.yearName);
      let response = await FeeCollectionStructure.StudentFeesCollectionList(
        data.yearName
      );
      console.log(response.data.data);
      setStudentList(response.data.data);
    } catch (error) {
      console.log(error);
    }

    // try {
    //   // alert(data.yearName);
    //   let response = await StudentInfoService.studentInfoListStudents(
    //     data.yearName
    //   );
    //   console.log(response.data.data);
    //   setStudentList(response.data.data);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const [selectedStudentsList, setselectedStudentList] = React.useState([]);
  const [selected, setselected] = React.useState(false);
  const onSelectStudent = (data) => {
    console.log(data);
    setselected(true);
    setselectedStudentList([data]);
    onAppliedStudentDataLoad(data);
    studentDetailsLoad(data.usid);
  };

  const [studentInfo, updateStudentInfo] = React.useState({});

  const studentDetailsLoad = async (usid) => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await StudentInfoService.studentInfoShowStudentDetails(
        usid,
        data.yearName
      );
      console.log(response.data.data);
      updateStudentInfo(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const onSearch = (v) => {
    console.log(v);
    const student = [];
    if (v != "") {
      let filtered = studentsList.filter(
        (e) => e.studentName.toLowerCase() == v.toLowerCase() || e.usid == v
      );
      console.log(filtered);
      if (filtered.length > 0) {
        filtered.map((e, i) => {
          student.push({
            id: i + 1,
            name: e.studentName.toUpperCase(),
            gender: e.gender,
            usid: e.usid.toUpperCase(),
            std: e.standardName,
            fathername: e.fatherName.toUpperCase(),
            totalFees: e.totalFees ? e.totalFees : "0",
            paid: e.paid ? e.paid : "0",
            concession: e.concession ? e.concession : "0",
            balance: e.balance ? e.balance : "0",
            divison: e.division,
          });
        });
        setFilterStudentList(student);
      }
    } else {
      setFilterStudentList(student);
    }
  };

  const [appliedFees, setAppliedFees] = React.useState([]);

  const onAppliedStudentDataLoad = async (s) => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let gridData = [];

    try {
      let response =
        await FeeCollectionStructure.getIndividualstudentappliedFeereceipts(
          s.name,
          s.usid,
          data.yearName
        );
      console.log(response.data.data);
      response.data.data.forEach((r, i) => {
        console.log(r, i);
        gridData.push({
          id: i,
          feesTitle: r.feestitleName.toUpperCase(),
          amount: r.feesAmount ? r.feesAmount : "0",
          receiptNo: r.feesReceiptNumber,
          paid: r.feesPaid ? r.feesPaid : "0",
          concession: r.concessions,
          balance: r.balance ? r.balance : "0",
        });
      });
      setAppliedFees(gridData);
    } catch (error) {
      console.log(error);
    }
  };

  const onCons = (r, v) => {
    const found = appliedFees.find((element) => element.id == r.id);
    found.concession = v;
    console.log(found);
  };
  const onLogout = () => {
    console.log("onLogout");
    navigate("/launchpad", { replace: true });
  };

  const onFeeApplies = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    // console.log(filterStudentsList);
    console.log(selectedStudentsList);
    console.log(studentInfo);
    console.log(appliedFees);
    let obj = [];
    let TotalBalance = 0;
    let TotalBalancefinal = 0;
    let TotalConcession = 0;
    appliedFees.forEach((r) => {
      console.log(r);
      TotalConcession = TotalConcession + parseInt(r.concession);
      TotalBalance = TotalBalance + parseInt(r.balance);
      let balance = parseInt(r.balance) - parseInt(r.concession);
      let amount = parseInt(r.amount) - parseInt(r.concession);
      obj.push({
        balance: balance,
        concessions: r.concession,
        feesAmount: r.amount,
        feesPaid: r.paid,
        feesReceiptNumber: r.receiptNo,
        feestitleName: r.feesTitle,
        studentGender: selectedStudentsList[0].gender,
        studentName: selectedStudentsList[0].name,
        studentUSN: selectedStudentsList[0].usid,
        remark: r.remake,
        yearID: data.yearID,
        yearName: data.yearName,
      });
    });
    TotalBalancefinal = TotalBalance - TotalConcession;

    try {
      let response =
        await FeeCollectionStructure.createStudentAppliedFeesCollectionReciept(
          obj
        );
    } catch (error) {
      console.log(error);
    }
    setTimeout(async () => {
      let obj2 = {
        balance: TotalBalancefinal,
        concession: TotalConcession,
        division: selectedStudentsList[0].divison,
        fatherName: selectedStudentsList[0].fathername,
        gender: selectedStudentsList[0].gender,
        // idStudentFeesCollection: "string",
        paid: selectedStudentsList[0].paid,
        standardName: selectedStudentsList[0].std,
        studentName: selectedStudentsList[0].name,
        totalFees: selectedStudentsList[0].totalFees,
        usid: selectedStudentsList[0].usid,
        yearID: data.yearID,
        yearName: data.yearName,
      };

      try {
        let response = await FeeCollectionStructure.createStudentFeesCollection(
          obj2
        );
      } catch (error) {
        console.log(error);
      }
      console.log(obj2);
    }, 2000);

    console.log(obj);
    // console.log(TotalBalancefinal);
    // console.log(TotalConcession);

    // try {
    //   let response = await StudentFeeStructure.studentsFeesAppliedCreate(obj);
    //   C.c(response.data.data);
    // } catch (error) {
    //   C.c(error);
    // }
  };

  return (
    <div>
      {/* <LaunchpadTopBar id="fees" /> */}
      {!selected ? (
        <Box sx={{ mt: 4 }}>
          <Box display={"flex"} justifyContent={"center"}>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  // backgroundColor: "lightblue",
                  m: "10px 10px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Search Student
              </Typography>
              <TextField
                id="search"
                label="Search"
                variant="outlined"
                sx={{
                  margin: "10px",
                }}
                onChange={(e) => onSearch(e.target.value)}
              />
            </Box>
          </Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Box sx={{ height: 450, width: "88%" }}>
              <DataGrid
                disableColumnMenu
                rows={filterStudentsList ? filterStudentsList : []}
                columns={columns}
                onRowDoubleClick={(r) => onSelectStudent(r.row)}
                rowsPerPageOptions={[10, 25, 50, 100]}
                sx={{
                  "& .super-app-theme--header": {
                    backgroundColor: "#c0c0c0",
                    //  color:'white',
                    fontWeight: "bold",
                    fontSize: "15px",
                    border: "1px solid white",
                  },
                }}
              />
            </Box>
          </Box>
          <Button
            variant="contained"
            onClick={onLogout}
            color="error"
            sx={{
              borderRadius: "10px",
              fontWeight: "bold",
              ml: 155,
              mt: 2,
              backgroundColor: "#ca424f",
              color: "white",
            }}
          >
            DO NOTHING-EXIT
          </Button>
        </Box>
      ) : (
        <Box sx={{ mt: 4 }}>
          <Grid container item>
            <Grid item xs={12}>
              <Box sx={{ width: "100%", display: "flex" }}>
                {/* student display from localstorage */}
                <Box sx={{ height: 200, width: "100%", margin: 5 }}>
                  <DataGrid
                    disableColumnMenu
                    rows={selectedStudentsList}
                    columns={columnstd}
                    rowsPerPageOptions={[10]}
                    sx={{
                      "& .super-app-theme--header": {
                        backgroundColor: "#c0c0c0",
                        //  color:'white',
                        fontWeight: "bold",
                        fontSize: "15px",
                        border: "1px solid white",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container item>
            <Grid item xs={4}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <Box sx={{ height: 500, width: "100%", margin: 5 }}>
                  <h3>Applied Fee</h3>
                  <DataGrid
                    disableColumnMenu
                    rows={appliedFees ? appliedFees : []}
                    columns={columnf}
                    rowsPerPageOptions={[10]}
                    sx={{
                      "& .super-app-theme--header": {
                        backgroundColor: "#c0c0c0",
                        //  color:'white',
                        fontWeight: "bold",
                        fontSize: "15px",
                        border: "1px solid white",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box sx={{ width: "100%", display: "flex" }}>
                {/* student display from localstorage */}
                <Box sx={{ height: 500, width: "100%", margin: 5 }}>
                  <h3>Applied Fee right</h3>
                  <DataGrid
                    disableColumnMenu
                    rows={appliedFees ? appliedFees : []}
                    columns={columnaf}
                    rowsPerPageOptions={[10]}
                    onCellEditCommit={(e) => onCons(e.row, e.value)}
                    sx={{
                      "& .super-app-theme--header": {
                        backgroundColor: "#c0c0c0",
                        //  color:'white',
                        fontWeight: "bold",
                        fontSize: "15px",
                        border: "1px solid white",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              m: 5,
            }}
          >
            <Button variant="contained" onClick={onFeeApplies}>
              Apply Fee
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
}
