import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TopBar from "../../MainTopbar/Appbar";
import ClassRoutine from "./classRoutineCreate";
import TeacherRoutine from "./teacheRoutine";

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ height: "100%", width: "100%" }}>{children}</Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [highlight, setHighlight] = React.useState(false);

  const Click = (newValue) => {
    setValue(newValue);
    setHighlight(newValue);
    console.log(value);
    console.log(highlight);
  };

  return (
    <div>
      <TopBar />

      <Box
        sx={{
          border: "2px solid black",
          background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
        }}
      >
        <Grid container spacing={2} py={0.5}>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="warning"
              fullWidth
              size="small"
              sx={{ fontWeight: "bold" }}
            >
              ROUTINE
            </Button>
          </Grid>
          <Grid item xs={1.6}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(0)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 0 ? "black" : "",
                color: value == 0 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              CLASS ROUTINE
            </Button>
          </Grid>
          <Grid item xs={1.8}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(1)}
              // onClick={() => alert("Coming Soon")}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 1 ? "black" : "",
                color: value == 1 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              TEACHER ROUTINE
            </Button>
          </Grid>
        </Grid>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <ClassRoutine />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TeacherRoutine />
      </CustomTabPanel>
    </div>
  );
}
