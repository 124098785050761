import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { StaffService } from "../../../services/StaffService";
import { useNavigate } from "react-router-dom";
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

export default function DataGridDemo() {
  React.useEffect(() => {
    onStaffLoad();
  }, []);

  const [columns, setColumns] = React.useState([
    {
      field: "id",
      headerName: "NO",
      headerAlign: "center",
      align:"center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"} </strong>,
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "ssid",
      headerName: "SSID",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"SSID"} </strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "staffName",
      headerName: "STAFF NAME",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"STAFF NAME"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "accountNo",
      headerName: "ACCOUNT NO",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"ACCOUNT NO"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "ifsCode",
      headerName: "IFSC CODE",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"IFSC CODE"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "bank",
      headerName: "BANK",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"BANK"} </strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "branch",
      headerName: "BRANCH",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"BRANCH"} </strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "paySalary",
      headerName: "PAY SALARY",
      flex: 1,
      editable: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"PAY SALARY"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
  ]);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [staff, setStaff] = React.useState([]);

  const onStaffLoad = async () => {
    setLoading(true);
    let yearData = JSON.parse(localStorage.getItem("academicYear"));
    console.log(yearData);

    try {
      let bankObj = [];
      // setDetails(response.data.data);
      // setStaff(response.data.data);

      let response = await StaffService.bankDetails("NOVEMBER", yearData.yearName);
      // details(data.yearName);
      bankObj = response.data.data;
      let bankArr = [];
      bankObj.forEach((rec,i) => {
        // console.log(rec.bankDetails.ssid)

        if (rec.bankDetails) {
          bankArr.push({
            // id: rec.bankDetails.ssid,
            id: i+1,
            ssid: rec.bankDetails.ssid,
            staffName: rec.bankDetails.staffName,
            accountNo: rec.bankDetails.accountNo,
            ifsCode: rec.bankDetails.ifscDetails,
            bank: rec.bankDetails.bankName,
            branch: rec.bankDetails.branch,
            paySalary: rec.salary,
          });
        }
      });
      console.log(bankArr);
      setRows(bankArr);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  let navigate = useNavigate();
  const onLogout = () => {
    console.log("onLogout");
    navigate("/launchpad", { replace: true });
  };

  const [academicYear, setacademicYear] = React.useState(
    JSON.parse(localStorage.getItem("academicYear"))
  );
  const [schoolName, setschoolName] = React.useState(
    JSON.parse(localStorage.getItem("schoolDetails"))
  );

  const generatePDF = () => {
    const doc = new jsPDF("landscape");
    const sch = schoolName.schoolNameOnDocs.toUpperCase();
    const acad = academicYear.yearName;
    const img = schoolName.schoolLogoUrl;
    doc.setFont("helvetica", "bold");
    // doc.text(`${sch}`, 150, 35);
    var fontSize = 18;
    var text = `${sch}`;
    var pageWidth = doc.internal.pageSize.getWidth();
    var textWidth =
      (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
    var centerX = (pageWidth - textWidth) / 2;
    doc.text(text, centerX, 15);

    doc.setFontSize(10);
    doc.text(` ${acad}`, 250, 15);
    doc.addImage(img ,"JPEG", 15,5,27,27);
    doc.setFontSize(15);
    doc.text("STAFF SALARY CREDIT TO BANK", 105, 35);

    const tableData = rows.map((row) => [
      row.id,
      row.ssid,
      row.staffName,
      row.accountNo,
      row.ifsCode,
      row.bank,
      row.branch,
      row.paySalary,
    ]);
    doc.autoTable({
      startY: 40,
      head: [
        [
          "NO",
          "SSID",
          "STAFF NAME",
          "ACCOUNT NO",
          "IFSC CODE",
          "BANK",
          "BRANCH",
          "PAY SALARY",
        ],
      ],
      body: tableData,
      theme: "grid",
      headStyles: {
        valign: "middle",
        backgroundColor: "lightgray",
        halign: "center",
      },
      bodyStyles: {
        valign: "middle",
        halign: "center",
      },
    });
    doc.setFontSize(10);
    // doc.text("Copyright: Technotharanga Solutions Pvt.ltd", 190, 800);
    doc.save("Staff Bank Details.pdf");
  };

  const fileType = "csv";
  const exportToCSV = () => {
    const visible = XLSX.utils.json_to_sheet(rows);
    const wb = { Sheets: { product: visible }, SheetNames: ["product"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    filesaver.saveAs(data, "StaffBankDetails" + ".csv");
  };


  const date = new Date();
  const today = {
    month: "long",
    year: "numeric",
  };
  const textDate = date.toLocaleDateString("eng-GB", today);


  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="staff" /> */}
      {loading ? (
        <div style={{padding:"180px 0px", display:"flex", justifyContent:"center",}}>
        <div className = "spinner-container">
        <div className = "loading-spinner">
        </div>
      </div>
      </div>
      ) : (
      <Box>
     <Box sx={{ width:"100%",
          padding:"0 10px",
          boxSizing:"border-box",mt:2}}>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          backgroundColor: "lightblue",
          fontWeight: "bold",
        }}
      >
        LIST FOR THE BANK FOR SALARY CREDIT FOR THE MONTH OF {textDate.toUpperCase()}
      </Typography>
      </Box>
     
      <Box sx={{ height: 423, mx: 2, mt: "1%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[6]}
          disableSelectionOnClick
          disableColumnMenu
          sx={{
            width: "100%",
            "& .super-app-theme--header": {
              backgroundColor: "#c0c0c0",
              // color:'white',
              fontWeight: "bold",
              fontSize: "15px",
              border: "1px solid white",
            },
          }}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
      
      <Box sx={{display:"flex", justifyContent:"space-evenly",mt:2}}>
      <Button
                variant="contained"
                size="large"
                color="error"
                onClick={generatePDF}
                sx={{
                  borderRadius: "30px",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                PDF
              </Button>

              <Button
                variant="contained"
                size="large"
                color="success"
                onClick={exportToCSV}
                sx={{
                  borderRadius: "30px",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                excel
              </Button>

              <Button
                variant="contained"
                size="large"
                onClick={()=>{window.print()}}
                sx={{
                  borderRadius: "30px",
                  backgroundColor:"black",
                  color:"white",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                  "&:hover":{backgroundColor:"black",color:"white"}
                }}
              >
                print
              </Button>

              <Button
            onClick={onLogout}
            variant="contained"
            sx={{
              backgroundColor: "#ca424f",
              width:"140px",
              color: "white",
              marginBottom:2,
              borderRadius: " 30px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#ca424f" },
              
            }}
          >
            EXIT
          </Button>

      </Box>
      </Box>
      )}
    </React.Fragment>
  );
}
