import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TopBar from '../../MainTopbar/Appbar';
import BookEntry from './booksEntry';
import BookList from './bookList';
import BookType from './listBooksTypeWise';
import Borrow from './bookBorrow';
import Search from './searchAbook';
import SaveBook from './book';


function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ height:"100%", width:"100%" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs() {

  React.useEffect(()=>{
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, null, window.location.href);
    };
  },[])

  const [value, setValue] = React.useState(0);
  const [highlight, setHighlight] = React.useState(false);

  const Click = (newValue) => {
    setValue(newValue);
    setHighlight(newValue);
    console.log(value);
    console.log(highlight);
  };

  return (
    <div>

<TopBar/>
     
<Box sx={{border:"2px solid black",   background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",}}>
      <Grid
          container
          spacing={2}
          py={0.5}
         
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="warning"
              fullWidth
              size="small"
              sx={{ fontWeight: "bold" }}
            >
              LIBRARY
            </Button>
          </Grid>
          <Grid item xs={1.2}>
            <Button
              fullWidth
              size="small"
              onClick={()=> Click(0)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 0 ? 'black' : '' ,
                color: value == 0 ? 'white' : 'black' ,
                fontWeight: "bold",
              }}
            >
              BOOK ENTRY
            </Button>
          </Grid>
          <Grid item xs={1.2}>
            <Button
              fullWidth
              size="small"
              onClick={()=> Click(1)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 1 ? 'black' : '' ,
                color: value == 1 ? 'white' : 'black' ,
                fontWeight: "bold",
              }}
            >
              LIST ALL BOOKS
            </Button>
          </Grid>
          <Grid item xs={1.8}>
            <Button
              fullWidth
              size="small"
              onClick={()=> Click(2)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 2 ? 'black' : '' ,
                color: value == 2 ? 'white' : 'black' ,
                fontWeight: "bold",
              }}
            >
              LIST BOOKE TYPE WISE
            </Button>
          </Grid>
          <Grid item xs={1.8}>
            <Button
              fullWidth
              size="small"
              onClick={()=> Click(3)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 3 ? 'black' : '' ,
                color: value == 3 ? 'white' : 'black' ,
                fontWeight: "bold",
              }}
            >
            LIST  BOOKS BORROWED
            </Button>
          </Grid>
          <Grid item xs={1.5}>
            <Button
              fullWidth
              size="small"
              onClick={()=> Click(4)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 4 ? 'black' : '' ,
                color: value == 4 ? 'white' : 'black' ,
                fontWeight: "bold",
              }}
            >
              SEARCH A BOOK
            </Button>
          </Grid>
          <Grid item xs={1.5}>
            <Button
              fullWidth
              size="small" 
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 5 ? 'black' : '' ,
                color: value == 5 ? 'white' : 'black' ,
                fontWeight: "bold",
              }}
              onClick={()=> Click(5)}
            >
              BORROW/RETURN
            </Button>
          </Grid>
        </Grid>
        </Box>

      <CustomTabPanel value={value} index={0}>
        <BookEntry/>   
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
       <BookList/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
       <BookType/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        <Borrow/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={4}>
        <Search/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={5}>
       <SaveBook/>
      </CustomTabPanel>

    </div>
  );
}
