import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { Button, Checkbox, Stack, Typography } from "@mui/material";
import { AddStandarsService } from "../../../services/addStandardsService";
import { C } from "../../util/C";
import { useNavigate } from "react-router-dom";
import { StandardDivision } from "../../../services/StandardDIvision";
import { StudentService } from "../../../services/studentService";
import { StudentAttendenanceService } from "../../../services/studentAttendance";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import AttendenceTotal from "./attendenceTotal";
import jsPDF from "jspdf";
import { autoTable } from "jspdf-autotable"; 
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",

  // color: theme.palette.text.secondary
}));
const Items = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  // color: theme.palette.text.secondary
}));
const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color: "white",
  },
}));
const ItemD = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#03dff9",
  "&:hover": {
    background: "#038da6",
    color: "white",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid red",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};

// const columns = [
//   // { field: "id", headerName: "ID", width: 90 },
//   // { field: "id", headerName: "Role", width: 90 },
//   {
//     field: "id",
//     headerName: "NO",
//     width: 90,
//     headerAlign: "center",
//     headerClassName: "super-app-theme--header",
//     renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"}</strong>,
//     align: "center",
//     sortable: false,
//   },
//   {
//     field: "name",
//     headerName: "NAME",
//     flex: 1,
//     // editable: true,
//     headerAlign: "center",
//     align: "center",
//     headerClassName: "super-app-theme--header",
//     renderHeader: () => <strong style={{ fontSize: 15 }}>{"NAME"}</strong>,
//     sortable: false,
//   },
//   {
//     field: "absent",
//     headerName: "ABSENT",
//     headerAlign: "center",
//     align: "center",
//     type: "boolean",
//     flex: 1,
//     renderHeader: () => <strong style={{ fontSize: 15 }}>{"ABSENT"}</strong>,
//     // editable: true,
//     headerClassName: "super-app-theme--header",
//     sortable: false,
//   },
//   {
//     field: "leave",
//     headerName: "LEAVE",
//     headerAlign: "center",
//     align: "center",
//     type: "boolean",
//     flex: 1,
//     renderHeader: () => <strong style={{ fontSize: 15 }}>{"LEAVE"}</strong>,
//     // editable: true,
//     headerClassName: "super-app-theme--header",
//     sortable: false,
//   },
// ];

export default function BasicGrid() {
  const [standard, setStandard] = React.useState([]);
  const [columns, setColums] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [excel, setExcel] = React.useState([]);
  const [openSucess, setSucess] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [highlightedButton, setHighlightedButton] = React.useState();

  React.useEffect(() => {
    onstandardsLoad();
  }, []);

  const date = new Date();  // 2009-11-10
  const month = date.toLocaleString('default', { month: 'long' });
  console.log(month);


  const onstandardsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      // C.c(response.data.data);
      setStandard(response.data.data);
    } catch (error) {
      // C.c(error);
    }
  };

  let navigate = useNavigate();
  const onLaunchpad = () => {
    navigate("/launchpad", { replace: true });
  };

  const [Standardname, setStandardName] = React.useState();
  const [StandardID, setStandardID] = React.useState();
  const [DivisionName, setDivisionName] = React.useState();
  const [Division, setDivision] = React.useState([]);
  const [studentData, setStudentData] = React.useState([]);
  const [selectedMonth, setMonth] = React.useState("");
  const options = { day: "2-digit", month: "long", year: "numeric" };
  const currentDate = new Date().toLocaleDateString(undefined, options);

  // const currentDate = new Date();
  // const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  // const formattedDate = currentDate.toLocaleDateString(undefined, options);


  const onMonthSelect = async (e) => {
    onstandardsLoad();
    setHighlightedButton(e.target.value);
    setMonth(e.target.value);
    setRows([]);
    // setStandardName("");
    // setStandardID("");
    setDivisionName("");
    setDivisionID("");
  };


  async function onStandardSelect(e, index) {
    e.preventDefault();
    setRows("");
    setAbsent(0);
    setLeave(0);
    setStudentTotal(0);
    setStandardName(e.target.textContent);
    setStandardID(e.target.id);
    C.c(e.currentTarget.value);

    let highlightStandardList = [...standard];
    highlightStandardList = highlightStandardList.map(
      ({ isSelected, ...highlightStandardList }) => highlightStandardList
    );
    setStandard(highlightStandardList);
    highlightStandardList[index]["isSelected"] = highlightStandardList[index][
      "isSelected"
    ]
      ? false
      : true;

    let stdId = e.currentTarget.id;

    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await StandardDivision.StandardDivisionWiseStandard(
        e.currentTarget.id,
        data.yearName,
        data.yearID
      );
      C.c(response.data.data);
      setDivision(response.data.data);
    } catch (error) {
      C.c(error.message);
    }
  }

  const [studentTotal, setStudentTotal] = React.useState(0);
  const [divisionID, setDivisionID] = React.useState();
  const [attData, setData] = React.useState([]);
  const [absent, setAbsent] = React.useState(null);
  const [leave, setLeave] = React.useState(null);

  async function onSelectDivision(e, index) {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    const currentDate = new Date();
    const iso8601Date = currentDate.toISOString().split("T")[0];
    const options = { month: "long" };
    const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);
    console.log(iso8601Date);

    // const formattedDate = currentDate.toLocaleDateString();
    // const formattedTime = currentDate.toLocaleTimeString();
    // console.log(formattedDate);
    // console.log(formattedTime);
    // console.log(e.currentTarget.id);
    setDivisionID(e.currentTarget.id);

    let highlightDivision = [...Division];
    highlightDivision = highlightDivision.map(
      ({ isSelected, ...highlightDivision }) => highlightDivision
    );
    setDivision(highlightDivision);
    highlightDivision[index]["isSelected"] = highlightDivision[index][
      "isSelected"
    ]
      ? false
      : true;

    let student = [];
    try {
      let response = await StudentAttendenanceService.getClassDivreportMonth(
        e.currentTarget.id,
        selectedMonth,
        StandardID,
        data.yearName
      );
      C.c(response.data.data);
      // console.clear();
      setData(response.data.data.rows.sort((a,b)=>a.studentName.localeCompare(b.studentName)));
      // console.log(data);
      // setData(response.data.data)
      onload(response.data.data);

      let stud = [];
      response.data.data.rows.forEach((res,i)=>{
        stud.push({
          NAME : res.studentName,
          "DATE 1": res.s0,
          "DATE 2": res.s1,
          "DATE 3": res.s2,
          "DATE 4": res.s3,
          "DATE 5": res.s4,
          "DATE 6": res.s5,
          "DATE 7": res.s6,
          "DATE 8": res.s7,
          "DATE 9": res.s8,
          "DATE 10": res.s9,
          "DATE 11": res.s10,
          "DATE 12": res.s11,
          "DATE 13": res.s12,
          "DATE 14": res.s13,
          "DATE 15": res.s14,
          "DATE 16": res.s15,
          "DATE 17": res.s16,
          "DATE 18": res.s17,
          "DATE 19": res.s18,
          "DATE 20": res.s19,
          "DATE 21": res.s20,
          "DATE 22": res.s21,
          "DATE 23": res.s22,
          "DATE 24": res.s23,
          "DATE 25": res.s24,
          "DATE 26": res.s25,
          "DATE 27": res.s26,
          "DATE 28": res.s27,
          "DATE 29": res.s28,
          "DATE 30": res.s29,
          "DATE 31": res.s30,

        });
      })
      console.log(stud);
      setExcel(stud);
// response.data.data.rows.sort((a,b)=>a.studentName.localeCompare(b.studentName));
      //   // student = response.data.data;
      //   let count = 0;
      //   let absent = 0;
      //   let leave = 0;
      //   // if (response.data.data.studentAttendance.lenght == 0) {
      //   response.data.data.studentAttendance.forEach((rec, i) => {
      //     count = count + 1;
      //     rec.studentAttendanceStatus === "absent"
      //        (absent = absent + 1)
      //       : (absent = absent + 0);
      //     rec.studentAttendanceStatus === "absent"
      //        (leave = leave + 1)
      //       : (leave = leave + 0);
      //     student.push({
      //       id: i + 1,
      //       student_id: rec.studentID,
      //       name: rec.studentName,
      //       absent: rec.studentAttendanceStatus === "absent" ? true : false,
      //       leave: rec.studentAttendanceStatus === "leave" ? true : false,
      //     });
      //     // C(rec)
      //   });
      //   console.log(count);
      //   // setStudentData(response.data.data);
      //   setRows(student);
      //   setStudentTotal(count);
      //   setAbsent(absent);
      //   setLeave(leave);
      //   // }
      //   C.c(rows);

    } catch (error) {
      C.c(error.message);
    }
  }

  
  

  // const onMonthSelect = async (e) => {
  //   setHighlightedButton(e.target.value);
  //   setMonth(e.target.value);
  //   setRows("");
  //   setStandardName("");
  //   setStandardID("");
  //   setDivisionName("");
  //   setDivisionID("");
  // };

  function onload(data) {
    console.log(data);

    let col = [];
    col.push({
      field: "standard",
      headerName: "Student",
      width: "200",
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"Student"}</strong>,
      headerClassName: "super-app-theme--header",
    });
    data.column.forEach((rec, i) => {
      col.push({
        field: i.toString(),
        headerName: rec.day,
        width: "20",
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderHeader: () => <strong style={{ fontSize: 15 }}>{rec.day}</strong>,
        headerClassName: "super-app-theme--header",
      });
    });

    setColums(col);

    let row = [];
    data.rows.forEach((rec, i) => {
      console.log(rec);
      // console.log(
      //   rec.s13
      //      "👧🏽" +
      //         rec.s13.split("|")[0] +
      //         " | 👦🏽" +
      //         rec.s13.split("|")[1] +
      //         " || " +
      //         "👧🏽" +
      //         rec.s13.split("|")[2] +
      //         " | 👦🏽" +
      //         rec.s13.split("|")[3]
      //     : "------------"
      // );
      let obj = {
        id: i,
        standard: rec.studentName + "-" + rec.ssid,
        0: rec.s0,
        // "👧🏽" +
        //   rec.s0.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s0.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s0.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s0.split("|")[3]
        // : "--",
        1: rec.s1,
        //  "👧🏽" +
        //   rec.s1.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s1.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s1.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s1.split("|")[3]
        // : "--",
        2: rec.s2,
        //  "👧🏽" +
        //   rec.s2.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s2.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s2.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s2.split("|")[3]
        // : "--",
        3: rec.s3,
        //  "👧🏽" +
        //   rec.s3.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s3.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s3.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s3.split("|")[3]
        // : "--",
        4: rec.s4,
        //  "👧🏽" +
        //   rec.s4.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s4.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s4.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s4.split("|")[3]
        // : "--",
        5: rec.s5,
        //  "👧🏽" +
        //   rec.s5.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s5.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s5.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s5.split("|")[3]
        // : "--",
        6: rec.s6,
        //  "👧🏽" +
        //   rec.s6.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s6.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s6.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s6.split("|")[3]
        // : "--",
        7: rec.s7,
        //  "👧🏽" +
        //   rec.s7.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s7.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s7.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s7.split("|")[3]
        // : "--",
        8: rec.s8,
        //   //  "👧🏽"
        // 9: rec.s9
        //   ? "👧🏽" +
        //     rec.s9.split("|")[0] +
        //     " | 👦🏽" +
        //     rec.s9.split("|")[1] +
        //     " || " +
        //     "👧🏽" +
        //     rec.s9.split("|")[2] +
        //     " | 👦🏽" +
        //     rec.s9.split("|")[3]
        //   : "--",
        10: rec.s10,
        //  "👧🏽" +
        //   rec.s10.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s10.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s10.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s10.split("|")[3]
        // : "--",
        11: rec.s11,
        //  "👧🏽" +
        //   rec.s11.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s11.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s11.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s11.split("|")[3]
        // : "--",
        12: rec.s12,
        //  "👧🏽" +
        //   rec.s12.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s12.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s12.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s12.split("|")[3]
        // : "--",
        13: rec.s13,
        //  "👧🏽" +
        //   rec.s13.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s13.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s13.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s13.split("|")[3]
        // : "--",
        14: rec.s14,
        //  "👧🏽" +
        //   rec.s14.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s14.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s14.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s14.split("|")[3]
        // : "--",
        15: rec.s15,
        //  "👧🏽" +
        //   rec.s15.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s15.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s15.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s15.split("|")[3]
        // : "--",
        16: rec.s16,
        //  "👧🏽" +
        //   rec.s16.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s16.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s16.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s16.split("|")[3]
        // : "--",
        17: rec.s17,
        //  "👧🏽" +
        //   rec.s17.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s17.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s17.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s17.split("|")[3]
        // : "--",
        18: rec.s18,
        //  "👧🏽" +
        //   rec.s18.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s18.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s18.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s18.split("|")[3]
        // : "--",
        19: rec.s19,
        //  "👧🏽" +
        //   rec.s19.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s19.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s19.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s19.split("|")[3]
        // : "--",
        20: rec.s20,
        //  "👧🏽" +
        //   rec.s20.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s20.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s20.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s20.split("|")[3]
        // : "--",
        21: rec.s21,
        //  "👧🏽" +
        //   rec.s21.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s21.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s21.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s21.split("|")[3]
        // : "--",
        22: rec.s22,
        //  "👧🏽" +
        //   rec.s22.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s22.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s22.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s22.split("|")[3]
        // : "--",
        23: rec.s23,
        //  "👧🏽" +
        //   rec.s23.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s23.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s23.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s23.split("|")[3]
        // : "--",
        24: rec.s24,
        //  "👧🏽" +
        //   rec.s24.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s24.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s24.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s24.split("|")[3]
        // : "--",
        25: rec.s25,
        //  "👧🏽" +
        //   rec.s25.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s25.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s25.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s25.split("|")[3]
        // : "--",
        26: rec.s26,
        //  "👧🏽" +
        //   rec.s26.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s26.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s26.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s26.split("|")[3]
        // : "--",
        27: rec.s27,
        //  "👧🏽" +
        //   rec.s27.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s27.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s27.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s27.split("|")[3]
        // : "--",
        28: rec.s28,
        //  "👧🏽" +
        //   rec.s28.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s28.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s28.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s28.split("|")[3]
        // : "--",
        29: rec.s29,
        //  "👧🏽" +
        //   rec.s29.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s29.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s29.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s29.split("|")[3]
        // : "--",
        30: rec.s30,
        //  "👧🏽" +
        //   rec.s30.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s30.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s30.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s30.split("|")[3]
        // : "--",
        31: rec.s31,
        //  "👧🏽" +
        //   rec.s31.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s31.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s31.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s31.split("|")[3]
        // : "--",
      };
      row.push(obj);
    });
    setRows(row);
    console.log(col);
    console.log(rows);

    // setTimeout(() => {
    //   Data.data[0].rows.attendence.forEach((rec, i) => {
    //     console.log(rec);
    //     // row[i][rec.divison] = rec.totalpresent + " | " + rec.totalabsent;
    //   });
    // }, 2000);
    // console.log(row);
    // setisLoading(false);
    
   
  }

  // let [totalAbsent, setTotalAbsent] = React.useState();

  function onAttendence(rec, v) {
    // C.c(rec);
    // C.c(v);
    const found = rows.find((element) => element.id == rec.id);

    if (rec.field === "leave") {
      if (rec.value === true && rec.row.absent) {
        // alert("both are true");
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 3000);
        return;
      }
      found.leave = rec.value;
      if (rec.value === true) setLeave(leave + 1);
      if (rec.value === false) {
        if (leave === 0) {
          return;
        } else {
          setLeave(leave - 1);
        }
      }
    } else if (rec.field === "absent") {
      if (rec.value === true && rec.row.leave) {
        alert("both are true");
        return;
      }
      found.absent = rec.value;
      if (rec.value === true) setAbsent(absent + 1);
      if (rec.value === false) {
        if (absent === 0) {
          return;
        } else {
          setAbsent(absent - 1);
        }
      }
    }
    // C.c(rows);
  }

  const stundentAttendence = [];

  // const [open, setOpen] = React.useState(false);

  const onAttendenceApprove = async (e) => {
    setSucess(true);
    setTimeout(() => {
      setSucess(false);
    }, 2000);

    console.log("attendenceApprove");
    // C.c(rows);
    let data = JSON.parse(localStorage.getItem("academicYear"));

    const currentDate = new Date();
    const options = { month: "long" };
    const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);

    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString();
    console.log(formattedDate);
    console.log(formattedTime);

    let studentAttendance = [];
    let status;

    rows.forEach((row) => {
      if (row.absent === true) {
        status = "absent";
      }
      if (row.leave === true) {
        status = "leave";
      }
      if (row.leave === false && row.absent === false) {
        status = "present";
      }

      const recData = studentData.find(
        (element) => element.studentID == row.student_id
      );
      // console.log(recData);

      studentAttendance.push({
        date: formattedDate,
        dateTime: 0,
        divisionID: recData.divisionID,
        divisionName: recData.divisionName,
        monthName: month,
        standardID: recData.standardID,
        standardName: recData.standardName,
        studentAttendanceStatus: status,
        studentGender: recData.studentGender,
        studentID: row.id,
        studentName: row.name,
        studentUSN: recData.studentUSN,
        yearID: data.yearID,
        yearName: data.yearName,
      });
    });

    let boyAbsent = 0;
    let boyPresent = 0;
    let girlAbsent = 0;
    let girlPresent = 0;
    studentAttendance.forEach((rec) => {
      // console.log(rec);
      if (rec.studentGender == "BOY") {
        if (
          rec.studentAttendanceStatus == "absent" ||
          rec.studentAttendanceStatus == "leave"
        ) {
          boyAbsent = boyAbsent + 1;
        } else {
          boyPresent = boyPresent + 1;
        }
      }
      if (rec.studentGender == "GIRL") {
        if (
          rec.studentAttendanceStatus == "absent" ||
          rec.studentAttendanceStatus == "leave"
        ) {
          girlAbsent = girlAbsent + 1;
        } else {
          girlPresent = girlPresent + 1;
        }
      }
    });

    let studentObject = {
      absentBoys: boyAbsent,
      absentGirls: girlAbsent,
      date: formattedDate,
      day: new Date().getDay(),
      dateTime: 0,
      divisionID: divisionID,
      divisionName: divisionID,
      monthName: month,
      presentBoys: boyPresent,
      presentGirls: girlPresent,
      standardID: StandardID,
      standardName: Standardname,
      status: "false",
      studentAttendance: studentAttendance,
      total: studentTotal,
      totalAbsent: absent,
      totalPresent: studentTotal - (absent + leave),
      yearID: data.yearID,
      yearName: data.yearName,
    };

    C.c(studentObject);
    let attendenceList;
    try {
      let response = await StudentAttendenanceService.getAbsentreport(
        formattedDate,
        data.yearName
      );
      attendenceList = response.data.data;
      C.c(response.data.data);
    } catch (error) {
      C.c(error.message);
    }

    // new Attendence

    C.c(attendenceList);
    // C.c(attendenceList[0].standardID);
    // C.c(attendenceList[0].status);

    let attendenceStatus = false;

    if (attendenceList) {
      // console.log(StandardID);
      let found = attendenceList.find(
        (element) => element.standardID == StandardID
      );
      C.c(found);

      if (found) {
        if (found.standardID == StandardID) {
          if (found.status == "false") {
            C.c("update");
            attendenceStatus = true;
            let studentObjectupdate = {
              studentdayattendanceID: found.studentdayattendanceID,
              absentBoys: boyAbsent,
              absentGirls: girlAbsent,
              date: formattedDate,
              day: new Date().getDay(),
              dateTime: 0,
              divisionID: divisionID,
              divisionName: divisionID,
              monthName: month,
              presentBoys: boyPresent,
              presentGirls: girlPresent,
              standardID: StandardID,
              standardName: Standardname,
              status: "false",
              studentAttendance: studentAttendance,
              total: studentTotal,
              totalAbsent: absent,
              totalPresent: studentTotal - (absent + leave),
              yearID: data.yearID,
              yearName: data.yearName,
            };
            console.log(studentObjectupdate);
            try {
              let response = await StudentAttendenanceService.createAttendance(
                studentObjectupdate
              );
              setOpen(true);
              setTimeout(() => {
                setOpen(false);
              }, 2000);
              alert("Attendence successfully Update");
            } catch (error) {
              C.c(error.message);
            }
          }
        }
      } else {
        // new  student attendence
        // attendenceStatus = false;
        C.c("new");

        try {
          let response = await StudentAttendenanceService.createAttendance(
            studentObject
          );
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 2000);
          alert("Attendence successfully Saved");
          return;
        } catch (error) {
          C.c(error.message);
        }
      }
    }
  };

  console.clear();
  console.log(attData);

  const generatePDF = ()=> {
    const academic = JSON.parse(localStorage.getItem("academicYear"));
    const school = JSON.parse(localStorage.getItem("schoolDetails"));
    const doc = new jsPDF("landscape");
    // const doc = new jsPDF("p","pt","a4");

    doc.setFontSize(10);
    doc.setFont("Helvetica", "bold")
    doc.text(`${academic.yearName}`, 250, 10);

    const text = `${school.schoolNameOnDocs.toUpperCase()}`;
    const fontSize = 18;
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.setFontSize(fontSize);
    doc.setFont("Helvetica", "bold");
    doc.text(text, pageWidth / 2, 10, { align: "center" });

    const img = `${school.schoolLogoUrl}`;
    doc.addImage(img, "JPEG", 20, 5, 27, 27);

    doc.setFontSize(15);
    doc.text(month.toUpperCase() +" MONTH ATTENDANCE REPORT- "+Standardname+"-"+divisionID, 150, 25, 'center');


    const tableData = attData.map((row) => [
      row.studentName,
      row.s0,row.s1,row.s2,row.s3,row.s4,
      row.s5,row.s6,row.s7,row.s8,row.s9,row.s10,
      row.s11,row.s12,row.s13,row.s14,row.s15,row.s16,
      row.s17,row.s18,row.s19,row.s20,row.s21,row.s22,row.s23,
      row.s24,row.s25,row.s26,row.s27,row.s28,row.s29,row.s30

    ]);
    console.clear();
    console.log(tableData);
    doc.autoTable({
      startY:40,
      tableLineColor: [0,0,0],
      tableLineWidth: 0.45,
      head:[[
        "STUDENT",
        "1","2","3","4","5","6",
        "7","8","9","10","11","12",
        "13","14","15","16","17","18",
        "19","20","21","22","23","24",
        "25","26","27","28","29","30","31"
    ],],
    body: tableData,
  headStyles:{
    valign: "middle",
    halign: "center",
    fillColor : "#95d5b2",
    lineColor: [0,0,0],
  },
  bodyStyles: {
    valign: "middle",
    halign: "center",
    lineColor: [0,0,0],
  },
    theme: "grid",
    })
    doc.save("attendance.pdf");
  }

// const stud = data.rows.map((row)=>[
//   row.studentName,row.s0,row.s1,row.s2,row.s3,row.s4,row.s5,
//   row.s6,row.s7,row.s8,row.s9,row.s10,row.s11,row.s12,row.s13,row.s14,
//   row.s15,row.s16,row.s17,row.s18,row.s19,row.s20,row.s21,row.s22,row.s23,
//   row.s24,row.s25,row.s26,row.s27,row.s28,row.s29,row.s30
// ]);
// console.clear();
// console.log(stud);


  const fileType = "csv";
  const exportToCSV = () => {
    const visible = XLSX.utils.json_to_sheet(excel);
    const wb = { Sheets: { product: visible }, SheetNames: ["product"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    filesaver.saveAs(data, " Monthly Attendance"+""+Standardname+"-"+DivisionName + ".csv");
  };

// const fileType = "csv";
// const exportToCSV =()=> {
// const excelData = [columns, ...rows];
// const visible = XLSX.utils.json_to_sheet(excelData);
// const wb = {Sheets:{products: visible}, SheetNames:["product"]};
// const excelBuffer = 
// }


  return (
    <div>
      <Box sx={{ width: "100%", padding: "0 10px", boxSizing: "border-box" }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            backgroundColor: "lightblue",
            mt: 1,
            fontWeight: "bold",
          }}
        >
          TODAY'S ATTENDANCE! {currentDate}
        </Typography>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          padding: "0 10px",
          boxSizing: "border-box",
          mt: 1,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack direction="row" mx={20} spacing={2}>
              <Button
                onClick={onMonthSelect}
                value="May"
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  borderRadius: 20,
                  backgroundColor:
                    highlightedButton === "May" ? "primary" : "lightblue",
                  color: highlightedButton === "May" ? "white" : "black",
                }}
              >
                MAY
              </Button>
              <Button
                // onClick={onMonthSelect}
                onClick={onMonthSelect}
                value="June"
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  borderRadius: 20,
                  backgroundColor:
                    highlightedButton === "June" ? "primary" : "lightblue",
                  color: highlightedButton === "June" ? "white" : "black",
                }}
              >
                JUNE
              </Button>
              <Button
                // onClick={onMonthSelect}
                onClick={onMonthSelect}
                value="July"
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  borderRadius: 20,
                  backgroundColor:
                    highlightedButton === "July" ? "primary" : "lightblue",
                  color: highlightedButton === "July" ? "white" : "black",
                }}
              >
                JULY
              </Button>
              <Button
                onClick={onMonthSelect}
                value="August"
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  borderRadius: 20,
                  backgroundColor:
                    highlightedButton === "August" ? "primary" : "lightblue",
                  color: highlightedButton === "August" ? "white" : "black",
                }}
              >
                AUGUST
              </Button>
              <Button
                onClick={onMonthSelect}
                value="September"
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  borderRadius: 20,
                  backgroundColor:
                    highlightedButton === "September" ? "primary" : "lightblue",
                  color: highlightedButton === "September" ? "white" : "black",
                }}
              >
                SEPTEMBER
              </Button>
              <Button
                onClick={onMonthSelect}
                value="October"
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  borderRadius: 20,
                  backgroundColor:
                    highlightedButton === "October" ? "primary" : "lightblue",
                  color: highlightedButton === "October" ? "white" : "black",
                }}
              >
                OCTOBER
              </Button>
              <Button
                onClick={onMonthSelect}
                value="November"
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  borderRadius: 20,
                  backgroundColor:
                    highlightedButton === "November" ? "primary" : "lightblue",
                  color: highlightedButton === "November" ? "white" : "black",
                }}
              >
                NOVEMBER
              </Button>
              <Button
                onClick={onMonthSelect}
                value="December"
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  borderRadius: 20,
                  backgroundColor:
                    highlightedButton === "December" ? "primary" : "lightblue",
                  color: highlightedButton === "December" ? "white" : "black",
                }}
              >
                DECEMBER
              </Button>
              <Button
                onClick={onMonthSelect}
                value="January"
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  borderRadius: 20,
                  backgroundColor:
                    highlightedButton === "January" ? "primary" : "lightblue",
                  color: highlightedButton === "January" ? "white" : "black",
                }}
              >
                JANUARY
              </Button>
              <Button
                onClick={onMonthSelect}
                value="February"
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  borderRadius: 20,
                  backgroundColor:
                    highlightedButton === "February" ? "primary" : "lightblue",
                  color: highlightedButton === "February" ? "white" : "black",
                }}
              >
                FEBRUARY
              </Button>
              <Button
                onClick={onMonthSelect}
                value="March"
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  borderRadius: 20,
                  backgroundColor:
                    highlightedButton === "March" ? "primary" : "lightblue",
                  color: highlightedButton === "March" ? "white" : "black",
                }}
              >
                March
              </Button>
              <Button
                onClick={onMonthSelect}
                value="April"
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  borderRadius: 20,
                  backgroundColor:
                    highlightedButton === "April" ? "primary" : "lightblue",
                  color: highlightedButton === "April" ? "white" : "black",
                }}
              >
                April
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <Item>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {standard.map((element, index) => (
                  <Grid item xs={2} sx={{ cursor: "pointer" }}>
                    <ItemS
                      id={element.standardID}
                      name={element.standardName}
                      onClick={(e) => {
                        onStandardSelect(e, index);
                      }}
                      className={element.isSelected ? "Selected" : ""}
                      sx={{ fontWeight: "bold" }}
                    >
                      {element.standardName}
                    </ItemS>
                  </Grid>
                ))}
              </Grid>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Division.map((element, index) => (
                  <Grid item xs={2} sx={{ cursor: "pointer" }}>
                    <ItemD
                      sx={{
                        fontWeight: "bold",
                      }}
                      onClick={(e) => {
                        onSelectDivision(e, index);
                      }}
                      className={element.isSelected ? "selected" : ""}
                      id={element.divisionName}
                    >
                      {" "}
                      {element.divisionName}
                    </ItemD>
                  </Grid>
                ))}
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </Box>

      {/* <Grid container spacing={0}>
        <Grid Items xs={12}>
          <Items> */}
      <Box
        sx={{
          height: 400,
          mt: 1,
          width: "100%",
          padding: "0 5px",
          boxSizing: "border-box",
          "& .super-app-theme--header": {
            backgroundColor: "#c0c0c0",
            //  color:'white',
            fontWeight: "bold",
            fontSize: "15px",
            border: "1px solid white",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          onCellEditCommit={onAttendence}
          disableColumnMenu
          hideFooter={true}
          // components={{ Toolbar: GridToolbar }}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: 3 }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            border: "2px solid #ff3131",
            width: "180px",
            borderRadius: "5px",
            backgroundColor: "#ff3131",
            color: "white",
          }}
        >
          ABSENT : {absent}
        </Typography>

        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            border: "2px solid green",
            width: "180px",
            borderRadius: "5px",
            backgroundColor: "green",
            color: "white",
          }}
        >
          PRESENT : {studentTotal - (absent + leave)}
        </Typography>

        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            border: "2px solid #39335e",
            width: "180px",
            borderRadius: "5px",
            backgroundColor: "#39335e",
            color: "white",
          }}
        >
          TOTAL : {studentTotal}
        </Typography>
      </Box>
      <AttendenceTotal></AttendenceTotal>

      <Box sx={{display:"flex", justifyContent:"space-evenly",paddingBottom:1}}>
      <Button variant="contained" size="large" sx={{borderRadius:"15px",width:"140px",borderRadius:"30px",fontWeight:"bold"}} color="error" onClick={generatePDF}>pdf</Button>
        <Button variant="contained" size="large" sx={{borderRadius:"15px",width:"140px",borderRadius:"30px",fontWeight:"bold"}} color="success" onClick={exportToCSV}>excel</Button>
        <Button
                  variant="contained"
                  onClick={onLaunchpad}
                  sx={{
                    backgroundColor: "#ca424f",
                    fontSize: 15,
                    fontWeight: "bold",
                    borderRadius: "30px",
                    width: "140px",
                    ml: 5,
                    "&:hover": { backgroundColor: "#ca424f" },
                  }}
                >
                  exit
                </Button>
      </Box>
    </div>
  );
}
