import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import {
  Button,
  Modal,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { C } from "../../util/C";
import { StaffService } from "../../../services/StaffService";
import { StaffAttendenanceService } from "../../../services/StaffAttendanence";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import jsPDF from "jspdf";
import { autoTable } from "jspdf-autotable"; 
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid green",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicGrid() {
  React.useEffect(() => {
    onStaffLoad();
    onLoad();
  }, []);

  const [columns, setColumns] = React.useState([
    {
      field: "id",
      headerName: "SSID",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      hide: true,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"SSID"} </strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "designation",
      headerName: "DESIGNATION",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"DESIGNATION"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "staffName",
      headerName: "STAFF NAME",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"STAFF NAME"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "absent",
      headerName: "ABSENT",
      type: "boolean",
      flex: 1,
      editable: true,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"ABSENT"} </strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "leave",
      headerName: "LEAVE",
      flex: 1,
      type: "boolean",
      editable: true,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"LEAVE"} </strong>,
      headerClassName: "super-app-theme--header",
    },
  ]);

  const [rows, setRows] = React.useState([]);

  const [staff, setStaff] = React.useState([]);
  const [staffTotal, setStaffTotal] = React.useState(0);
  const [isloading, setisLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [disable, setdisable] = React.useState(false);

  const onLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const adjustedDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
    const iso8601Date = currentDate.toISOString().split("T")[0];
    const options = { month: "long" };
    const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);
    console.log(iso8601Date.split("-")[2]);
    try {
      let response = await StaffAttendenanceService.DayStaffAttendance(
        iso8601Date,
        data.yearName
      );
      console.log(response.data.data);

      if (response.data.data != null) {
        console.log(response.data.data);
        setdisable(true);
      } else {
        console.log(response.data.data);
        setdisable(false);
      }
    } catch (error) {
      C.c(error.message);
    }
  };
  const onStaffLoad = async () => {
    let yearData = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await StaffService.staffListAll();
      console.log(response.data.data);

      setStaff(response.data.data);
      let staff_array = [];
      let count = 0;
      response.data.data.forEach(function (data) {
        count = count + 1;
        console.log(data);
        staff_array.push({
          id: data.staffID,
          designation: data.designation,
          staffName: data.staffName,
        });
      });
      setRows(staff_array);
      setStaffTotal(count);
      console.log(rows);
    } catch (error) {
      console.log(error);
    }
  };

  const onDateSelect = (val) => {
    // debugger

    // console.log(val);
    if (val === 0) {
      return "Sunday";
    } else if (val === 1) {
      return "Monday";
    } else if (val === 2) {
      return "Tueday";
    } else if (val === 3) {
      return "Wednesday";
    } else if (val === 4) {
      return "Thursday";
    } else if (val === 5) {
      return "Friday";
    } else if (val === 6) {
      return "Saturday";
    } else {
      return "Invalid day";
    }
  };

  const [staffAbsentTotal, setAbsentStaffTotal] = React.useState(0);
  const [staffLeaveTotal, setLeaveStaffTotal] = React.useState(0);

  const onAttendanceEntry = (rec, v) => {
    const found = rows.find((element) => element.id == rec.id);
    if (rec.field === "leave") {
      // setLeaveStaffTotal(staffLeaveTotal + 1);
      found.leave = rec.value;
      if (rec.value === true) setLeaveStaffTotal(staffLeaveTotal + 1);
      if (rec.value === false) {
        if (staffLeaveTotal === 0) {
          return;
        } else {
          setLeaveStaffTotal(staffLeaveTotal - 1);
        }
      }
    } else if (rec.field === "absent") {
      if (rec.value === true) setAbsentStaffTotal(staffAbsentTotal + 1);
      if (rec.value === false) {
        if (staffAbsentTotal === 0) {
          return;
        } else {
          setAbsentStaffTotal(staffAbsentTotal - 1);
        }
      }
      found.absent = rec.value;
    }

    if (found.leave === true && found.absent === true) {
      // alert(
      //   "Either a student must be absent or should be on leave, please recheck"
      // );
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
      return;
    } else {
      console.log("Attendence entered successfully");
    }
    console.log(found);
    console.log(rows);
  };

  let AttendanceObj = [];

  let data = JSON.parse(localStorage.getItem("academicYear"));

  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay();
  const adjustedDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
  const iso8601Date = currentDate.toISOString().split("T")[0];
  const options = { month: "long" };
  const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);
  console.log(iso8601Date);

  // const formattedDate = currentDate.toLocaleDateString();
  // const formattedTime = currentDate.toLocaleTimeString();
  // console.log(formattedDate);
  //   console.log(formattedTime);

  const onAttendence = async (e) => {
    // C.c(rows);
    rows.forEach((rec) => {
      const recData = staff.find((element) => element.staffID == rec.id);
      console.log(recData);
      AttendanceObj.push({
        date: iso8601Date,
        // day: onDateSelect(currentDate.getDay()),
        day: adjustedDayOfWeek,
        month: month,
        staffAttendanceStatus:
          rec.leave === true
            ? "absent"
            : rec.absent === true
            ? "absent"
            : "present",
        staffID: recData.staffID,
        staffName: recData.staffName,
        staffSSID: recData.ssid,
        todaysDate: iso8601Date,
        year: data.yearName,
        yearID: data.yearID,
        yearName: data.yearName,
      });
    });

    // console.log(AttendanceObj);
    let obj = {
      date: iso8601Date,
      dateTime: 0,
      // day: onDateSelect(currentDate.getDay()),
      day: adjustedDayOfWeek,
      deleteStatus: true,
      month: month,
      staffAttendance: AttendanceObj,
      todaysDate: iso8601Date,
      total: staffTotal,
      totalAbsent: staffAbsentTotal,
      totalPresent: staffTotal - (staffAbsentTotal + staffLeaveTotal),
      year: data.yearName,
      yearID: data.yearID,
      yearName: data.yearName,
    };

    console.log(obj);

    try {
      let response = await StaffAttendenanceService.createAttendance(obj);
      C.c(response.data.data);

      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 1000);
      setdisable(true);
    } catch (error) {
      C.c(error);
    }
  };

  let date = new Date().toLocaleDateString();

  const [value, setValue] = React.useState(0);
  const [selectedMonth, setMonth] = React.useState("");
  const [highlight, setHighlight] = React.useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onMonthSelect = (e) => {
    setHighlight(e.target.value);
    setMonth(e.target.value);
  };

  const [reportRows, setReportRows] = React.useState([]);
  const [reportColumns, setReportColumns] = React.useState([]);
  const [attandenceData, setAttandenceData] = React.useState([]);
  const [excel, setExcel] = React.useState([]);
  const [att, setAtt] = React.useState([]);

  const onAttendenceReport = async () => {
    // console.log(selectedMonth);
    let data = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await StaffAttendenanceService.getAllAttendanceMonth(
        selectedMonth,
        data.yearName
      );
      console.log(response.data.data);
      onload(response.data.data);
      setAtt(response.data.data.rows);

      let sta = [];
      response.data.data.rows.forEach((res)=>{
        sta.push({
"STAFF" : res.staffName,
"DATE 1": res.s0,
"DATE 2":res.s1,
"DATE 3":res.s2,
"DATE 4":res.s3,
"DATE 5":res.s4,
"DATE 6":res.s5,
"DATE 7":res.s6,
"DATE 8":res.s7,
"DATE 9": res.s8,
"DATE 10":res.s9,
"DATE 11":res.s10,
"DATE 12":res.s11,
"DATE 13":res.s12,
"DATE 14":res.s13,
"DATE 15":res.s14,
"DATE 16":res.s15,
"DATE 17": res.s16,
"DATE 18":res.s17,
"DATE 19":res.s18,
"DATE 20":res.s19,
"DATE 21":res.s20,
"DATE 22":res.s21,
"DATE 23":res.s22,
"DATE 24":res.s23,
"DATE 25": res.s24,
"DATE 26":res.s25,
"DATE 27":res.s26,
"DATE 28":res.s27,
"DATE 29":res.s28,
"DATE 30":res.s29,
"DATE 31":res.s30,
        });
      });
      setExcel(sta);
      // setAttandenceData(response.data.data);
      // setReportColumns(response.data.data);
      // setReportRows(response.data.data.staffAttendance);
    } catch (error) {
      C.c(error.message);
    }
    // setTimeout(() => {
    // setReportColumns(column);
    // }, 1000);
    // console.log(column);
  };

  function onload(data) {
    console.log(data);

    let col = [];
    col.push({
      field: "standard",
      headerName: "Staff",
      width: "200",
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"Staff"}</strong>,
      headerClassName: "super-app-theme--header",
    });
    data.column.forEach((rec, i) => {
      col.push({
        field: i.toString(),
        headerName: rec.day + " - present || Absent",
        width: "20",
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderHeader: () => <strong style={{ fontSize: 15 }}>{rec.day}</strong>,
        headerClassName: "super-app-theme--header",
      });
    });

    // setColumns(col);
    setReportColumns(col);

    let row = [];
    data.rows.forEach((rec, i) => {
      console.log(rec);
      // console.log(
      //   rec.s13
      //      "👧🏽" +
      //         rec.s13.split("|")[0] +
      //         " | 👦🏽" +
      //         rec.s13.split("|")[1] +
      //         " || " +
      //         "👧🏽" +
      //         rec.s13.split("|")[2] +
      //         " | 👦🏽" +
      //         rec.s13.split("|")[3]
      //     : "------------"
      // );
      let obj = {
        id: i,
        standard: rec.staffName + "-" + rec.ssid,
        0: rec.s0,
        // "👧🏽" +
        //   rec.s0.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s0.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s0.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s0.split("|")[3]
        // : "--",
        1: rec.s1,
        //  "👧🏽" +
        //   rec.s1.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s1.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s1.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s1.split("|")[3]
        // : "--",
        2: rec.s2,
        //  "👧🏽" +
        //   rec.s2.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s2.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s2.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s2.split("|")[3]
        // : "--",
        3: rec.s3,
        //  "👧🏽" +
        //   rec.s3.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s3.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s3.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s3.split("|")[3]
        // : "--",
        4: rec.s4,
        //  "👧🏽" +
        //   rec.s4.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s4.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s4.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s4.split("|")[3]
        // : "--",
        5: rec.s5,
        //  "👧🏽" +
        //   rec.s5.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s5.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s5.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s5.split("|")[3]
        // : "--",
        6: rec.s6,
        //  "👧🏽" +
        //   rec.s6.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s6.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s6.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s6.split("|")[3]
        // : "--",
        7: rec.s7,
        //  "👧🏽" +
        //   rec.s7.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s7.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s7.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s7.split("|")[3]
        // : "--",
        8: rec.s8,
        //   //  "👧🏽"
        // 9: rec.s9
        //   ? "👧🏽" +
        //     rec.s9.split("|")[0] +
        //     " | 👦🏽" +
        //     rec.s9.split("|")[1] +
        //     " || " +
        //     "👧🏽" +
        //     rec.s9.split("|")[2] +
        //     " | 👦🏽" +
        //     rec.s9.split("|")[3]
        //   : "--",
        10: rec.s10,
        //  "👧🏽" +
        //   rec.s10.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s10.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s10.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s10.split("|")[3]
        // : "--",
        11: rec.s11,
        //  "👧🏽" +
        //   rec.s11.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s11.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s11.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s11.split("|")[3]
        // : "--",
        12: rec.s12,
        //  "👧🏽" +
        //   rec.s12.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s12.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s12.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s12.split("|")[3]
        // : "--",
        13: rec.s13,
        //  "👧🏽" +
        //   rec.s13.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s13.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s13.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s13.split("|")[3]
        // : "--",
        14: rec.s14,
        //  "👧🏽" +
        //   rec.s14.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s14.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s14.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s14.split("|")[3]
        // : "--",
        15: rec.s15,
        //  "👧🏽" +
        //   rec.s15.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s15.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s15.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s15.split("|")[3]
        // : "--",
        16: rec.s16,
        //  "👧🏽" +
        //   rec.s16.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s16.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s16.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s16.split("|")[3]
        // : "--",
        17: rec.s17,
        //  "👧🏽" +
        //   rec.s17.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s17.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s17.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s17.split("|")[3]
        // : "--",
        18: rec.s18,
        //  "👧🏽" +
        //   rec.s18.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s18.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s18.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s18.split("|")[3]
        // : "--",
        19: rec.s19,
        //  "👧🏽" +
        //   rec.s19.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s19.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s19.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s19.split("|")[3]
        // : "--",
        20: rec.s20,
        //  "👧🏽" +
        //   rec.s20.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s20.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s20.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s20.split("|")[3]
        // : "--",
        21: rec.s21,
        //  "👧🏽" +
        //   rec.s21.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s21.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s21.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s21.split("|")[3]
        // : "--",
        22: rec.s22,
        //  "👧🏽" +
        //   rec.s22.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s22.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s22.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s22.split("|")[3]
        // : "--",
        23: rec.s23,
        //  "👧🏽" +
        //   rec.s23.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s23.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s23.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s23.split("|")[3]
        // : "--",
        24: rec.s24,
        //  "👧🏽" +
        //   rec.s24.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s24.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s24.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s24.split("|")[3]
        // : "--",
        25: rec.s25,
        //  "👧🏽" +
        //   rec.s25.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s25.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s25.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s25.split("|")[3]
        // : "--",
        26: rec.s26,
        //  "👧🏽" +
        //   rec.s26.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s26.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s26.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s26.split("|")[3]
        // : "--",
        27: rec.s27,
        //  "👧🏽" +
        //   rec.s27.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s27.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s27.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s27.split("|")[3]
        // : "--",
        28: rec.s28,
        //  "👧🏽" +
        //   rec.s28.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s28.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s28.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s28.split("|")[3]
        // : "--",
        29: rec.s29,
        //  "👧🏽" +
        //   rec.s29.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s29.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s29.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s29.split("|")[3]
        // : "--",
        30: rec.s30,
        //  "👧🏽" +
        //   rec.s30.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s30.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s30.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s30.split("|")[3]
        // : "--",
        31: rec.s31,
        //  "👧🏽" +
        //   rec.s31.split("|")[0] +
        //   " | 👦🏽" +
        //   rec.s31.split("|")[1] +
        //   " || " +
        //   "👧🏽" +
        //   rec.s31.split("|")[2] +
        //   " | 👦🏽" +
        //   rec.s31.split("|")[3]
        // : "--",
      };
      row.push(obj);
    });
    setReportRows(row);
    console.log(col);
    console.log(row);

    // setTimeout(() => {
    //   Data.data[0].rows.attendence.forEach((rec, i) => {
    //     console.log(rec);
    //     // row[i][rec.divison] = rec.totalpresent + " | " + rec.totalabsent;
    //   });
    // }, 2000);
    // console.log(row);
    setisLoading(false);
  }

  let navigate = useNavigate();
  const onLogout = () => {
    console.log("onLogout");
    navigate("/launchpad", { replace: true });
  };

  const genaratePDF=()=> {
    const doc = new jsPDF("landscape");
    const academic = JSON.parse(localStorage.getItem("academicYear"));
    const school = JSON.parse(localStorage.getItem("schoolDetails"));

    doc.setFontSize(10);
    doc.setFont("Helvetica", "bold")
    doc.text(`${academic.yearName}`, 250, 10);

    const text = `${school.schoolNameOnDocs.toUpperCase()}`;
    const fontSize = 18;
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.setFontSize(fontSize);
    doc.setFont("Helvetica", "bold");
    doc.text(text, pageWidth / 2, 10, { align: "center" });

    const img = `${school.schoolLogoUrl}`;
    doc.addImage(img, "JPEG", 20, 5, 27, 27);

    doc.setFontSize(15);
    doc.text(month.toUpperCase() +" MONTH STAFF ATTENDANCE REPORT", 150, 25, 'center');

    const tableData = att.map((r)=>([
      r.staffName,r.s0,r.s1,r.s2,r.s3,r.s4,r.s5,r.s6,r.s7,
      r.s8,r.s9,r.s10,r.s11,r.s12,r.s13,r.s14,r.s15,r.s16,
      r.s17,r.s18,r.s19,r.s20,r.s21,r.s22,r.s23,r.s24,
      r.s25,r.s26,r.s27,r.s28,r.s29,r.s30
    ]));

doc.autoTable({
  startY:40,
  tableLineColor:[0,0,0],
  tableLineWidth:0.45,
  head:[[
    "STAFF","1","2","3","4","5","6","7","8","9",
    "10","11","12","13","14","15","16","17","18",
    "19","20","21","22","23","24","25","26","27",
    "28","29","30","31"
  ]],
  body: tableData,
  headStyles:{
    valign: "middle",
    halign: "center",
    fillColor : "#95d5b2",
    lineColor: [0,0,0],
  },
  bodyStyles: {
    valign: "middle",
    halign: "center",
    lineColor: [0,0,0],
  },
    theme: "grid",
})

doc.save("Staff Monthly Attendance Report-"+month+".pdf");
  }


  const fileType = "csv";
  const exportToCSV = () => {
    const visible = XLSX.utils.json_to_sheet(excel);
    const wb = { Sheets: { product: visible }, SheetNames: ["product"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    filesaver.saveAs(data, "Staff Monthly Attendance Report"+"-"+month+ ".csv");
  };


  return (
    <div
      style={{
        backgroundImage: `url("bgColor11.png")`,
        backgroundSize: "cover",
      }}
    >
      {/* <LaunchpadTopBar id="staff" /> */}

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Mark Attendance" {...a11yProps(0)} />
          <Tab label="Attendance Report" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            backgroundColor: "lightblue",
            my: 1,
            fontWeight: "bold",
          }}
        >
          MARK TODAY'S ATTENDANCE <span>{date}</span>
        </Typography>
        {/* ..............TABLE DATA ..............*/}
        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .super-app-theme--header": {
              backgroundColor: "#c0c0c0",
              //  color:'white',
              fontWeight: "bold",
              fontSize: "15px",
              border: "1px solid white",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            disableColumnMenu
            sx={{ border: "1px solid black" }}
            onCellEditCommit={onAttendanceEntry}
          />
        </Box>
        {/* <Box sx={{display:"flex"}}>
       
       
          <Typography
            sx={{
              width: "110px",
              backgroundColor: "red",
              border: "2px solid black",
              borderRight: "none",
            }}
          >
            ABSENT :  {staffAbsentTotal}
          </Typography>

         
          <Typography
            style={{
              width: "110px",
              
              backgroundColor: "lightGreen",
              color: "black",
              border: "2px solid black",
              borderRight: "none",
            }}
          >
            PRESENT :  {staffTotal - (staffAbsentTotal + staffLeaveTotal)}
          </Typography>


          <Typography
            sx={{
              color: "black",
          
              border: "2px solid black",
            
            }}
          >
            TOTAL :  {staffTotal}
          </Typography>
         
        </Box> */}

        <Grid container>
          <Grid item xs={4} sx={{ alignItems: "center" }}>
            {/* ...........STAFF ABSENT............ */}
            <Item
              sx={{ display: "flex", justifyContent: "center", border: "none" }}
            >
              <Typography
                variant="h6"
                sx={{
                  border: "2px solid #ff3131",
                  width: "180px",
                  borderRadius: "5px",
                  backgroundColor: "#ff3131",
                  color: "white",
                }}
              >
                ABSENT : {staffAbsentTotal}
              </Typography>
            </Item>
          </Grid>

          {/* ................STAFF PRESENT................. */}
          <Grid item xs={4}>
            <Item
              sx={{ display: "flex", justifyContent: "center", border: "none" }}
            >
              <Typography
                variant="h6"
                sx={{
                  border: "2px solid green",
                  width: "180px",
                  borderRadius: "5px",
                  backgroundColor: "green",
                  color: "white",
                }}
              >
                PRESENT : {staffTotal - (staffAbsentTotal + staffLeaveTotal)}
              </Typography>
            </Item>
          </Grid>

          {/* .................STAFF TOTAL....... */}
          <Grid item xs={4}>
            <Item
              sx={{ display: "flex", justifyContent: "center", border: "none" }}
            >
              <Typography
                variant="h6"
                sx={{
                  border: "2px solid #39335e",
                  width: "180px",
                  borderRadius: "5px",
                  backgroundColor: "#39335e",
                  color: "white",
                }}
              >
                {" "}
                TOTAL : {staffTotal}
              </Typography>
            </Item>
          </Grid>
        </Grid>

        <Box display={"flex"} justifyContent={"space-evenly"} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            onClick={onAttendence}
            disabled={disable}
            sx={{
              borderRadius: " 30px",
              marginRight: 2,
            }}
          >
            {" "}
            Save Attendance
          </Button>
          <Button
            onClick={onLogout}
            variant="contained"
            sx={{
              backgroundColor: "#ca424f",
              color: "white",
              borderRadius: " 30px",
              padding: "10px 40px 10px 40px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#ca424f" },
            }}
          >
            EXIT
          </Button>

          <Modal
            open={open}
            // onClose={() => setSucess(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div sx={style}>
              <SucessModal
                text="Staff Attendance Saved Successfully"
                name="Staff Attendance Saved Successfully"
              />
            </div>
          </Modal>

          <Modal
            open={success}
            // onClose={() => setSucess(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div sx={style}>
              <SucessModal
                text="Staff must be either on leave or Absent, please enter properly "
                name="Staff must be either on leave or Absent, please enter properly "
              />
            </div>
          </Modal>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Stack direction="row" mx={20} spacing={2}>
          <Button
            onClick={onMonthSelect}
            value="May"
            variant="contained"
            sx={{
              backgroundColor: highlight === "May" ? "primary" : "lightblue",
              color: highlight === "May" ? "white" : "black",
              fontWeight: "bold",
              borderRadius:"30px"
            }}
          >
            MAY
          </Button>
          <Button
            onClick={onMonthSelect}
            value="June"
            variant="contained"
            sx={{
              backgroundColor: highlight === "June" ? "primary" : "lightblue",
              color: highlight === "June" ? "white" : "black",
              fontWeight: "bold",
              borderRadius:"30px"
            }}
          >
            JUNE
          </Button>
          <Button
            onClick={onMonthSelect}
            value="July"
            variant="contained"
            sx={{
              backgroundColor: highlight === "July" ? "primary" : "lightblue",
              color: highlight === "July" ? "white" : "black",
              fontWeight: "bold",
              borderRadius:"30px"
            }}
          >
            JULY
          </Button>
          <Button
            onClick={onMonthSelect}
            value="August"
            variant="contained"
            sx={{
              backgroundColor: highlight === "August" ? "primary" : "lightblue",
              color: highlight === "August" ? "white" : "black",
              fontWeight: "bold",
              borderRadius:"30px"
            }}
          >
            AUGUST
          </Button>
          <Button
            onClick={onMonthSelect}
            value="September"
            variant="contained"
            sx={{
              backgroundColor: highlight === "September" ? "primary" : "lightblue",
              color: highlight === "September" ? "white" : "black",
              fontWeight: "bold",
              borderRadius:"30px"
            }}
          >
            SEPTEMBER
          </Button>
          <Button
            onClick={onMonthSelect}
            value="October"
            variant="contained"
            sx={{
              backgroundColor: highlight === "October" ? "primary" : "lightblue",
              color: highlight === "October" ? "white" : "black",
              fontWeight: "bold",
              borderRadius:"30px"
            }}
          >
            OCTOBER
          </Button>
          <Button
            onClick={onMonthSelect}
            value="November"
            variant="contained"
            sx={{
              backgroundColor: highlight === "November" ? "primary" : "lightblue",
              color: highlight === "November" ? "white" : "black",
              fontWeight: "bold",
              borderRadius:"30px"
            }}
          >
            NOVEMBER
          </Button>
          <Button
            onClick={onMonthSelect}
            value="December"
            variant="contained"
            sx={{
              backgroundColor: highlight === "December" ? "primary" : "lightblue",
              color: highlight === "December" ? "white" : "black",
              fontWeight: "bold",
              borderRadius:"30px"
            }}
          >
            DECEMBER
          </Button>
          <Button
            onClick={onMonthSelect}
            value="January"
            variant="contained"
            sx={{
              backgroundColor: highlight === "January" ? "primary" : "lightblue",
              color: highlight === "January" ? "white" : "black",
              fontWeight: "bold",
              borderRadius:"30px"
            }}
          >
            JANUARY
          </Button>
          <Button
            onClick={onMonthSelect}
            value="February"
            variant="contained"
            sx={{
              backgroundColor: highlight === "February" ? "primary" : "lightblue",
              color: highlight === "February" ? "white" : "black",
              fontWeight: "bold",
              borderRadius:"30px"
            }}
          >
            FEBRUARY
          </Button>
          <Button
            onClick={onMonthSelect}
            value="March"
            variant="contained"
            sx={{
              backgroundColor: highlight === "March" ? "primary" : "lightblue",
              color: highlight === "March" ? "white" : "black",
              fontWeight: "bold",
              borderRadius:"30px"
            }}
          >
            MARCH
          </Button>
          <Button
            onClick={onMonthSelect}
            value="April"
            variant="contained"
            sx={{
              backgroundColor: highlight === "April" ? "primary" : "lightblue",
              color: highlight === "April" ? "white" : "black",
              fontWeight: "bold",
              borderRadius:"30px"
            }}
          >
            APRIL
          </Button>
        </Stack>
        <Box
          m={2}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button
            onClick={onAttendenceReport}
            disabled={selectedMonth === "" ? true : false}
            variant="contained"
          >
            {selectedMonth} Attendance Report
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {attandenceData.map((r) => (
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  mt: "40px",
                  backgroundColor: "lightblue",
                }}
              >
                <Box display={"flex"}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="h5" fontWeight={"bolder"}>
                        Month : {r.month}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5" fontWeight={"bolder"}>
                        Day : {r.day}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5" fontWeight={"bolder"}>
                        Date : {r.date}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display={"flex"}
                  sx={{
                    borderBottom: "2px solid",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Typography variant="h6" fontWeight={"bolder"}>
                        {" "}
                        No{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h6" fontWeight={"bolder"}>
                        {" "}
                        SSID{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h6" fontWeight={"bolder"}>
                        {" "}
                        Staff Name{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h6" fontWeight={"bolder"}>
                        {" "}
                        Status{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {r.staffAttendance.map((rec, i) => (
                <Grid item xs={12}>
                  <Box
                    display={"flex"}
                    sx={{
                      borderBottom: "2px solid",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Typography>{i + 1}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>{rec.staffSSID}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>{rec.staffName}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>{rec.staffAttendanceStatus}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
              {/* <Grid item xs={4}>
                <Box>
                  <Typography>Day :{r.day}</Typography>
                </Box>
              </Grid> */}
            </Grid>
          ))}
        </Box>
        <Box
          sx={{
            mt: "15px",
            height: 423,
            width: "100%",
            "& .super-app-theme--header": {
              backgroundColor: "#c0c0c0",
              //  color:'white',
              fontWeight: "bold",
              fontSize: "15px",
              border: "1px solid white",
            },
          }}
        >
          {isloading ? (
            <Typography variant="h3"></Typography>
          ) : (
            <DataGrid
              rows={reportRows}
              columns={reportColumns}
              pageSize={50}
              disableColumnMenu
              rowsPerPageOptions={[50]}
              // components={{ Toolbar: GridToolbar }}
            />
          )}
        </Box>
<Box sx={{display:"flex", justifyContent:"space-evenly",mt:2}}>

<Button
                variant="contained"
                size="large"
                color="error"
                onClick={genaratePDF}
                sx={{
                  borderRadius: "30px",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                PDF
              </Button>
              <Button
                variant="contained"
                size="large"
                color="success"
                onClick={exportToCSV}
                sx={{
                  borderRadius: "30px",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                excel
              </Button>
     
        <Button
                variant="contained"
                size="large"
                onClick={()=>{window.print()}}
                sx={{
                  borderRadius: "30px",
                  backgroundColor:"black",
                  color:"white",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                  "&:hover":{backgroundColor:"black",color:"white"}
                }}
              >
                print
              </Button>
        <Button
            onClick={onLogout}
            variant="contained"
            sx={{
              backgroundColor: "#ca424f",
              width:"140px",
              color: "white",
              marginBottom:2,
              borderRadius: " 30px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#ca424f" },
              
            }}
          >
            EXIT
          </Button>
          </Box>
        {/* <Box
          sx={{
            height: 300,
            width: "100%",
            "& .super-app-theme--header": {
              backgroundColor: "#B3E5FC",
              //  color:'white',
              fontWeight: "bold",
              fontSize: "15px",
              border: "1px solid white",
            },
          }}
        >
          <DataGrid rows={reportRows} columns={reportColumns} />
        </Box> */}
      </TabPanel>
    </div>
  );
}
