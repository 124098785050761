import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { Button, Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import ClassWise from "../Fees/ClassWise";
import Remainder from "../Fees/Remainder";
import Summary from "../Fees/Summary";
import { FeeCollectionStructure } from "../../../services/Feescollectionservice";
import { useState } from "react";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  // color: theme.palette.text.secondary
}));

export default function BasicGrid() {
  React.useEffect(() => {
    onMonthFeeCollectionLoad();
  }, []);

  const [totalMonthFee, SetTotalMonthFee] = useState([]);
  const [FeeDate, setFeeDate] = useState([]);
  const [totalMonthBank, setTotalmonthBank] = useState(0);
  const [totalMonthCash, setTotalmonthCash] = useState(0);
  const [totalYear, setTotalYear] = useState(0);
  async function onMonthFeeCollectionLoad() {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let yearTotal = 0;
    try {
      let response = await FeeCollectionStructure.monthFeesCollectionreport(
        data.yearName
      );
      response.data.data.forEach((r) => {
        yearTotal = parseInt(r.amount) + yearTotal;
      });
      setTotalYear(yearTotal);
      SetTotalMonthFee(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const onMonthSelect = async (e) => {
    console.log(e.target.textContent);
    let data = JSON.parse(localStorage.getItem("academicYear"));

    let cash = 0,
      bank = 0;

    try {
      let response =
        await FeeCollectionStructure.monthindivisualFeesCollectionreport(
          e.target.textContent,
          data.yearName
        );
      // console.log(response.data.data);
      response.data.data.forEach((v) => {
        cash = parseInt(v.byCash) + cash;
        bank = parseInt(v.byBank) + bank;
      });
      setTotalmonthBank(bank);
      setTotalmonthCash(cash);
      setFeeDate(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Box sx={{ width: "100%", mt: 1 }}>
        <Grid container spacing={1}>
          {totalMonthFee.map((f) => (
            <Grid item xs={1}>
              <Item sx={{ border: "1px solid black" }}>
                <Box>
                  <Typography
                    sx={{
                      // backgroundColor: "",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    onClick={onMonthSelect}
                  >
                    {f.month}
                  </Typography>
                  <hr />
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {f.amount}
                  </Typography>
                </Box>
              </Item>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Item sx={{ border: "1px solid black" }}>
              <Box>
                <Typography
                  sx={{
                    // backgroundColor: "",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={onMonthSelect}
                >
                  Total Year
                </Typography>
                <hr />
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {totalYear}
                </Typography>
              </Box>
            </Item>
          </Grid>
          <Box sx={{ mt: 2, textAlign: "center", flexGrow: 1 }}>
            <Typography
              variant="subtitle1"
              sx={{
                flexGrow: 1,
                textAlign: "center",
                fontWeight: "bold",
                backgroundColor: "lightblue",
                width: "100%",
              }}
            >
              DAILY FEES COLLECTED FOR THE MONTH OF MARCH 2021
            </Typography>
          </Box>
        </Grid>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          {FeeDate.map((f) => (
            <Grid item xs={1.5}>
              <Item sx={{ border: "1px solid black" }}>
                <Typography fontWeight={"bold"}>
                  {f.day}
                  <hr />
                  <Box display={"flex"} justifyContent={"space-evenly"}>
                    <Typography fontWeight={"bold"}>
                      Cash : {f.byCash}Rs
                    </Typography>
                    <Typography fontWeight={"bold"}>
                      Bank : {f.byBank}Rs
                    </Typography>
                  </Box>
                </Typography>
              </Item>
            </Grid>
          ))}

          <Grid item xs={12}>
            <Item sx={{ border: "1px solid black" }}>
              <Typography fontWeight={"bold"}>
                Weekly Total
                <hr />
                <Box>CASH | BANK</Box>
                <Box>
                  {totalMonthBank} | {totalMonthCash}
                </Box>
              </Typography>
            </Item>
          </Grid>
          <Grid sx={{ mt: 5 }}>
            <Button
              variant="contained"
              color="error"
              sx={{
                borderRadius: "30px",
                ml: 60,
                fontSize: 15,
                width: "120px",
                fontWeight: "bold",
              }}
            >
              PDF
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{
                borderRadius: "30px",
                ml: 3,
                fontSize: 15,
                width: "120px",
                fontWeight: "bold",
              }}
            >
              Excel
            </Button>
            <Button
              variant="contained"
              sx={{
                borderRadius: "30px",
                ml: 3,
                fontSize: 15,
                width: "120px",
                fontWeight: "bold",
                backgroundColor: "black",
                "&:hover": { backgroundColor: "black" },
              }}
            >
              Print
            </Button>
            <Button
              variant="contained"
              sx={{
                borderRadius: "30px",
                ml: 3,
                fontSize: 15,
                width: "120px",
                fontWeight: "bold",
                backgroundColor: "#ca424f",
                "&:hover": { backgroundColor: "#ca424f" },
              }}
            >
              Exit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
