import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Stack, TextField, Typography } from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  GridCellParams,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { C } from "../../util/C";
import { AddStandarsService } from "../../../services/addStandardsService";
import { StudentInfoService } from "../../../services/StudentInfo/StudentList";
import { FeeStructure } from "../../../services/FeeStructure";
import { useNavigate } from "react-router-dom";
import { FeeCollectionStructure } from "../../../services/Feescollectionservice";

export default function BasicGrid() {
  const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    // color: theme.palette.text.secondary
  }));

  const ItemS = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: "center",
    border: "1px solid black",
    background: "#aaecac",
    "&:hover": {
      background: "#94cd95",
    },
  }));

  const Item2 = styled(Paper)(({ theme }) => ({
    textAlign: "center",
    // background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
  }));

  const columns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO."}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "studentName",
      headerName: "NAME",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NAME"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },

    {
      field: "studentGender",
      headerName: "GENDER",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"GENDER"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },

    {
      field: "studentUSN",
      headerName: "USID",
      // type: 'number',
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"USID"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },

    {
      field: "standardWithDivision",
      headerName: "STD/DIV",
      // description: 'This column has a value getter and is not sortable.',
      // sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"STD/DIV"}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "fathersName",
      headerName: "FATHER'S NAME",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"FATHER'S NAME"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },

    {
      field: "totalFees",
      headerName: "TOTAL FEES",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"TOTAL FEES"}</strong>
      ),
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "paid",
      headerName: "PAID",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"PAID"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "concession",
      headerName: "CONCESSION",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"CONCESSION"}</strong>
      ),
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "balance",
      headerName: "BALANCE",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"BALANCE"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
  ];
  const [studentsList, setStudentList] = React.useState([]);
  const [stdFeesCollectionToday, setstdFeesCollectionToday] = React.useState(
    []
  );
  const [totalCollection, settotalCollection] = React.useState([]);

  React.useEffect(() => {
    onStudentsListLoad();
    onfeescollectiontoday();
  }, []);

  const [row, setRow] = React.useState([]);

  const onStudentsListLoad = async () => {
    let yearData = JSON.parse(localStorage.getItem("academicYear"));
    C.c(yearData);

    try {
      let response = await FeeStructure.feescollectiontodaytotal(
        date,
        yearData.yearName
      );
      C.c(response.data.data.standardFeesCollection);
      C.c(response.data.data);
      setstdFeesCollectionToday(response.data.data.standardFeesCollection);
      settotalCollection(response.data.data);
    } catch (error) {
      C.c(error);
    }

    let data = JSON.parse(localStorage.getItem("academicYear"));
    let studentArray = [];
    try {
      // alert(data.yearName);
      let response = await FeeCollectionStructure.StudentFeesCollectionList(
        data.yearName
      );
      console.log(response.data.data);
      response.data.data.forEach((r, i) => {
        studentArray.push({
          id: i + 1,
          studentName: r.studentName,
          studentGender: r.gender,
          studentUSN: r.usid,
          standardWithDivision: r.standardName + " | " + r.division,
          fathersName: r.fatherName,
          totalFees: r.totalFees,
          paid: r.paid ? r.paid : "0",
          concession: r.concession ? r.concession : "0",
          balance: r.balance,
        });
      });
      setRow(studentArray);
    } catch (error) {
      console.log(error);
    }
  };
  const navigate = useNavigate();
 
  const onLaunchpad = () => {
    navigate("/launchpad", { replace: true });
  };

  const gotoStudentInfo = (params, v) => {
    localStorage.setItem("StudentFeedata", JSON.stringify(params.row));
    navigate("/FeesCollection", { replace: true });
  };



  //adding id and standardWithDivision properties to display in the DataGrid
  let updatedStudentsList = studentsList.map((object) => {
    return {
      ...object,
      ...{
        id: object.studentID,
        standardWithDivision: object.standardName + "/" + object.divisionName,
      },
    };
  });

  // console.log(alteredArray);

  const date = new Date();
  const today = {
    day: "2-digit",
    month: "long",
    year: "numeric",
    weekday: "long",
  };
  const dateString = date.toLocaleDateString("eng-GB", today);

  const [Standard, setStandard] = React.useState([]);

  const onfeescollectiontoday = async () => {
    const dates = new Date();
    const today = {
      day: "2-digit",
      month: "long",
      year: "numeric",
      weekday: "long",
    };
    const date = dates.toLocaleDateString("eng-GB", today);
    const currentDate = new Date();
    const iso8601Date = currentDate.toISOString().split("T")[0];
    let yearData = JSON.parse(localStorage.getItem("academicYear"));
    C.c(yearData);

    try {
      let response = await FeeStructure.feescollectiontodaytotal(
        iso8601Date,
        yearData.yearName
      );
      C.c(response.data.data.standardFeesCollection);
      C.c(response.data.data);
      setstdFeesCollectionToday(response.data.data.standardFeesCollection);
      settotalCollection(response.data.data);
    } catch (error) {
      C.c(error);
    }
  };

  const [selectedStandard, onSelectedStandard] = React.useState("");

  const onSelectStandard = (v) => {
    console.log(v);
  };

  return (
    <div>
      {/* <LaunchpadTopBar id="fees" /> */}
      <Box sx={{ width: "100%", padding: "0 10px", boxSizing: "border-box" }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: 20,
            fontWeight: "bold",
            backgroundColor: "lightblue",
            textAlign: "center",
            mt: 2,
          }}
        >
          Fees Collected Today <b> {dateString}</b>
        </Typography>
        <Grid container>
          <Grid Item2 xs={9}>
            <Item2>
              <Grid container spacing={0} sx={{ minHeight: 40 }}>
                {stdFeesCollectionToday
                  ? stdFeesCollectionToday.map((element) => (
                      <Grid item xs={2} sx={{ cursor: "pointer" }}>
                        <ItemS
                          variant="button"
                          id={element.standardID}
                          name={element.standardName}
                          sx={{ fontWeight: "bold" }}
                          onClick={() => {
                            console.log(element);
                          }}
                        >
                          {element.standardName}
                        </ItemS>

                        <Typography
                          size="large"
                          sx={{
                            border: "1px solid black",
                            height: 30,
                            borderRadius: 1,
                          }}
                          inputProps={{
                            style: {
                              fontSize: 25,
                              height: 30,
                              fontWeight: "bold",
                            },
                          }}
                        >
                          {element.total}
                        </Typography>
                      </Grid>
                    ))
                  : ""}
              </Grid>
            </Item2>
          </Grid>
          {/* {totalCollection.map((rec)=>( */}
          <Grid item xs={3}>
            <Item>
              <Typography
                sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}
              >
                Total Collection
              </Typography>
              <Stack direction="row">
                <Grid xs={4}>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{ fontSize: 18 }}
                  >
                    CASH
                  </Typography>
                  <Typography variant="outlined" size="small">
                    {totalCollection.cash}
                  </Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{ fontSize: 18 }}
                  >
                    BANK
                  </Typography>
                  <Typography variant="outlined" size="small">
                    {totalCollection.bank}
                  </Typography>
                </Grid>

                <Grid xs={4}>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{ fontSize: 18 }}
                  >
                    TOTAL
                  </Typography>
                  <Typography variant="outlined" size="small">
                    {totalCollection.total}
                  </Typography>
                </Grid>
              </Stack>
            </Item>
          </Grid>
          {/* ))} */}
        </Grid>
        <Box sx={{ height: 370 }}>
          <DataGrid
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            id={updatedStudentsList.studentUSN}
            // rows={updatedStudentsList}
            rows={row}
            columns={columns}
            onCellClick={gotoStudentInfo}
            rowsPerPageOptions={[10, 25, 50, 100]}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            sx={{
              "& .super-app-theme--header": {
                backgroundColor: "#c0c0c0",
                //  color:'white',
                fontWeight: "bold",
                fontSize: "15px",
                border: "1px solid white",
              },
            }}
          />
        </Box>

        <Box sx={{display:"flex",justifyContent:"center"}}>
        <Button
                  variant="contained"
                  onClick={onLaunchpad}
                  sx={{
                    backgroundColor: "#ca424f",
                    fontSize: 15,
                    fontWeight: "bold",
                    borderRadius: "30px",
                    width: "120px",
                    ml: 5,
                    "&:hover": { backgroundColor: "#ca424f" },
                  }}
                >
                  exit
                </Button>
        </Box>
      </Box>
    </div>
  );
}
