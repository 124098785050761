import { useEffect, useState } from "react";
import { TimeTable } from "../../../services/TimeTable";
import { AddStandarsService } from "../../../services/addStandardsService";
import { StandardDivision } from "../../../services/StandardDIvision";
import { Avatar, Box, Button, Grid, Typography, Modal } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import { StaffService } from "../../../services/StaffService";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { TimeTableService } from "../../../services/TimeTableService";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color: "white",
  },
}));
const ItemD = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#03dff9",
  "&:hover": {
    background: "#038da6",
    color: "white",
  },
}));
const ItemBg = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));

function ClassRoutineCreate() {
  const [StandardList, setStandardList] = useState([]);
  const [SelectStandard, setSelectStandard] = useState("");

  const [divisionList, setDivisionList] = useState([]);
  const [SelectDivision, setSelectDivision] = useState("");

  const [staffList, setStaffList] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState("");

  const [SubjectList, setSubjectList] = useState([]);
  const [SelectSubject, setSelectSubject] = useState("");

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const [Break, setBreak] = useState("");
  const [timeTableMaster, setTimeTableMaster] = useState([]);
  const [staff, setStaff] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    onstandardsLoad();
    onStaffLoad();
  }, []);

  const onstandardsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      setStandardList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onStaffLoad = async () => {
    try {
      let response = await StaffService.staffListAll();
      setStaffList(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
    // setStaff(stafD);
  };

  const onSelectStandard = async (e, index) => {

    let highlightStandardList = [...StandardList];
    highlightStandardList = highlightStandardList.map(
      ({ isSelected, ...highlightStandardList }) => highlightStandardList
    );
    setStandardList(highlightStandardList);
    highlightStandardList[index]["isSelected"] = highlightStandardList[index][
      "iSSelected"
    ]
      ? false
      : true;


    let data = JSON.parse(localStorage.getItem("academicYear"));

    if (StandardList.length == 0) {
      return;
    }
    const found = StandardList.find(
      (element) => element.standardID == e.target.id
    );
    setSelectStandard(found);
    try {
      let response = await StandardDivision.StandardDivisionWiseStandard(
        e.currentTarget.id,
        data.yearName,
        data.yearID
      );
      setDivisionList(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onSelectDivision = async (e, index) => {

    let highlightDivision = [...divisionList];
    highlightDivision = highlightDivision.map(
      ({ isSelected, ...highlightDivision }) => highlightDivision
    );
    setDivisionList(highlightDivision);
    highlightDivision[index]["isSelected"] = highlightDivision[index][
      "isSelected"
    ]
      ? false
      : true;

    let data = JSON.parse(localStorage.getItem("academicYear"));

    if (divisionList.length == 0) {
      return;
    }
    const found = divisionList.find(
      (element) => element.divisionName == e.target.id
    );
    setSelectDivision(found);

    let daysList = [];
    let typeList = [];

    let dayColumn = [];

    try {
      let response = await TimeTable.StandardTimeTable(
        SelectStandard.standardID,
        SelectStandard.standardName,
        found.divisionName,
        data.yearName
      );
      daysList = response.data.data.daysList;
      if (response.data.data.titleList == null) {
        alert("Title List is null can't create table");
      } else {
        typeList = response.data.data.titleList;
        // alert("Table Created");
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 2000);
      }
      setTimeTableMaster(response.data.data.timeTableMasterList);
      console.log(response.data.data.timeTableMasterList);
      const { Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday } =
        response.data.data.timeTableMasterList;
      console.log(Monday);
      // console.log(Tuesday);
      // console.log(Wednesday);
      // console.log(Thursday);
      // console.log(Friday);
      // console.log(Saturday);
      // console.log(Sunday);
      if (Monday) {
        let monObj = {
          id: 0,
          Timetable: "Mon",
          Period0: "",
          Period1: "",
          Period2: "",
          Period3: "",
          Period4: "",
          Period5: "",
          Period6: "",
          Period7: "",
          Period8: "",
          Period9: "",
          Period10: "",
          Period11: "",
          Period12: "",
          Period13: "",
          Period14: "",
          timeTableID: "",
        };
        Monday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0") {
            console.log("if", element);
            monObj.Period0 = element.subjectName.toUpperCase() +" - "+ element.staffName;
            // } else {
            //   console.log("else");
            //   monObj.Period0 = "--";
          }
          if (element.title == "Period 1") {
            monObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period1 = "--";
          }
          if (element.title == "Period 2") {
            monObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period2 = "--";
          }
          if (element.title == "Period 3") {
            monObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period3 = "--";
          }
          if (element.title == "Period 4") {
            monObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period4 = "--";
          }
          if (element.title == "Period 5") {
            monObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period5 = "--";
          }
          if (element.title == "Period 6") {
            monObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period6 = "--";
          }
          if (element.title == "Period 7") {
            monObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period7 = "--";
          }
          if (element.title == "Period 8") {
            monObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period8 = "--";
          }
          if (element.title == "Period 9") {
            monObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period9 = "--";
          }
          if (element.title == "Period 10") {
            monObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period10 = "--";
          }
          if (element.title == "Period 11") {
            monObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period11 = "--";
          }
          if (element.title == "Period 12") {
            monObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period12 = "--";
          }
          if (element.title == "Period 13") {
            monObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period13 = "--";
          }
          if (element.title == "Period 14") {
            monObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period14 = "--";
          }
        });
        dayColumn.push(monObj);
      }
      if (Tuesday) {
        let tueObj = {
          id: 1,
          Timetable: "Tue",
          Period0: "",
          Period1: "",
          Period2: "",
          Period3: "",
          Period4: "",
          Period5: "",
          Period6: "",
          Period7: "",
          Period8: "",
          Period9: "",
          Period10: "",
          Period11: "",
          Period12: "",
          Period13: "",
          Period14: "",
        };
        Tuesday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0") {
            tueObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period0 = "--";
          }
          if (element.title == "Period 1") {
            tueObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period1 = "--";
          }
          if (element.title == "Period 2") {
            tueObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period2 = "--";
          }
          if (element.title == "Period 3") {
            tueObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period3 = "--";
          }
          if (element.title == "Period 4") {
            tueObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period4 = "--";
          }
          if (element.title == "Period 5") {
            tueObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period5 = "--";
          }
          if (element.title == "Period 6") {
            tueObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period6 = "--";
          }
          if (element.title == "Period 7") {
            tueObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period7 = "--";
          }
          if (element.title == "Period 8") {
            tueObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period8 = "--";
          }
          if (element.title == "Period 9") {
            tueObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period9 = "--";
          }
          if (element.title == "Period 10") {
            tueObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period10 = "--";
          }
          if (element.title == "Period 11") {
            tueObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period11 = "--";
          }
          if (element.title == "Period 12") {
            tueObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period12 = "--";
          }
          if (element.title == "Period 13") {
            tueObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period13 = "--";
          }
          if (element.title == "Period 14") {
            tueObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period14 = "--";
          }
        });
        dayColumn.push(tueObj);
      }
      if (Wednesday) {
        let wedObj = {
          id: 2,
          Timetable: "Wed",
          Period0: "",
          Period1: "",
          Period2: "",
          Period3: "",
          Period4: "",
          Period5: "",
          Period6: "",
          Period7: "",
          Period8: "",
          Period9: "",
          Period10: "",
          Period11: "",
          Period12: "",
          Period13: "",
          Period14: "",
        };
        Wednesday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0") {
            wedObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period0 = "--";
          }
          if (element.title == "Period 1") {
            wedObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period1 = "--";
          }
          if (element.title == "Period 2") {
            wedObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period2 = "--";
          }
          if (element.title == "Period 3") {
            wedObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period3 = "--";
          }
          if (element.title == "Period 4") {
            wedObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period4 = "--";
          }
          if (element.title == "Period 5") {
            wedObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period5 = "--";
          }
          if (element.title == "Period 6") {
            wedObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period6 = "--";
          }
          if (element.title == "Period 7") {
            wedObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period7 = "--";
          }
          if (element.title == "Period 8") {
            wedObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period8 = "--";
          }
          if (element.title == "Period 9") {
            wedObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period9 = "--";
          }
          if (element.title == "Period 10") {
            wedObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period10 = "--";
          }
          if (element.title == "Period 11") {
            wedObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period11 = "--";
          }
          if (element.title == "Period 12") {
            wedObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period12 = "--";
          }
          if (element.title == "Period 13") {
            wedObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period13 = "--";
          }
          if (element.title == "Period 14") {
            wedObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period14 = "--";
          }
        });
        dayColumn.push(wedObj);
      }
      if (Thursday) {
        let thuObj = {
          id: 3,
          Timetable: "Thu",
          Period0: "",
          Period1: "",
          Period2: "",
          Period3: "",
          Period4: "",
          Period5: "",
          Period6: "",
          Period7: "",
          Period8: "",
          Period9: "",
          Period10: "",
          Period11: "",
          Period12: "",
          Period13: "",
          Period14: "",
        };
        Thursday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0") {
            thuObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period0 = "--";
          }
          if (element.title == "Period 1") {
            thuObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period1 = "--";
          }
          if (element.title == "Period 2") {
            thuObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period2 = "--";
          }
          if (element.title == "Period 3") {
            thuObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period3 = "--";
          }
          if (element.title == "Period 4") {
            thuObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period4 = "--";
          }
          if (element.title == "Period 5") {
            thuObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period5 = "--";
          }
          if (element.title == "Period 6") {
            thuObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period6 = "--";
          }
          if (element.title == "Period 7") {
            thuObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period7 = "--";
          }
          if (element.title == "Period 8") {
            thuObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period8 = "--";
          }
          if (element.title == "Period 9") {
            thuObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period9 = "--";
          }
          if (element.title == "Period 10") {
            thuObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period10 = "--";
          }
          if (element.title == "Period 11") {
            thuObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period11 = "--";
          }
          if (element.title == "Period 12") {
            thuObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period12 = "--";
          }
          if (element.title == "Period 13") {
            thuObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period13 = "--";
          }
          if (element.title == "Period 14") {
            thuObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period14 = "--";
          }
        });
        dayColumn.push(thuObj);
      }
      if (Friday) {
        let friObj = {
          id: 4,
          Timetable: "Fri",
          Period0: "",
          Period1: "",
          Period2: "",
          Period3: "",
          Period4: "",
          Period5: "",
          Period6: "",
          Period7: "",
          Period8: "",
          Period9: "",
          Period10: "",
          Period11: "",
          Period12: "",
          Period13: "",
          Period14: "",
        };
        Friday.forEach((element) => {
          if (element.title == "Period 0") {
            friObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period0 = "--";
          }
          if (element.title == "Period 1") {
            friObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period1 = "--";
          }
          if (element.title == "Period 2") {
            friObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period2 = "--";
          }
          if (element.title == "Period 3") {
            friObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period3 = "--";
          }
          if (element.title == "Period 4") {
            friObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period4 = "--";
          }
          if (element.title == "Period 5") {
            friObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period5 = "--";
          }
          if (element.title == "Period 6") {
            friObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period6 = "--";
          }
          if (element.title == "Period 7") {
            friObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period7 = "--";
          }
          if (element.title == "Period 8") {
            friObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period8 = "--";
          }
          if (element.title == "Period 9") {
            friObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period9 = "--";
          }
          if (element.title == "Period 10") {
            friObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period10 = "--";
          }
          if (element.title == "Period 11") {
            friObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period11 = "--";
          }
          if (element.title == "Period 12") {
            friObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period12 = "--";
          }
          if (element.title == "Period 13") {
            friObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period13 = "--";
          }
          if (element.title == "Period 14") {
            friObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period14 = "--";
          }
        });
        dayColumn.push(friObj);
      }
      if (Saturday) {
        let satObj = {
          id: 5,
          Timetable: "Sat",
          Period0: "",
          Period1: "",
          Period2: "",
          Period3: "",
          Period4: "",
          Period5: "",
          Period6: "",
          Period7: "",
          Period8: "",
          Period9: "",
          Period10: "",
          Period11: "",
          Period12: "",
          Period13: "",
          Period14: "",
        };
        Saturday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0") {
            satObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period0 = "--";
          }
          if (element.title == "Period 1") {
            satObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period1 = "--";
          }
          if (element.title == "Period 2") {
            satObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period2 = "--";
          }
          if (element.title == "Period 3") {
            satObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period3 = "--";
          }
          if (element.title == "Period 4") {
            satObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period4 = "--";
          }
          if (element.title == "Period 5") {
            satObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period5 = "--";
          }
          if (element.title == "Period 6") {
            satObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period6 = "--";
          }
          if (element.title == "Period 7") {
            satObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period7 = "--";
          }
          if (element.title == "Period 8") {
            satObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period8 = "--";
          }
          if (element.title == "Period 9") {
            satObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period9 = "--";
          }
          if (element.title == "Period 10") {
            satObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period10 = "--";
          }
          if (element.title == "Period 11") {
            satObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period11 = "--";
          }
          if (element.title == "Period 12") {
            satObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period12 = "--";
          }
          if (element.title == "Period 13") {
            satObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period13 = "--";
          }
          if (element.title == "Period 14") {
            satObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period14 = "--";
          }
        });
        dayColumn.push(satObj);
      }
      if (Sunday) {
        let sunObj = {
          id: 6,
          Timetable: "Sun",
          Period0: "",
          Period1: "",
          Period2: "",
          Period3: "",
          Period4: "",
          Period5: "",
          Period6: "",
          Period7: "",
          Period8: "",
          Period9: "",
          Period10: "",
          Period11: "",
          Period12: "",
          Period13: "",
          Period14: "",
        };
        Sunday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0") {
            sunObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period0 = "--";
          }
          if (element.title == "Period 1") {
            sunObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period1 = "--";
          }
          if (element.title == "Period 2") {
            sunObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period2 = "--";
          }
          if (element.title == "Period 3") {
            sunObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period3 = "--";
          }
          if (element.title == "Period 4") {
            sunObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period4 = "--";
          }
          if (element.title == "Period 5") {
            sunObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period5 = "--";
          }
          if (element.title == "Period 6") {
            sunObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period6 = "--";
          }
          if (element.title == "Period 7") {
            sunObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period7 = "--";
          }
          if (element.title == "Period 8") {
            sunObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period8 = "--";
          }
          if (element.title == "Period 9") {
            sunObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period9 = "--";
          }
          if (element.title == "Period 10") {
            sunObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period10 = "--";
          }
          if (element.title == "Period 11") {
            sunObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period11 = "--";
          }
          if (element.title == "Period 12") {
            sunObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period12 = "--";
          }
          if (element.title == "Period 13") {
            sunObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period13 = "--";
          }
          if (element.title == "Period 14") {
            sunObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period14 = "--";
          }
        });
        dayColumn.push(sunObj);
      }
    } catch (error) {
      console.log(error.message);
    }

    // console.log(daysList);

    let periodColumn = [];
    periodColumn.unshift({
      field: "Timetable",
      headerName: "Timetable",
      editable: "true",
      flex: 1.1,
      ClassName: "Timetable",
      sortable: false,
      headerClassName: "super-app-theme--header",
    });
    for (let i = 0; i < typeList.length; i++) {
      let period = typeList[i].startTime + " " + typeList[i].endTime;
      console.log(typeList[i]);
      let st = typeList[i].startTime
        ? typeList[i].startTime.split(":")
        : "00:00";
      let et = typeList[i].endTime ? typeList[i].endTime.split(":") : "00:00";
      let type = typeList[i].typeOfPeriod == "class" ? "C" : "B";
      // let title = typeList[i].title == "class" ? "C" : "B";
      periodColumn.push({
        field: typeList[i].title.split(" ").join(""),
        headerName:
          st[0] + ":" + st[1] + " - " + et[0] + ":" + et[1] ,
          // + " [" + type + "]",
        editable: "true",
        renderHeader: () => (
          <strong style={{ fontSize: 13,lineHeight:"16px" }}>
            {st[0] +
              ":" +
              st[1] +
              " - " +
              et[0] +
              ":" +
              et[1] 
              // " [" +
              // type +
              // "]"
              }
            <br />
            {typeList[i].title}
          </strong>
        ),
        headerClassName: "super-app-theme--header",
        flex: 2.5,
        headerAlign:"center",
        align:"center",
        ClassName: typeList[i].title,
        sortable: false,

      });
      console.log(periodColumn);
    }
    setColumns(periodColumn);

    setRows(dayColumn);
  };

  const onSelectStaff = (e) => {
    setBreak("");
    const found = staffList.find((element) => element.staffID == e.target.id);
    // if (found.staffSubjectList.length == 0) {
    //   return;
    // }
    setSelectedStaff(found);
    setSubjectList(found.staffSubjectList);
    setStaff(found.staffID);
    // console.log(found);
  };

  const onSelectSubject = (e) => {
    console.log(e.target.id);
    console.log(SubjectList);
    if (SubjectList.length == 0) {
      return;
    }
    let found = SubjectList.find(
      (element) => element.subjectID == e.target.id
    );
    console.log(found);
   setSelectSubject(found);
  };

  const onSelectBreak = (e)=> {
    setSelectedStaff("");
    setSelectSubject("");
    console.log(e.target.id);
    setBreak(e.target.id);
    console.log(selectedStaff);
    console.log(SelectSubject);
  }

  const oncellselect = async (cell, e) => {
    let yeardata = JSON.parse(localStorage.getItem("academicYear"));

    let day = cell.field;
    console.log(cell.field);
    console.log(cell.row.id);
    // console.log(timeTableMaster);
    console.log(SelectSubject);
    console.log(selectedStaff);
    console.log(Break);

    const { Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday } =
      timeTableMaster;

    let found;
    if (Monday && cell.row.id == 0) {
      found = Monday.find((r) => {
        console.log(r);
        let period = r.title.split(" ").join("");
        if (cell.row.id == 0 && r.day == "Monday" && cell.field == period) {
          return r;
        }
      });
    }
    console.log(found);

    if (Tuesday && cell.row.id == 1) {
      found = Tuesday.find((r) => {
        // console.log(r);
        let period = r.title.split(" ").join("");
        if (cell.row.id == 1 && r.day == "Tuesday" && cell.field == period) {
          return r;
        }
      });
    }
    console.log(found);

    if (Wednesday && cell.row.id == 2) {
      found = Wednesday.find((r) => {
        // console.log(r);
        let period = r.title.split(" ").join("");
        if (cell.row.id == 2 && r.day == "Wednesday" && cell.field == period) {
          return r;
        }
      });
    }
    console.log(found);

    if (Thursday && cell.row.id == 3) {
      found = Thursday.find((r) => {
        // console.log(r);
        let period = r.title.split(" ").join("");
        if (cell.row.id == 3 && r.day == "Thursday" && cell.field == period) {
          return r;
        }
      });
    }
    console.log(found);

    if (Friday && cell.row.id == 4) {
      found = Friday.find((r) => {
        // console.log(r);
        let period = r.title.split(" ").join("");
        if (cell.row.id == 4 && r.day == "Friday" && cell.field == period) {
          return r;
        }
      });
    }
    if (Saturday && cell.row.id == 5) {
      found = Saturday.find((r) => {
        // console.log(r);
        let period = r.title.split(" ").join("");
        if (cell.row.id == 5 && r.day == "Saturday" && cell.field == period) {
          return r;
        }
      });
    }
    console.log(found);

    if (Sunday && cell.row.id == 6) {
      found = Sunday.find((r) => {
        // console.log(r);
        let period = r.title.split(" ").join("");
        if (cell.row.id == 6 && r.day == "Sunday" && cell.field == period) {
          return r;
        }
      });
    }
    console.log(found);
    console.log(selectedStaff);
    console.log(SelectSubject);

    // debugger;
    if(Break == 0||null||undefined) {
      found.staffID = selectedStaff.staffID;
    found.staffName = selectedStaff.staffName;
    found.subjectID = SelectSubject.subjectID;
    found.subjectName = SelectSubject.subjectName;
    found.yearID = yeardata.yearID;
    }
    else{
      found.staffID = Break;
    found.staffName = "TIME";
    found.subjectID = Break;
    found.subjectName = Break;
    found.yearID = yeardata.yearID;
    }

    let daysList = [];
    let typeList = [];

    let dayColumn = [];
    console.log(found);

    try {
      let response = await TimeTableService.saveStdDivTimeTable(found);
      const { Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday } =
        response.data.data.timeTableMasterList;
      console.log(Monday);
      // console.log(Tuesday);
      // console.log(Wednesday);
      // console.log(Thursday);
      // console.log(Friday);
      // console.log(Saturday);
      // console.log(Sunday);
      if (Monday) {
        let monObj = {
          id: 0,
          Timetable: "Monday",
          Period0: "",
          Period1: "",
          Period2: "",
          Period3: "",
          Period4: "",
          Period5: "",
          Period6: "",
          Period7: "",
          Period8: "",
          Period9: "",
          Period10: "",
          Period11: "",
          Period12: "",
          Period13: "",
          Period14: "",
          timeTableID: "",
        };
        Monday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0") {
            monObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period0 = "--";
          }
          if (element.title == "Period 1") {
            monObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period1 = "--";
          }
          if (element.title == "Period 2") {
            monObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period2 = "--";
          }
          if (element.title == "Period 3") {
            monObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period3 = "--";
          }
          if (element.title == "Period 4") {
            monObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period4 = "--";
          }
          if (element.title == "Period 5") {
            monObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period5 = "--";
          }
          if (element.title == "Period 6") {
            monObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period6 = "--";
          }
          if (element.title == "Period 7") {
            monObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period7 = "--";
          }
          if (element.title == "Period 8") {
            monObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period8 = "--";
          }
          if (element.title == "Period 9") {
            monObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period9 = "--";
          }
          if (element.title == "Period 10") {
            monObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period10 = "--";
          }
          if (element.title == "Period 11") {
            monObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period11 = "--";
          }
          if (element.title == "Period 12") {
            monObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period12 = "--";
          }
          if (element.title == "Period 13") {
            monObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period13 = "--";
          }
          if (element.title == "Period 14") {
            monObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   monObj.Period14 = "--";
          }
        });
        dayColumn.push(monObj);
      }
      if (Tuesday) {
        let tueObj = {
          id: 1,
          Timetable: "Tuesday",
          Period0: "",
          Period1: "",
          Period2: "",
          Period3: "",
          Period4: "",
          Period5: "",
          Period6: "",
          Period7: "",
          Period8: "",
          Period9: "",
          Period10: "",
          Period11: "",
          Period12: "",
          Period13: "",
          Period14: "",
        };
        Tuesday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0") {
            tueObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period0 = "--";
          }
          if (element.title == "Period 1") {
            tueObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period1 = "--";
          }
          if (element.title == "Period 2") {
            tueObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period2 = "--";
          }
          if (element.title == "Period 3") {
            tueObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period3 = "--";
          }
          if (element.title == "Period 4") {
            tueObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period4 = "--";
          }
          if (element.title == "Period 5") {
            tueObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period5 = "--";
          }
          if (element.title == "Period 6") {
            tueObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period6 = "--";
          }
          if (element.title == "Period 7") {
            tueObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period7 = "--";
          }
          if (element.title == "Period 8") {
            tueObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period8 = "--";
          }
          if (element.title == "Period 9") {
            tueObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period9 = "--";
          }
          if (element.title == "Period 10") {
            tueObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period10 = "--";
          }
          if (element.title == "Period 11") {
            tueObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period11 = "--";
          }
          if (element.title == "Period 12") {
            tueObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period12 = "--";
          }
          if (element.title == "Period 13") {
            tueObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period13 = "--";
          }
          if (element.title == "Period 14") {
            tueObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   tueObj.Period14 = "--";
          }
        });
        dayColumn.push(tueObj);
      }
      if (Wednesday) {
        let wedObj = {
          id: 2,
          Timetable: "Wednesday",
          Period0: "",
          Period1: "",
          Period2: "",
          Period3: "",
          Period4: "",
          Period5: "",
          Period6: "",
          Period7: "",
          Period8: "",
          Period9: "",
          Period10: "",
          Period11: "",
          Period12: "",
          Period13: "",
          Period14: "",
        };
        Wednesday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0") {
            wedObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period0 = "--";
          }
          if (element.title == "Period 1") {
            wedObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period1 = "--";
          }
          if (element.title == "Period 2") {
            wedObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period2 = "--";
          }
          if (element.title == "Period 3") {
            wedObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period3 = "--";
          }
          if (element.title == "Period 4") {
            wedObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period4 = "--";
          }
          if (element.title == "Period 5") {
            wedObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period5 = "--";
          }
          if (element.title == "Period 6") {
            wedObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period6 = "--";
          }
          if (element.title == "Period 7") {
            wedObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period7 = "--";
          }
          if (element.title == "Period 8") {
            wedObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period8 = "--";
          }
          if (element.title == "Period 9") {
            wedObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period9 = "--";
          }
          if (element.title == "Period 10") {
            wedObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period10 = "--";
          }
          if (element.title == "Period 11") {
            wedObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period11 = "--";
          }
          if (element.title == "Period 12") {
            wedObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period12 = "--";
          }
          if (element.title == "Period 13") {
            wedObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period13 = "--";
          }
          if (element.title == "Period 14") {
            wedObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   wedObj.Period14 = "--";
          }
        });
        dayColumn.push(wedObj);
      }
      if (Thursday) {
        let thuObj = {
          id: 3,
          Timetable: "Thursday",
          Period0: "",
          Period1: "",
          Period2: "",
          Period3: "",
          Period4: "",
          Period5: "",
          Period6: "",
          Period7: "",
          Period8: "",
          Period9: "",
          Period10: "",
          Period11: "",
          Period12: "",
          Period13: "",
          Period14: "",
        };
        Thursday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0") {
            thuObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period0 = "--";
          }
          if (element.title == "Period 1") {
            thuObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period1 = "--";
          }
          if (element.title == "Period 2") {
            thuObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period2 = "--";
          }
          if (element.title == "Period 3") {
            thuObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period3 = "--";
          }
          if (element.title == "Period 4") {
            thuObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period4 = "--";
          }
          if (element.title == "Period 5") {
            thuObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period5 = "--";
          }
          if (element.title == "Period 6") {
            thuObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period6 = "--";
          }
          if (element.title == "Period 7") {
            thuObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period7 = "--";
          }
          if (element.title == "Period 8") {
            thuObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period8 = "--";
          }
          if (element.title == "Period 9") {
            thuObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period9 = "--";
          }
          if (element.title == "Period 10") {
            thuObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period10 = "--";
          }
          if (element.title == "Period 11") {
            thuObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period11 = "--";
          }
          if (element.title == "Period 12") {
            thuObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period12 = "--";
          }
          if (element.title == "Period 13") {
            thuObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period13 = "--";
          }
          if (element.title == "Period 14") {
            thuObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   thuObj.Period14 = "--";
          }
        });
        dayColumn.push(thuObj);
      }
      if (Friday) {
        let friObj = {
          id: 4,
          Timetable: "Friday",
          Period0: "",
          Period1: "",
          Period2: "",
          Period3: "",
          Period4: "",
          Period5: "",
          Period6: "",
          Period7: "",
          Period8: "",
          Period9: "",
          Period10: "",
          Period11: "",
          Period12: "",
          Period13: "",
          Period14: "",
        };
        Friday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0") {
            friObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period0 = "--";
          }
          if (element.title == "Period 1") {
            friObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period1 = "--";
          }
          if (element.title == "Period 2") {
            friObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period2 = "--";
          }
          if (element.title == "Period 3") {
            friObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period3 = "--";
          }
          if (element.title == "Period 4") {
            friObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period4 = "--";
          }
          if (element.title == "Period 5") {
            friObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period5 = "--";
          }
          if (element.title == "Period 6") {
            friObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period6 = "--";
          }
          if (element.title == "Period 7") {
            friObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period7 = "--";
          }
          if (element.title == "Period 8") {
            friObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period8 = "--";
          }
          if (element.title == "Period 9") {
            friObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period9 = "--";
          }
          if (element.title == "Period 10") {
            friObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period10 = "--";
          }
          if (element.title == "Period 11") {
            friObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period11 = "--";
          }
          if (element.title == "Period 12") {
            friObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period12 = "--";
          }
          if (element.title == "Period 13") {
            friObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period13 = "--";
          }
          if (element.title == "Period 14") {
            friObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   friObj.Period14 = "--";
          }
        });
        dayColumn.push(friObj);
      }
      if (Saturday) {
        let satObj = {
          id: 5,
          Timetable: "Saturday",
          Period0: "",
          Period1: "",
          Period2: "",
          Period3: "",
          Period4: "",
          Period5: "",
          Period6: "",
          Period7: "",
          Period8: "",
          Period9: "",
          Period10: "",
          Period11: "",
          Period12: "",
          Period13: "",
          Period14: "",
        };
        Saturday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0") {
            satObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period0 = "--";
          }
          if (element.title == "Period 1") {
            satObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period1 = "--";
          }
          if (element.title == "Period 2") {
            satObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period2 = "--";
          }
          if (element.title == "Period 3") {
            satObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period3 = "--";
          }
          if (element.title == "Period 4") {
            satObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period4 = "--";
          }
          if (element.title == "Period 5") {
            satObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period5 = "--";
          }
          if (element.title == "Period 6") {
            satObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period6 = "--";
          }
          if (element.title == "Period 7") {
            satObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period7 = "--";
          }
          if (element.title == "Period 8") {
            satObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period8 = "--";
          }
          if (element.title == "Period 9") {
            satObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period9 = "--";
          }
          if (element.title == "Period 10") {
            satObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period10 = "--";
          }
          if (element.title == "Period 11") {
            satObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period11 = "--";
          }
          if (element.title == "Period 12") {
            satObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period12 = "--";
          }
          if (element.title == "Period 13") {
            satObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period13 = "--";
          }
          if (element.title == "Period 14") {
            satObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   satObj.Period14 = "--";
          }
        });
        dayColumn.push(satObj);
      }
      if (Sunday) {
        let sunObj = {
          id: 6,
          Timetable: "Sunday",
          Period0: "",
          Period1: "",
          Period2: "",
          Period3: "",
          Period4: "",
          Period5: "",
          Period6: "",
          Period7: "",
          Period8: "",
          Period9: "",
          Period10: "",
          Period11: "",
          Period12: "",
          Period13: "",
          Period14: "",
        };
        Sunday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0") {
            sunObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period0 = "--";
          }
          if (element.title == "Period 1") {
            sunObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period1 = "--";
          }
          if (element.title == "Period 2") {
            sunObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period2 = "--";
          }
          if (element.title == "Period 3") {
            sunObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period3 = "--";
          }
          if (element.title == "Period 4") {
            sunObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period4 = "--";
          }
          if (element.title == "Period 5") {
            sunObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period5 = "--";
          }
          if (element.title == "Period 6") {
            sunObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period6 = "--";
          }
          if (element.title == "Period 7") {
            sunObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period7 = "--";
          }
          if (element.title == "Period 8") {
            sunObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period8 = "--";
          }
          if (element.title == "Period 9") {
            sunObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period9 = "--";
          }
          if (element.title == "Period 10") {
            sunObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period10 = "--";
          }
          if (element.title == "Period 11") {
            sunObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period11 = "--";
          }
          if (element.title == "Period 12") {
            sunObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period12 = "--";
          }
          if (element.title == "Period 13") {
            sunObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period13 = "--";
          }
          if (element.title == "Period 14") {
            sunObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.staffName}`;
            // } else {
            //   sunObj.Period14 = "--";
          }
        });
        dayColumn.push(sunObj);
      }
      setRows(dayColumn);
    } catch (error) {
      console.log(error);
    }
  };

  const generatePDF =()=> {
    const doc = new jsPDF("landscape");

    const academic = JSON.parse(localStorage.getItem("academicYear"));
    const school = JSON.parse(localStorage.getItem("schoolDetails"))

  //  const head = columns.map(c=>c.headerName+" "+c.ClassName);
  const head = columns.map((obj, index) => [
    index !== 0 ? obj.headerName+" "+obj.ClassName : "Timetable"
  ]);
  
   const header = [[...head]];

   const tableData = rows.map((r)=>[
    r.Timetable,r.Period0,r.Period1,r.Period2,r.Period3,
    r.Period4,r.Period5,r.Period6,r.Period7,r.Period8,
    r.Period9
   ]);

   doc.setFontSize(10);
    doc.setFont("Helvetica", "bold")
    doc.text(`${academic.yearName}`, 250, 20);

    const text = `${school.schoolNameOnDocs.toUpperCase()}`;
    const fontSize = 18;
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.setFontSize(fontSize);
    doc.setFont("Helvetica", "bold");
    doc.text(text, pageWidth / 2, 22, { align: "center" });

    const img = `${school.schoolLogoUrl}`;
    doc.addImage(img, "JPEG", 20, 5, 27, 27);

    doc.setFontSize(15);
    doc.text(SelectStandard.standardName+" - "+SelectDivision.divisionName+" TIMETABLE", 150, 40, 'center');

    doc.autoTable({
      startY:50,
      tableLineColor:[0,0,0],
      margin: 5,
      head:header,
      body:tableData,
      styles: { cellWidth:26, fontSize:10,fontStyle:'bold'},
      headStyles: {
        valign: "middle",
        halign: "center",
      },
      bodyStyles: {
        valign: "middle",
        halign: "center",
        fontSize:8.1,
        lineColor:[0,0,0],
        fontStyle:'bold'   
      },
      tableLineWidth: 0.45,
      theme: "grid",
    })
    doc.save(SelectStandard.standardName+"-"+SelectDivision.divisionName+" Timetable.pdf");
  }

  return (
    <div>
      {" "}
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          backgroundColor: "lightblue",
          mt: 2,
          fontWeight: "bold",
        }}
      >
        SELECT THE STANDARD AND DIVISION
      </Typography>
      {/* <Box sx={{ flexGrow: 1, m: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              {StandardList.map((element) => (
                <Grid item xs={2}>
                  <Button
                    variant="text"
                    value={element.standardName}
                    id={element.standardID}
                    size="small"
                    sx={{
                      mt: 1,
                      background: "#aaecac",
                      "&:hover": {
                        background: "#035e06",
                      },
                      color: "white",
                    }}
                    onClick={onSelectStandard}
                    fullWidth
                  >
                    {element.standardName}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2}>
              {divisionList.map((element) => (
                <Grid item xs={2}>
                  <Button
                    variant="text"
                    value={element.divisionName}
                    id={element.divisionName}
                    size="small"
                    sx={{
                      mt: 1,
                      background: "#03dff9",
                      "&:hover": {
                        background: "#038da6",
                      },
                      color: "white",
                    }}
                    onClick={onSelectDivision}
                    fullWidth
                  >
                    {element.divisionName}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box> */}

<Box
        sx={{
          flexGrow: 1,
          mt: 1,
          width: "100%",
          padding: "0 10px",
          boxSizing: "border-box",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <ItemBg>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {StandardList.map((element, index) => (
                  <Grid item xs={2} sx={{ cursor: "pointer" }}>
                    <ItemS
                      id={element.standardID}
                      value={element.standardName}
                      onClick={(e) => {
                        onSelectStandard(e, index);
                      }}
                      className={element.isSelected ? "Selected" : ""}
                      sx={{ fontWeight: "bold" }}
                    >
                      {element.standardName}
                    </ItemS>
                  </Grid>
                ))}
              </Grid>
            </ItemBg>
          </Grid>
          <Grid item xs={4}>
            <ItemBg>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {divisionList.map((element, index) => (
                  <Grid item xs={2} sx={{ cursor: "pointer" }}>
                    <ItemD
                      sx={{
                        fontWeight: "bold",
                      }}
                      onClick={(e) => {
                        onSelectDivision(e, index);
                      }}
                      id={element.divisionName}
                      className={element.isSelected ? "selected" : ""}
                    >
                      {" "}
                      {element.divisionName}
                    </ItemD>
                  </Grid>
                ))}
              </Grid>
            </ItemBg>
          </Grid>
        </Grid>
      </Box>

      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          backgroundColor: "lightblue",
          mt: 2,
          fontWeight: "bold",
        }}
      >
        SELECT THE CLASS TEACHER AND RESPECTED SUBJECT
      </Typography>
      <Box sx={{ flexGrow: 1, m: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Grid container spacing={1}>
              {staffList.map((element) => (
                <Grid item xs={3}>
                  {/* <Item> */}
                    <Box display={"flex"}
                    sx={{backgroundColor: staff == element.staffID ? "	#008B8B" : "white"}}
                    >
                      <Avatar
                        variant="square"
                        sx={{ mx: 4, my: 1, height:"50px",width:"50px" }}
                        alt={element.staffName}
                        src={element.staffProfilePhoto}
                      />
                      {/* <Typography>{element.staffName}</Typography> */}
                      <Button
                        variant="text"
                        value={element.staffName}
                        id={element.staffID}
                        size="small"
                        sx={{ mt: 1,fontWeight:"bold",
                        color: staff == element.staffID ? "white" : "black" ,
                              // backgroundColor: staff == element.staffID ? "black" : "white"
                      }}
                        onClick={onSelectStaff}
                      >
                        {element.staffName}
                      </Button>
                    </Box>
                  {/* </Item> */}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2}>
              {SubjectList.map((element) => (
                
                <Grid item xs={4}>
                  {/* <Item> */}
                  
                  <Button
                    variant="text"
                    value={element.subjectName}
                    id={element.subjectID}
                    size="small"
                    sx={{
                      mt: 1,
                      background: "#37bc98",
                      fontWeight:"bold",
                      "&:hover": {
                        background: "#2ea283",
                      },
                      color: "white",
                    }}
                    onClick={onSelectSubject}
                    fullWidth
                  >
                    {element.subjectName}
                  </Button>
                  {/* </Item> */}
                </Grid>
               
              ))}
              <Grid item xs={4}>
              <Button
                    variant="text"
                    id="prayer"
                    size="small"
                    sx={{
                      mt: 1,
                      background: "#37bc98",
                      fontWeight:"bold",
                      "&:hover": {
                        background: "#2ea283",
                      },
                      color: "white",
                    }}
                    onClick={onSelectBreak}
                    fullWidth
                  >
                   PRAYER-TIME
                  </Button>
                  </Grid>

<Grid item xs={4}>
                  <Button
                    variant="text"
                    id="lunch"
                    size="small"
                    sx={{
                      mt: 1,
                      background: "#37bc98",
                      fontWeight:"bold",
                      "&:hover": {
                        background: "#2ea283",
                      },
                      color: "white",
                    }}
                    onClick={onSelectBreak}
                    fullWidth
                  >
                   LUNCH-TIME
                  </Button>
                  </Grid>

                  <Grid item xs={4}>
                  <Button
                    variant="text"
                    id="tea"
                    size="small"
                    sx={{
                      mt: 1,
                      background: "#37bc98",
                      fontWeight:"bold",
                      "&:hover": {
                        background: "#2ea283",
                      },
                      color: "white",
                    }}
                    onClick={onSelectBreak}
                    fullWidth
                  >
                   TEA-TIME
                  </Button>
                  </Grid>
                  
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          backgroundColor: "lightblue",
          mt: 2,
          fontWeight: "bold",
        }}
      >
        TIMETABLE - {SelectStandard.standardName} -{" "}
        {SelectDivision.divisionName}
      </Typography>
      <Box
        style={{
          m: 2,
          height: 480,
          width: "auto",
          textAlign: "center",
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={12}
          hideFooter
          disableColumnMenu
          rowsPerPageOptions={[12]}
          onCellClick={oncellselect}
          getRowHeight={() => 'auto'}
          showCellRightBorder={true}
          showColumnRightBorder={true}
          getRowId={(row) => row.id}
          getRowClassName={(params) =>
            `myRowClassName ${params.id }`
          }
          sx={{
            "& .super-app-theme--header": {
              backgroundColor: "#c0c0c0",
              //  color:'white',
              fontWeight: "bold",
              fontSize: "15px",
              border: "1px solid white",
            },
          }}
          // autoHeaderHeight={true}
          // columnHeaderHeight="500px"
          // onCellDoubleClick={onCellDelete}
        />
      </Box>
      <Box sx={{display:"flex",justifyContent:'center'}}>
      <Button variant="contained" 
      size="large" 
      sx={{borderRadius:"15px",width:"140px",borderRadius:"30px",fontWeight:"bold",mt:1}} 
      color="error"
       onClick={generatePDF}
       >
        pdf
        </Button>
      </Box>

      <Modal
                  open={open}
                  // onClose={() => setSucess(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div sx={style}>
                    <SucessModal
                      text="Time Table Created Successfully"
                      name="Time Table Created Successfully"
                    />
                  </div>
                </Modal>

    </div>
  );
}
export default ClassRoutineCreate;
