import * as React from "react";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Button, Stack } from "@mui/material";
import { AddStandarsService } from "../../../services/addStandardsService";
import { useNavigate } from "react-router-dom"; //for browser back button navigation handling
import { BuildSchoolControll } from "../../../services/buildSchoolControllService";

// import { Item, DataItem } from "../../commonComponents";

var _sections = ["A", "B", "C", "D", "E", "F", "G"];

var _defaultSections = 1;

const setValue = (event) => {};

const handleKeyPress = (that) => {
  console.log(that);
  // if(el.value != ""){
  //   if(parseInt(el.value) < parseInt(el.min)){
  //     el.value = el.min;
  //   }
  //   if(parseInt(el.value) > parseInt(el.max)){
  //     el.value = el.max;
  //   }
  // }
};

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0),
  textAlign: "center",
  border: "1px solid ",
}));

const DataItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  display: "inline-flex",
}));

const StandardComponent = ({ stdIdx, standard, handlers }) => {
  //browser back button redirection to dashboard
  const Navigate = useNavigate();
  const [finishStatus, setfinishStatus] = React.useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to go back to Dashboard?")) {
        setfinishStatus(true);
        // your logic
        Navigate("/dashborad");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  const [status, setStatus] = React.useState();

  const onLoadBuildSchool = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolControll.buildSchoolStatusListAll(
        data.yearName
      );
      // console.log(response.data.data[0]);
      setStatus(response.data.data[0].buildSchoolStatus);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    onLoadBuildSchool();
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);
  // back button logic ends

  const [totalSection, setTotalSection] = React.useState(_defaultSections);
  var noOfSections = 0;
  console.log(standard);
  if (standard) {
    return (
      <Grid item xs={2.4}>
        <Item>
          <Typography sx={{ p: 1 }}>{standard.standardName}</Typography>
          <Box sx={{ width: "100%" }}>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2 }}>
              {standard.division != null
                ? standard.division.map((val, idx) => {
                    noOfSections++;
                    if (!val.deleteStatus) {
                      // return <div key={idx}>
                      //     <label> {val.divisionName} </label>
                      //     <input stdidx={stdIdx} divisionidx={idx} onChange={handlers.sectionChange} value={val.maxNo} type="text"/>
                      // </div>
                      return (
                        <Grid key={idx} xs={6}>
                          <DataItem
                            id={val.divisionName + standard.standardName}
                          >
                            <Typography sx={{ p: 1 }}>
                              {val.divisionName}
                            </Typography>
                            <TextField
                              id={val.divisionName + standard.standardName}
                              // label="Outlined"
                              variant="outlined"
                              size="small"
                              // type="number"
                              InputProps={{
                                inputProps: {
                                  // min: 1,
                                  max: 99,
                                  stdidx: stdIdx,
                                  divisionidx: idx,
                                  inputMode: "numeric",
                                  maxLength: 2,
                                },
                              }}
                              onBlur={handlers.default}
                              // onKeyUp={handleKeyPress()}
                              onChange={handlers.sectionChange}
                              value={val.maxNo ? val.maxNo : ""}
                              sx={{
                                width: "60px",
                              }}
                            />
                          </DataItem>
                        </Grid>
                      );
                    } else {
                      return false;
                    }
                  })
                : [...Array(_defaultSections)].map((_item, index) => {
                    var item = _sections[index];
                    return (
                      <Grid key={index} item xs={6}>
                        <DataItem>
                          <Typography sx={{ p: 1 }}>{item}</Typography>

                          {/* <TextField
                            id="outlined-basic"
                            // label="Outlined"
                            variant="outlined"
                            size="small"
                            sx={{
                              "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                  display: "none",
                                },
                            }}
                            // inputProps={{ inputMode: "numeric", maxLength: 2 }}
                            InputProps={{
                              inputProps: {
                                min: 1,
                                max: 99,
                                stdidx: stdIdx,
                                divisionidx: index,
                                inputMode: "numeric",
                                maxLength: 2,
                              },
                            }}
                            onChange={handlers.sectionChange}
                          /> */}
                          <TextField
                            id="outlined-basic"
                            // label="Outlined"
                            variant="outlined"
                            size="small"
                            InputProps={{
                              inputProps: {
                                min: 1,
                                max: 99,
                                stdidx: stdIdx,
                                divisionidx: index,
                                inputMode: "numeric",
                                maxLength: 2,
                                // inputProps={{ inputMode: "numeric", maxLength: 2 }}
                              },
                            }}
                            // onBlur={setValue(this)}
                            onChange={handlers.sectionChange}
                            // value="0"
                            sx={{
                              width: "60px",
                            }}
                          />
                        </DataItem>
                      </Grid>
                    );
                  })}
            </Grid>
          </Box>
          <Stack
            direction="row"
            spacing={0}
            sx={{ alignContent: "center", p: 1 }}
          >
            <Button
              variant="contained"
              size="small"
              color="success"
              sx={{ marginLeft: 5, marginRight: 2 }}
              stdidx={stdIdx}
              onClick={handlers.sectionAdd}
              disabled={status == "true" ? true : false}
            >
              Add more
            </Button>
            <Button
              variant="contained"
              size="small"
              color="error"
              stdidx={stdIdx}
              onClick={handlers.sectionRemove}
              disabled={status == "true" ? true : false}
            >
              Remove
            </Button>
          </Stack>
        </Item>
      </Grid>
    );
  } else {
    return "";
  }
};

export default StandardComponent;
