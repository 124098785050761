import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SchoolMaster } from "../../services/SchoolMaster";
import { yearService } from "../../services/yearService";
import { BuildSchoolControll } from "../../services/buildSchoolControllService";
import { AddStandarsService } from "../../services/addStandardsService";
import { SubjectService } from "../../services/subjectService";
import { DesignationService } from "../../services/DesignationService";
import { FeeStructure } from "../../services/FeeStructure";
import { BuildSchoolExam } from "../../services/examStandards";
import { StandardSubjectService } from "../../services/StandardSubject";
import { getUniqueDomId } from "@fullcalendar/core";

const AcadmicYear = () => {
  const [school, setSchool] = useState();
  const [yearList, setYear] = useState([]);
  const [progress, setProgress] = useState(30);
  const [openLoad, setOpenLoad] = useState(false);
  const [standard,setStandard] = useState([]);

  useEffect(() => {
    loadSchool();
    loadYear();
  }, []);

  async function loadYear() {
    let pageNo = 0;
    try {
      let response = await yearService.yearListAll(pageNo);
      setYear(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function loadSchool() {
    try {
      let response = await SchoolMaster.schoolList();
      setSchool(response.data.data[0]);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    let year;
    setOpenLoad(true);
    const data = new FormData(event.currentTarget);
    year = data.get("year");

    if (!year) {
      alert("Please enters new year");
      return;
    }

    let ExsitingYear = yearList.find((rec) => rec.yearName === year);
    // console.log(ExsitingYear);
    if (ExsitingYear) {
      alert("Entered year already exists");
    } else {
      console.log(data.get("year"));
      try {
        let response = await yearService.createYear({ yearName: year });
        onCreateBuildschool(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  async function onCreateBuildschool(newYear) {
    let { yearName, yearID } = newYear;

    let standard = [
      "standard",
      "subject",
      "designation",
      "timetable",
      "feestructure",
      "exam",
      "buildschoolcomplete",
    ];

    let step = ["one", "two", "three", "four", "five", "six", "seven"];

    for (let i = 0; i <= 6; i++) {
      console.log("onBuildSchool" + i);
      if (i == 0) {
        console.log("if");

        try {
          let response = await BuildSchoolControll.createBuildSchoolStatus({
            activeStatus: "active",
            buildSchoolName: standard[i],
            buildSchoolStatus: "false",
            buildSchoolStep: step[i],
            buildSchoolYear: yearName,
            yearID: yearID,
            yearName: yearName,
          });
        } catch (error) {
          alert("Error creating buildSchool " + error);
        }
      } else {
        console.log("else");

        try {
          let response = await BuildSchoolControll.createBuildSchoolStatus({
            activeStatus: "active",
            buildSchoolName: standard[i],
            buildSchoolStatus: "false",
            buildSchoolStep: step[i],
            buildSchoolYear: yearName,
            yearID: yearID,
            yearName: yearName,
          });
        } catch (error) {
          alert("Error creating buildSchool " + error);
        }
      }
    }

    console.log("Standard ");

    onStandardsMigration(yearName, yearID);
    // onStdSubMigration(yearName,yearID);

    //   const timer = setInterval(() => {
    //     setProgress((prevProgress) =>
    //       prevProgress >= 100 ? 0 : prevProgress + 10
    //     );
    //   }, 800);
    //   return () => {
    //     clearInterval(timer);
    //   };
    //   navigate("/dashborad", { replace: true });
  }

  async function onStandardsMigration(yearName, yearID) {
    let prevStandardData = [];
    let nextStandardData = [];
    let data = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      // console.log(response.data.data);
      prevStandardData = response.data.data;
      setStandard(response.data.data)
    } catch (error) {
      console.log(error);
    }
    
    if (prevStandardData) {
      prevStandardData.forEach((rec) => {
        nextStandardData.push({
          standardName: rec.standardName,
          yearID: yearID,
          yearName: yearName,
        });
      });
      console.log(nextStandardData);

      if (nextStandardData) {
        try {
          nextStandardData.forEach(async (rec) => {
            let response = await AddStandarsService.createAddStandards(rec);
          });
        } catch (error) {
          console.log(error);
          console.log("standard migration stoped");
        }
      }
    } else {
      console.log("standard migration stoped");
    }

    // ---------------------standard subjects migration-------------------

// const prevStdSub = [];
// try {
//   for (const s of prevStandardData) {
//     let response = await StandardSubjectService.findSubjectListByStandardIDYearID(data.yearName, s.standardID);
//     prevStdSub.push(response.data.data);
//   }
// } catch (error) {
//   console.log(error);
// }

// console.log(prevStdSub);

// const nextStdSub = prevStdSub.reduce((acc, innerArray) => {

//   innerArray.forEach(obj => {
//       acc.push({ 
//         standardID: obj.standardID,
//         standardName: obj.standardName,
//         subjectID: obj.subjectID,
//         subjectName: obj.subjectName,
//         subjectOrder: obj.subjectOrder,
//         yearID: yearID,
//         yearName: yearName 
//       });
//   });
//   return acc;
// }, []);

// console.log(nextStdSub);

// if(nextStdSub){
//   try{
//     nextStdSub.forEach(async(ss)=>{
//       console.log(ss);
//       let response = await StandardSubjectService.standardsubject(ss)
//     });
//   }catch(error) {
//     console.log(error)
//   }
// }else{
//   console.log("std subject stoped");
// }


// -------------------subjects migration----------------
    let prevSubjects = [];
    let nextSubjects=[];

    try{
      let response = await SubjectService.subjectListAll(data.yearName)
      console.log(response.data.data);
      prevSubjects = response.data.data
    }catch(error){
      console.log(error)
    }

    if(prevSubjects) {
      prevSubjects.forEach((r)=>{
        nextSubjects.push({
          subjectName:r.subjectName,
          yearID: yearID,
          yearName: yearName
        })
      })
      console.log(nextSubjects);

      if(nextSubjects){
        try{
          nextSubjects.forEach(async(r)=>{
            let response = await SubjectService.createSubject(r)
          })
        }catch(error){
          console.log(error)
        }
      }
    }else{
      console.log("error occured")
    }

    // -----------Designation migration-------------
    let prevDesignation=[];
    let nextDesignation=[];
    try{
      let response = await DesignationService.designationListAll(data.yearName)
prevDesignation = response.data.data;
    }catch(error){
      console.log(error)
    }

    if(prevDesignation){
      prevDesignation.forEach((d)=>{
        nextDesignation.push({
          designation: d.designation,
  yearID: yearID,
  yearName: yearName
        })
      })
      console.log(nextDesignation)

      if(nextDesignation){
       try{
        nextDesignation.forEach(async(d)=>{
          let response = await DesignationService.createDesignation(d)
        })
       }catch(error){
        console.log(error);
       }
      }
    } else{
      console.log("designation error")
    }

    // -----------Fees migration------------
    let prevFee=[]
    let nextFee=[]

    try{
      let response = await FeeStructure.feeListAll(data.yearName)
      prevFee = response.data.data
      console.log(prevFee);
   }catch(error){
    console.log(error)
   }

   if(prevFee){
    prevFee.forEach((f)=>{
      console.log(f);
      nextFee.push({
        feestitleName:f.feestitleName,
        yearID: yearID,
        yearName: yearName
      })
    })
    console.log(nextFee);

    if(nextFee){
      try{
        nextFee.forEach(async(f)=>{
          console.log(f);
          let response = await FeeStructure.createFee(f)
        })
      }catch(error){
        console.log(error)
      }
    }
   } else{
    console.log("Fee migrate error")
   }

  //  ----------------Exam Migration-------------

//  let prevExam = [];
//  let nextExam = [];
//  try{
//   let response = await BuildSchoolExam.listBuildSchoolStandardTest(data.yearName);
//   prevExam = response.data.data;
//  }catch(error){
//   console.log(error)
//  }

//  if(prevExam) {
//   prevExam.forEach((t)=>{
//     nextExam.push({
// testTitleName : t.testTitleName,
// yearID : yearID,
// yearName : yearName
//     })
//   })
//   console.log(nextExam)

//   if(nextExam) {
//     try{
//       nextExam.forEach(async(t)=>{
//         let response = await BuildSchoolExam.addBuildSchoolStandardsTest(t)
//       })
//     }catch(error){
//       console.log(error)
//     }
//   }
//  }else{
//   console.log("Test migrate error")
//  }

// ---------------standard subjects migration--------------
let std =[];
    try{
      let response = await AddStandarsService.standardListAll(yearName);
      std = response.data.data
      console.log(std)
    }catch(error){
      console.log(error)
    }

    let sub = [];
    try{
      let response = await SubjectService.subjectListAll(yearName)
      sub = response.data.data
      console.log(sub);
    }catch(error){
      console.log(error)
    }

    let preStdSub=[]
      try {
        for (const s of prevStandardData) {
          let response = await StandardSubjectService.findSubjectListByStandardIDYearID(data.yearName, s.standardID);
          preStdSub.push(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }     
      // console.log(preStdSub);

//       const nextStdSub = preStdSub.reduce((acc, innerArray) => {

//   innerArray.forEach(obj => {
//       acc.push({ 
//         standardID:  obj.standardID,
//         standardName: obj.standardName,
//         subjectID: obj.subjectID,
//         subjectName: obj.subjectName,
//         subjectOrder: obj.subjectOrder,
//         yearID: yearID,
//         yearName: yearName 
//       });
//   });
//   return acc;
// }, []);

// console.log(nextStdSub);
const nextStdSub = preStdSub.map(innerArr=>{
  return innerArr.map(obj => {
    const subid = sub.find(rec => rec.subjectName == obj.subjectName);
    const stdid = std.find(rec => rec.standardName == obj.standardName);

    if(subid && stdid){
        obj.subjectID = subid.subjectID;
        obj.standardID = stdid.standardID;
    }
    return obj;
  })
})
console.log(nextStdSub)

let newar = [];

nextStdSub.map(innerArr=>{
  return innerArr.forEach((r)=>{
newar.push({
  standardID: r.standardID,
    standardName: r.standardName,
    subjectID: r.subjectID,
    subjectName: r.subjectName,
    subjectOrder: r.subjectOrder,
    yearID: yearID,
    yearName: yearName
})
  })
})
console.log(newar);
console.log(newar[0]);

if(newar){
  migratestdsub(newar);
  try{
    newar.forEach(async(ss)=>{
      // console.log(ss);
      let response = await StandardSubjectService.standardsubject(ss)
    })
  }catch(error){
    console.log(error)
  }
} else{
  console.log("standard subject migration stopped")
}
    setOpenLoad(false);
  }

   function migratestdsub(newar){
    console.log(newar);
    try{
      newar.forEach(async(ss)=>{
        // console.log(ss);
        let response = await StandardSubjectService.standardsubject(ss)
      })
    }catch(error){
      console.log(error)
    }
  }
  // async function onStdSubMigration(yearName,yearID){
  //   let data = JSON.parse(localStorage.getItem("academicYear"))
  //   let std =[];
  //   try{
  //     let response = await AddStandarsService.standardListAll(yearName);
  //     std = response.data.data
  //     console.log(std)
  //   }catch(error){
  //     console.log(error)
  //   }

  //   let sub = [];
  //   try{
  //     let response = await SubjectService.subjectListAll(yearName)
  //     sub = response.data.data
  //     console.log(sub);
  //   }catch(error){
  //     console.log(error)
  //   }

  //   let preStdSub=[]
  //   try {
  //     for (const s of standard) {
  //       let response = await StandardSubjectService.findSubjectListByStandardIDYearID(data.yearName, s.standardID);
  //       preStdSub.push(response.data.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
    
  //   console.log(preStdSub);

  // }
  return (
    <Container
      component="main"
      // maxWidth="xs"
      sx={{
        backgroundColor: "lightgrey",
        borderColor: "2px solid green",
        borderRadius: "5px",
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openLoad}
        // onClick={handleClose}
      >
        <CircularProgress variant="determinate" value={progress} />
      </Backdrop>
      <Box
        sx={{
          marginTop: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 1, minWidth: 100 }}
        >
          <h1>{school ? school.schoolName : "School Name"}</h1>
          <TextField
            margin="normal"
            required
            fullWidth
            id="year"
            label="Enter Year"
            name="year"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Create Acadmic Year
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
export default AcadmicYear;