import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { Button, Grid, Stack,Modal } from "@mui/material";
import { StaffService } from "../../../services/StaffService";
import { useNavigate } from "react-router-dom";
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid red",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};

export default function DataGridDemo() {
  React.useEffect(() => {
    onStaffLoad();
  }, []);

  const [columns, setColumns] = React.useState([
    //   {
    //   field: "ssid",
    //   headerName: "SSID",
    // },
    {
      field: "ssid",
      headerName: "SSID",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"SSID"} </strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "staffName",
      headerName: "STAFF NAME",
      flex: 1,
      align: "center",
      sortable: false,
      headerAlign: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"STAFF NAME"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "designantion",
      headerName: "DESIGNATION",
      flex: 1,
      align: "center",
      sortable: false,
      headerAlign: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"DESIGNATION"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "monthlySalary",
      headerName: "MONTHLY SALARY",
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"MONTHLY SALARY"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "changeSalary",
      headerName: "UPDATE SALARY",
      flex: 1,
      editable: true,
      sortable: false,
      type: "number",
      align: "center",
      headerAlign: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"UPDATE SALARY"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
  ]);

  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [excel, setExcel] = React.useState([]);
  const [staff, setStaff] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const onStaffLoad = async () => {
    setLoading(true);
    try {
      // let response = await StaffService.staffListAll();

      let response = await StaffService.salariesDetailsList();

      // console.log("Hi");
      console.log(response.data.data);

      setStaff(response.data.data);
      //console.log(staff);
      let yearData = JSON.parse(localStorage.getItem("academicYear"));

      let staffArray = [];
      response.data.data.forEach(function (data) {
        //console.log(data);
        staffArray.push({
          id: data.ssid,
          // id: data.staffID,
          ssid: data.ssid,
          staffName: data.staffName,
          designantion: data.designation,
          changeSalary: 0,
          monthlySalary: data.monthlysalary,
          // paySalaries:data.monthlySalary,
          // yearID: data.year,
          yearID: yearData.yearName,
        });
      });

      setRows(staffArray);
      setLoading(false);
      //console.log(rows);
      let salaryexcel = [];
      response.data.data.forEach(function (data) {
        salaryexcel.push({
          SSID: data.ssid,
          STAFFNAME: data.staffName,
          DESIGNATION: data.designation,
          MONTHLYSALARY: data.monthlysalary,
        });
      });
      setExcel(salaryexcel);
    } catch (error) {
      // console.log(error);
    }
  };
  const onSalaryUpdate = (e) => {
    console.log(e);

    const found = rows.find((element) => element.id == e.id);

    console.log(found);
    found.changeSalary = e.value;
    found.changed = true;
  };

  const handleUpdate = async () => {
    let yearData = JSON.parse(localStorage.getItem("academicYear"));

    let updatedStaffArray = [],
      salary,
      ss;

    rows.forEach(function (data) {
      if (data.changeSalary != 0) {
        salary = data.changeSalary;
        ss = data.ssid;
        console.log(ss);
        updatedStaffArray.push({
          ssid: ss,
          monthlysalary: salary.toString(),
        });
      } else {
        salary = data.monthlySalary;
        ss = data.ssid;
      }
    });

    console.log(updatedStaffArray);
    // debugger
    updatedStaffArray.forEach(async (r) => {
      try {
        setOpen(true);
        let response = await StaffService.updatesalariesDetails([r]);
        console.log(response.data.data);
        // setRows(updatedStaffArray);
        //console.log(rows);
        // setTimeout(() => {
        // onStaffLoad();
        // }, 2000);
        setTimeout(() => {
          setOpen(false);
        }, 2000);
      } catch (error) {
        console.log(error);
      }
    });
  };

  let navigate = useNavigate();
  const onLogout = () => {
    console.log("onLogout");
    navigate("/launchpad", { replace: true });
  };

  const [academicYear, setacademicYear] = React.useState(
    JSON.parse(localStorage.getItem("academicYear"))
  );
  const [schoolName, setschoolName] = React.useState(
    JSON.parse(localStorage.getItem("schoolDetails"))
  );

  const generatePDF = () => {
    const doc = new jsPDF("p", "pt", "a4");
    const sch = schoolName.schoolNameOnDocs.toUpperCase();
    const acad = academicYear.yearName;
    const img = schoolName.schoolLogoUrl;
    doc.setFont("helvetica", "bold");

    var fontSize = 18;
    var text = `${sch}`;
    var pageWidth = doc.internal.pageSize.getWidth();
    var textWidth =
      (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
    var centerX = (pageWidth - textWidth) / 2;
    doc.text(text, centerX, 35);

    doc.setFontSize(10);
    doc.text(` ${acad}`, 500, 35);
    doc.setFontSize(15);

    doc.addImage(img, "JPEG", 20, 10, 80, 70);

    doc.setFont("helvetica", "bold");
    doc.text("Staff Salaries Details", 220, 75);

    const tableData = rows.map((row) => [
      row.ssid,
      row.staffName,
      row.designantion,
      row.monthlySalary,
      // row.changeSalary,
    ]);

    doc.autoTable({
      startY: 95,
      tableLineColor: [0, 0, 0],
      head: [
        [
          "SSID",
          "STAFF NAME",
          "DESIGNATION",
          "MONTHLY SALARY",
          // "UPDATE SALARY",
        ],
      ],
      body: tableData,
      headStyles: {
        valign: "middle",
        halign: "center",
      },
      bodyStyles: {
        valign: "middle",
        halign: "center",
        lineColor: [0, 0, 0],
      },
      tableLineWidth: 0.95,
      theme: "grid",
    });
    doc.setFontSize(10);
    // doc.text("Copyright: Technotharanga Solutions Pvt.ltd", 190, 800);
    doc.save("Staff salary details.pdf");
  };

  const fileType = "csv";
  const exportToCSV = () => {
    const visible = XLSX.utils.json_to_sheet(excel);
    const wb = { Sheets: { product: visible }, SheetNames: ["product"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    filesaver.saveAs(data, "StaffsalaryDetails" + ".csv");
  };

  return (
    <div
      style={{
        backgroundImage: `url("bgColor8.png")`,
        backgroundSize: "cover",
      }}
    >
      <React.Fragment>
        {/* <LaunchpadTopBar id="staff" /> */}
        {/* SALARY DETAILS TABLE DATA  */}
        {loading ? (
          <div
            style={{
              padding: "180px 0px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          </div>
        ) : (
          <Box>
            <Box sx={{ height: 423, mx: 2, marginTop: "2%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[15]}
                disableSelectionOnClick
                disableColumnMenu
                sx={{
                  width: "100%",
                  "& .super-app-theme--header": {
                    backgroundColor: "#c0c0c0",
                    // color:'white',
                    fontWeight: "bold",
                    fontSize: "15px",
                    border: "1px solid white",
                  },
                }}
                // experimentalFeatures={{ newEditingApi: true }}
                onCellEditCommit={onSalaryUpdate}
              />
            </Box>
            {/*---END OF SALARY DETAILS TABLE DATA  ---*/}

            <Box
              sx={{ display: "flex", justifyContent: "space-evenly", mt: 2 }}
            >
               <Button
                variant="contained"
                size="large"
                color="error"
                // onClick={genaratePDF}
                sx={{
                  borderRadius: "30px",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                PDF
              </Button>

              <Button
                variant="contained"
                size="large"
                color="success"
                onClick={exportToCSV}
                sx={{
                  borderRadius: "30px",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                excel
              </Button>

              <Button
                variant="contained"
                sx={{
                  color: "white",
                  borderRadius: "30px",
                  width:"140px",
                  fontWeight: "bold",
                  marginBottom:2,
                }}
                // type="submit"
                // onClick={handlesubmit}
                onClick={handleUpdate}
              >
                UPDATE
              </Button>

              <Button
                variant="contained"
                size="large"
                onClick={()=>{window.print()}}
                sx={{
                  borderRadius: "30px",
                  backgroundColor:"black",
                  color:"white",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                  "&:hover":{backgroundColor:"black",color:"white"}
                }}
              >
                print
              </Button>

              <Button
            onClick={onLogout}
            variant="contained"
            sx={{
              backgroundColor: "#ca424f",
              width:"140px",
              color: "white",
              marginBottom:2,
              borderRadius: " 30px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#ca424f" },
              
            }}
          >
            EXIT
          </Button>

          <Modal
                  open={open}
                  // onClose={() => setSucess(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div sx={style}>
                    <SucessModal
                      text="Staff Salaries Updated Successully"
                      name="Staff Salaries Updated Successully"
                    />
                  </div>
                </Modal>

            </Box>
          </Box>
        )}
      </React.Fragment>
    </div>
  );
}
