import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Avatar,
  Button,
  Modal,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { C } from "../../util/C";
import { AddStandarsService } from "../../../services/addStandardsService";
import { StandardDivision } from "../../../services/StandardDIvision";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DataGrid } from "@mui/x-data-grid";
import BoyTwoToneIcon from "@mui/icons-material/BoyTwoTone";
import { StudentService } from "../../../services/studentService";
import { ro } from "date-fns/locale";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";

// const Item = styled(Paper)(({ theme }) => ({
//   // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   // ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   // height: 80
//   // color: theme.palette.text.secondary,
// }));
// const DataItem = styled(Paper)(({ theme }) => ({
//   // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   // ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   // height: 80
//   // color: theme.palette.text.secondary,
// }));
// const FormItem = styled(Paper)(({ theme }) => ({
//   // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   // ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   height: 400,
//   // height: 80
//   // color: theme.palette.text.secondary,
// }));
const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color: "white",
  },
}));
const ItemBg = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));
const DivisionItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  // border: "1px solid"
  // height: 80
  // color: theme.palette.text.secondary,
}));
const StandardItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid",

  // height: 80
  // color: theme.palette.text.secondary,
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid red",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};
export default function BasicGrid() {
  const [openSucess, setSucess] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [gender, setGender] = React.useState("");

  const handleChange = (event) => {
    setGender(event.target.value);
  };
  const [DivisionOnly, setDivisionOnly] = React.useState([
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "I",
    "J",
    "K",
  ]);
  const [Status, setStatus] = React.useState(["✅", "❌"]);

  const [Standard, setStandard] = React.useState([]);
  React.useEffect(() => {
    onstandardsLoad();
  }, []);

  //api integration
  const onstandardsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      C.c(response.data.data);
      setStandard(response.data.data);
    } catch (error) {
      C.c(error);
    }
  };

  const [Division, setDivision] = React.useState([]);

  const [Capacity, setCapacity] = React.useState(0);

  const [studentList, setStudentList] = React.useState([]);

  const [rows, setRows] = React.useState([]);

  const [Standardname, setStandardName] = React.useState();
  const [StandardID, setStandardID] = React.useState();

  async function onStandardSelect(e, index) {
    setRows("");
    setDivisionName("");
    e.preventDefault();
    C.c(e.currentTarget.id);
    setStandardName(e.target.textContent);
    setStandardID(e.target.id);

    let highlightStandardList = [...Standard];
    highlightStandardList = highlightStandardList.map(
      ({ isSelected, ...highlightStandardList }) => highlightStandardList
    );
    setStandard(highlightStandardList);
    highlightStandardList[index]["isSelected"] = highlightStandardList[index][
      "isSelected"
    ]
      ? false
      : true;

    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await StudentService.StandardDivisionGenderCount(
        e.currentTarget.id,
        data.yearName,
        data.yearID
      );
      C.c(response.data.data);
      setDivision(response.data.data);
      setDivisionOnly(response.data.data);
      let divisionObject = [];
      for (let i = 0; i < response.data.data.length; i++) {
        divisionObject.push(response.data.data[i].division);
      }
      setDivisionOnly(divisionObject);
      // C.c(DivisionOnly);
    } catch (error) {
      alert(error.message);
    }
    // try {
    //   let response = await StandardDivision.StandardDivisionWiseStandard(
    //     e.currentTarget.id,
    //     data.yearName,
    //     data.yearID
    //   );
    //   // C.c(response.data.data);

    //   var MaxCap = 0;
    //   for (let i = 0; i < response.data.data.length; i++) {
    //     let max = response.data.data[i].maxNo;
    //     // C.c(max);
    //     MaxCap += max;
    //     // C.c(MaxCap);
    //   }
    //   setCapacity(MaxCap);

    //   let dataarray = [];
    //   response.data.data.forEach(async (r, i) => {
    //     let girlcount = 0;
    //     let boycount = 0;
    //     try {
    //       let resp = await StudentService.approveddivisionstudentListAll(
    //         data.yearName,
    //         r.divisionName,
    //         e.target.id
    //       );
    //       resp.data.data.forEach((e) => {
    //         if (e.studentGender.toUpperCase() == "BOY") {
    //           boycount = boycount + 1;
    //         }
    //         if (e.studentGender.toUpperCase() == "GIRL") {
    //           girlcount = girlcount + 1;
    //         }
    //       });
    //     } catch (err) {
    //       console.log(err);
    //     }
    //     console.log(girlcount, boycount);
    //     dataarray.push({
    //       divisionID: r.divisionID,
    //       divisionName: r.divisionName,
    //       maxNo: r.maxNo,
    //       standardDivisionID: r.standardDivisionID,
    //       standardID: r.standardID,
    //       standardName: r.standardName,
    //       yearID: r.yearID,
    //       yearName: r.yearName,
    //       gCount: girlcount,
    //       bCount: boycount,
    //     });
    //   });
    //   setTimeout(() => {
    //     console.log(dataarray);
    //     setDivision(dataarray);
    //   }, 500);

    //   var divisionObject = [];
    //   for (let i = 0; i < response.data.data.length; i++) {
    //     id: divisionObject.push(response.data.data[i].divisionName);
    //   }
    //   setDivisionOnly(divisionObject);
    //   // C.c(DivisionOnly);
    // } catch (error) {
    //   C.c(error);
    // }
  }

  const [DivisionName, setDivisionName] = React.useState();

  async function onDivisionSelect(e, index) {
    console.log(e.currentTarget.id);
    // setDivisionOnly(e.currentTarget.id);
    setDivisionName(e.currentTarget.id);

    let highlightDivision = [...Division];
    highlightDivision = highlightDivision.map(
      ({ isSelected, ...highlightDivision }) => highlightDivision
    );
    setDivision(highlightDivision);
    highlightDivision[index]["isSelected"] = highlightDivision[index][
      "isSelected"
    ]
      ? false
      : true;

    let data = JSON.parse(localStorage.getItem("academicYear"));

    let student = [];
    try {
      let response = await StudentService.unApproveddivisionstudentListAll(
        data.yearName,
        e.currentTarget.id,
        StandardID
      );
      C.c(response.data.data);
      // student = response.data.data;
      response.data.data.forEach((rec) => {
        student.push({
          id: rec.studentID,
          student_name: rec.studentName.toUpperCase(),
          gender: rec.studentGender,
          current_division: rec.divisionName,
        });
        // C(rec)
      });
      setStudentList(response.data.data);
      setRows(student);
      C.c(rows);
    } catch (error) {
      C.c(error.message);
    }
  }

  const columns = [
    {
      field: "current_division",
      headerName: "PRESENT DIVISION",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong>{"PRESENT DIVISION"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "student_name",
      headerName: "NAME",
      flex: 2,
      headerAlign: "center",
      align: "center",
      sortable: false,
      // editable: true,
      renderHeader: () => <strong>{"NAME"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "gender",
      headerName: "GENDER",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong>{"GENDER"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   sortable: false,
    //   flex: 2,
    //   disableClickEventBubbling: true,
    //   renderCell: (params) => {
    //     return (
    //       <div
    //         className="d-flex justify-content-between align-items-center"
    //         style={{ cursor: "pointer", hidden: "true" }}
    //       >
    //         <FormControl
    //           variant="filled"
    //           size="small"
    //           sx={{ m: 0, flex: 1 }}
    //         >
    //           {/* <InputLabel id="gender">Division</InputLabel> */}

    //           <Select
    //             // labelId=""
    //             // id="demo-simple-select-fille"
    //             value={gender}
    //             onChange={handleChange}
    //             name="studentGender"
    //           >
    //             {Division.map((element) => (
    //               <MenuItem value={element.divisionName}>
    //                 {element.divisionName}
    //               </MenuItem>
    //             ))}
    //           </Select>
    //         </FormControl>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "assign_division",
      headerName: "ASSIGN DIVISION",
      flex: 1,
      editable: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: !DivisionOnly ? ["A", "B", "C", "D"] : DivisionOnly,
      renderHeader: () => <strong>{"ASSIGN DIVISION"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "approve_Status",
      headerName: "STATUS",
      flex: 1,
      editable: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: ["Approve", "Denied"],
      renderHeader: () => <strong>{"STATUS"}</strong>,
      headerClassName: "super-app-theme--header",
    },
  ];

  const onDivisionAssign = (e) => {
    // C.c(e);
    if (e.field === "assign_division") {
      const found = rows.find((element) => element.id == e.id);

      found.assign_division = e.value;
    }
    if (e.field === "approve_Status") {
      const found = rows.find((element) => element.id == e.id);

      found.approve_Status = e.value;
    }

    C.c(rows);
    // debugger;
    // debugger;

    // let studentDivAssignObj = {
    //   studentID: e.id,
    //   divisionName: e.value,
    // };

    // try {
    //   let response = await StudentService.studentDivUpdate([
    //     studentDivAssignObj,
    //   ]);
    //   C.c(response.data.data);
    // } catch (error) {
    //   C.c(error.message);
    // }
  };

  const onApproveStudent = async () => {
    C.c(rows);
    let studentArray = [];

    rows.forEach((rec) => {
      let status;
      if (rec.approve_Status === "Approve") {
        status = true;
        setRows("");
      } else if (rec.approve_Status === "Denied") {
        status = false;
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 2000);
      } else {
        status = false;
      }

      studentArray.push({
        studentID: rec.id,
        approveStatus: status,
        divisionName: rec.assign_division
          ? rec.assign_division
          : rec.current_division,
        divisionID: rec.assign_division
          ? rec.assign_division
          : rec.current_division,
      });
    });
    C.c(studentArray);

    setSucess(true);
    try {
      let response = await StudentService.studentDivUpdate(studentArray);
      setTimeout(() => {
        setSucess(false);
      }, 2000);
      C.c(response.data.data);
    } catch (error) {
      C.c(error.message);
    }
  };
  return (
    <div>
      {/* <LaunchpadTopBar id="dataentry" /> */}
      <Box
        sx={{
          mt: 2,
          textAlign: "center",
          fontWeight: "bold",
          width: "100%",
          boxSizing: "border-box",
          padding: "0 10px",
        }}
      >
        <Typography
          variant="h6"
          color="black"
          fontWeight="bold"
          sx={{
            backgroundColor: "lightblue",
          }}
        >
          APPROVE THE STUDENTS ADDED TO THE SCHOOL DATABASE / SELECT STANDARD,
          DIVISION AND APPROVE DETAIL
        </Typography>

        <Grid container spacing={0} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <ItemBg>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Standard.map((element, index) => (
                  <Grid item xs={2} sx={{ cursor: "pointer" }}>
                    <ItemS
                      id={element.standardID}
                      name={element.standardName}
                      onClick={(e) => {
                        onStandardSelect(e, index);
                      }}
                      className={element.isSelected ? "Selected" : ""}
                      sx={{ fontWeight: "bold" }}
                    >
                      {element.standardName}
                    </ItemS>
                  </Grid>
                ))}
              </Grid>
            </ItemBg>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <DivisionItem>
              {/* <Stack direction="row"> */}
              <Grid container spacing={{}}>
                {Division.map((element, index) => (
                  <Grid item xs={1} sx={{ cursor: "pointer" }}>
                    <Box sx={{}}>
                      <Box
                        sx={{
                          backgroundColor: "lightblue",
                          border: "2px solid",
                          borderRadius: "10px",
                        }}
                      >
                        <Button
                          // variant="contained"
                          id={element.division}
                          sx={{
                            backgroundColor: "#03dff9",
                            cursor: "pointer",
                            color: "black",
                            fontWeight: "bold",
                            width: "120px",
                            borderRadius: "10px",
                            "&:hover": {
                              backgroundColor: "#038da6",
                              color: "white",
                            },
                          }}
                          onClick={(e) => {
                            onDivisionSelect(e, index);
                          }}
                          className={element.isSelected ? "selected" : ""}
                        >
                          {element.division}
                        </Button>
                      </Box>
                      <Stack
                        // paddingLeft="10px"
                        direction="row"
                        border="2px solid"
                        borderRadius="10px"
                        justifyContent=" center"
                        divider={<Divider orientation="vertical" flexItem />}
                        sx={{ textAlign: "center", px: 0 }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "lightpink",
                          }}
                        >
                          <Typography
                            sx={{
                              m: 1,
                              p: "0px 2px",
                              backgroundColor: "lightpink",
                            }}
                          >
                            {element.girls > 0 ? element.girls : 0}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: "lightBlue",
                          }}
                        >
                          <Typography
                            sx={{
                              m: 1,
                              p: "0px 2px",
                              backgroundColor: "lightBlue",
                            }}
                          >
                            {element.boy > 0 ? element.boy : 0}
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                ))}
              </Grid>

              {/* </Stack> */}
            </DivisionItem>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, m: 2, textAlign: "center" }}>
        <Typography
          variant="subtitle1"
          sx={{
            px: "1%",
            fontWeight: "bold",
            py: "2px",
            backgroundColor: "lightblue",
          }}
        >
          APPROVE AND REASSIGN THE DIVISION OF STUDENT{" "}
        </Typography>
        <Typography
          variant="subtitle1"
          backgroundColor="yellow"
          sx={{
            px: "1%",
            fontWeight: "bold",
            py: "2px",
            backgroundColor: "lightblue",
          }}
        >
          {Standardname} - {DivisionName}
        </Typography>
      </Box>
      <Box
        sx={{
          height: 300,
          width: "100%",
          padding: "0 30px",
          boxSizing: "border-box",
          "& .super-app-theme--header": {
            backgroundColor: "#c0c0c0",
            //  color:'white',
            fontWeight: "bold",
            fontSize: "15px",
            border: "1px solid white",
          },
        }}
      >
        <DataGrid
          // showColumnRightBorder={true}
          // showCellRightBorder={true}
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          disableColumnMenu
          onCellEditCommit={onDivisionAssign}
          sx={{ border: "1px solid black" }}
          // onCellEditCommit={(e) => {
          //   console.log("edingo");
          // }}
          // experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
      <Box sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Button
            sx={{
              fontSize: 15,
              fontWeight: "bold",
              borderRadius: "30px",
              ml: 85,
            }}
            onClick={onApproveStudent}
            variant="contained"
          >
            APPROVE STUDENTS
          </Button>

          <Modal
            open={openSucess}
            // onClose={() => setSucess(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div sx={style}>
              <SucessModal
                text="Student are Approved Successully"
                name="Student are Approved Successully"
              />
            </div>
          </Modal>

          <Modal
            open={open}
            // onClose={() => setSucess(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div sx={style}>
              <SucessModal
                text="Student Approval is Denied"
                name="Student Approval is Denied"
              />
            </div>
          </Modal>
        </Grid>
      </Box>
    </div>
  );
}
