import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { AddStandarsService } from "../../../services/addStandardsService";
import { C } from "../../util/C";
import { CenterFocusStrong } from "@mui/icons-material";
import { FeeCollectionStructure } from "../../../services/Feescollectionservice";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  // color: theme.palette.text.secondary
}));

export default function BasicGrid() {
  React.useEffect(() => {
    // onStandardLoad();
  }, []);

  const [columns, setColumns] = React.useState([
    {
      field: "id",
      headerName: "No.",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong style={{ fontSize: 15 }}>NO.</strong>,
    },

    {
      field: "standaredName",
      headerName: "Standard ",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong style={{ fontSize: 15 }}>Standard</strong>,
    },

    {
      field: "a",
      headerName: "A",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong style={{ fontSize: 15 }}>A</strong>,
    },

    {
      field: "b",
      headerName: "B",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong style={{ fontSize: 15 }}>B</strong>,
    },

    {
      field: "c",
      headerName: "C",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong style={{ fontSize: 15 }}>C</strong>,
    },

    {
      field: "d",
      headerName: "D",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong style={{ fontSize: 15 }}>D</strong>,
    },

    {
      field: "e",
      headerName: "E",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong style={{ fontSize: 15 }}>E</strong>,
    },
    {
      field: "f",
      headerName: "F",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong style={{ fontSize: 15 }}>F</strong>,
    },
    {
      field: "g",
      headerName: "G",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong style={{ fontSize: 15 }}>G</strong>,
    },
    {
      field: "h",
      headerName: "H",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong style={{ fontSize: 15 }}>H</strong>,
    },
    {
      field: "i",
      headerName: "I",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong style={{ fontSize: 15 }}>I</strong>,
    },
    {
      field: "j",
      headerName: "J",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong style={{ fontSize: 15 }}>J</strong>,
    },

    {
      field: "total",
      headerName: "Total",
      headerAlign: "center",
      align: "center",
      flex: 1,
      editable: true,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong style={{ fontSize: 15 }}>TOTAL</strong>,
    },
  ]);

  const [Standard, setStandard] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [standardName, setStandardName] = React.useState();
  const [StandardID, setStandardID] = React.useState();

  // const onStandardLoad = async () => {
  //   let data = JSON.parse(localStorage.getItem("academicYear"));
  //   try {
  //     let response = await AddStandarsService.standardListAll(data.yearName);
  //     console.log(response.data.data);
  //     setStandard(response.data.data);

  //     let standardArray = [];
  //     response.data.data.forEach(function (data) {
  //       console.log(data);
  //       standardArray.push({
  //         id: data.standardID,
  //         stdDiv: data.standardName,
  //       });
  //     });
  //     setRows(standardArray);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const onselectMonth = async (e) => {
    console.log(e.target.textContent);
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response =
        await FeeCollectionStructure.monthstandardFeesCollectionreport(
          e.target.textContent,
          data.yearName
        );
      // console.log(response.data.data);
      // setRows(response.data.data);
      let standardArray = [];
      response.data.data.forEach(function (data, i) {
        console.log(data);
        standardArray.push({
          id: i + 1,
          standaredName: data.standaredName,
          a: data.a,
          b: data.b,
          c: data.c,
          d: data.d,
          e: data.e,
          f: data.f,
          g: data.g,
          h: data.h,
          i: data.i,
          j: data.j,
          total: data.total,
        });
      });
      setRows(standardArray);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <Button
            variant="contained"
            sx={{ width: "110px", borderRadius: "30px", fontWeight: "bold" }}
            onClick={onselectMonth}
          >
            May
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            sx={{ width: "110px", borderRadius: "30px", fontWeight: "bold" }}
            onClick={onselectMonth}
          >
            June
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            sx={{ width: "110px", borderRadius: "30px", fontWeight: "bold" }}
            onClick={onselectMonth}
          >
            July
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            sx={{ width: "110px", borderRadius: "30px", fontWeight: "bold" }}
            onClick={onselectMonth}
          >
            August
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            sx={{ width: "110px", borderRadius: "30px", fontWeight: "bold" }}
            onClick={onselectMonth}
          >
            September
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            sx={{ width: "110px", borderRadius: "30px", fontWeight: "bold" }}
            onClick={onselectMonth}
          >
            October
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            sx={{ width: "110px", borderRadius: "30px", fontWeight: "bold" }}
            onClick={onselectMonth}
          >
            November
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            sx={{ width: "110px", borderRadius: "30px", fontWeight: "bold" }}
            onClick={onselectMonth}
          >
            December
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            sx={{ width: "110px", borderRadius: "30px", fontWeight: "bold" }}
            onClick={onselectMonth}
          >
            January
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            sx={{ width: "110px", borderRadius: "30px", fontWeight: "bold" }}
            onClick={onselectMonth}
          >
            February
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            sx={{ width: "110px", borderRadius: "30px", fontWeight: "bold" }}
            onClick={onselectMonth}
          >
            March
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            sx={{ width: "110px", borderRadius: "30px", fontWeight: "bold" }}
            onClick={onselectMonth}
          >
            April
          </Button>
        </Grid>
      </Grid>
      <Box
        sx={{
          height: 400,
          width: "100%",
          my: 5,
          "& .super-app-theme--header": {
            backgroundColor: "#c0c0c0",
          },
        }}
      >
        <DataGrid
          showColumnRightBorder={true}
          showCellRightBorder={true}
          rows={rows}
          columns={columns}
          rowsPerPageOptions={[10]}
          hideFooter
          sx={{ showCellRightBorder: "2px solid black" }}
        />
      </Box>
      <Grid sx={{ textAlign: "center" }}>
        <Button
          variant="contained"
          color="error"
          sx={{
            borderRadius: "30px",
            fontWeight: "bold",
            fontSize: 15,
            width: "120px",
            mr: 2,
          }}
        >
          PDF
        </Button>
        <Button
          variant="contained"
          color="success"
          sx={{
            borderRadius: "30px",
            fontWeight: "bold",
            fontSize: 15,
            width: "120px",
            mr: 2,
          }}
        >
          Excel
        </Button>
        <Button
          variant="contained"
          sx={{
            borderRadius: "30px",
            fontWeight: "bold",
            fontSize: 15,
            width: "120px",
            backgroundColor: "black",
            mr: 2,
            "&:hover": { backgroundColor: "black" },
          }}
        >
          Print
        </Button>
        <Button
          variant="contained"
          sx={{
            borderRadius: "30px",
            fontWeight: "bold",
            fontSize: 15,
            width: "120px",
            backgroundColor: "#ca424f",
            mr: 2,
            "&:hover": { backgroundColor: "#ca424f" },
          }}
        >
          Exit
        </Button>
      </Grid>
    </Box>
  );
}
