import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { Button, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { C } from "../../util/C";
import { StaffService } from "../../../services/StaffService";
import PropTypes from "prop-types";
import { StudentAttendenanceService } from "../../../services/studentAttendance";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StandardDivision } from "../../../services/StandardDIvision";
import { AddStandarsService } from "../../../services/addStandardsService";
import AttendenceClass from "./attendenceClass";
import AttendenceClassDiv from "./attendenceClassDiv";
import AttendenceMonth from "./attendenceMonth";
import AttendenceDate from "./attendenceDate";
import AttendenceClassDivMonth from "./attendenceClassDivMonth";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
}));

const ItemBg = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));

const ItemD = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#03dff9",
  "&:hover": {
    background: "#038da6",
    color: "white",
  },
}));

const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color: "white",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicGrid() {
  const currentDate = new Date();
  const options = { month: "long" };
  const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);

  const [value, setValue] = React.useState(0);
  const [startValue, setStartValue] = React.useState();
  const [endValue, setEndValue] = React.useState();

  const [StandardName, setStandardName] = React.useState();
  const [StandardID, setStandardID] = React.useState();
  const [DivisionName, setDivisionName] = React.useState();
  const [Division, setDivision] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setMonthAttendence([]);
    }
    if (newValue === 1) {
      onAttendenceMonthReport(month);
    }
    if (newValue === 2) {
      toFromReport(startValue, "", "load");
    }
    if (newValue === 3) {
      console.log(newValue);
      setMonthAttendence([]);
      onstandardsLoad();
    }
  };

  const [selectedMonth, setMonth] = React.useState("");

  const onMonthSelect = (e) => {
    setMonth(e.target.value);
  };
  const [monthAttendence, setMonthAttendence] = React.useState([]);

  const onAttendenceMonthReport = async (selectedMonth) => {
    console.log(selectedMonth);
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await StudentAttendenanceService.thatMouthAbentReport(
        selectedMonth,
        data.yearName
      );
      setMonthAttendence(response.data.data);
      C.c(response.data.data);
    } catch (error) {
      C.c(error.message);
    }
  };
  const onAttendenceReport = async () => {
    console.log(selectedMonth);
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await StudentAttendenanceService.mouthAbentReport(
        selectedMonth,
        data.yearName
      );
      setMonthAttendence(response.data.data);
      C.c(response.data.data);
    } catch (error) {
      C.c(error.message);
    }
  };
  const toFromReport = async (start, end, on) => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    C.c(start);

    if (on === "load") {
      try {
        let response = await StudentAttendenanceService.dateAbentReport(
          new Date().toLocaleDateString(),
          data.yearName
        );
        setMonthAttendence(response.data.data);
        C.c(response.data.data);
      } catch (error) {
        C.c(error.message);
      }
    }
    if (on === "click") {
      try {
        let response = await StudentAttendenanceService.toFromAbentReport(
          startValue,
          endValue,
          data.yearName
        );
        setMonthAttendence(response.data.data);
        C.c(response.data.data);
      } catch (error) {
        C.c(error.message);
      }
    }
  };

  const [Standard, setStandard] = React.useState([]);

  const onstandardsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      C.c(response.data.data);
      setStandard(response.data.data);
    } catch (error) {
      C.c(error);
    }
  };

  async function onDivisionSelect(e, index) {
    // C.c(e.target.id);
    // C.c(StandardID);
    // setDivisionName(e.target.id);
    // let highlightDivision = [...Division];
    // highlightDivision = highlightDivision.map(
    //   ({ isSelected, ...highlightDivision }) => highlightDivision
    // );
    // setDivision(highlightDivision);
    // highlightDivision[index]["isSelected"] = highlightDivision[index][
    //   "isSelected"
    // ]
    //   ? false
    //   : true;
    // let data = JSON.parse(localStorage.getItem("academicYear"));
    // try {
    //   let response = await StudentAttendenanceService.classAbentReport(
    //     new Date().toLocaleDateString(),
    //     StandardID,
    //     e.target.id,
    //     data.yearName
    //   );
    //   setMonthAttendence([response.data.data]);
    //   C.c(response.data.data);
    // } catch (error) {
    //   C.c(error.message);
    // }
  }

  return (
    <div
      style={{
        backgroundImage: `url("bgColor11.png")`,
        backgroundSize: "cover",
      }}
    >
      {/* <LaunchpadTopBar id="attendence" /> */}
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Today" />
          <Tab label="Student Month Wise" />
          <Tab label="This Month" />
          <Tab label="Month Wise" />
          <Tab label="Class Wise" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AttendenceClassDiv />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AttendenceClassDivMonth />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box sx={{ margin: "0px" }}>
          <AttendenceDate />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AttendenceMonth />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <AttendenceClass />
      </TabPanel>
    </div>
  );
}
