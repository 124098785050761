import React from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Modal,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tab,Tabs
} from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { DesignationService } from "../../../services/DesignationService";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import Student from "../Reports/individualStudentInfo";
import { C } from "../../util/C";
import { User } from "../../../services/User";
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import ParentUser from "./ParentUser";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid red",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const UserEntry = () => {
  const [designationVal, setDesignationVal] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [value, setValue] = React.useState(0)

  const columns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.3,
      align:"center",
      headerAlign:"center",
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO."}</strong>,
    },
    {
      field: "userName",
      headerName: "USER NAME",
      flex: 1,
      align:"center",
      headerAlign:"center",
      sortable: false,
      renderCell: (params) => (
        <p style={{ textTransform: "uppercase" }}>{params.value}</p>
      ),
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"USER NAME"}</strong>,
    },
    {
      field: "userEmailID",
      headerName: " EMAIL",
      flex: 1,
      align:"center",
      headerAlign:"center",
      sortable: false,
      renderCell: (params) => (
        <p style={{ textTransform: "uppercase" }}>{params.value}</p>
      ),
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"EMAIL"}</strong>,
    },
    {
      field: "designation",
      headerName: "DESIGNATION",
      flex: 1,
      align:"center",
      headerAlign:"center",
      sortable: false,
      renderCell: (params) => (
        <p style={{ textTransform: "uppercase" }}>{params.value}</p>
      ),
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"DESIGNATION"}</strong>,
    },
  ];


  const [openSucess, setSucess] = React.useState(false);
  const handleSucessOpen = () => setSucess(true);


  React.useEffect(() => {
    // onDesignationload();
    onUserListLoad();
  }, []);

  let navigate = useNavigate();
  const onLaunchpad = () => {
    navigate("/launchpad", { replace: true });
  };

  const onUserListLoad = async () => {
    let userArray = [];
    try {
      let response = await User.UserList();
      console.log(response.data.data);
      response.data.data.forEach((rec,i) => {
        userArray.push({
          // id: rec.createdTime,
          id: i+1,
          userName: rec.userName,
          userEmailID: rec.userEmailID,
          designation: rec.designation,
        });
      });
      console.log(userArray);
      setRows(userArray);
    } catch (error) {
      console.log(error);
    }
  };
  // const onDesignationload = async () => {
  //   let data = JSON.parse(localStorage.getItem("academicYear"));
  //   try {
  //     let response = await DesignationService.designationListAll(data.yearName);
  //     setDesignation(response.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // * users login
  async function onCreateUser(event) {
    // event.preventDefault();
    // debugger;
    const data = new FormData(event.currentTarget);
    let yearData = JSON.parse(localStorage.getItem("academicYear"));
    if (data.get("password") == data.get("re_password")) {
      // console.log();
      let userObj = {
        designation: data.get("designation"),
        userEmailID: data.get("email"),
        userName: data.get("userName"),
        userPassword: data.get("password"),
        yearID: yearData.yearID,
        yearName: yearData.yearName,
      };

      try {
        let response = await User.UserRegister(userObj);
        // alert("User registration");
        setTimeout(()=>{
          setSucess(false);
        },3000);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("invalid password");
      // console.log("");
    }

    // if (pwd == repwd) {
    // }
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // * validation
  const [name, setName] = React.useState();
  function onNameEntry(e) {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z ]+/gi, "")
      : "";

    // if (e.target.value !== value) {
    //   e.target.value = value;
    // }

    // if (typeof callback === "function") {
    //   return callback(value);
    // }
  }

  return (
    <div>
      {/* <LaunchpadTopBar id="settings" /> */}

      <Box
        sx={{
          textAlign: "center",
        }}
      >
       <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          backgroundColor: "lightblue",
          my: 1,
          fontWeight: "bold",
        }}
      >
        USER MANAGEMENT
        </Typography>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Create Staff Users" {...a11yProps(0)} />
          <Tab label="Create Student User" {...a11yProps(1)} />
        </Tabs>
      </Box>
<TabPanel value={value} index={0}>
      <Box
        component="form"
        sx={{
          // margin: '8px 10px 0 10px',
          mt:4,
          ml:10,
          mr:10,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        onSubmit={onCreateUser}
      >
        <Grid container spacing={2}>
          <Grid item xs={4.5} >
            <Grid container spacing={2}>
              <Grid m={1} xs={12}>
                <TextField
                  required
                  id="userName"
                  name="userName"
                  label="USER NAME"
                  variant="filled"
                  sx={{
                    marginX: "10px",
                  }}
                />
                <TextField
                  required
                  id="email"
                  name="email"
                  label="EMAIL"
                  variant="filled"
                  sx={{
                    marginX: "10px",
                  }}
                />
              </Grid>
              <Grid m={1} xs={12}>
                <FormControl required sx={{ marginX: "10px", minWidth: 460 }}>
                  <InputLabel>DESIGNATION</InputLabel>
                  <Select
                    required
                    labelId="designationID"
                    id="designation"
                    label="Designation"
                    variant="filled"
                    onChange={(e) => setDesignationVal(e.target.value)}
                    value={designationVal}
                    name="designation"
                  >
                    <MenuItem value="administrator">ADMINISTRATOR</MenuItem>
                    <MenuItem value="principal">PRINCIPAL</MenuItem>
                    <MenuItem value="vice_principal">
                      {" "}
                      VICE - PRINCIPAL
                    </MenuItem>
                    <MenuItem value="teacher">TEACHER</MenuItem>
                    <MenuItem value="student">STUDENT</MenuItem>
                    <MenuItem value="librarian">LIBRARIAN</MenuItem>
                    <MenuItem value="accountant">ACCOUNTANT</MenuItem>
                    <MenuItem value="non_teacher"> NON-TEACHER</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid m={1} xs={12}>
                <TextField
                  required
                  id="password"
                  name="password"
                  label="PASSWORD"
                  variant="filled"
                  sx={{
                    marginX: "10px",
                  }}
                  type="password"
                />
                <TextField
                  required
                  id="password"
                  name="re_password"
                  label="CONFIRM PASSWORD"
                  variant="filled"
                  sx={{
                    marginX: "10px",
                  }}
                  type="password"
                />
              </Grid>
              {/* <Grid xs={12}> */}
               

                  <Modal
            open={openSucess}
            // onClose={() => setSucess(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div sx={style}>
              <SucessModal
                text="User Created Successfully"
                name="User Created Successfully"
              />
            </div>
          </Modal>

              {/* </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={7.5}>
            <Box
              sx={{
                height: 400,
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "#c0c0c0",
                  //  color:'white',
                  fontWeight: "bold",
                  fontSize: "15px",
                  border: "1px solid white",
                },
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                disableColumnSelector
        disableColumnMenu
        disableColumnFilter
        disableDensitySelector
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
              />
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display:"flex", justifyContent:"space-evenly",mt:2,width:"100%" }}>
                  <Button variant="contained" type="submit"
                  sx={{
                    fontSize: 15,
                    fontWeight: "bold",
                    borderRadius: "30px",
                    width: "180px",
                  }}
                  onClick={handleSucessOpen}>
                    Create User
                  </Button>

                  <Button
            variant="contained"
            size="large"
            onClick={onLaunchpad}
            sx={{
              backgroundColor: "#ca424f",
              fontSize: 15,
              fontWeight: "bold",
              borderRadius: "30px",
              width: "120px",
              "&:hover": { backgroundColor: "#ca424f" },
            }}
          >
            exit
          </Button>
                  
                </Box>

      </Box>
      </TabPanel>

      <TabPanel value={value} index={1}>
      <ParentUser/>
      </TabPanel>
    </div>
  );
};

export default UserEntry;

// {

//     "designation": "string", 1
//     "designationID": "string",
//     "userEmailID": "string", 2
//     "userID": "string",
//     "userName": "string", 3
//     "userPassword": "string",
//     "yearID": "string",
//     "yearName": "string"
//   }
