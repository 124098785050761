import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Typography, IconButton, Stack, Modal, Fade } from "@mui/material";
import TextField from "@mui/material/TextField";
import Topbar from "../../MainTopbar/Topbar";
import FreezeModal from "../Dashboard/freezeModel";
import { SubjectService } from "../../../services/subjectService";
import CancelIcon from "@mui/icons-material/Cancel";
import { AddStandarsService } from "../../../services/addStandardsService";
import { StandardSubjectService } from "../../../services/StandardSubject";
import { yearService } from "../../../services/yearService";
import Backdrop from "@mui/material/Backdrop";

import { useNavigate } from "react-router-dom"; //for browser back button navigation handling
import { BuildSchoolControll } from "../../../services/buildSchoolControllService";
import SucessModal from "./popup/sucessPopUp";
import AlertModal from "./popup/alertPopUp";
import DeleteModal from "./popup/deletePopUp";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  height: "98%",
  // width: "100%",
  border: "2px solid yellow",
  color: theme.palette.text.secondary,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};

const ItemSubject = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Language() {
  //browser back button redirection to dashboard
  const Navigate = useNavigate();
  const [finishStatus, setfinishStatus] = React.useState(false);
  const textInput = React.useRef("");

  // * =======================window Pop=====================================
  // *delete
  const [openDelete, setDelete] = React.useState(false);
  const handleDeleteOpen = () => setDelete(true);
  const handleDeleteClose = () => setDelete(false);

  // *alert
  const [openAlert, setAlert] = React.useState(false);
  const [alertVal, setAlertVal] = React.useState("");
  const handleAlertOpen = () => setAlert(true);
  const handleAlertClose = () => setAlert(false);

  // *sucess
  const [openSucess, setSucess] = React.useState(false);
  const [sucessVal, setSucessVal] = React.useState("");
  const handleSucessOpen = () => setSucess(true);
  const handleSucessClose = () => setSucess(false);

  // * =======================window Popup end=====================================

  const onlyCharaters = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to go back to Dashboard?")) {
        setfinishStatus(true);
        // your logic
        Navigate("/dashborad");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  React.useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);
  // back button logic ends

  const yearData = JSON.parse(localStorage.getItem("academicYear"));
  const yearID = yearData.yearID;
  const yearName = yearData.yearName;

  const [academicYear, setacademicYear] = React.useState(yearName);
  const [academicYearID, setacademicYearID] = React.useState(yearID);

  // const yearData = JSON.parse(localStorage.getItem("academicYear"));
  // setacademicYear(yearData.yearName);
  // setacademicYearID(yearData.yearID);

  React.useEffect(() => {
    onStandardLoad();
    onSubjectLoad();
    onStandardSubjectLoad();
    onLoadBuildSchool();
    console.log(academicYearID);
  }, []);

  let navigate = useNavigate();

  const [standardSubjectlist, updateStandardSubject] = React.useState([]);

  const onStandardSubjectLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    console.log("onStandardSubjectLoad");
    try {
      let response = await StandardSubjectService.standardSubjectList(
        data.yearID,
        data.yearName
      );
      // console.log(response.data.data);

      updateStandardSubject([...standardSubjectlist, response.data.data]);
      console.log(standardSubjectlist);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };

  // const [, updateStandard] = React.useState([]);

  const [standardListAll, updateStandard] = React.useState([]);
  const [subjectList, updateBooks] = React.useState([]);
  const [standardID, updateStandardID] = React.useState("");

  // console.log(standardListAll);

  const onStandardLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    // console.log("hello standard");

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      //console.log(response.data.data);

      updateStandard(response.data.data);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };

  const onSubjectLoad = async () => {
    console.log("hello subject");
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await SubjectService.subjectListAll(data.yearName);
      console.log(response.data.data);

      updateBooks(response.data.data);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };

  const onAddlanguage = async (event) => {
    let yearData = JSON.parse(localStorage.getItem("academicYear"));

    // console.log(event);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log(data.get("subject"));
    let subject = data.get("subject");

    // if (subject === "" && subject === " ") {
    if (subject.trim().length === 0) {
      console.log(data.get("subject"));
      setAlertVal("Please enter a valid subject");
      handleAlertOpen();
      // alert("Please enter a valid subject");
    } else {
      console.log("not isEmpty");

      console.log(subjectList);
      const found = subjectList.find(
        (element) => element.subjectName == data.get("subject")
      );

      console.log(found);
      if (found) {
        setAlertVal("Subject is already entered");
        handleAlertOpen();
      } else {
        let YearData = JSON.parse(localStorage.getItem("academicYear"));

        try {
          let response = await SubjectService.createSubject({
            subjectName: data.get("subject"),
            yearID: YearData.yearID,
            yearName: YearData.yearName,
          });
          let nObj = {
            subjectName: response.data.data.subjectName,
            subjectID: response.data.data.subjectID,
            yearID: yearData.yearID,
            yearName: yearData.yearName,
          };
          updateBooks([...subjectList, nObj]);
          if (response.data.success) {
            console.log("success");
            // console.log("Designation Added Successfully");
            setSucessVal("Subject Added Successfully");
            handleSucessOpen();
          } else {
            alert(response.data.message);
          }
        } catch (error) {
          console.log("error");
          setSucessVal("error");
          handleSucessOpen();
          // alert("Failure");
        }
      }
    }
    textInput.current.value = "";
    setTimeout(() => {
      handleSucessClose();
      handleAlertClose();
    }, 2000);
  };

  const [loop, onLoop] = React.useState([]);

  const onSubjectSelect = async (e) => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    console.log(e.currentTarget.accessKey);
    try {
      let response = await SubjectService.deleteSubject(
        e.currentTarget.accessKey,
        data.yearName
      );

      let subjectAfterDelete = loop.filter(function (itm) {
        return itm.subjectID != response.data.data.subjectID;
      });

      onLoop(subjectAfterDelete);

      updateBooks(
        subjectList.filter(
          (item) => item.subjectID !== response.data.data.subjectID
        )
      );
    } catch (error) {
      console.log("error");
      setAlertVal("error");
      handleAlertOpen();
      // alert("Failure");
    }
  };

  const [selectedSub, onselectedSub] = React.useState("Standard");
  const [selectedSubID, onselectedSubID] = React.useState();

  async function onStandardSelect(event, index) {
    event.preventDefault();
    updateStandardID(event.target.value);
    let highlightedStandardList = [...standardListAll];
    highlightedStandardList = highlightedStandardList.map(
      ({ isSelected, ...highlightedStandardList }) => highlightedStandardList
    );
    updateStandard(highlightedStandardList);
    // console.log(event.currentTarget.value, event.currentTarget.name)
    onselectedSubID(event.currentTarget.value);
    onselectedSub(event.currentTarget.name);
    highlightedStandardList[index]["isSelected"] = highlightedStandardList[
      index
    ]["isSelected"]
      ? false
      : true;
    // console.log(selectedSubID);
    // console.log(selectedSub);
    let data = JSON.parse(localStorage.getItem("academicYear"));

    updateStandard(highlightedStandardList);
    let response =
      await StandardSubjectService.findSubjectListByStandardIDYearID(
        data.yearName,
        event.currentTarget.value
      );

    console.log(response.data.data);
    response.data.data.sort(function(a,b){
      return a.subjectOrder - b.subjectOrder;
    });

    onLoop(response.data.data);

    //   (element) => element.standardID === selectedSubID
    // );
    // console.log(obj);

    // console.log(standardSubjectlist);
  }

  // const [loop, onLoop] = React.useState([]);
  const [standardSubject, onStandardSubject] = React.useState([]);

  // console.log(loop);

  const onSubjectAdd = async (e) => {
    // alert(e.currentTarget.name);
    // alert(JSON.stringify(loop));
    if (selectedSub == "Standard") {
      setAlertVal("Please select a valid standard");
      handleAlertOpen();
      setTimeout(() => {
        handleAlertClose();
      }, 2000);
      // alert("Please select the Standard!");
      return;
    } else if (
      loop.find((subject) => subject.subjectName === e.currentTarget.name)
    ) {
      setAlertVal("Subject already exists!");
      handleAlertOpen();
      // alert("Subject already exists!");
      setTimeout(() => {
        handleAlertClose();
      }, 2000);
      return;
    }

    // alert('on subject select on right');
    e.preventDefault();
    const obj = {
      subjectID: e.currentTarget.id,
      subjectName: e.currentTarget.name,
    };
    console.log(obj);
    onLoop([...loop, obj]);
    //  subjectList

    //  const [subjectList, updateBooks] = React.useState([]);

    // updateBooks([...subjectList, obj]);
  };

  const onSubjectDelete = async (e) => {
    console.log(loop);
    console.log(standardID);
    console.log(e.currentTarget.id);

    try {
      console.log(standardID);
      // console.log(e.target.name)

      if (!standardID || !e.currentTarget.id) {
        setAlertVal("Error!");
        handleAlertOpen();
        // alert();
        return;
      }

      let subjectTarget = e.currentTarget.id;

      let data = JSON.parse(localStorage.getItem("academicYear"));

      let response = await SubjectService.deleteSubjectForStandard(
        standardID,
        e.currentTarget.id,
        data.yearName
      );
      console.log(response);
      if (response.data.success) {
        console.log("Test Deleted Succesfully!");
        // alert("Test Deleted Succesfully!");

        console.log(loop);

        let examTestAfterDeleteForStandard = loop.filter((item) => {
          console.log(item);
          console.log(e.currentTarget);
          console.log(e.target);
          return item.subjectID !== subjectTarget;
        });

        console.log(examTestAfterDeleteForStandard);

        onLoop(examTestAfterDeleteForStandard);
      } else {
        // alert(response.data.message);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      // alert("Please ");
    }
  };

  const onSave = async (e) => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    console.log(e);
    e.preventDefault();
    let standardNameID = document.getElementById("selectedSub").value;
    let standardName = document.getElementById("selectedSub").name;
    console.log(standardName);
    console.log(standardNameID);

    var standardSubjectList = [];

    console.log(loop);

    for (let i = 0; i < loop.length; i++) {
      var standardSubjectObj = {
        standardID: standardNameID,
        standardName: standardName,
        subjectID: loop[i].subjectID,
        subjectName: loop[i].subjectName,
        subjectOrder : i,
        yearID: data.yearName,
        yearName: data.yearName,
      };

      standardSubjectList.push(standardSubjectObj);
      console.log(standardSubjectList);
      try {
        let response = await StandardSubjectService.standardsubject(
          standardSubjectList
        );
        if (response.data.success) {
          if (i + 1 == loop.length) {
            setSucessVal("Subjects saved successfully");
            handleSucessOpen();
            // alert("");
          }
          // Navigate("/dashborad");
        } else {
          setAlertVal(response.data.message);
          handleAlertOpen();
          // alert(response.data.message);
        }
      } catch (error) {
        console.log("error");
        setAlertVal("Failure");
        handleAlertOpen();
        // alert("Failure");
      }
    }

    setTimeout(() => {
      handleSucessClose();
      handleAlertClose();
    }, 2000);
    // try {
    //   let response = await StandardSubjectService.standardsubject(
    //     standardSubjectList
    //   );
    //   if (response.data.success) {
    //     console.log("success");
    //     setAlertVal("Added Successfully");
    //     handleAlertOpen();
    //     // alert("Added Successfully");
    //     // Navigate("/dashborad");
    //   } else {
    //     setAlertVal(response.data.message);
    //     handleAlertOpen();
    //     // alert(response.data.message);
    //   }
    // } catch (error) {
    //   console.log("error");
    //   setAlertVal("Failure");
    //   handleAlertOpen();
    //   // alert("Failure");
    // }
  };
  const [open, setOpen] = React.useState(false);
  // const onFreeze = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const nextPage = (e) => {
    // navigate("/dashborad", { replace: true });
    setOpen(true);
  };

  const [status, setStatus] = React.useState();

  const onLoadBuildSchool = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolControll.buildSchoolStatusListAll(
        data.yearName
      );
      // console.log(response.data.data[0]);
      setStatus(response.data.data[1].buildSchoolStatus);
      setTwoId(response.data.data[1].buildSchoolID);
      setThreeId(response.data.data[2].buildSchoolID);
    } catch (error) {
      console.error(error);
    }
  };

  const [two, setTwo] = React.useState();
  const [twoId, setTwoId] = React.useState();
  const [three, setThree] = React.useState();
  const [threeId, setThreeId] = React.useState();

  const onBuildSchool = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    console.log("onBuildSchool");
    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: twoId,
        buildSchoolName: "subject",
        buildSchoolStatus: "true",
        buildSchoolStep: "two",
        buildSchoolYear: data.yearName,
        yearName: data.yearName,
      });
    } catch (error) {
      setAlertVal("Error creating buildSchool " + error);
      handleAlertOpen();
      // alert("Error creating buildSchool " + error);
    }
    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: threeId,
        buildSchoolName: "designation",
        buildSchoolStatus: "false",
        buildSchoolStep: "three",
        buildSchoolYear: data.yearName,
        yearName: data.yearName,
      });
    } catch (error) {
      setAlertVal("Error creating buildSchool " + error);
      handleAlertOpen();
      // alert("Error creating buildSchool " + error);
    }
    navigate("/dashborad", { replace: true });
  };

  const onPause = () => {
    setAlertVal("Subjects Module will be paused");
    handleAlertOpen();
    // alert("Subjects Module will be paused");
    navigate("/dashborad", { replace: true });
  };

  const Demo = async (e) => {
    console.log(e.currentTarget.id);
  };

  return (
    <div>
      <Topbar
        text={
          " Step:2 of Building School, Please Click on the Standard on left and click on the Subjects on Right to add it to selected standard, Click again to de-Select"
        }
      />
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Item
              sx={{
                // width: 220,
                height: 460,
                overflow: "auto",
                background: "transparent",
              }}
            >
              {standardListAll.map((element, index) => (
                <Button
                  size="small"
                  // id="selectedSub"
                  value={element.standardID}
                  name={element.standardName}
                  sx={{
                    position: "relative",
                    border: "3px solid lightgreen",
                    borderRadius: "5px",
                    minWidth: "100%",
                    fontWeight: "bold",
                    height: 30,
                    color: "Black",
                    marginBottom: 0.2,
                    background: "#aaecac",
                    "&:hover": {
                      background: "#035e06",
                      color: "white",
                    },
                  }}
                  onClick={(event) => {
                    onStandardSelect(event, index);
                  }}
                  className={element.isSelected ? "standardSelected" : ""}
                >
                  {element.standardName}
                </Button>
              ))}
            </Item>
          </Grid>

          <Grid
            item
            xs={4}
            sx={{
              background: "transparent",
              
            }}
          >
            {/* <Button
              size="small"
              sx={{
                position: "relative",
                border: "2px solid skyblue",
                width: "99%",
                height: 30,
                color: "Black",
                fontWeight: "bold",
                marginBottom: 0.2,
              }}
              id="selectedSub"
              name={selectedSub}
              value={selectedSubID}
              onChange={Demo}
              className="standardSelected"
            >
              {selectedSub}
            </Button> */}
            <Item
              sx={{
                // position: "relative",
                // marginLeft: "0%",
                // float: "left",
                height: 300,
            
                overflowY: "auto",
              }}
            >

<Button
              size="small"
              sx={{
                position: "relative",
                border: "2px solid skyblue",
                width: "99%",
                height: 30,
                color: "Black",
                fontWeight: "bold",
                marginBottom: 0.2,
              }}
              id="selectedSub"
              name={selectedSub}
              value={selectedSubID}
              onChange={Demo}
              className="standardSelected"
            >
              {selectedSub}
            </Button>

              {loop.map((element) => (
                <div>
                  <Button
                    size="small"
                    sx={{
                      position: "relative",
                      border: "2px solid blue",
                      minWidth: "70%",
                      borderRadius: 2,
                      height: 30,
                      color: "Black",
                      marginTop: 1,
                      marginLeft: 0.2,
                      background: "#37bc98",
                      "&:hover": { background: "#03946d" },
                    }}
                  >
                    <Grid container spacing={0}>
                      <Grid item xs={10}>
                        <Typography
                          sx={{ mx: 2, color: "white", fontWeight: "bold" }}
                        >
                          {element.subjectName}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {/* Maths */}
                        <IconButton
                          aria-label="delete"
                          id={element.subjectID}
                          name={element.subjectID}
                          onClick={onSubjectDelete}
                          size="small"
                          color="error"
                          disabled={status == "true" ? true : false}
                          // sx={{ marginLeft: 5 }}
                        >
                          <CancelIcon fontSize="inherit" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Button>
                </div>
              ))}
            </Item>

            {/* <Box
              variant="contained"
              sx={{
                marginLeft: "10%",
                float: "left",
                marginTop: "4%",
                width: 316,
                backgroundColor: "#87f7f4",
              }}
            > */}
            {/* <Box
              display={"flex"}
              alignContent="center"
              justifyContent={"center"}
            > */}
            <Button
              fullWidth
              variant="contained"
              size="large"
              disabled={status == "true" || loop.length === 0 ? true : false}
              // disabled = {loop.length === 0}
              color="success"
              sx={{
                mt: 10,
                borderRadius: "25px",
              }}
              onClick={onSave}
            >
              Save Subjects
            </Button>
            {/* </Box> */}
            {/* <FreezeModal /> */}
            {/* </Box> */}
          </Grid>

          <Grid item xs={6}>
            <Item
              sx={{
                position: "relative",
                // float: "left",
                // width: 720,
                overflow: "auto",
                height: 340,
                background: "transparent",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                  {subjectList.map((element) => (
                    <Grid item xs={4}>
                      {/* <ItemSubject> */}
                      <Box key={element.subjectID}>
                        <Box
                          component="form"
                          sx={{
                            border: "2px solid #37bc98",
                            borderRadius: 2,
                          }}
                        >
                          <Grid
                            container
                            spacing={0}
                            sx={{
                              background: "#37bc98",
                              fontWeight: "bold",
                              "&:hover": {
                                background: "#03946d",
                                color: "white",
                              },
                            }}
                          >
                            <Grid item xs={10}>
                              <Button
                                size="small"
                                id={element.subjectID}
                                name={element.subjectName}
                                sx={{
                                  position: "relative",
                                  minWidth: "23%",
                                  height: 30,
                                  color: "white",
                                  fontWeight: "bold",
                                  marginBottom: 0.2,
                                }}
                                onClick={onSubjectAdd}

                                // onClick={(event) => {
                                //   // console.log(element);
                                //   onSubjectDelete(element.standardID, element.subjectID);
                                // }}
                              >
                                {element.subjectName}
                              </Button>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                disabled={status == "true" ? true : false}
                                aria-label="delete"
                                accessKey={element.subjectID}
                                onClick={onSubjectSelect}
                                size="small"
                                color="error"
                                // sx={{ marginLeft: 5 }}
                              >
                                <CancelIcon fontSize="inherit" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                      {/* </ItemSubject> */}
                    </Grid>
                  ))}
                </Grid>
              </Box>

              {/* {subjectList.map((element) => (
                <Box key={element.subjectID}>
                  <Box component="form">
                    <Button
                      size="small"
                      id={element.subjectID}
                      name={element.subjectName}
                      sx={{
                        position: "relative",
                        border: "2px solid blue",
                        minWidth: "23%",
                        borderRadius: 2,
                        height: 30,
                        color: "Black",
                        marginBottom: 0.2,
                      }}
                      onClick={onSubjectAdd}
                    >
                      {element.subjectName}
                    </Button>
                    <IconButton
                      aria-label="delete"
                      accessKey={element.subjectID}
                      onClick={onSubjectSelect}
                      size="small"
                      color="error"
                      // sx={{ marginLeft: 5 }}
                    >
                      <CancelIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                </Box>
              ))} */}
            </Item>
            <Item
              component="form"
              onSubmit={onAddlanguage}
              sx={{
                mt: 1,
                // position: "relative",
                // float: "left",
                // width: 720,
                height: "20%",
                fontWeight: 800,
                background: "transparent",
              }}
            >
              If any subject is not found in the top list, kindly add it
              <Box>
                {/* <label sx={{fontWeight:800,marginRight:0,mt:3}}>Add Subjects:  */}
                {/* </label> */}

                <TextField
                  // fullWidth
                  id="outlined-basic"
                  label="Add Subject"
                  variant="outlined"
                  size="small"
                  name="subject"
                  inputRef={textInput}
                  onInput={(e) => onlyCharaters(e)}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  sx={{ borderRadius: 2, mt: 2 }}
                />
                <Button
                  variant="contained"
                  type="submit"
                  disabled={status == "true" ? true : false}
                  sx={{
                    borderRadius: "25px",
                    marginTop: 3,
                    mt: 2,
                    mx: 2,
                  }}
                  color="success"
                >
                  Add Subject
                </Button>
              </Box>
            </Item>
            {/* <Button */}
            {/* variant="Button" sx=
            {{
              backgroundColor: "orange",
              mt: 4,
              height: 50,
              color: "blue",
              border: "2px solid blue",
            }}
            > */}
            {/* <Typography sx={{ fontSize: 10 }}>
                No Changes allowed after all the{" "}
              </Typography> */}
            {/* <Typography sx={{ fontSize: 10 }}>
                standards are assigned subjects
              </Typography> */}
            {/* </Button> */}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "96%" }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
              // sx={{ m: 1 }}
            >
              <Button
                variant="contained"
                onClick={nextPage}
                color="primary"
                sx={{
                  borderRadius: "25px",
                }}
                disabled={status == "true" || loop.length === 0 ? true : false}
                // disabled = {loop.length === 0}
              >
                Review and Freeze
              </Button>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                // onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <Box sx={style}>
                    <Typography
                      id="transition-modal-title"
                      variant="body1"
                      component="h2"
                    >
                      You have clicked on
                    </Typography>
                    <Typography
                      id="transition-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      FREEZ INFORMATION
                    </Typography>
                    <Typography
                      id="transition-modal-description"
                      sx={{ mt: 2 }}
                    >
                      We request you to kindly review the information once again
                      and then freeze the data, as once its FREEZE can’t be
                      changed. Thank you
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                      <Button
                        variant="contained"
                        size="small"
                        color="success"
                        onClick={onBuildSchool}
                      >
                        FREEZE
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => setOpen(false)}
                      >
                        Review
                      </Button>
                    </Stack>
                  </Box>
                </Fade>
              </Modal>
              {/* <Button variant="outlined">SKIP Creating Time for now</Button> */}
              <Button
                variant="contained"
                onClick={onPause}
                disabled={status == "true" ? true : false}
                color="warning"
                sx={{
                  // margin: "12px",
                  borderRadius: "25px",
                }}
              >
                Pause and Continue Setup Later
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Modal
          open={openSucess}
          onClose={handleSucessClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div sx={style}>
            <SucessModal text={sucessVal} />
          </div>
        </Modal>
        <Modal
          open={openAlert}
          onClose={handleAlertClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div sx={style}>
            <AlertModal text={alertVal} />
          </div>
        </Modal>
        <Modal
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div sx={style}>
            <DeleteModal />
          </div>
        </Modal>
      </Box>
    </div>
  );
}
