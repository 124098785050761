import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Button,
  Fade,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Topbar from "../../MainTopbar/Topbar";
import { AddStandarsService } from "../../../services/addStandardsService";
import { FeeStructure } from "../../../services/FeeStructure";
import CancelIcon from "@mui/icons-material/Cancel";
import Backdrop from "@mui/material/Backdrop";
import { useNavigate } from "react-router-dom";
import { BuildSchoolControll } from "../../../services/buildSchoolControllService";
import SucessModal from "./popup/sucessPopUp";
import AlertModal from "./popup/alertPopUp";
import DeleteModal from "./popup/deletePopUp";
import { SixKOutlined } from "@mui/icons-material";
// import CancelIcon from "@mui/icons-material/Cancel";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  height: 370,
  textAlign: "center",
  border: "2px solid",
  overflowY: "scroll",
  background: "transparent",
}));
const BtnItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "2px solid",
}));
const InnerItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};

export default function BasicGrid() {
  const [selectedSub, onselectedSub] = React.useState("");
  const [selectedSubID, onselectedSubID] = React.useState("");

  const [input_values, set_inputvalues] = React.useState({});
  const [standardListAll, updateStandard] = React.useState([]);
  const [FeeList, onFeeList] = React.useState([]);
  const [totalAmountData, setTotalAmount] = React.useState([]);
  const textInput = React.useRef("");
  const onlyCharaters = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const [total, set_total] = React.useState(0);
  React.useEffect(() => {
    onStandardLoad();
    onLoadFeeStructure();
    onLoadBuildSchool();
    // feeStructureListLoad();
  }, []);

  React.useEffect(() => {
    const arrValues = Object.values(input_values);
    const inputTotals = arrValues.reduce((accum, curr) => (accum += curr), 0);
    set_total(inputTotals);
  }, [input_values]);

  const [Tutionvalue, setValue] = React.useState([]);

  const onEnter = (e) => {
    let value;
    if (isNaN(e.currentTarget.value)) {
      value = 0;
    } else {
      value = e.currentTarget.value;
    }
    const totalMonths = 12;
    console.log(value);
    setValue(totalMonths * value);
    var ss = parseInt(Tutionvalue);
    var count = ss * 12;
    var total = totalMonths * value;
    set_inputvalues({ ...input_values, total });
  };

  const onStandardLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    // console.log("hello standard");

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      //console.log(response.data.data);

      updateStandard(response.data.data);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };
  // const feeStructureListLoad = async () => {
  //   // console.log("hello standard");

  //   try {
  //     let response = await FeeStructure.feeStructureListAll();
  //     console.log(response.data.data);

  //     // updateStandard(response.data.data);
  //   } catch (error) {
  //     console.log(error);
  //     // alert("Failure");
  //   }
  // };

  const onAmountEnteredChange = async ({ name, value }) => {
    console.log(name);
    console.log(value);
    let fee_value;
    if (value === "") {
      fee_value = 0;
    } else {
      fee_value = value;
    }
    // console.log(Tutionvalue);
    // console.log(input_values);

    //set_inputvalues({ ...input_values, [name]: parseInt(parseInt(value)+parseInt(Tutionvalue)) });
    set_inputvalues({
      ...input_values,
      [name]: parseInt(parseInt(fee_value)),
    });
  };

  // * =======================window Pop=====================================
  // *delete
  const [openDelete, setDelete] = React.useState(false);
  const [delVal, setDelVal] = React.useState("");
  const handleDeleteOpen = () => setDelete(true);
  const handleDeleteClose = () => setDelete(false);

  // *alert
  const [openAlert, setAlert] = React.useState(false);
  const [alertVal, setAlertVal] = React.useState("");
  const handleAlertOpen = () => setAlert(true);
  const handleAlertClose = () => setAlert(false);

  // *sucess
  const [openSucess, setSucess] = React.useState(false);
  const [sucessVal, setSucessVal] = React.useState("");
  const handleSucessOpen = () => setSucess(true);
  const handleSucessClose = () => setSucess(false);

  // * =======================window Popup end=====================================

  // =============================fee title save =========================
  const onFeeTitleSave = async (e) => {
    e.preventDefault();
    let yeardata = JSON.parse(localStorage.getItem("academicYear"));

    const data = new FormData(e.currentTarget);
    console.log(data.get("fee"));

    if (data.get("fee").trim().length === 0) {
      setAlertVal("Please enter a valid Fees ");
      handleAlertOpen();
    } else if (
      data.get("fee").toUpperCase() == "TUTION FEE" ||
      data.get("fee").toUpperCase() == "ADMISSION FEE"
    ) {
      setAlertVal("TUTION FEE and ADMISSION FEE is mandatory to all standard");
      handleAlertOpen();
    } else {
      const found = FeeList.find(
        (element) => element.subjectName == data.get("fee").toUpperCase()
      );
      if (found) {
        setAlertVal("Fees is already entered");
        handleAlertOpen();
      } else {
        // alert(" not found");
        try {
          let response = await FeeStructure.createFee({
            feestitleName: data.get("fee"),
            yearID: yeardata.yearID,
            yearName: yeardata.yearName,
          });

          let nObj = {
            feestitleName: response.data.data.feestitleName,
          };

          console.log("GGGGGGGGGG");

          onFeeList([...FeeList, nObj]);

          if (response.data.success) {
            console.log("success");
            console.log("FeeStructure Added Successfully");
          } else {
            setAlertVal("Fees is already entered");
            handleAlertOpen();
            // alert(response.data.message);
          }
        } catch (error) {
          // console.log("error");
          setAlertVal("Fees is already entered");
          handleAlertOpen();
          // alert("Failure");
        }
      }
    }

    textInput.current.value = "";
    setTimeout(() => {
      handleAlertClose();
    }, 2000);
  };
  // =============================fee title save =========================

  const [feeLoop, onFeeLoop] = React.useState([]);

  async function onStandardSelect(event, index) {
    event.preventDefault();
    // debugger;
    let highlightedStandardList = [...standardListAll];
    highlightedStandardList = highlightedStandardList.map(
      ({ isSelected, ...highlightedStandardList }) => highlightedStandardList
    );
    updateStandard(highlightedStandardList);
    console.log(event.target.id, event.target.name);
    onselectedSubID(event.target.id);
    onselectedSub(event.target.name);
    // console.log(selectedSubID);
    // console.log(selectedSub);
    highlightedStandardList[index]["isSelected"] = highlightedStandardList[
      index
    ]["isSelected"]
      ? false
      : true;
    updateStandard(highlightedStandardList);

    let yearData = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await FeeStructure.feeStructureListAll(
        event.target.id,
        yearData.yearName
      );

      if (response.data.data.length != 0) {
        let feeArray = response.data.data;
        console.log(feeArray);
        let filteredData = feeArray.filter((r) => {
          // if (r.feestitleName == "Admission Fee") {
          //   console.log(r.feestitleName);
          // } else {
          // }
          return (
            r.feestitleName != "Admission Fee" &&
            r.feestitleName != "Total Fees Amount" &&
            r.feestitleName != "Tution Fee"
          );
        });
        onFeeLoop(filteredData);

        console.log(filteredData);
        let amountObj = feeArray.pop();
        set_total(amountObj.amount);
        //   function (d) {
        //   console.log(d);
        //   return (d.feestitleName = "Tution_Fee");
        // }
        let tutionObj = feeArray.pop();
        setValue(tutionObj.amount);
        //   function (d) {
        //   console.log(d);
        //   return (d.feestitleName = "Tution_Fee");
        // }
        // console.log(feeArray);
        // console.log(amountObj);
        // console.log(tutionObj);
        // onFeeLoop(feeArray);
      } else {
        let feeArray = [];
        onFeeLoop(feeArray);
      }

      // console.log(feeLoop);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  }

  // const [feeLoop, onFeeLoop] = React.useState([]);

  var dataList = [];

  const onFeeTitleButtonClick = async (e) => {
    e.preventDefault();
    // console.log(e.currentTarget.id);
    // console.log(e.currentTarget.name);
    if (
      feeLoop.find(
        (fee) => fee.feestitleName === e.currentTarget.name.toUpperCase()
      )
    ) {
      setAlertVal("Fees already exists!");
      handleAlertOpen();
      setTimeout(() => {
        handleAlertClose();
      }, 2000);
      return;
    }

    let obj = {
      id: e.currentTarget.id,
      feestitleName: e.currentTarget.name.toUpperCase(),
    };
    // onLoop([...loop, obj]);
    onFeeLoop([...feeLoop, obj]);
    dataList.push(obj);

    //console.log("data obj is");
    //  console.log(obj);
    console.log("feeloop obj is");

    // console.log(feeLoop);

    console.log("######################");
    console.log(dataList);
  };

  const onLoadFeeStructure = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await FeeStructure.feeListAll(data.yearName);
      console.log(response.data.data);

      onFeeList(response.data.data);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };

  const onSave = async (e) => {
    // console.log(e);
    let data = JSON.parse(localStorage.getItem("academicYear"));

    var feeBDList = [];

    var totalAmount = 0;
    for (var i = 0; i < feeLoop.length; i++) {
      // console.log("*******************");

      // console.log("feeAmounttInputBox" + feeLoop[i].feestitleName);
      // console.log(
      //   document.getElementById("feeAmounttInputBox" + feeLoop[i].feestitleName)
      //     .value
      // );

      // console.log("*******************");
      console.log(selectedSubID, selectedSub);

      var feeListDBObj = {
        standardID: selectedSubID,
        standardName: selectedSub,
        feestitleName: feeLoop[i].feestitleName,
        amount: 1,
        yearID: data.yearID,
        yearName: data.yearName,
      };

      // var amount = document.getElementById(
      //   "feeAmounttInputBox" + feeLoop[i].feestitleName
      // ).value;

      // totalAmount += parseFloat(amount);
      // totalAmtInputBox
      feeBDList.push(feeListDBObj);
    }

    var totalObj = {
      standardID: selectedSubID,
      standardName: selectedSub,
      feestitleName: "Total Fees Amount",
      // amount: totalAmount,
      amount: 0,
      yearID: data.yearID,
      yearName: data.yearName,
    };
    feeBDList.push(totalObj);

    var tutionObj = {
      standardID: selectedSubID,
      standardName: selectedSub,
      feestitleName: "Tution Fee",
      amount: 0,
      // amount: Tutionvalue,
      yearID: data.yearID,
      yearName: data.yearName,
    };
    feeBDList.push(tutionObj);
    var admissionObj = {
      standardID: selectedSubID,
      standardName: selectedSub,
      feestitleName: "Admission Fee",
      amount: 1,
      // amount: Tutionvalue,
      yearID: data.yearID,
      yearName: data.yearName,
    };
    feeBDList.push(admissionObj);

    console.log("Total Amount is here ");
    // console.log(totalAmount);

    // setTotalAmount({ totalAmount: e.target.value });
    // setTotalAmount(totalAmount);
    // var end = document.getElementById(
    //   "feeAmounttInputBox" + feeLoop.feestitleName
    // ).value;

    // feeAmounttInputBox
    //feeLoop

    //totalAmtInputBox

    console.log(feeBDList);

    if (feeBDList) {
      try {
        let response = await FeeStructure.feesStructureCreate(feeBDList);
        console.log(response.data.data);
        setSucessVal("Added Successfully");
        handleSucessOpen();
      } catch (error) {
        console.log(error.message);
      }
    } else {
      console.log("Fees error");
    }
    setTimeout(() => {
      handleSucessClose();
    }, 2000);
  };

  const feeTitleDelete = async (e) => {
    let yearData = JSON.parse(localStorage.getItem("academicYear"));
    console.log(e.currentTarget.name);
    try {
      let response = await FeeStructure.feeDelete(
        e.currentTarget.name,
        yearData.yearName
      );
      onFeeList(
        FeeList.filter(
          (item) => item.feestitleName !== response.data.data.feestitleName
        )
      );
      setDelVal("Deleted");
      handleDeleteOpen();
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      handleDeleteClose();
    }, 2000);
  };
  const onStandardFeeDelete = async (e) => {
    console.log(e.currentTarget.id);
    let feeName = e.currentTarget.id;
    console.log(selectedSubID);
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await FeeStructure.feesStructureDelete(
        e.currentTarget.id,
        selectedSubID,
        data.yearName
      );

      console.log(feeLoop);
      // console.log(FeeList);

      let feeDel = feeLoop.filter((item) => item.feestitleName != feeName);
      onFeeLoop(feeDel);
      setDelVal("Deleted form Standard");
      handleDeleteOpen();
    } catch (error) {
      console.log("error");
      // alert("Failure");
    }
    setTimeout(() => {
      handleDeleteClose();
    }, 2000);
  };
  const [open, setOpen] = React.useState(false);
  // const onFreeze = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let navigate = useNavigate();

  const onFeeFreeze = () => {
    setOpen(true);
  };

  const [status, setStatus] = React.useState();

  const onLoadBuildSchool = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolControll.buildSchoolStatusListAll(
        data.yearName
      );
      // console.log(response.data.data[0]);
      setStatus(response.data.data[4].buildSchoolStatus);
      setFiveId(response.data.data[4].buildSchoolID);
      setSixId(response.data.data[5].buildSchoolID);
    } catch (error) {
      console.error(error);
    }
  };

  const [five, setFive] = React.useState();
  const [fiveId, setFiveId] = React.useState();
  const [six, setSix] = React.useState();
  const [sixId, setSixId] = React.useState();

  const onFeezeReview = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    console.log("onFeezeReview");
    // try {
    //   let response = await BuildSchoolControll.updateBuildSchoolStatus({
    //     activeStatus: "active",
    //     buildSchoolID: fiveId,
    //     buildSchoolName: "feestructure",
    //     buildSchoolStatus: "true",
    //     buildSchoolStep: "five",
    //     buildSchoolYear: data.yearName,
    //     yearName: data.yearName,
    //   });
    // } catch (error) {
    //   alert("Error creating buildSchool " + error);
    // }
    // try {
    //   let response = await BuildSchoolControll.updateBuildSchoolStatus({
    //     activeStatus: "active",
    //     buildSchoolID: sixId,
    //     buildSchoolName: "exam",
    //     buildSchoolStatus: "false",
    //     buildSchoolStep: "six",
    //     buildSchoolYear: data.yearName,
    //     yearName: data.yearName,
    //   });
    // } catch (error) {
    //   alert("Error creating buildSchool " + error);
    // }
    // navigate("/feesReview", { replace: true });
  };

  const onNextPage = () => {
    navigate("/feesEntry", { replace: true });
  };

  const onPause = () => {
    setAlertVal("FeeStructure Module will be paused");
    handleAlertOpen();
    // alert("FeeStructure Module will be paused");
    navigate("/dashborad", { replace: true });
  };

  return (
    <div>
      <Topbar
        text={
          "Step 5 CREATING FEES STRUCTURE 1. Select Standard from left 2. Click the Fees Head from Right and fill the Amount "
        }
      ></Topbar>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Item>
              {/* <Typography variant="subtitle2">SELECT STANDARD</Typography> */}
              {/* <Stack direction="column" spacing={0}> */}
              {standardListAll.map((element, index) => (
                <Button
                  size="small"
                  id={element.standardID}
                  // value={element.standardID}
                  name={element.standardName}
                  sx={{
                    // position: "relative",
                    border: "3px solid #aaecac",
                    minWidth: "100%",
                    // height: 30,
                    color: "Black",
                    fontWeight: "bold",
                    // marginBottom: 0.2,
                    mt:0.5,
                    background: "#aaecac",
                    "&:hover": {
                      background: "#035e06",
                      color: "white",
                    },
                  }}
                  onClick={(event) => {
                    onStandardSelect(event, index);
                  }}
                  className={element.isSelected ? "standardSelected" : ""}
                >
                  {element.standardName}
                </Button>
              ))}
              {/* </Stack> */}
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item sx={{ overflowY: "none", height: 305 }}>
              <Box sx={{display:"flex"}}>
                {/* <Stack
                  direction="row"
                  spacing={0.5}
                  
                > */}
                  {/* <InnerItem> */}
                  <Box sx={{width:"100%"}}>
                    <Typography
                      id="selectedSub"
                      name={selectedSub}
                      value={selectedSubID}
                      sx={{float:"left",backgroundColor:"yellow"}}
                    >
                      {selectedSub}
                    </Typography>
                    {/* </Box> */}
                  {/* </InnerItem> */}
                  {/* <InnerItem> */}
                  {/* <Box sx={{backgroundColor:"skyblue",display:"flex",alignItems:"center",ml:2}}> */}
                    <Typography
                      sx={{
                        backgroundColor: "skyblue",
                       fontWeight:"bold",
                        // paddingX: 3,
                        fontSize: 15,

                        float:"right"
                        // display:"inline"
                      }}
                    >
                      ENTER FEES
                    </Typography>
                    </Box>
                  {/* </InnerItem> */}
                {/* </Stack> */}
              </Box>

              <Box sx={{ overflowY: "scroll" }}>
                <Stack
                  direction="column"
                  spacing={0}
                  sx={{ m: 1, alignText: "center" }}
                >
                  <InnerItem
                    sx={{
                      border: "2px solid black",
                      marginY: 1,
                      backgroundColor: "beige",
                      "&:hover": { backgroundColor: "rgb(219, 219, 163)" },
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={0}
                      sx={{
                        m: 0,
                        alignText: "center",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          width: 380,
                          paddingX: 0,
                          marginX: 3,
                        }}
                      >
                        TUTION FEE
                      </Typography>
                    </Stack>
                  </InnerItem>
                  <InnerItem
                    sx={{
                      border: "2px solid black",
                      marginY: 1,
                      backgroundColor: "beige",
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={0}
                      sx={{ m: 0, alignText: "center" }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ width: 380, paddingX: 0, marginX: 3 }}
                      >
                        ADMISSION FEE
                      </Typography>
                    </Stack>
                  </InnerItem>
                  {/* <InnerItem>
                    <Stack
                      direction="row"
                      spacing={0}
                      sx={{ m: 1, alignText: "center" }}
                    >
                      <TextField
                        onChange={onEnter}
                        id="month"
                        variant="outlined"
                        size="small"
                        type="number"
                      />
                      <TextField
                        id="total"
                        size="small"
                        variant="outlined"
                        value={Tutionvalue}
                        readOnly="true"
                      />
                    </Stack>
                  </InnerItem> */}
                </Stack>

                {feeLoop.map((element) => (
                  // <Box
                  //   sx={{

                  //   }}
                  // >
                  <Stack
                    direction="row"
                    spacing={0}
                    sx={{ m: 1, alignText: "center" }}
                    id={element.id}
                  >
                    <InnerItem
                      sx={{
                        width: 380,
                        border: "2px solid black",
                        marginY: 1,
                        backgroundColor: "beige",
                        "&:hover": { backgroundColor: "rgb(219, 219, 163)" },
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ paddingX: 0, marginX: 3 }}
                      >
                        {element.feestitleName}
                      </Typography>
                    </InnerItem>
                    {/* <InnerItem>
                      <TextField
                        id={"feeAmounttInputBox" + element.feestitleName}
                        onChange={({ target }) => onAmountEnteredChange(target)}
                        //onChange={onAmountEnteredChange()}
                        name={"feeAmounttInputBox" + element.feestitleName}
                        value={element.amount}
                        size="small"
                      >
                        amount
                      </TextField>
                    </InnerItem> */}
                    <IconButton
                      onClick={onStandardFeeDelete}
                      id={element.feestitleName}
                      aria-label="delete"
                      color="error"
                    >
                      <CancelIcon fontSize="inherit" />
                    </IconButton>
                  </Stack>
                  // </Box>
                ))}
              </Box>
            </Item>

            <Box>
              <Stack
                direction="row"
                spacing={0}
                sx={{ m: 1, alignText: "center", }}
              >
                {/* <InnerItem> */}
                  {/* <Stack
                    direction="row"
                    spacing={0}
                    sx={{ width: 480, mx: 0, alignText: "center" }}
                  > */}
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: "25px",
                        marginX: 3,
                      }}
                      color="success"
                      onClick={onSave}
                      fullWidth
                      disabled={
                        feeLoop.length == 0 || status == "true" ? true : false
                      }
                      // disabled={
                      //   feeLoop.length == 0 || standardListAll.length == 0
                      // }
                    >
                      Save Fee
                    </Button>
                  {/* </Stack> */}
                {/* </InnerItem> */}
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Item>
              <Stack spacing={2} sx={{ overflowY: "scroll" }}>
                <Box sx={{ height: 250 }}>
                  <Typography variant="subtitle1">
                    CLICK SELECT FEES TITLE / FEES HEADINGS
                  </Typography>
                  <Box>
                    <Box>
                      <Grid container spacing={2}>
                        {FeeList.map((element) => (
                          <Grid item xs={6} md={3}>
                            <BtnItem
                              sx={{
                                backgroundColor: "beige",
                                "&:hover": {
                                  backgroundColor: "rgb(219, 219, 163)",
                                },
                              }}
                            >
                              <Grid container spacing={0}>
                                <Grid item xs={10}>
                                  <Button
                                    id={element.feestitleName}
                                    name={element.feestitleName}
                                    onClick={onFeeTitleButtonClick}
                                    size="small"
                                    sx={{
                                      cursor: "pointer",
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {element.feestitleName}
                                  </Button>
                                </Grid>
                                <Grid item xs={2}>
                                  <IconButton
                                    aria-label="delete"
                                    // id={element.feestitleName}
                                    name={element.feestitleName}
                                    onClick={feeTitleDelete}
                                    size="small"
                                    color="error"
                                    disabled={status == "true" ? true : false}
                                    // sx={{ marginLeft: 5 }}
                                  >
                                    <CancelIcon fontSize="inherit" />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </BtnItem>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Stack>
              <Box component="form" onSubmit={onFeeTitleSave} mt="10px">
                <Stack direction="row">
                  <Typography
                    sx={{
                      width: 600,
                      mt:1
                    }}
                  >
                    ADD NEW FEES TITLE IF REQUIRED:
                  </Typography>
                  <Grid sx={{}} container spacing={1}>
                    <Grid item xs={12}>
                      <Box>
                        <TextField
                          size="small"
                          variant="outlined"
                          name="fee"
                          label="Enter FEE"
                          fullWidth
                          inputRef={textInput}
                          onInput={(e) => onlyCharaters(e)}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        ></TextField>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Button
                          color="success"
                          type="submit"
                          variant="contained"
                          fullWidth
                          sx={{
                            borderRadius: "25px",
                          }}
                          disabled={status == "true" ? true : false}
                        >
                          ADD FEES TITLE
                        </Button>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={6}>
                      <Box>
                        <Button fullWidth color="error" variant="contained">
                          Clear
                        </Button>
                      </Box>
                    </Grid> */}
                  </Grid>
                </Stack>
              </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
              sx={{ m: 2 }}
            >
              <Typography
                sx={{
                  backgroundColor: "lightyellow",
                  color: "red",
                  p: 1,
                  border: "2px solid black",
                  borderRadius: "5px",
                }}
              >
                PLEASE ENTER INFORMATION CAREFULLY THIS INFORMATION CAN NOT BE
                EDITED ONCE THE FEES RECEIPTS ARE GENERATED.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "25px",
                  width: 400,
                }}
                color="primary"
                onClick={onNextPage}
                disabled={feeLoop.length == 0 || standardListAll.length == 0}
              >
                Next Page
              </Button>
              {/* <Button
                variant="outlined"
                sx={{
                  py: 2,
                  width: 300,
                  backgroundColor: "black",
                  color: "lightblue",
                  border: "4px solid lightblue",
                  borderRadius: "30px",
                }}
                onClick={onFeeFreeze}
                disabled={status == "true" ? true : false}
              >
                Freeze and Review{" "}
              </Button> */}
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                // onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <Box sx={style}>
                    <Typography
                      id="transition-modal-title"
                      variant="body1"
                      component="h2"
                    >
                      You have clicked on
                    </Typography>
                    <Typography
                      id="transition-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      FREEZ INFORMATION
                    </Typography>
                    <Typography
                      id="transition-modal-description"
                      sx={{ mt: 2 }}
                    >
                      We request you to kindly review the information once again
                      and then freeze the data, as once its FREEZE can’t be
                      changed. Thank you
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                      <Button
                        variant="contained"
                        size="small"
                        color="success"
                        onClick={onFeezeReview}
                      >
                        FREEZE
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={handleClose}
                      >
                        Review
                      </Button>
                    </Stack>
                  </Box>
                </Fade>
              </Modal>
              <Button
                variant="contained"
                color="warning"
                sx={{
                  borderRadius: "25px",
                  // py: 2,
                  width: 400,
                }}
                onClick={onPause}
                disabled={status == "true" ? true : false}
              >
                Pause and Continue Setup Later
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Modal
        open={openSucess}
        onClose={handleSucessClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <SucessModal text={sucessVal} />
        </div>
      </Modal>
      <Modal
        open={openAlert}
        onClose={handleAlertClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <AlertModal text={alertVal} />
        </div>
      </Modal>
      <Modal
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <DeleteModal text={delVal} />
        </div>
      </Modal>
    </div>
  );
}
