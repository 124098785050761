import * as React from "react";
import { Button, Box, Typography, Stack, Grid } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { StudentAttendenanceService } from "../../../services/studentAttendance";
import Paper from "@mui/material/Paper";
import { C } from "../../util/C";
import { useNavigate } from "react-router-dom";
import AttendenceTotal from "./attendenceTotal";
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";

const ItemBg = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));
const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color: "white",
  },
}));

export default function VariantButtonGroup(props) {
  const [isloading, setisLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [excel, setExcel] = React.useState([]);
  // const columns = [];
  const [selectedMonth, setMonth] = React.useState("");
  const [StandardName, setStandardName] = React.useState();
  const [StandardID, setStandardID] = React.useState();
  const [Standard, setStandard] = React.useState([]);

  useEffect(() => {
    onDateSelect();
  }, []);

  const date = new Date();  // 2009-11-10
  const month = date.toLocaleString('default', { month: 'long' });
  console.log(month);

  let navigate = useNavigate();
  const onLaunchpad = () => {
    navigate("/launchpad", { replace: true });
  };

  const onDateSelect = async (e) => {
    const currentDate = new Date();
    const iso8601Date = currentDate.toISOString().split("T")[0];
    const options = { month: "long" };
    const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);

    // const formattedDate = currentDate.toLocaleDateString();
    // const formattedTime = currentDate.toLocaleTimeString();

   

    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response =
        await StudentAttendenanceService.getmonthstandaredabsentreportmonth(
          month,
          data.yearName
        );
      console.log(response.data.data);
      onload(response.data.data);
      
      let stud=[];
      response.data.data.rows.forEach((res,i)=>{
        stud.push({
          "STANDARD" : res.standard+"-"+res.division,
          "1 - PR[G][B]| AB[G][B]": res.s0,
          "2 - PR[G][B]| AB[G][B]": res.s1,
          "3 - PR[G][B]| AB[G][B]": res.s2,
          "4 - PR[G][B]| AB[G][B]": res.s3,
          "5 - PR[G][B]| AB[G][B]": res.s4,
          "6 - PR[G][B]| AB[G][B]": res.s5,
          "7 - PR[G][B]| AB[G][B]": res.s6,
          "8 - PR[G][B]| AB[G][B]": res.s7,
          "9 - PR[G][B]| AB[G][B]": res.s8,
          "10 - PR[G][B]| AB[G][B]": res.s9,
          "11 - PR[G][B]| AB[G][B]": res.s10,
          "12 - PR[G][B]| AB[G][B]": res.s11,
          "13 - PR[G][B]| AB[G][B]": res.s12,
          "14 - PR[G][B]| AB[G][B]": res.s13,
          "15 - PR[G][B]| AB[G][B]": res.s14,
          "16 - PR[G][B]| AB[G][B]": res.s15,
          "17 - PR[G][B]| AB[G][B]": res.s16,
          "18 - PR[G][B]| AB[G][B]": res.s17,
          "19 - PR[G][B]| AB[G][B]": res.s18,
          "20 - PR[G][B]| AB[G][B]": res.s19,
          "21 - PR[G][B]| AB[G][B]": res.s20,
          "22 - PR[G][B]| AB[G][B]": res.s21,
          "23 - PR[G][B]| AB[G][B]": res.s22,
          "24 - PR[G][B]| AB[G][B]": res.s23,
          "25 - PR[G][B]| AB[G][B]": res.s24,
          "26 - PR[G][B]| AB[G][B]": res.s25,
          "27 - PR[G][B]| AB[G][B]": res.s26,
          "28 - PR[G][B]| AB[G][B]": res.s27,
          "29 - PR[G][B]| AB[G][B]": res.s28,
          "30 - PR[G][B]| AB[G][B]": res.s29,
          "31 - PR[G][B]| AB[G][B]": res.s30,
          
        });
      });
      setExcel(stud);
    } catch (error) {
      console.log(error);
    }
  };

  function onload(data) {
    console.log(data.rows);

    let col = [];
    col.push({
      field: "standard",
      headerName: "Standard",
      width: "200",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"STANDARD"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    });
    data.column.forEach((rec, i) => {
      col.push({
        field: i.toString(),
        headerName: rec.day + " - present || Absent",
        width: "240",
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderHeader: () => (
          <strong style={{ fontSize: 15 }}>
            {rec.day} - PR
            <span
              style={{
                // color: "#fff",
                padding: 2,
                backgroundColor: "#ffc0cb",
              }}
            >
              [ G ]
            </span>
            <span
              style={{
                // color: "#fff",
                padding: 2,
                backgroundColor: "#ADD8E6",
              }}
            >
              [ B ]
            </span>{" "}
            || AB
            <span
              style={{
                // color: "#fff",
                padding: 2,
                backgroundColor: "#ffc0cb",
              }}
            >
              [ G ]
            </span>
            <span
              style={{
                // color: "#fff",
                padding: 2,
                backgroundColor: "#ADD8E6",
              }}
            >
              [ B ]
            </span>
          </strong>
        ),
        headerClassName: "super-app-theme--header",
      });
    });

    setColumns(col);

    let row = [];
    data.rows.forEach((rec, i) => {
      // console.log(
      //   rec.s13
      //      "G-" +
      //         rec.s13.split("|")[0] +
      //         " | B-" +
      //         rec.s13.split("|")[1] +
      //         " || " +
      //         "G-" +
      //         rec.s13.split("|")[2] +
      //         " | B-" +
      //         rec.s13.split("|")[3]
      //     : "------------"
      // );
      let obj = {
        id: i,
        standard: rec.standard + "-" + rec.division,
        0: rec.s0
          ? "G-" +
            rec.s0.split("|")[0] +
            " | B-" +
            rec.s0.split("|")[1] +
            " || " +
            "G-" +
            rec.s0.split("|")[2] +
            " | B-" +
            rec.s0.split("|")[3]
          : "--",
        1: rec.s1
          ? "G-" +
            rec.s1.split("|")[0] +
            " | B-" +
            rec.s1.split("|")[1] +
            " || " +
            "G-" +
            rec.s1.split("|")[2] +
            " | B-" +
            rec.s1.split("|")[3]
          : "--",
        2: rec.s2
          ? "G-" +
            rec.s2.split("|")[0] +
            " | B-" +
            rec.s2.split("|")[1] +
            " || " +
            "G-" +
            rec.s2.split("|")[2] +
            " | B-" +
            rec.s2.split("|")[3]
          : "--",
        3: rec.s3
          ? "G-" +
            rec.s3.split("|")[0] +
            " | B-" +
            rec.s3.split("|")[1] +
            " || " +
            "G-" +
            rec.s3.split("|")[2] +
            " | B-" +
            rec.s3.split("|")[3]
          : "--",
        4: rec.s4
          ? "G-" +
            rec.s4.split("|")[0] +
            " | B-" +
            rec.s4.split("|")[1] +
            " || " +
            "G-" +
            rec.s4.split("|")[2] +
            " | B-" +
            rec.s4.split("|")[3]
          : "--",
        5: rec.s5
          ? "G-" +
            rec.s5.split("|")[0] +
            " | B-" +
            rec.s5.split("|")[1] +
            " || " +
            "G-" +
            rec.s5.split("|")[2] +
            " | B-" +
            rec.s5.split("|")[3]
          : "--",
        6: rec.s6
          ? "G-" +
            rec.s6.split("|")[0] +
            " | B-" +
            rec.s6.split("|")[1] +
            " || " +
            "G-" +
            rec.s6.split("|")[2] +
            " | B-" +
            rec.s6.split("|")[3]
          : "--",
        7: rec.s7
          ? "G-" +
            rec.s7.split("|")[0] +
            " | B-" +
            rec.s7.split("|")[1] +
            " || " +
            "G-" +
            rec.s7.split("|")[2] +
            " | B-" +
            rec.s7.split("|")[3]
          : "--",
        8: rec.s8
          ? "G-" +
            rec.s8.split("|")[0] +
            " | B-" +
            rec.s8.split("|")[1] +
            " || " +
            "G-" +
            rec.s8.split("|")[2] +
            " | B-" +
            rec.s8.split("|")[3]
          : "--",
        9: rec.s9
          ? "G-" +
            rec.s9.split("|")[0] +
            " | B-" +
            rec.s9.split("|")[1] +
            " || " +
            "G-" +
            rec.s9.split("|")[2] +
            " | B-" +
            rec.s9.split("|")[3]
          : "--",
        10: rec.s10
          ? "G-" +
            rec.s10.split("|")[0] +
            " | B-" +
            rec.s10.split("|")[1] +
            " || " +
            "G-" +
            rec.s10.split("|")[2] +
            " | B-" +
            rec.s10.split("|")[3]
          : "--",
        11: rec.s11
          ? "G-" +
            rec.s11.split("|")[0] +
            " | B-" +
            rec.s11.split("|")[1] +
            " || " +
            "G-" +
            rec.s11.split("|")[2] +
            " | B-" +
            rec.s11.split("|")[3]
          : "--",
        12: rec.s12
          ? "G-" +
            rec.s12.split("|")[0] +
            " | B-" +
            rec.s12.split("|")[1] +
            " || " +
            "G-" +
            rec.s12.split("|")[2] +
            " | B-" +
            rec.s12.split("|")[3]
          : "--",
        13: rec.s13
          ? "G-" +
            rec.s13.split("|")[0] +
            " | B-" +
            rec.s13.split("|")[1] +
            " || " +
            "G-" +
            rec.s13.split("|")[2] +
            " | B-" +
            rec.s13.split("|")[3]
          : "--",
        14: rec.s14
          ? "G-" +
            rec.s14.split("|")[0] +
            " | B-" +
            rec.s14.split("|")[1] +
            " || " +
            "G-" +
            rec.s14.split("|")[2] +
            " | B-" +
            rec.s14.split("|")[3]
          : "--",
        15: rec.s15
          ? "G-" +
            rec.s15.split("|")[0] +
            " | B-" +
            rec.s15.split("|")[1] +
            " || " +
            "G-" +
            rec.s15.split("|")[2] +
            " | B-" +
            rec.s15.split("|")[3]
          : "--",
        16: rec.s16
          ? "G-" +
            rec.s16.split("|")[0] +
            " | B-" +
            rec.s16.split("|")[1] +
            " || " +
            "G-" +
            rec.s16.split("|")[2] +
            " | B-" +
            rec.s16.split("|")[3]
          : "--",
        17: rec.s17
          ? "G-" +
            rec.s17.split("|")[0] +
            " | B-" +
            rec.s17.split("|")[1] +
            " || " +
            "G-" +
            rec.s17.split("|")[2] +
            " | B-" +
            rec.s17.split("|")[3]
          : "--",
        18: rec.s18
          ? "G-" +
            rec.s18.split("|")[0] +
            " | B-" +
            rec.s18.split("|")[1] +
            " || " +
            "G-" +
            rec.s18.split("|")[2] +
            " | B-" +
            rec.s18.split("|")[3]
          : "--",
        19: rec.s19
          ? "G-" +
            rec.s19.split("|")[0] +
            " | B-" +
            rec.s19.split("|")[1] +
            " || " +
            "G-" +
            rec.s19.split("|")[2] +
            " | B-" +
            rec.s19.split("|")[3]
          : "--",
        20: rec.s20
          ? "G-" +
            rec.s20.split("|")[0] +
            " | B-" +
            rec.s20.split("|")[1] +
            " || " +
            "G-" +
            rec.s20.split("|")[2] +
            " | B-" +
            rec.s20.split("|")[3]
          : "--",
        21: rec.s21
          ? "G-" +
            rec.s21.split("|")[0] +
            " | B-" +
            rec.s21.split("|")[1] +
            " || " +
            "G-" +
            rec.s21.split("|")[2] +
            " | B-" +
            rec.s21.split("|")[3]
          : "--",
        22: rec.s22
          ? "G-" +
            rec.s22.split("|")[0] +
            " | B-" +
            rec.s22.split("|")[1] +
            " || " +
            "G-" +
            rec.s22.split("|")[2] +
            " | B-" +
            rec.s22.split("|")[3]
          : "--",
        23: rec.s23
          ? "G-" +
            rec.s23.split("|")[0] +
            " | B-" +
            rec.s23.split("|")[1] +
            " || " +
            "G-" +
            rec.s23.split("|")[2] +
            " | B-" +
            rec.s23.split("|")[3]
          : "--",
        24: rec.s24
          ? "G-" +
            rec.s24.split("|")[0] +
            " | B-" +
            rec.s24.split("|")[1] +
            " || " +
            "G-" +
            rec.s24.split("|")[2] +
            " | B-" +
            rec.s24.split("|")[3]
          : "--",
        25: rec.s25
          ? "G-" +
            rec.s25.split("|")[0] +
            " | B-" +
            rec.s25.split("|")[1] +
            " || " +
            "G-" +
            rec.s25.split("|")[2] +
            " | B-" +
            rec.s25.split("|")[3]
          : "--",
        26: rec.s26
          ? "G-" +
            rec.s26.split("|")[0] +
            " | B-" +
            rec.s26.split("|")[1] +
            " || " +
            "G-" +
            rec.s26.split("|")[2] +
            " | B-" +
            rec.s26.split("|")[3]
          : "--",
        27: rec.s27
          ? "G-" +
            rec.s27.split("|")[0] +
            " | B-" +
            rec.s27.split("|")[1] +
            " || " +
            "G-" +
            rec.s27.split("|")[2] +
            " | B-" +
            rec.s27.split("|")[3]
          : "--",
        28: rec.s28
          ? "G-" +
            rec.s28.split("|")[0] +
            " | B-" +
            rec.s28.split("|")[1] +
            " || " +
            "G-" +
            rec.s28.split("|")[2] +
            " | B-" +
            rec.s28.split("|")[3]
          : "--",
        29: rec.s29
          ? "G-" +
            rec.s29.split("|")[0] +
            " | B-" +
            rec.s29.split("|")[1] +
            " || " +
            "G-" +
            rec.s29.split("|")[2] +
            " | B-" +
            rec.s29.split("|")[3]
          : "--",
        30: rec.s30
          ? "G-" +
            rec.s30.split("|")[0] +
            " | B-" +
            rec.s30.split("|")[1] +
            " || " +
            "G-" +
            rec.s30.split("|")[2] +
            " | B-" +
            rec.s30.split("|")[3]
          : "--",
        31: rec.s31
          ? "G-" +
            rec.s31.split("|")[0] +
            " | B-" +
            rec.s31.split("|")[1] +
            " || " +
            "G-" +
            rec.s31.split("|")[2] +
            " | B-" +
            rec.s31.split("|")[3]
          : "--",
      };
      row.push(obj);
    });
    setRows(row);
    console.log(col);
    console.log(row);

   
    // setTimeout(() => {
    //   Data.data[0].rows.attendence.forEach((rec, i) => {
    //     console.log(rec);
    //     // row[i][rec.divison] = rec.totalpresent + " | " + rec.totalabsent;
    //   });
    // }, 2000);
    // console.log(row);
    setisLoading(false);
  }

  // function onload(data) {
  //   let col = [];
  //   col.push({
  //     field: "date",
  //     headerName: "Date",
  //     flex: 1,
  //     headerClassName: "super-app-theme--header",
  //   });
  //   data.column.forEach((rec, i) => {
  //     col.push({
  //       field: i.toString(),
  //       headerName: rec.division + " - Present || Absent - " + rec.total,
  //       flex: 1,
  //       headerClassName: "super-app-theme--header",
  //     });
  //   });

  //   col.push({
  //     field: "total_absent",
  //     headerName: "Total Absent",
  //     flex: 1,
  //     headerClassName: "super-app-theme--header",
  //   });
  //   col.push({
  //     field: "total_present",
  //     headerName: "Total Present",
  //     flex: 1,
  //     headerClassName: "super-app-theme--header",
  //   });
  //   setColumns(col);

  //   let row = [];
  //   data.rows.forEach((rec, i) => {
  //     let obj = {
  //       id: i,
  //       date: rec.date + "-" + rec.day,
  //       total_absent: rec.totalAbsent,
  //       total_present: rec.totalPresent,
  //       0: rec.a ? rec.a : "--",
  //       1: rec.b ? rec.b : "--",
  //       2: rec.c ? rec.c : "--",
  //       3: rec.d ? rec.d : "--",
  //     };
  //     row.push(obj);
  //   });
  //   setRows(row);
  //   console.log(col);
  //   // console.log(row);

  //   setisLoading(false);
  // }

  const [attendenceClass, setAttendenceClass] = React.useState([]);

  const fileType = "csv";
  const exportToCSV = () => {
    const visible = XLSX.utils.json_to_sheet(excel);
    const wb = { Sheets: { product: visible }, SheetNames: ["product"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    filesaver.saveAs(data, " Monthly Attendance"+ ".csv");
  };

  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          backgroundColor: "lightblue",
          m: "10px 10px",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        {month.toUpperCase()} MONTH ATTENDANCE REPORT OF ALL STANDARDS AND DIVISIONS
      </Typography>
      <Box
        sx={{
          mt: "15px",
          height: 423,
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "#c0c0c0",
            //  color:'white',
            fontWeight: "bold",
            fontSize: "15px",
            border: "1px solid white",
          },
        }}
      >
        {isloading ? (
          // <Typography variant="h1"> </Typography>
          <div style={{padding:"180px 0px", display:"flex", justifyContent:"center",}}>
          <div className = "spinner-container">
          <div className = "loading-spinner">
          </div>
        </div>
        </div>
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            disableColumnMenu
            // components={{ Toolbar: GridToolbar }}
          />
        )}
         <AttendenceTotal></AttendenceTotal>

         <Box sx={{display:"flex", justifyContent:"space-evenly",paddingBottom:1}}>
      
        <Button variant="contained" size="large" sx={{borderRadius:"15px",width:"140px",borderRadius:"30px",fontWeight:"bold"}} color="success" onClick={exportToCSV}>excel</Button>
        <Button
                  variant="contained"
                  onClick={onLaunchpad}
                  sx={{
                    backgroundColor: "#ca424f",
                    fontSize: 15,
                    fontWeight: "bold",
                    borderRadius: "30px",
                    width: "140px",
                    ml: 5,
                    "&:hover": { backgroundColor: "#ca424f" },
                  }}
                >
                  exit
                </Button>
      </Box>
        
      </Box>
    </Box>
  );
}
