import * as React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Topbar from "../MainTopbar/Topbar";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BuildSchoolControll } from "../../services/buildSchoolControllService";

export default function Welcome(location) {
  const [yearName, setYearName] = React.useState("");
  const [yearID, setYearID] = React.useState("");
  React.useEffect(() => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    setYearName(data.yearName);
    setYearID(data.yearID);
  }, []);
  // * "========================Route===================================="

  let navigate = useNavigate();

  const onBuildSchool = async () => {
    // console.log("onBuildSchool");

    let standard = [
      "standard",
      "subject",
      "designation",
      "timetable",
      "feestructure",
      "exam",
      "buildschoolcomplete",
    ];

    let step = ["one", "two", "three", "four", "five", "six", "seven"];

    for (let i = 0; i <= 6; i++) {
      console.log("onBuildSchool" + i);
      if (i == 0) {
        console.log("if");

        try {
          let response = await BuildSchoolControll.createBuildSchoolStatus({
            activeStatus: "active",
            buildSchoolName: standard[i],
            buildSchoolStatus: "false",
            buildSchoolStep: step[i],
            buildSchoolYear: yearName,
            yearID: yearID,
            yearName: yearName,
          });
        } catch (error) {
          alert("Error creating buildSchool " + error);
        }
      } else {
        console.log("else");

        try {
          let response = await BuildSchoolControll.createBuildSchoolStatus({
            activeStatus: "inactive",
            buildSchoolName: standard[i],
            buildSchoolStatus: "false",
            buildSchoolStep: step[i],
            buildSchoolYear: yearName,
            yearID: yearID,
            yearName: yearName,
          });
        } catch (error) {
          alert("Error creating buildSchool " + error);
        }
      }
    }

    navigate("/dashborad", { replace: true });
  };
  // * "========================Route===================================="

  return (
    <div>
      <Topbar text={"Welcome to writting future..."} />
      <Container
        component="main"
        sx={{
          width: "50%",
        }}
      >
        <Box
          component="form"
          noValidate
          sx={{
            marginTop: 15,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: "3px solid #EE82EE",
            borderRadius: "50px",
          }}
        >
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, mt: 2 }}
          >
            WELCOME TO NEXT LEVEL OF INTELLIGENCE
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, mt: 1, color: "blue", fontWeight: "bold" }}
          >
            Writing Future
          </Typography>
          <Typography
            variant="subtitle2"
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
          >
            The complete School Management Online Software.
          </Typography>
          <Typography
            variant="subtitle1"
            noWrap
            component="div"
            sx={{ flexGrow: 1, mt: 2 }}
          >
            Writingfurture will take your School's Management Online and
          </Typography>
          <Typography
            variant="subtitle1"
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
          >
            make most of your school daily task easy and available on your
            fingertips
          </Typography>
          <Typography
            variant="subtitle1"
            noWrap
            component="div"
            sx={{ flexGrow: 1, mt: 5 }}
          >
            Kindly take the first step towards building your school online
          </Typography>
          <Typography
            variant="subtitle1"
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
          >
            We request you click the first option available on Launch Pad i.e.
          </Typography>
          <Button variant="contained" onClick={onBuildSchool}>
            Build School
          </Button>
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{ flexGrow: 1, mt: 2, mb: 2, color: "blue" }}
          >
            Thank you
          </Typography>
        </Box>
      </Container>
    </div>
  );
}
