import * as React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbar,
  GridCellParams,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
// import { useDemoData } from '@mui/x-data-grid-generator';
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom"; //for browser back button navigation handling
import { StudentInfoService } from "../../../services/StudentInfo/StudentList";

export default function QuickFilteringGrid() {
  const navigate = useNavigate();

  const gotoStudentInfo = async (e) => {
    console.log();
    localStorage.setItem(
      "studentUSN",
      e.currentTarget.parentNode.parentNode.getAttribute("data-id")
    );
    navigate("/studentInfo", { replace: true });
  };

  const onLaunchpad = () => {
    navigate("/launchpad", { replace: true });
  };

  const columns = [
    {
      field: "id",
      headerName: "No.",
      flex: 0.5,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"No."}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "studentName",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NAME"}</strong>,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p style={{ textTransform: "uppercase" }}>{params.value}</p>
      ),
    },
    {
      field: "studentGender",
      headerName: "Gender",
      flex: 0.6,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"GENDER"}</strong>,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p style={{ textTransform: "uppercase" }}>{params.value}</p>
      ),
    },
    {
      field: "studentUSN",
      headerName: "USID",
      flex: 0.5,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"USID"}</strong>,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p style={{ textTransform: "uppercase" }}>{params.value}</p>
      ),
    },
    {
      field: "standardWithDivision",
      headerName: "STD/DIV",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"STD/DIV"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "fatherName",
      headerName: "Father's Name",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"FATHER'S NAME"}</strong>
      ),
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p style={{ textTransform: "uppercase" }}>{params.value}</p>
      ),
    },
    {
      field: "motherName",
      headerName: "Mother's Name",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"MOTHER'S NAME"}</strong>
      ),
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p style={{ textTransform: "uppercase" }}>{params.value}</p>
      ),
    },
    {
      field: "info",
      headerName: "Show Info",
      flex: 1,
      sortable: false,
      renderCell: () => {
        return (
          <Button
            variant="contained"
            color="primary"
            // sx={{ height: 27, my: "5px", ml: "6px" }}
            onClick={gotoStudentInfo}
            // id={}
          >
            Show Info
          </Button>
        );
      },
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"SHOW INFO"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
  ];

  const [studentsList, setStudentList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const onStudentsListLoad = async () => {
    // setLoading(true);
    let data = JSON.parse(localStorage.getItem("academicYear"));
    try {
      // alert(data.yearName);
      let response = await StudentInfoService.studentInfoList();
      console.log(response.data.data);
      setStudentList(
        response.data.data.filter((student) => student.approveStatus == true)
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  //adding id and standardWithDivision properties to display in the DataGrid
  let updatedStudentsList = studentsList.map((object, i) => {
    return {
      ...object,
      ...{
        usn: object.studentID,
        id: i + 1,
        standardWithDivision: object.standardName + "/" + object.divisionName,
      },
    };
  });

  // console.log(alteredArray);
  React.useEffect(() => {
    onStudentsListLoad();
  }, []);

  return (
    <Box>
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: "#c0c0c0",
          //  color:'white',
          fontWeight: "bold",
          fontSize: "15px",
          border: "1px solid white",
        },
      }}
    >

      <LaunchpadTopBar id="studentinfo" />
{loading ? (
  <Box sx={{padding:"180px 0px", display:"flex", justifyContent:"center",}}>
  <Box className = "spinner-container">
  <Box className = "loading-spinner">
  </Box>
</Box>
</Box>
) : (
      <Box
        sx={{
          height: 550,
          width: "100%",
          padding: "0 10px",
          boxSizing: "border-box",
        }}
      >
        <DataGrid
          getRowId={(row) => row.usn}
          disableColumnFilter
          // id={updatedStudentsList.studentUSN}
          disableColumnSelector
          disableDensitySelector
          rows={updatedStudentsList}
          columns={columns}
          // id={updatedStudentsList.studentUSN}
          rowsPerPageOptions={[10, 25, 50, 100, 200]}
          disableColumnMenu
          components={{ Toolbar: GridToolbar }}
          // componentsProps={{
          //   toolbar: {
          //     showQuickFilter: true,
          //     quickFilterProps: { debounceMs: 500 },
          //   },
          // }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }}
        />
        
      </Box>
)}

    </Box>
    {/* <Box sx={{display:"flex", justifyContent:"space-evenly",paddingBottom:1}}>
     
        <Button
                  variant="contained"
                  onClick={onLaunchpad}
                  sx={{
                    backgroundColor: "#ca424f",
                    fontSize: 15,
                    fontWeight: "bold",
                    borderRadius: "30px",
                    width: "140px",
                    ml: 5,
                    "&:hover": { backgroundColor: "#ca424f" },
                  }}
                >
                  exit
                </Button>
      </Box> */}
    </Box>
  );
}
