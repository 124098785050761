import { useEffect } from "react";
import { StaffAttendenanceService } from "../../../services/StaffAttendanence";
import { Box, Grid, Stack, Typography, Paper,Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
}));

function IndividualAttendence(props) {
  const [allMonthStaffInfo, setAllMonthStaffInfo] = useState([]);
  const [MonthStaffInfo, setMonthStaffInfo] = useState([]);

 
  useEffect(() => {
    onload();
    console.log(props.data);
  }, []);

  let navigate = useNavigate();
  const onLaunchPad=()=>{
    navigate("/launchpad", {replace:true});
  }
  const onload = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    // const currentDate = new Date();
    // const iso8601Date = currentDate.toISOString().split("T")[0];
    // const options = { month: "long" };
    // const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);

    // try {
    //   let response = await StaffAttendenanceService.getStaffAttendance(
    //     props.data.ssid,
    //     month,
    //     props.data.staffName,
    //     data.yearName
    //   );
    //   console.log(response.data.data);
    //   if (response.data.data.length != 0) {
    //     setMonthStaffInfo(response.data.data[0]);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
    try {
      let response =
        await StaffAttendenanceService.getIndividualStaffAttendance(
          props.data.ssid,
          props.data.staffName,
          data.yearName
        );
      console.log(response.data.data);
      setAllMonthStaffInfo(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [highlight, setHighlight] = useState();
  // const [MonthStaffInfo, setMonthStaffInfo] = useState([]);

  const [month, setMonth] = useState();
  const onSelectMonth = async(e)=>{
    console.clear();
    console.log(e.target.value);
    setHighlight(e.target.value);
    setMonth(e.target.value);

    let data = JSON.parse(localStorage.getItem("academicYear"));
    
    try {
      let response = await StaffAttendenanceService.getStaffAttendance(
        props.data.ssid,
        e.target.value,
        props.data.staffName,
        data.yearName
      );
      console.log(response.data.data);
      console.log(response.data.data);
      setMonthStaffInfo(response.data.data);
      
    } catch (error) {
      console.log(error);
    }

  }
  // console.clear();
  console.log(MonthStaffInfo);
  // console.log(MonthStaffInfo[0].staffName);

  const genaratePDF = () => {
    const doc = new jsPDF("landscape");
   const school = JSON.parse(localStorage.getItem("schoolDetails"));
   const academic = JSON.parse(localStorage.getItem("academicYear"));
    const sch = school.schoolNameOnDocs.toUpperCase();
    const acad = academic.yearName;
    const img = school.schoolLogoUrl;
    doc.setFont("helvetica", "bold");
    var fontSize = 18;
    var text = `${sch}`;
    var pageWidth = doc.internal.pageSize.getWidth();
    var textWidth =
      (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
    var centerX = (pageWidth - textWidth) / 2;
    doc.text(text, centerX, 15);
  
    doc.addImage(img, "JPEG", 15,5,27,27);
    doc.setFontSize(10);
    doc.text(` ${acad}`, 250, 15);
  
    
  
      doc.setFontSize(15);
      doc.setFont("helvetica", "bold");
      doc.text("INDIVIDUAL STAFF ATTENDANCE REPORT", 90, 30);
  
      doc.setFontSize(14);
      doc.text(props.data.staffName+"--"+props.data.ssid, 110,48);
      doc.addImage(props.data.staffProfilePhoto, 'JPEG', 220,25,30,36);
   
      doc.autoTable({
        startY:60,
        head:[["DESIGNATION : "+props.data.designation, 
     "CLASS :"+ props.data.staffClass,
      ]],
      headStyles:{
        fillColor : "white",
        textColor: 0,
        fontSize:12
      },
      theme:"grid",
      });
      
  // doc.setFontSize(11);
  //     doc.text("MAY",10,85);
  //     doc.text("JUNE",24,85);
  //     doc.text("JULY",40,85);
  //     doc.text("AUGUST",55,85);
  //     doc.text("SEPTEMBER",75,85);
  //     doc.text("OCTOBER",103,85);
  //     doc.text("NOVEMBER",128,85);
  //     doc.text("DECEMBER",158,85);
  //     doc.text("JANUARY",185,85);
  //     doc.text("FEBRUARY",208,85);
  //     doc.text("MARCH",235,85);
  //     doc.text("APRIL",253,85);
    
  
  // let arr = allMonthStaffInfo.map(obj => ({...obj, 
  //   numberOfDays: String(obj.numberOfDays),
  //   numberOfDaysAbsent: String(obj.numberOfDaysAbsent),
  //   numberOfDaysPresent: String(obj.numberOfDaysPresent),
  // }));
  // console.log(arr);
  
  // doc.setLineWidth(0.5);
  // doc.rect(7,80,260,20);
  // doc.text(arr[0].numberOfDays, 13,90);
  // doc.text(arr[1].numberOfDays,29,90);
  // doc.text(arr[2].numberOfDays,43.5,90);
  // doc.text(arr[3].numberOfDays,62,90);
  // doc.text(arr[4].numberOfDays,85,90);
  // doc.text(arr[5].numberOfDays,112,90);
  // doc.text(arr[6].numberOfDays,137,90);
  // doc.text(arr[7].numberOfDays,169,90);
  // doc.text(arr[8].numberOfDays,194,90);
  // doc.text(arr[9].numberOfDays,216,90);
  // doc.text(arr[10].numberOfDays,240,90);
  // doc.text(arr[11].numberOfDays,257,90);
  
  // doc.text(arr[0].numberOfDaysPresent+" | ",10,95);
  // doc.text(arr[1].numberOfDaysPresent+" | ",26,95);
  // doc.text(arr[2].numberOfDaysPresent+" | ",41,95);
  // doc.text(arr[3].numberOfDaysPresent+" | ",60,95);
  // doc.text(arr[4].numberOfDaysPresent+" | ",83,95);
  // doc.text(arr[5].numberOfDaysPresent+" | ",110,95);
  // doc.text(arr[6].numberOfDaysPresent+" | ",135,95);
  // doc.text(arr[7].numberOfDaysPresent+" | ",167,95);
  // doc.text(arr[8].numberOfDaysPresent+" | ",192,95);
  // doc.text(arr[9].numberOfDaysPresent+" | ",213,95);
  // doc.text(arr[10].numberOfDaysPresent+" | ",237,95);
  // doc.text(arr[11].numberOfDaysPresent+" | ",255,95);
  
  // doc.setTextColor(255,0,0);
  // doc.text(arr[0].numberOfDaysAbsent,16.5,95);
  // doc.text(arr[1].numberOfDaysAbsent,32.5,95);
  // doc.text(arr[2].numberOfDaysAbsent,48,95);
  // doc.text(arr[3].numberOfDaysAbsent,66.5,95);
  // doc.text(arr[4].numberOfDaysAbsent,89.5,95);
  // doc.text(arr[5].numberOfDaysAbsent,116.5,95);
  // doc.text(arr[6].numberOfDaysAbsent,141.5,95);
  // doc.text(arr[7].numberOfDaysAbsent,173.5,95);
  // doc.text(arr[8].numberOfDaysAbsent,198.5,95);
  // doc.text(arr[9].numberOfDaysAbsent,219.5,95);
  // doc.text(arr[10].numberOfDaysAbsent,243.5,95);
  // doc.text(arr[11].numberOfDaysAbsent,261.5,95);
  
  doc.setTextColor(0,0,0);
  doc.setFontSize(13);
  doc.text("1",16,125);
  doc.text("2",42,125);
  doc.text("3",68,125);
  doc.text("4",94,125);
  doc.text("5",120,125);
  doc.text("6",146,125);
  doc.text("7",172,125);
  doc.text("8",198,125);
  doc.text("9",224,125);
  doc.text("10",250,125);
  doc.text("11",276,125);
  doc.text("12",16,142);
  doc.text("13",42,142);
  doc.text("14",68,142);
  doc.text("15",94,142);
  doc.text("16",120,142);
  doc.text("17",146,142);
  doc.text("18",172,142);
  doc.text("19",198,142);
  doc.text("20",224,142);
  doc.text("21",250,142);
  doc.text("22",276,142);
  doc.text("23",16,161);
  doc.text("24",42,161);
  doc.text("25",68,161);
  doc.text("26",94,161);
  doc.text("27",120,161);
  doc.text("28",146,161);
  doc.text("29",172,161);
  doc.text("30",198,161);
  doc.text("31",224,161);
  
  
  MonthStaffInfo[0].s0 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s0 == null ? "- - - " : MonthStaffInfo[0].s0.toUpperCase(),16,131);
  
  MonthStaffInfo[0].s1 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s1 == null ? "- - - " : MonthStaffInfo[0].s1.toUpperCase(),42,131);
  
  MonthStaffInfo[0].s2 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s2 == null ? "- - - " : MonthStaffInfo[0].s2.toUpperCase(),66,131);
  
  MonthStaffInfo[0].s3 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s3 == null ? "- - - " : MonthStaffInfo[0].s3.toUpperCase(),93,131);
  
  MonthStaffInfo[0].s4 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s4 == null ? "- - - " : MonthStaffInfo[0].s4.toUpperCase(),120,131);
  
  MonthStaffInfo[0].s5 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s5 == null ? "- - - " : MonthStaffInfo[0].s5.toUpperCase(),146,131);
  
  MonthStaffInfo[0].s6 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s6 == null ? "- - - " : MonthStaffInfo[0].s6.toUpperCase(),172,131);
  
  MonthStaffInfo[0].s7 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s7 == null ? "- - - " : MonthStaffInfo[0].s7.toUpperCase(),198,131);
  
  MonthStaffInfo[0].s8 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s8 == null ? "- - - " : MonthStaffInfo[0].s8.toUpperCase(),224,131);
  
  MonthStaffInfo[0].s9 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s9 == null ? "- - - " : MonthStaffInfo[0].s9.toUpperCase(),248,131);
  
  MonthStaffInfo[0].s10 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s10 == null ? "- - - " : MonthStaffInfo[0].s10.toUpperCase(),277,131);
  
  MonthStaffInfo[0].s11 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s11 == null ? "- - - " : MonthStaffInfo[0].s11.toUpperCase(),17,148);
  
  MonthStaffInfo[0].s12 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s12 == null ? "- - - " : MonthStaffInfo[0].s12.toUpperCase(),43,148);
  
  MonthStaffInfo[0].s13 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s13 == null ? "- - - " : MonthStaffInfo[0].s13.toUpperCase(),67,148);
  
  MonthStaffInfo[0].s14 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s14 == null ? "- - - " : MonthStaffInfo[0].s14.toUpperCase(),95,148);
  
  MonthStaffInfo[0].s15 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s15 == null ? "- - - " : MonthStaffInfo[0].s15.toUpperCase(),121,148);
  
  MonthStaffInfo[0].s16 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s16 == null ? "- - - " : MonthStaffInfo[0].s16.toUpperCase(),147,148);
  
  MonthStaffInfo[0].s17 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s17 == null ? "- - - " : MonthStaffInfo[0].s17.toUpperCase(),173,148);
  
  MonthStaffInfo[0].s18 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s18 == null ? "- - - " : MonthStaffInfo[0].s18.toUpperCase(),199,148);
  
  MonthStaffInfo[0].s19 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s19 == null ? "- - - " : MonthStaffInfo[0].s19.toUpperCase(),225,148);
  
  MonthStaffInfo[0].s20 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s20 == null ? "- - - " : MonthStaffInfo[0].s20.toUpperCase(),249,148);
  
  MonthStaffInfo[0].s21 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s21 == null ? "- - - " : MonthStaffInfo[0].s21.toUpperCase(),275,148);
  
  MonthStaffInfo[0].s22 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s22 == null ? "- - - " : MonthStaffInfo[0].s22.toUpperCase(),17,167);
  
  MonthStaffInfo[0].s23 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s23 == null ? "- - - " : MonthStaffInfo[0].s23.toUpperCase(),43,167);
  
  MonthStaffInfo[0].s24 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s24 == null ? "- - - " : MonthStaffInfo[0].s24.toUpperCase(),67,167);
  
  MonthStaffInfo[0].s25 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s25 == null ? "- - - " : MonthStaffInfo[0].s25.toUpperCase(),95,167);
  
  MonthStaffInfo[0].s26 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s26 == null ? "- - - " : MonthStaffInfo[0].s26.toUpperCase(),121,167);
  
  MonthStaffInfo[0].s27 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s27 == null ? "- - - " : MonthStaffInfo[0].s27.toUpperCase(),147,167);
  
  MonthStaffInfo[0].s28 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s28 == null ? "- - - " : MonthStaffInfo[0].s28.toUpperCase(),173,167);
  
  MonthStaffInfo[0].s29 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s29 == null ? "- - - " : MonthStaffInfo[0].s29.toUpperCase(),199,167);
  
  MonthStaffInfo[0].s30 == "A" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
  doc.text(MonthStaffInfo[0].s30 == null ? "- - - " : MonthStaffInfo[0].s30.toUpperCase(),225,167);
  
  doc.setLineWidth(0.5);
  doc.line(83,111.5,182,111.5);
  doc.setTextColor(0,0,0);
  doc.setFont("Times new roman","bold");
  doc.setFontSize(15);
  doc.text("MONTHLY ATTENDANCE REPORT",85,110);
  
   doc.save("Attendance Report.pdf");  
   
  }

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        {/* <Grid container>
          <Grid xs={12}>
            <Box
              sx={{
                width: "80%",
                marginLeft: "2%",
                my: "2%",
                // border: "3px solid black",
              }}
            >
              <Stack direction="row">
                {allMonthStaffInfo.map((r) => (
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item sx={{ width: "100px" }}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        {r.month}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          borderBottom: "2px solid black",
                        }}
                      >
                        {r.total}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {r.totolPresent} | {r.totalAbsent}
                      </Typography>
                    </Item>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Grid>
        </Grid> */}
 <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid xs={12}>
            <Box
              sx={{
                width: "100%",
                // marginLeft: "2%",
                my: "2%",
                // border: "3px solid black",
              }}
            >
              <Stack direction="row">
                {allMonthStaffInfo.map((r) => (
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item sx={{ width: "100px" }}>
                      <Button
                        variant="contained"
                        value={r.month}
                        gutterBottom
                        sx={{
                          width:"100px",
                          borderRadius:"25px",
                         color: highlight === r.month ? "white" : "black",
                          backgroundColor: highlight === r.month ? "primary":"lightblue",
                          fontWeight:"bold",
                        }}
                        onClick={onSelectMonth}
                      >
                        {r.month}
                      </Button>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          borderBottom: "2px solid black",
                        }}
                      >
                        {r.total}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {r.totolPresent} | <span style={{color:"red"}}>{r.totalAbsent} </span>
                      </Typography>
                    </Item>
                  </Stack>
                ))}

{/* <Item sx={{ width: "150px" }}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        TOTAL FOR YEAR
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          borderBottom: "2px solid black",
                        }}
                      >
                        {total}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {present} |<span style={{color:"red"}}> {A} </span>
                      </Typography>
          </Item> */}
              </Stack>

              {/* <Item sx={{ width: "100px" }}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        TOTAL FOR YEAR
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          borderBottom: "2px solid black",
                        }}
                      >
                        {total}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {present} | {absent}
                      </Typography>
                    </Item> */}

            </Box>
          </Grid>
        </Grid>
      </Box>


        <Box
          display={"flex"}
          justifyContent={"center"}
          backgroundColor={"lightgray"}
        >
          <Typography variant="h6" fontWeight={"bold"}>
            {month} MONTH ATTENDANCE REPORT
          </Typography>
        </Box>
        {MonthStaffInfo.length == 0 ? (
          <div></div>
        ) : (
          <Grid container>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      1
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                     {MonthStaffInfo[0].s0 != null ? MonthStaffInfo[0].s0 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      2
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s1 != null ? MonthStaffInfo[0].s1 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      3
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s2 != null ? MonthStaffInfo[0].s2 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      4
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s3 != null ? MonthStaffInfo[0].s3 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      5
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s4 != null ? MonthStaffInfo[0].s4 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      6
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s5 != null ? MonthStaffInfo[0].s5 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      7
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s6 != null ? MonthStaffInfo[0].s6 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      8
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s7 != null ? MonthStaffInfo[0].s7 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      9
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s8 != null ? MonthStaffInfo[0].s8 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      10
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s9 != null ? MonthStaffInfo[0].s9 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      11
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s10 != null ? MonthStaffInfo[0].s10 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      12
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s11 != null ? MonthStaffInfo[0].s11 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      13
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s12 != null ? MonthStaffInfo[0].s12 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      14
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s13 != null ? MonthStaffInfo[0].s13 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      15
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s14 != null ? MonthStaffInfo[0].s14 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      16
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s15 != null ? MonthStaffInfo[0].s15 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      17
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s16 != null ? MonthStaffInfo[0].s16 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      18
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s17 != null ? MonthStaffInfo[0].s17 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      19
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s18 != null ? MonthStaffInfo[0].s18 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      20
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s19 != null ? MonthStaffInfo[0].s19 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      21
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s20 != null ? MonthStaffInfo[0].s20 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      22
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s21 != null ? MonthStaffInfo[0].s21 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      23
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s22 != null ? MonthStaffInfo[0].s22 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      24
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s23 != null ? MonthStaffInfo[0].s23 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      25
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s24 != null ? MonthStaffInfo[0].s24 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      26
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s25 != null ? MonthStaffInfo[0].s25 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      27
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s26 != null ? MonthStaffInfo[0].s26 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      28
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s27 != null ? MonthStaffInfo[0].s27 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      29
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s28 != null ? MonthStaffInfo[0].s28 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      30
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s29 != null ? MonthStaffInfo[0].s29 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={1.7}>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "2%",
                  my: "2%",
                  // border: "3px solid black",
                }}
              >
                <Stack direction="column" sx={{ marginX: 0.5 }}>
                  <Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      31
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {MonthStaffInfo[0].s30 != null ? MonthStaffInfo[0].s30 : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        )}
        <Box sx={{display:"flex", justifyContent:"space-evenly", mt:2}}>
      <Button
                variant="contained"
                size="large"
                color="error"
                onClick={genaratePDF}
                sx={{
                  borderRadius: "30px",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                PDF
              </Button>
          
        <Button
                variant="contained"
                size="large"
                onClick={()=>{window.print()}}
                sx={{
                  borderRadius: "30px",
                  backgroundColor:"black",
                  color:"white",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                  "&:hover":{backgroundColor:"black",color:"white"}
                }}
              >
                print
              </Button>

              <Button
                variant="contained"
                size="large"
                onClick={onLaunchPad}
                sx={{
                  borderRadius: "30px",
                  backgroundColor:"#ca424f",
                  color:"white",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                  "&:hover":{backgroundColor:"#ca424f",color:"white"}
                }}
              >
                exit
              </Button>
      </Box>
      </Box>
    </div>
  );
}
export default IndividualAttendence;
