import { styled } from "@mui/material/styles";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { AddStandarsService } from "../../../services/addStandardsService";
import { StandardDivision } from "../../../services/StandardDIvision";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { C } from "../../util/C";
// ----------------------------------------------------------------------
import { BuildSchoolExam } from "../../../services/examStandards";
import Img from "../../launchPad/student.jpg";
// ----------------Student Lists Import---------------------------
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { StudentService } from "../../../services/studentService";
import { Report } from "../../../services/ReportService";
import Modal from "@mui/material/Modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import jsPDF from "jspdf";
import { autoTable } from "jspdf-autotable";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  // padding: theme.spacing(1),
  textAlign: "center",
  // border: "1px solid black",
}));

const style = {
  position: "absolute",
  top: "100%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1350,
  height: 565,
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color:"white",
  },
}));
const ItemD = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#03dff9",
  "&:hover": {
    background: "#038da6",
    color:"white"
  },
}));
const Item1 = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
}));

const Item2 = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
}));

export default function StudentInformation() {
  const [Standard, setStandard] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  React.useEffect(() => {
    onstandardsLoad();
  }, []);

  const onstandardsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      C.c(response.data.data);
      setStandard(response.data.data);
    } catch (error) {
      C.c(error);
    }
  };
  const [Standardname, setStandardName] = React.useState();
  const [StandardID, setStandardID] = React.useState();
  const [DivisionName, setDivisionName] = React.useState();
  const [Division, setDivision] = React.useState([]);
  const [standardTest, updateStandardTest] = React.useState([]);
  const [StudentListAll, SetStudentListAll] = React.useState([]);
  const [studentSelected, setstudentSelected] = React.useState([]);
  const [studentSelectedContition, setstudentSelectedContition] =
    React.useState(false);
  const [studentList, SetStudentList] = React.useState([]);
  const [schoolName, setSchoolName] = React.useState(
    JSON.parse(localStorage.getItem("schoolDetails"))
  );

  async function onStandardSelect(e, index) {
    e.preventDefault();
    setStandardName(e.target.textContent);
    setStandardID(e.target.id);
    C.c(e.currentTarget.id);
    let highlightStandardList = [...Standard];
    highlightStandardList = highlightStandardList.map(
      ({ isSelected, ...highlightStandardList}) => highlightStandardList
    );
    setStandard(highlightStandardList);
    highlightStandardList[index]["isSelected"] = highlightStandardList[index]["iSSelected"] ? false : true;

    // let stdId = e.currentTarget.id
    let data = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await StandardDivision.StandardDivisionWiseStandard(
        e.currentTarget.id,
        data.yearName,
        data.yearID
      );
      C.c(response.data.data);
      setDivision(response.data.data);
    } catch (error) {
      C.c(error.message);
    }

    try {
      let response = await BuildSchoolExam.listBuildSchoolStandardTest(
        e.target.id
      );
      console.log(response.data.data);

      updateStandardTest(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }
  async function onSelectDivision(e, index) {
    console.log(e.currentTarget.id);
    let highlightDivision = [...Division];
    highlightDivision = highlightDivision.map(
      ({ isSelected, ...highlightDivision}) => highlightDivision
    );
    setDivision(highlightDivision);
    highlightDivision[index]["isSelected"] = highlightDivision[index]["isSelected"] ? false : true;

    let data = JSON.parse(localStorage.getItem("academicYear"));
    const StudentArry = [];
    C.c(StudentArry);
    try {
      let response = await StudentService.approveddivisionstudentListAll(
        data.yearName,
        e.currentTarget.id,
        StandardID,
       
      );
      C.c(response.data.data);
      SetStudentListAll(response.data.data.sort((a,b)=>a.studentName.localeCompare(b.studentName)));

      response.data.data.forEach((res) => {
        StudentArry.push({
          label: res.studentName + "--" + res.studentUSN,
          id: res.studentUSN,
        });
      });
      SetStudentList(StudentArry);
      C.c(SetStudentList);
    } catch (error) {
      C.c(error.message);
    }
  }

  const onStudentSelect = (e, v) => {
    const found = StudentListAll.find((element) => element.studentUSN == v.id);
    setstudentSelected(found);
    // found ?  setstudentSelectedContition(true) : setstudentSelectedContition(true)

    C.c(found);
  };

  let [info, setIfo] = React.useState({});

  const onInfoSelect = (e, v) => {
    v.forEach((rec) => {
      let name = rec.id;
      info[name] = true;
    });

    console.log(info);
  };

  const generatePDF = () => {
    const doc = new jsPDF('p', 'pt', 'a4');
    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(15)
    doc.text("STUDENT INFO",245,90)

    const year = JSON.parse(localStorage.getItem('academicYear'));
    doc.setFontSize(12)
    doc.text(year.yearName , 500,35)

    const text = `${schoolName.schoolNameOnDocs.toUpperCase()}`;
    const fontSize = 18;
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.setFontSize(fontSize);
    doc.text(text, pageWidth / 2, 40, { align: 'center' });

const img = `${schoolName.schoolLogoUrl}`;
doc.addImage(img, 'JPEG', 20, 10, 80, 70);

doc.addImage(studentSelected.studentProfileUrl, 'JPEG', 245,110,120,105);

    const data = [
      ['STUDENT ID:', info.usid === true ? studentSelected.studentUSN : "----"],
      // ['PHOTO:', info.photo === true ? studentSelected.photo : ""],
      ['NAME:', info.name === true ? studentSelected.studentName : "----"],
      ['DATE OF BIRTH:', info.date_of_birth === true ? studentSelected.studentDOB : "----"],
      ['BLOOD GROUP:', info.blood_group === true ? studentSelected.studentBloodGroup : "----"],
      // ['AADHAAR NO:', info.aadhaar_no === true ? studentSelected.studentAadhar : "----"],
      ['GENDER:', info.gender === true ? studentSelected.studentGender : ""],
      ['FATHER NAME:', info.father_name === true ? studentSelected.fatherName : "----"],
      ['FATHER PHONE NUMBER:', info.father_phone_no === true ? studentSelected.fatherMobNum : "----"],
      ['FATHER EMAIL:', info.father_email === true ? studentSelected.fatherEmailID : "----"],
      ['MOTHER NAME:', info.mother_name === true ? studentSelected.motherName : "----"],
      ['MOTHER PHONE NUMBER:', info.mother_phone_no === true ? studentSelected.motherMobNum : "----"],
      ['MOTHER EMAIL:', info.mother_email === true ? studentSelected.motherEmailID : "----"],
      ['GUARDIAN NAME', info.guardians_name === true ? studentSelected.guardianName : "----"],
      ['PRESENT ADDRESS:', info.present_address === true ? studentSelected.studentAddress : "----"],
      ['PREVIOUS SCHOOL:', info.previous_school === true ? studentSelected.studentPreviousSchool : "----"],
      ['ATTENDANCE DAYS:' , info.attendance_days === true ? studentSelected.sttendanceDays : "----"],
      ['TOTAL FEES:' , info.total_fees === true ? studentSelected.totalFees : "----"],
      ['FEES PAID:', info.fees_paid === true ? studentSelected.feesPaid : "---"],
      ['FEES CONSESSION:', info.fees_consession === true ? studentSelected.feesConssession : "----"]
    ];
    doc.setFontSize(10)
  // doc.text('Copyright: Technotharanga Solutions Pvt.ltd',210,800);
  doc.autoTable({
    startY: 240,
    //  head:[[" ", ""]], 
  body:data,
  headerStyles: {
    textAlign: 'center',
    marginLeft:"50px"
  } ,
  bodyStyles: {
    valign: "middle",
    halign: "center",
    // fontSize:12,
    // cellWidth:"10px"
  },
  theme:"grid"});
  doc.save('Student Info - '+`${studentSelected.studentUSN}`+'.pdf')
  }

let navigate = useNavigate();
const onLaunchpad = () => {
  navigate("/launchpad", {replace:true})
}

  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="reports" /> */}
      <Typography
        variant="h6"
        textAlign="center"
        sx={{
          textAlign: "center",
          backgroundColor: "lightblue",
          fontWeight: "bold",
          my: 1,
        }}
      >
        SELECT STANDARD, DIVISION TO  DOWNLOAD STUDENT'S SELECTED INFORMATION
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={8}>
            <Item2
              sx={{ background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)"}}
            >
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Standard.map((element, index) => (
                  <Grid item xs={2} sx={{ cursor: "pointer" }}>
                    <ItemS
                      id={element.standardID}
                      name={element.standardName}
                      onClick={(e) => {
                        onStandardSelect(e, index)
                      }}
                      className={element.isSelected ? "Selected" : ""}
                      sx={{ fontWeight: "bold" }}
                    >
                      {element.standardName}
                    </ItemS>
                  </Grid>
                ))}
              </Grid>
            </Item2>
          </Grid>
          <Grid item xs={4}>
            <Item2
               sx={{ background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)"}}
            >
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Division.map((element, index) => (
                  <Grid item xs={2} sx={{ cursor: "pointer" }}>
                    <ItemD
                      sx={{
                        fontWeight: "bold",
                      }}
                      onClick={(e)=> {
                        onSelectDivision(e, index)
                      }}
                      className={element.isSelected ? "selected" : ""} 
                      id={element.divisionName}
                    >
                      {" "}
                      {element.divisionName}
                    </ItemD>
                  </Grid>
                ))}
              </Grid>
            </Item2>
          </Grid>
        </Grid>
        {/* <Box sx={{ width: '50%', marginLeft: '25%', my: '2%', border: '1px solid black' }}> */}
        {/* <Stack direction='row'>
          <Typography variant="h6" fontWeight="bold" width="150px" sx={{ backgroundColor: "#F9FBE7", borderRight: '1px solid black', textAlign: 'center' }}>
            BOYS
          </Typography>
          <Typography variant="h6" fontWeight="bold" width="150px" sx={{ backgroundColor: "#F9FBE7", borderRight: '1px solid black', textAlign: 'center' }} >
            13
          </Typography>
          <Typography variant="h6" fontWeight="bold" width="150px" sx={{ backgroundColor: '#FFD180', borderRight: '1px solid black', textAlign: 'center' }}>
            GIRLS
          </Typography><Typography variant="h6" fontWeight="bold" width="150px" sx={{ backgroundColor: '#FFD180', borderRight: '1px solid black', textAlign: 'center' }} >
            14
          </Typography><Typography variant="h6" fontWeight="bold" width="150px" sx={{ backgroundColor: 'lightBlue', borderRight: '1px solid black', textAlign: 'center' }}>
            TOTAL
          </Typography><Typography variant="h6" fontWeight="bold" width="150px" sx={{ backgroundColor: 'lightBlue', textAlign: 'center' }}>
            27
          </Typography>
        </Stack> */}
        {/* </Box> */}
        {/* ----------------------------------------------------------------------------------------------------------------------------------------------------------- */}
        <Grid container spacing={2}>
          <Grid xs={4}>
            <Grid
              sx={{ width: "62%", mt: "17%", marginLeft: "30%", height: "55%" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={studentList}
                sx={{ width: 300 }}
                onChange={onStudentSelect}
                name="selectedStudent"
                renderInput={(params) => (
                  <TextField {...params} label="Select Students Here" />
                )}
              />
            </Grid>
          </Grid>
          <Grid xs={4}>
            <Box sx={{ width: "100%", mt: "17%", height:"55%" }}>
              <Grid container spacing={0}>
                <Grid xs={12}>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={StudentInfo}
                    getOptionLabel={(option) => option.title}
                    filterSelectedOptions
                    onChange={onInfoSelect}
                    disabled={studentSelectedContition}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Student's Informations"
                        placeholder="Select Information Here"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          </Grid>

          {/* --------------------------------------------------------------------------------------------------------------------- */}
          {/* <Grid conatainer>
            <Grid xs={12} sx={{ mt: 18}}> */}
            <Box sx={{display:"flex", justifyContent:"space-evenly", mt:4}}>
              <Button
                variant="contained"
                size="large"
                color="success"
                sx={{ borderRadius: "30px",fontSize:15,fontWeight:'bold' }}
                // disabled = {true}
                // disabled ={StudentListAll === [] ? false : true}
                onClick={handleOpen}
              >
                Generate Student's Info
              </Button>
              
              <Button variant="contained"
               onClick={onLaunchpad} 
               sx={{
                backgroundColor:"#ca424f",
                 fontSize:15,
                 fontWeight:'bold',
                 borderRadius:"30px",
                 width:'120px',
                 ml:5,
                 "&:hover":{backgroundColor:"#ca424f"},
                 }}>
                  exit
                  </Button>
                  </Box>
            {/* </Grid>
          </Grid> */}
       

        <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                <Box sx={{position: 'sticky', top: -33, zIndex: 1000,width:"100%"}}>

<Box sx={{float:"right",width:"100%",backgroundColor:"lightblue"}}> 
<Button variant="contained" color="error" sx={{float:"right"}} 
onClick={()=>{
setOpen(false);
setIfo({});
}}
>
close</Button>
<Typography variant="h5" fontWeight="bold" textAlign={"center"}sx={{ml:8}} >
ALL INFORMATION OF STUDENT
</Typography>            
  
</Box>
</Box>
                  <Box>
                    {/* <IconButton
                      color="error"
                      onClick={() => {
                        setOpen(false);
                        setIfo({});
                      }}
                      aria-label="delete"
                      size="large"
                      sx={{ float: "right", backgroundColor:"blue" }}
                    >
                      <HighlightOffIcon fontSize="inherit" />
                    </IconButton> */}
                    
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent:"center",
                       mt:6
                      }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src={studentSelected.studentProfileUrl}
                       
                        variant="square"
                        sx={{
                          width: 159,
                          height: 210,
                          mx: 5,
                        }}
                       
                      />
                    </Box>

                        <Item sx={{ textAlign: "center" }}>
                          {/* <Typography
                            variant="h5"
                            fontWeight="bold"
                            textAlign="center"
                            sx={{
                              textAlign: "center",
                              backgroundColor: "lightblue",
                              mt: 2,
                            }}
                          >
                            ALL INFORMATION OF STUDENT
                          </Typography> */}

                          {/* <Box
                      sx={{
                        border:"2px solid red",
                        display: "flex",
                        textAlign:"center",
                        // justifyContent:"center",
                       mt:2
                      }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src={studentSelected.studentProfileUrl}
                       
                        variant="square"
                        sx={{
                          width: 159,
                          height: 210,
                          mx: 5,
                        }}
                       
                      />
                    </Box> */}

                          <Typography
                            textAlign="center"
                            //border="1px solid black"
                           sx={{p:2}}
                            display={info.usid === true ? "block" : "None"}
                          >
                            <b> STUDENT_ID : </b>
                            {info.usid === true
                              ? studentSelected.studentUSN
                              : "Not found"}
                          </Typography>
                       
                              <Typography
                                textAlign="center"
                              sx={{p:2}}
                                display={info.name === true ? "block" : "None"}
                              >
                                <b>NAME :</b>
                                {info.name === true
                                  ? studentSelected.studentName
                                  : "Not found"}
                              </Typography>

                              <Typography
                                textAlign="center"
                                sx={{ p: 2}}
                                display={
                                  info.date_of_birth === true ? "block" : "None"
                                }
                              >
                                <b>DATE OF BIRTH :</b>
                                {info.date_of_birth === true
                                  ? studentSelected.studentDOB
                                  : "Not found"}
                              </Typography>

                              <Typography
                                textAlign="center"
                                sx={{ p: 2 }}
                                display={
                                  info.blood_group === true ? "block" : "None"
                                }
                              >
                                <b>BLOOD GROUP :</b>
                                {info.blood_group === true
                                  ? studentSelected.studentBloodGroup
                                  : "Not found"}
                              </Typography>

                              <Typography
                                textAlign="center"
                                sx={{ p: 2 }}
                                display={
                                  info.addhaar_no === true ? "block" : "None"
                                }
                              >
                                <b>AADHAAR NO :</b>
                                {info.addhaar_no === true
                                  ? studentSelected.StudentAadhar
                                  : "Not found"}
                              </Typography>

                              <Typography
                                textAlign="center"
                                sx={{ p: 2}}
                                display={
                                  info.gender === true ? "block" : "None"
                                }
                              >
                                <b>GENEDER :</b>
                                {info.gender === true
                                  ? studentSelected.studentGender
                                  : "Not found"}
                                  </Typography>
                             
                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.father_name === true ? "block" : "None"
                              }
                            >
                              <b>FATHER NAME :</b>
                              {info.father_name === true
                                ? studentSelected.fatherName
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.father_phone_no === true ? "block" : "None"
                              }
                            >
                              <b>FATHER PHONE NUMBER :</b>
                              {info.father_phone_no === true
                                ? studentSelected.fatherMobNum
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.father_email === true ? "block" : "None"
                              }
                            >
                              <b>FATHER EMAIL :</b>
                              {info.father_email === true
                                ? studentSelected.fatherEmailID
                                : "Not found"}
                            </Typography>
                        
                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.mother_name === true ? "block" : "None"
                              }
                            >
                              <b>MOTHER NAME :</b>
                              {info.mother_name === true
                                ? studentSelected.motherName
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.mother_phone_no === true ? "block" : "None"
                              }
                            >
                              <b>MOTHER PHONE NUMBER :</b>
                              {info.mother_phone_no === true
                                ? studentSelected.motherMobNum
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2}}
                              display={
                                info.mother_email === true ? "block" : "None"
                              }
                            >
                              <b>MOTHER EMAIL :</b>
                              {info.mother_email === true
                                ? studentSelected.motherEmailID
                                : "Not found"}
                            </Typography>
                         
                              <Typography
                                textAlign="center"
                                sx={{ p: 2 }}
                                display={
                                  info.guardians_name === true
                                    ? "block"
                                    : "None"
                                }
                              >
                                <b>GUARDIAN'S NAME :</b>
                                {info.guardians_name === true
                                  ? studentSelected.guardianName
                                  : "Not found"}
                              </Typography>

                              <Typography
                                textAlign="center"
                                sx={{ p: 2 }}
                                display={
                                  info.present_address === true
                                    ? "block"
                                    : "None"
                                }
                              >
                                <b>PRESENT ADDRESS :</b>
                                {info.present_address === true
                                  ? studentSelected.studentAddress
                                  : "Not found"}
                              </Typography>

                              <Typography
                                textAlign="center"
                                sx={{ p: 2 }}
                                display={
                                  info.previous_school === true
                                    ? "block"
                                    : "None"
                                }
                              >
                                <b>PREVIOUS SCHOOL :</b>
                                {info.previous_school === true
                                  ? studentSelected.studentPreviousSchool
                                  : "Not found"}
                              </Typography>
                         
                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.date_of_admission === true
                                  ? "block"
                                  : "None"
                              }
                            >
                              <b>DATE OF ADMISSION :</b>
                              {info.date_of_admission === true
                                ? studentSelected.dateOfAdmission
                                : "------"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.attendance_days === true ? "block" : "None"
                              }
                            >
                              <b>ATTENDANCE DAYS :</b>
                              {info.attendance_days === true
                                ? studentSelected.attendanceDays
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2}}
                              display={
                                info.total_fees === true ? "block" : "None"
                              }
                            >
                              <b>TOTAL FEES :</b>
                              {info.total_fees === true
                                ? studentSelected.totalFees
                                : "Not found"}
                            </Typography>
                          
                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.fees_paid === true ? "block" : "None"
                              }
                            >
                              <b>FEES PAID :</b>
                              {info.fees_paid === true
                                ? studentSelected.feesPaid
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2}}
                              display={
                                info.fees_consession === true ? "block" : "None"
                              }
                            >
                              <b>FEES CONSSESSION :</b>
                              {info.fees_consession === true
                                ? studentSelected.feesConssession
                                : "Not found"}
                            </Typography>
                         
                            {/* <Typography
                              textAlign="center"
                              sx={{ p: 2, ml: 10 }}
                              display={info.rte === true ? "block" : "None"}
                            >
                              <b>RTE NUMBER :</b>
                              {info.rte === true
                                ? studentSelected.rteNumber
                                : "Not found"}
                            </Typography> */}

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={info.sts === true ? "block" : "None"}
                            >
                              <b>GOVERMENT STS NUMBER :</b>
                              {info.sts === true
                                ? studentSelected.govtSTSNumber
                                : "----"}
                            </Typography>

                          <Typography
                            textAlign="center"
                            sx={{ p: 2 }}
                            display={
                              info.scholarship_no === true ? "block" : "None"
                            }
                          >
                            <b>SCHOLARSHIP NUMBER :</b>
                            {info.scholarship_no === true
                              ? studentSelected.scholarShipNumber
                              : "-----"}
                          </Typography>
                        </Item>

                        <Button
                          variant="contained"
                          color="error"
                          onClick={generatePDF}
                          sx={{ width: "120px", fontSize:15,fontWeight:'bold', borderRadius:"30px",ml:65 }}
                        >
                          pdf
                        </Button>

                        {/* <Button variant="contained"
                         color="success" 
                         sx={{ fontSize:15,
                         fontWeight:'bold',
                         borderRadius:"30px",
                         width:"120px",
                         ml:10 }}>
                         Excel
                         </Button> */}

                        {/* </Grid> */}
                      {/* </Grid>
                    </Grid> */}
                  </Box>
                </Box>
              </Modal>

      </Box>
    </React.Fragment>
  );
}
const StudentInfo = [

  { title: "USID", id: "usid" },
  { title: "NAME", id: "name" },
  { title: "DATE OF BIRTH", id: "date_of_birth" },
  { title: "BLOOD GROUP", id: "blood_group" },
  // { title: "AADHAAR NO", id: "addhaar_no" },
  { title: "GENDER", id: "gender" },
  { title: "FATHER NAME", id: "father_name" },
  { title: "FATHER PHONE NUMBER", id: "father_phone_no" },
  { title: "FATHER EMAIL", id: "father_email" },
  { title: "MOTHER NAME", id: "mother_name" },
  { title: "MOTHER PHONE NUMBER", id: "mother_phone_no" },
  { title: "MOTHER EMAIL", id: "mother_email" },
  { title: "GUARDIAN'S NAME", id: "guardians_name" },
  { title: "GUARDIAN'S PHONE NUMBER", id: "guardians_phone_no" },
  { title: "PRESENT ADDRESS ", id: "present_address" },
  { title: "PREVIOUS SCHOOL", id: "previous_school" },
  { title: "DATE OF ADMISSION", id: "date_of_admission" },
  { title: "ATTENDANCE DAYS", id: "attendance_days" },
  { title: "TOTAL FEES", id: "total_fees" },
  { title: "FEES PAID", id: "fees_paid" },
  { title: "FEES CONSSESSION", id: "fees_consession" },
  // { title: "GOVERMENT STS NUMBER ", id: "sts" },
  // { title: "SCHOLARSHIP NUMBER", id: "scholarship_no" },
];
