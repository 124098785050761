import React from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Modal,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DesignationService } from "../../../services/DesignationService";
import { StudentInfoService } from "../../../services/StudentInfo/StudentList";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import { C } from "../../util/C";
import { User } from "../../../services/User";
import { DataGrid,GridToolbar, } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import axios from "axios";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid red",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};

const UserEntry = () => {
  const [designationVal, setDesignationVal] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [openSucess, setSucess] = React.useState(false);
  const [studentsList, setStudentList] = React.useState([]);
  const handleSucessOpen = () => setSucess(true);

  const columns = [
    {
      field: "id",
      headerName: "NO",
      flex: 0.3,
      headerAlign: "center",
      editable: false,
      sortable: false,
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: " NAME",
      flex: 1,
      headerAlign: "center",
      editable: false,
      sortable: false,
      align: "center",
      // valueFormatter: (params) => params.value.toUpperCase(),
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NAME"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "std",
      headerName: " STD/DIV",
      flex: 0.6,
      headerAlign: "center",
      align: "center",
      type: "number",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"STD/DIV"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
      sortable: false,
    },
    {
      field: "father",
      headerName: " FATHER",
      flex: 1,
      editable: true,
      headerAlign: "center",
      align: "center",
      sortable: false,
      // valueFormatter: (params) => params.value.toUpperCase(),
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"FATHER"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "contact",
      headerName: " CONTACT.NO",
      flex: 0.5,
      editable: true,
      headerAlign: "center",
      align: "center",
      sortable: false,
      // valueFormatter: (params) => params.value.toUpperCase(),
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"CONTACT.NO"}</strong>,
      headerClassName: "super-app-theme--header",
    },
  ];
  
 
  React.useEffect(() => {
    // onDesignationload();
    onStudentsListLoad();
  }, []);

  let navigate = useNavigate();
  const onLaunchpad = () => {
    navigate("/launchpad", { replace: true });
  };

  const onStudentsListLoad = async () => {
    try {
      // alert(data.yearName);
      // let response = await StudentInfoService.studentInfoList();
     let response = await axios.get("http://139.59.11.130:6561/api/v1/student");
      // let response = await axios.get("http://localhost:6561/api/v1/student");
      console.log(response.data.data);
      setStudentList(
        response.data.data.filter((student) => student.approveStatus == true)
      );
      // setLoading(false);
      let studarr = [];
      response.data.data.forEach((rec,i)=>{
        studarr.push({
          id: rec.studentUSN,
          name: rec.studentName,
          ssid: rec.studentID,
          std: rec.standardName+"-"+rec.divisionName,
          father: rec.fatherName,
          contact:rec.fatherMobNum
        });
      });
      console.log(studarr);
      setRows(studarr);
    } catch (error) {
      console.log(error);
    }

  };
 
  const studID = (params)=>{
    console.log(params.row);
  }
  // * users login
  async function onCreateUser(event) {
    event.preventDefault();
    // debugger;
    const data = new FormData(event.currentTarget);
    let yearData = JSON.parse(localStorage.getItem("academicYear"));

    if (data.get("password") == data.get("re_password")) {
      // console.log();
      const found = rows.find((a)=>a.id == data.get("email"));
      console.log(found);
      let userObj = {
        designation: data.get("designation"),
        designationID: found.ssid,
        userEmailID: data.get("email"),
        userName: data.get("userName"),
        userPassword: data.get("password"),
        yearID: yearData.yearID,
        yearName: yearData.yearName,
      };

      try {
        let response = await User.UserRegister(userObj);
        setTimeout(()=>{
          setSucess(false);
        },3000);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("invalid password");
    }

    // if (pwd == repwd) {
    // }
  }


  // * validation
  const [name, setName] = React.useState();
  function onNameEntry(e) {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z ]+/gi, "")
      : "";

    // if (e.target.value !== value) {
    //   e.target.value = value;
    // }

    // if (typeof callback === "function") {
    //   return callback(value);
    // }
  }

  return (
    <div>
      {/* <LaunchpadTopBar id="settings" /> */}

    


      <Box
        component="form"
        sx={{
          // margin: '8px 10px 0 10px',
          mt:4,
          ml:10,
          mr:10,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        onSubmit={onCreateUser}
      >
        <Grid container spacing={2}>
          <Grid item xs={4.5} >
            <Grid container spacing={2}>
              <Grid m={1} xs={12}>
                <TextField
                  required
                  id="userName"
                  name="userName"
                  label="USER NAME"
                  variant="filled"
                  sx={{
                    marginX: "10px",
                  }}
                />
                <TextField
                  required
                  id="email"
                  name="email"
                  label="EMAIL"
                  variant="filled"
                  sx={{
                    marginX: "10px",
                  }}
                />
              </Grid>
              <Grid m={1} xs={12}>
                <FormControl required sx={{ marginX: "10px", minWidth: 460 }}>
                  <InputLabel>DESIGNATION</InputLabel>
                  <Select
                    required
                    labelId="designationID"
                    id="designation"
                    label="Designation"
                    variant="filled"
                    onChange={(e) => setDesignationVal(e.target.value)}
                    value={designationVal}
                    name="designation"
                  >
                    {/* <MenuItem value="administrator">ADMINISTRATOR</MenuItem> */}
                    {/* <MenuItem value="principal">PRINCIPAL</MenuItem> */}
                    {/* <MenuItem value="vice_principal">
                      {" "}
                      VICE - PRINCIPAL
                    </MenuItem> */}
                    {/* <MenuItem value="teacher">TEACHER</MenuItem> */}
                    <MenuItem value="student">STUDENT</MenuItem>
                    {/* <MenuItem value="librarian">LIBRARIAN</MenuItem> */}
                    {/* <MenuItem value="accountant">ACCOUNTANT</MenuItem> */}
                    {/* <MenuItem value="non_teacher"> NON-TEACHER</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid m={1} xs={12}>
                <TextField
                  required
                  id="password"
                  name="password"
                  label="PASSWORD"
                  variant="filled"
                  sx={{
                    marginX: "10px",
                  }}
                  type="password"
                />
                <TextField
                  required
                  id="password"
                  name="re_password"
                  label="CONFIRM PASSWORD"
                  variant="filled"
                  sx={{
                    marginX: "10px",
                  }}
                  type="password"
                />
              </Grid>
              {/* <Grid xs={12}> */}
               

                  <Modal
            open={openSucess}
            // onClose={() => setSucess(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div sx={style}>
              <SucessModal
                text="User Created Successfully"
                name="User Created Successfully"
              />
            </div>
          </Modal>

              {/* </Grid> */}
            </Grid>
          </Grid>

          <Grid item xs={7}>
          <Box sx={{ height: 400, width: '100%',
          "& .super-app-theme--header": {
                  backgroundColor: "#c0c0c0",
                  fontWeight: "bold",
                  fontSize: "15px",
                  border: "1px solid white",
                }, }}>
      <DataGrid
        rows={rows}
        columns={columns}
        onCellClick={studID}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableColumnSelector
        disableColumnMenu
        disableColumnFilter
        disableDensitySelector
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
      />
    </Box>
          </Grid>
          
        </Grid>

        <Box sx={{ display:"flex", justifyContent:"space-evenly",mt:2,width:"100%" }}>
                  <Button variant="contained" type="submit"
                  sx={{
                    fontSize: 15,
                    fontWeight: "bold",
                    borderRadius: "30px",
                    width: "180px",
                  }}
                  onClick={handleSucessOpen}
                  >
                    Create User
                  </Button>

                  <Button
            variant="contained"
            size="large"
            onClick={onLaunchpad}
            sx={{
              backgroundColor: "#ca424f",
              fontSize: 15,
              fontWeight: "bold",
              borderRadius: "30px",
              width: "120px",
              "&:hover": { backgroundColor: "#ca424f" },
            }}
          >
            exit
          </Button>
                  
                </Box>
                {/* <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        // checkboxSelection
        disableRowSelectionOnClick
      />
    </Box> */}

      </Box>


    </div>
  );
};

export default UserEntry;

// {

//     "designation": "string", 1
//     "designationID": "string",
//     "userEmailID": "string", 2
//     "userID": "string",
//     "userName": "string", 3
//     "userPassword": "string",
//     "yearID": "string",
//     "yearName": "string"
//   }
