import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Fade, Modal, Stack, Typography } from "@mui/material";
import { TextField } from "@mui/material";
import { AddStandarsService } from "../../../services/addStandardsService";
import { StandardSubjectService } from "../../../services/StandardSubject";
import { BuildSchoolExam } from "../../../services/examStandards";
import { useNavigate } from "react-router-dom"; //for browser back button navigation handling
import Topbar from "../../MainTopbar/Topbar";
import Backdrop from "@mui/material/Backdrop";
import { BuildSchoolControll } from "../../../services/buildSchoolControllService";
import SucessModal from "./popup/sucessPopUp";
import AlertModal from "./popup/alertPopUp";
import DeleteModal from "./popup/deletePopUp";

// import {Typography} from '@mui/material';
//import TextField from '@mui/material/TextField';

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  background: "transparent",

  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  height: 400,
  width: "100%",
  border: "2px solid yellow",
  overflowY: "scroll",
  color: theme.palette.text.secondary,
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};

export default function ExamAdding() {
  //browser back button redirection to dashboard
  const Navigate = useNavigate();
  const [finishStatus, setfinishStatus] = React.useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to go back to Dashboard?")) {
        setfinishStatus(true);
        // your logic
        Navigate("/dashborad");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  React.useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  // * =======================window Pop=====================================
  // *delete
  const [openDelete, setDelete] = React.useState(false);
  const [delVal, setDelVal] = React.useState("");
  const handleDeleteOpen = () => setDelete(true);
  const handleDeleteClose = () => setDelete(false);

  // *alert
  const [openAlert, setAlert] = React.useState(false);
  const [alertVal, setAlertVal] = React.useState("");
  const handleAlertOpen = () => setAlert(true);
  const handleAlertClose = () => setAlert(false);

  // *sucess
  const [openSucess, setSucess] = React.useState(false);
  const [sucessVal, setSucessVal] = React.useState("");
  const handleSucessOpen = () => setSucess(true);
  const handleSucessClose = () => setSucess(false);

  // * =======================window Popup end=====================================

  // back button logic ends

  // const [examStandardList, updateExamStandardList] = React.useState([]);
  // const [examSubjectList, updateExamSubjectList] = React.useState([]);
  const [standardListAll, updateStandard] = React.useState([]);
  const [examSelectedSub, updateExamSelectedSub] = React.useState([]);
  const [examSelectedSubID, updateExamSelectedSubID] = React.useState([]);
  const [examTestList, updateExamTestList] = React.useState([]);
  // const [examGradeNames, updateExamGradeNames] = React.useState([]);

  const [examGradeValues, updateExamGradeValues] = React.useState([
    { key1: "MAX", value: "" },
    { key1: "MIN", value: "" },
    { key1: "F", value: "" },
    { key1: "C", value: "" },
    { key1: "C+", value: "" },
    { key1: "B", value: "" },
    { key1: "B+", value: "" },
    { key1: "A", value: "" },
    { key1: "A+", value: "" },
  ]);

  const [loop, onLoop] = React.useState([]);

  React.useEffect(() => {
    // onYearLoad();
    onStandardLoad();
    onLoadBuildSchool();
    // onSubjectLoad();
    // onStandardSubjectLoad();
  }, []);
  const onStandardLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    console.log(data);
    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      console.log(response.data.data);
      updateStandard(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // let _standardList = [...standardListAll];
  let [selectedStandardID, updateSelectedStandardID] = React.useState("");
  let [selectedStandardName, updateSelectedStandardName] = React.useState("");

  async function onStandardSelect(e, ID, Name) {
    e.preventDefault();
    // console.log(e.target.value);
    console.log(ID);
    console.log(Name);
    updateSelectedStandardName(Name);
    updateSelectedStandardID(ID);

    // updateStandard(x);
    updateExamSelectedSub(e.currentTarget.value);
    updateExamSelectedSubID(e.currentTarget.name);
    // console.log(examSelectedSubID);
    // console.log(examSelectedSub);

    let currentTarget = e.currentTarget.value;

    let data = JSON.parse(localStorage.getItem("academicYear"));

    let response =
      await StandardSubjectService.findSubjectListByStandardIDYearID(
        data.yearName,
        e.currentTarget.value
      );
    console.log(response.data.data);
    response.data.data.sort(function (a, b) {
      return a.subjectOrder - b.subjectOrder;
    });
    onLoop(response.data.data);
    try {
      let response1 = await BuildSchoolExam.showBuildSchoolStandardTest(
        currentTarget,
        data.yearName
      );
      console.log(response1);
      response1.data.data.sort(function (a, b) {
        return a.testOrder - b.testOrder;
      });

      // Print the sorted array
      console.log(response1.data.data);
      updateExamTestList(response1.data.data);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }

    // examGradeValues
    setTestValue([]);
  }

  // let _testList = [...examTestList];

  let [selectedTestID, updateSelectedTestID] = React.useState("");

  function change(event, ID) {
    console.log(ID);
    updateExamGradeValues([
      { key1: "MAX", value: "" },
      { key1: "MIN", value: "" },
      { key1: "F", value: "" },
      { key1: "C", value: "" },
      { key1: "C+", value: "" },
      { key1: "B", value: "" },
      { key1: "B+", value: "" },
      { key1: "A", value: "" },
      { key1: "A+", value: "" },
    ]);
    updateSelectedTestID(ID);
    // updateSelectedStandardID(ID);
  }

  let [selectedSubjectID, updateSelectedSubjectID] = React.useState("");
  let [selectedSubjectName, updateSelectedSubjectName] = React.useState("");
  function onSubjectSelect(event, ID, Name) {
    setTestValue([]);
    console.log(ID);
    updateExamGradeValues([
      { key1: "MAX", value: "" },
      { key1: "MIN", value: "" },
      { key1: "F", value: "" },
      { key1: "C", value: "" },
      { key1: "C+", value: "" },
      { key1: "B", value: "" },
      { key1: "B+", value: "" },
      { key1: "A", value: "" },
      { key1: "A+", value: "" },
    ]);
    updateSelectedSubjectID(ID);
    updateSelectedSubjectName(Name);
    // updateSelectedStandardID(ID);
  }

  let [testvalue, setTestValue] = React.useState([]);
  let [testname, setTestName] = React.useState([]);
  let [testStatus, setTestStatus] = React.useState();

  async function ontestValues(e, testId, testName) {
    // console.log(testName.toUpperCase());
    setTestName(testName.toUpperCase());
    let data = JSON.parse(localStorage.getItem("academicYear"));

    // console.log(selectedStandardID);
    // console.log(selectedSubjectID);
    // console.log(testName);
    try {
      let response = await BuildSchoolExam.getStandardSubjectTestValue(
        selectedStandardID,
        selectedSubjectID,
        testName,
        data.yearName
      );
      console.log(response.data.data);
      if (response.data.data.length == 0) {
        setTestStatus(false);
      } else {
        setTestStatus(true);
      }
      setTestValue(response.data.data);
    } catch (error) {
      console.log("error: " + error);
    }
  }

  const saveExamScores = async (e) => {
    let testScores = [];
    let data = JSON.parse(localStorage.getItem("academicYear"));

    // console.log(selectedStandardID, selectedSubjectID, selectedTestID);

    const standardFound = standardListAll.find(
      (element) => element.standardID == selectedStandardID
    );
    const subjectFound = loop.find(
      (element) => element.standardSubjectID == selectedSubjectID
    );
    const testFound = examTestList.find(
      (element) => element.standardTestID == selectedTestID
    );

    console.log(examGradeValues);

    if (!standardFound || !subjectFound || !testFound) {
      alert("Please select the proper values");
      return;
    }

    examGradeValues.forEach(async (element) => {
      testScores.push({
        standardID: standardFound.standardID,
        standardName: standardFound.standardName,
        // standardTestValueID: selectedTestID,
        standardTestkey: element.key1,
        standardTestvalue: element.value,
        subjectID: selectedSubjectID,
        subjectName: subjectFound.subjectName,
        testTitleName: testFound.testTitleName,
        yearID: data.yearID,
        yearName: data.yearName,
      });
    });

    console.log(testStatus);

    if (testStatus) {
      testvalue.forEach((rec) => {
        testScores.forEach((r) => {
          if (rec.standardTestkey == r.standardTestkey) {
            r.standardTestValueID = rec.standardTestValueID;
          }
        });
      });
      console.log("true");
      console.log(testScores);
      try {
        let response = await BuildSchoolExam.showBuildSchoolStandardTestScore(
          testScores
        );
        setSucessVal("Test Scores Added Successfully!");
        handleSucessOpen();
      } catch (error) {
        console.log(error);
        alert("Failure");
      }
    } else {
      try {
        let response = await BuildSchoolExam.showBuildSchoolStandardTestScore(
          testScores
        );
        setSucessVal("Test Scores Added Successfully!");
        handleSucessOpen();
      } catch (error) {
        console.log(error);
        alert("Failure");
      }
      console.log("false");
      console.log(testScores);
    }

    setTimeout(() => {
      handleSucessClose();
    }, 2000);
  };

  const toDashboard = (event) => {
    Navigate("/dashborad", { replace: true });
  };

  function updateFieldChanged(e, index, cell) {
    console.log(index);
    console.log(e.currentTarget.value);
    //  e.preventDefault();
    const newArr = examGradeValues.map((item, i) => {
      if (cell == "key1") {
        if (index === i) {
          item["key1"] = e.target.value;
          return { ...item };
        } else {
          return item;
        }
      } else {
        if (index === i) {
          item["value"] = e.target.value;
          return { ...item };
        } else {
          return item;
        }
      }
    });
    console.log(newArr);
    updateExamGradeValues(newArr);
  }

  const [open, setOpen] = React.useState(false);

  const nextPage = (e) => {
    // navigate("/dashborad", { replace: true });
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  let navigate = useNavigate();

  const [status, setStatus] = React.useState();

  const onLoadBuildSchool = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolControll.buildSchoolStatusListAll(
        data.yearName
      );
      // console.log(response.data.data[0]);
      setStatus(response.data.data[5].buildSchoolStatus);
      setSixId(response.data.data[5].buildSchoolID);
      setSevenId(response.data.data[6].buildSchoolID);
    } catch (error) {
      console.error(error);
    }
  };
  const [six, setSix] = React.useState();
  const [sixId, setSixId] = React.useState();
  const [seven, setSeven] = React.useState();
  const [sevenId, setSevenId] = React.useState();

  const onBuildSchool = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: sixId,
        buildSchoolName: "exam",
        buildSchoolStatus: "true",
        buildSchoolStep: "six",
        buildSchoolYear: data.yearName,
        yearName: data.yearName,
      });
    } catch (error) {
      alert("Error creating buildSchool " + error);
    }
    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: sevenId,
        buildSchoolName: "buildschoolcomplete",
        buildSchoolStatus: "true",
        buildSchoolStep: "seven",
        buildSchoolYear: data.yearName,
        yearName: data.yearName,
      });
    } catch (error) {
      alert("Error creating buildSchool " + error);
    }
    console.log("onBuildSchool");
    navigate("/dashborad", { replace: true });
  };
  const onlyCharaters = (e, callback) => {
    const value = e.target.value ? e.target.value.replace(/[^0-9]+/gi, "") : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };
  const onBack = () => {
    navigate("/exammarkssetup", { replace: true });
  };

  return (
    <div>
      <Topbar
        text={
          "Kindly Proceed to Step 6 Exam marks creation has two steps 1. Information about exams 2. Information about min max marks and grades"
        }
      />
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Item sx={{ color: "black" }}>
              {" "}
              {/* SELECT STANDARDS{" "} */}
              {standardListAll.map((element, index) => (
                <Button
                  size="small"
                  key={element.standardID}
                  value={element.standardID}
                  name={element.standardName}
                  sx={{
                    position: "relative",
                    border: "2px solid #aaecac",
                    minWidth: "100%",
                    height: 30,
                    color: "Black",
                    fontWeight: "bold",
                    marginBottom: 0.2,
                    backgroundColor: "#aaecac",
                    "&:hover": { background: "#035e06", color: "white" },
                  }}
                  // onClick={(event)=>{change(event, element.standardID)}}
                  className={
                    selectedStandardID == element.standardID
                      ? "standardSelected"
                      : ""
                  }
                  onClick={(event) => {
                    onStandardSelect(
                      event,
                      element.standardID,
                      element.standardName,
                      index
                    );
                  }}
                >
                  {element.standardName}
                </Button>
              ))}
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item
              sx={{
                position: "relative",
                marginLeft: "10%",
                float: "left",
                // height: "98%",
                width: 300,
              }}
            >
              {" "}
              <Button
                size="small"
                sx={{
                  position: "relative",
                  border: "2px solid #aaecac",
                  minWidth: "100%",
                  height: 30,
                  color: "Black",
                  fontWeight: "bold",
                  marginBottom: 3,
                  background: "#aaecac",
                }}
                id="selectedSubjectExamadding"
                name={examSelectedSub}
                value={examSelectedSubID}
                // onChange={Demo}
              >
                {examSelectedSubID}
              </Button>
              <Stack direction="column">
                {loop.map((element) => (
                  <Button
                    size="small"
                    sx={{
                      position: "relative",
                      border: "2px solid #37bc98",
                      minWidth: "50%",
                      borderRadius: 2,
                      height: 30,
                      color: "Black",
                      // fontWeight:"bold",
                      marginBottom: 0.2,
                      mt:1,
                      background: "#37bc98",
                      "&:hover": { background: "#03946d" },
                    }}
                    className={
                      selectedSubjectID == element.standardSubjectID
                        ? "standardSelected"
                        : ""
                    }
                    onClick={(event) => {
                      onSubjectSelect(
                        event,
                        element.standardSubjectID,
                        element.subjectName
                      );
                    }}
                  >
                    <Typography sx={{ mx: 2, fontWeight: "bold" }}>
                      {element.subjectName}
                    </Typography>
                  </Button>
                ))}
              </Stack>
              <Button
                size="small"
                sx={{
                  position: "relative",
                  border: "2px solid blue",
                  minWidth: "50%",
                  borderRadius: 2,
                  height: 55,
                  color: "Black",
                  fontWeight: "bold",
                  marginTop: 1,
                  marginLeft: 0.2,
                }}
              >
                {" "}
                SELECT SUBJECT TO ENTER MARKS AND GRADES{" "}
              </Button>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item
              sx={{
                position: "relative",
                float: "left",
                width: 650,
                fontWeight: 800,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  backgroundColor: "lightblue",
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                CLICK SELECT THE STANDARD, SUBJECT AND ENTER MARKS AND GRADES
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  mt:1
                }}
              >
                {examTestList.map((element, index) => (
                  <Button
                    size="small"
                    sx={{
                      position: "relative",
                      border: "2px solid #5485e594",
                      minWidth: "10%",
                      fontWeight: "bold",
                      borderRadius: 2,
                      height: 30,
                      color: "Black",
                      marginBottom: 0.2,
                      ml: 1,
                      background: "#5485e594",
                      "&:hover": {
                        background: "#3661b494",
                      },
                    }}
                    onClick={(event) => {
                      change(event, element.standardTestID);
                      ontestValues(
                        event,
                        element.standardTest,
                        element.testTitleName
                      );
                    }}
                    className={
                      selectedTestID == element.standardTestID
                        ? "standardSelected"
                        : ""
                    }
                    value={element.standardTestID}
                    name={element.standardName}
                  >
                    {element.testTitleName}
                  </Button>
                ))}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {examGradeValues.map((element, index) => (
                  <TextField
                    size="small"
                    readOnly="true"
                    sx={{
                      position: "relative",
                      width: 62,
                      ml: 1,
                      my: 1,
                      borderRadius: 2,
                      color: "Black",
                    }}
                    name={element.key1}
                    id={element.key1}
                    value={element.key1}
                    onChange={(event) => {
                      updateFieldChanged(event, index, "key1");
                    }}

                    // onChange={(e) => updateExamGradeValues(e.target.value)}
                  ></TextField>
                ))}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {examGradeValues.map((element, index) => (
                  <TextField
                    size="small"
                    sx={{
                      position: "relative",
                      width: 62,
                      ml: 1,
                      borderRadius: 2,
                      color: "Black",
                    }}
                    InputProps={{
                      inputProps: {
                        // min: 1,
                        max: 99,
                        inputMode: "numeric",
                        maxLength: 3,
                      },
                    }}
                    name={element.value}
                    id={element.value}
                    value={element.value}
                    onChange={(event) => {
                      updateFieldChanged(event, index, "value");
                    }}
                    onInput={(e) => onlyCharaters(e)}

                    // onChange={()=>updateFieldChanged(index)}
                  ></TextField>
                ))}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{ float: "left", left: 40, my: 2, borderRadius: "25px" }}
                  onClick={saveExamScores}
                  disabled={status == "true" ? true : false}
                >
                  {" "}
                  Save {} Marks
                </Button>
              </Box>
              <Typography
                sx={{
                  backgroundColor: "lightblue",
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {selectedStandardName.toUpperCase()} -{" "}
                {selectedSubjectName.toUpperCase()} - {testname} PREVIEW
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {testvalue.map((r) => (
                  <Box sx={{ display: "flex", flexDirection: "column", m: 2 }}>
                    <Typography variant="subtitle1" fontWeight={700}>
                      {r.standardTestkey}
                    </Typography>
                    <Typography variant="subtitle1">
                      {r.standardTestvalue}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2} sx={{ mt: 1, justifyContent: "flex-end" }}>
          <Grid item xs={3}>
            <Button
              size="small"
              variant="contained"
              sx={{
                position: "relative",
                // border: "2px solid",
                minWidth: "40%",
                borderRadius: "25px",
                height: 40,
                // color: "white",
                // backgroundColor: "black",
                // marginTop: 3,
                marginLeft: 3,
              }}
              onClick={onBack}

              // onClick={exams}
            >
              Previous Page
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              size="small"
              variant="contained"
              sx={{
                position: "relative",
                // border: "2px solid",
                minWidth: "40%",
                borderRadius: "25px",
                height: 40,
                // color: "white",
                // backgroundColor: "black",
                // marginTop: 3,
                marginLeft: 3,
              }}
              onClick={nextPage}
              disabled={status == "true" ? true : false}

              // onClick={exams}
            >
              Review and Freeze
            </Button>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              // onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <Typography
                    id="transition-modal-title"
                    variant="body1"
                    component="h2"
                  >
                    You have clicked on
                  </Typography>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    FREEZ INFORMATION
                  </Typography>
                  <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                    We request you to kindly review the information once again
                    and then freeze the data, as once its FREEZE can’t be
                    changed. Thank you
                  </Typography>
                  <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                      onClick={onBuildSchool}
                    >
                      FREEZE
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={handleClose}
                    >
                      Review
                    </Button>
                  </Stack>
                </Box>
              </Fade>
            </Modal>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="warning"
              // onClick={onPause}
              sx={{
                borderRadius: "25px",
              }}
            >
              Pause and Continue Setup Later
            </Button>
          </Grid>
        </Grid>
        <Modal
          open={openSucess}
          onClose={handleSucessClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div sx={style}>
            <SucessModal text={sucessVal} />
          </div>
        </Modal>
        <Modal
          open={openAlert}
          onClose={handleAlertClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div sx={style}>
            <AlertModal text={alertVal} />
          </div>
        </Modal>
        <Modal
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div sx={style}>
            <DeleteModal text={delVal} />
          </div>
        </Modal>
      </Box>
    </div>
  );
}
