import { Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { StudentAttendenanceService } from "../../../services/studentAttendance";

function AttendenceTotal() {
  useEffect(() => {
    onload();
  }, []);

  const [Agirl, setAgirl] = useState(0);
  const [Aboy, setAboy] = useState(0);
  const [totalAbsent, setTotalAbsent] = useState(0);

  const [Pgirl, setPgirl] = useState(0);
  const [Pboy, setPboy] = useState(0);
  const [totalPresent, setTotalPresent] = useState(0);

  const [Tgirl, setTgirl] = useState(0);
  const [Tboy, setTboy] = useState(0);
  const [total, setTotal] = useState(0);

  const onload = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    const currentDate = new Date();
    const iso8601Date = currentDate.toISOString().split("T")[0];
    const options = { month: "long" };
    const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);
    console.log(iso8601Date);

    try {
      let response = await StudentAttendenanceService.getTotalReport(
        iso8601Date,
        month,
        data.yearName
      );
      console.log(response.data.data);
      setAgirl(response.data.data.absentGirls);
      setAboy(response.data.data.absentBoys);
      setTotalAbsent(response.data.data.totalAbsent);
      setPgirl(response.data.data.presentGirls);
      setPboy(response.data.data.presentBoys);
      setTotalPresent(response.data.data.totalPresent);
      setTgirl(response.data.data.totalGirls);
      setTboy(response.data.data.totalBoys);
      setTotal(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Grid container spacing={0} sx={{ m: 2 }}>
        <Grid item xs={1.3}>
          <Stack direction="column" sx={{ marginX: 0.5 }}>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: "500",
                  fontSize: "17px",
                  backgroundColor: "lightgray",
                }}
              >
                GIRLS
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {Agirl}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={1.3}>
          <Stack direction="column" sx={{ marginX: 0.5 }}>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: "500",
                  fontSize: "17px",
                  backgroundColor: "lightgray",
                }}
              >
                BOYS
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {Aboy}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={1.3}>
          <Stack direction="column" sx={{ marginX: 0.5 }}>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: "500",
                  fontSize: "17px",
                  backgroundColor: "lightgray",
                }}
              >
                TOTAL ABSENT
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "18px",
                  color: "red",
                }}
              >
                {totalAbsent}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={1.3}>
          <Stack direction="column" sx={{ marginX: 0.5 }}>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: "500",
                  fontSize: "17px",
                  backgroundColor: "lightgray",
                }}
              >
                GIRLS
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {Pgirl}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={1.3}>
          <Stack direction="column" sx={{ marginX: 0.5 }}>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: "500",
                  fontSize: "17px",
                  backgroundColor: "lightgray",
                }}
              >
                BOYS
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {Pboy}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={1.3}>
          <Stack direction="column" sx={{ marginX: 0.5 }}>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: "500",
                  fontSize: "17px",
                  backgroundColor: "lightgray",
                }}
              >
                TOTAL PRESENT
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "18px",
                  color: "green",
                }}
              >
                {totalPresent}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={1.3}>
          <Stack direction="column" sx={{ marginX: 0.5 }}>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: "500",
                  fontSize: "17px",
                  backgroundColor: "lightgray",
                }}
              >
                GIRLS
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {Agirl + Pgirl}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={1.3}>
          <Stack direction="column" sx={{ marginX: 0.5 }}>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: "500",
                  fontSize: "17px",
                  backgroundColor: "lightgray",
                }}
              >
                BOYS
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {Aboy + Pboy}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={1.3}>
          <Stack direction="column" sx={{ marginX: 0.5 }}>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: "500",
                  fontSize: "17px",
                  backgroundColor: "lightgray",
                }}
              >
                TOTAL STRENGTH
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "18px",
                  color: "blue",
                }}
              >
                {total}
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
export default AttendenceTotal;
